/* global HTMLElement */

/*
Expected attributes for the ornament-ticket component:
<dynamic-title class="$-title" data-greeting="/title/ /surname/">
</dynamic-title>

Localized Traditional Chinese example:
<dynamic-title className="$-title" data-greeting="/surname//title/ ">
</dynamic-title>
*/

import UserData from '../../utils/UserData'
import { getUserProperties } from '../../segment/IdentityTracking'

export class DynamicTitle extends HTMLElement {
  static greetingAttr = 'data-greeting'
  static tierLabelsAttr = 'data-preferred-tier-labels'
  static titleVar = '/title/'
  static lastNameVar = '/surname/'

  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.connectedCallbackExecuted = true
      this.salutation = getUserProperties().title
      this.userLastName = getUserProperties().lastName
      this.formatGreeting()
      this.formatTier()
    }
  }

  formatGreeting() {
    let localizedGreeting = this.getAttribute(DynamicTitle.greetingAttr)
    this.greeting = `${localizedGreeting.replace(
      DynamicTitle.titleVar,
      this.salutation
    )}`
    this.greeting = `${this.greeting.replace(
      DynamicTitle.lastNameVar,
      this.userLastName
    )}`
    const dynamicText = document.createTextNode(this.greeting)
    this.querySelector('h2').appendChild(dynamicText)
  }

  async formatTier() {
    const userAccessTier = await UserData.accessTier()
    if (userAccessTier) {
      // If there's some mismatch between tiers in the cookie and tiers defined
      // in properties it shouldn't break the page
      try {
        const localizedTierLabels = this.getAttribute(
          DynamicTitle.tierLabelsAttr
        ).split('|')
        this.querySelector('h3').innerHTML =
          localizedTierLabels[userAccessTier - 1]
      } catch (ex) {
        console.warn(
          "There was a problem matching the user's cookie to the membership tier labels"
        )
      }
    }
  }
}
