import { getPageProperties } from './PageInteractions'
import {
  getCookie,
  createCookie,
  eraseCookie,
  getRootCookieDomain
} from '../utils/CookieJar'

const _parseJWT = token => {
  return JSON.parse(window.atob(token.split('.')[1]))
}

export const GLOB_COOKIE = 'globid'
export const VUID_COOKIE = 'vuid'
export const IDENTIFY_COOKIE = 'indentifyUser'

export const getUserProperties = (err = () => {}) => {
  let properties = {}
  try {
    if (!getCookie(GLOB_COOKIE)) {
      throw new Error('No user session found.')
    }
    let user = _parseJWT(getCookie(GLOB_COOKIE))
    let jwtKeyDataUrl = `${
      getPageProperties('data-page-properties')['jwt_key_prefix']
    }`
    let appMetadata = `${jwtKeyDataUrl}/appMetadata`
    let userMetadata = `${jwtKeyDataUrl}/userMetadata`
    properties = {
      auth0_id: user.sub,
      preferred_member_flag: user[appMetadata].customerData.preferred,
      customer_id: user[appMetadata].customerData.partyId,
      firstName: user[userMetadata].firstName,
      lastName: user[userMetadata].lastName,
      name: user[userMetadata].name,
      title: user[userMetadata].title,
      email: user.email
    }
  } catch (ex) {
    err(ex)
  }
  return properties
}

export const appendUserTrackingProperties = (properties = {}) => {
  properties = {
    ...properties,
    auth0_id: getUserProperties().auth0_id,
    preferred_member_flag: getUserProperties().preferred_member_flag,
    customer_id: getUserProperties().customer_id
  }
  if (properties.customer_id === 0) {
    delete properties.customer_id
  }

  return properties
}

export const clearIdentityCookie = () =>
  eraseCookie(IDENTIFY_COOKIE, getRootCookieDomain())

export const indentifyUser = (additionalProperties = {}, forceCall = false) => {
  const KNOWN = 'known'
  const UNKNOWN = 'unknown'
  let userId = ''
  let properties = {}
  if (getCookie(VUID_COOKIE)) {
    let parseCookie = getCookie(VUID_COOKIE)
    // if the VUID cookie contains %7C, that means a `|` is included
    // and we only want to include the string before it
    if (parseCookie.indexOf('%') > -1) {
      parseCookie = parseCookie.split('%')[0]
    }
    properties = {
      vuid_web: parseCookie
    }
  }
  const identityTracking = async status => {
    if (status === KNOWN) {
      userId = getUserProperties().auth0_id

      properties = {
        ...properties,
        preferred_member_flag: getUserProperties().preferred_member_flag,
        customer_id: getUserProperties().customer_id,
        email: getUserProperties().email
      }
      if (properties.customer_id === 0) {
        delete properties.customer_id
      }
    }

    if (typeof window.analytics !== 'undefined') {
      window.analytics.identify(userId, {
        ...properties,
        ...additionalProperties
      })
    }
  }

  const identityCookie = status => {
    createCookie(IDENTIFY_COOKIE, status, 'max', getRootCookieDomain())
    identityTracking(status)
  }

  if (!getCookie(IDENTIFY_COOKIE)) {
    if (getCookie(GLOB_COOKIE)) {
      identityCookie(KNOWN)
    } else if (getCookie(VUID_COOKIE)) {
      // only setting unknown cookie and using 'indentify()' method from Segment
      // if we have access to the vuid cookie being set by Zaius
      identityCookie(UNKNOWN)
    } else if (forceCall) {
      identityTracking(UNKNOWN)
    }
  } else if (getCookie(IDENTIFY_COOKIE) === UNKNOWN && getCookie(GLOB_COOKIE)) {
    identityCookie(KNOWN)
  } else if (forceCall) {
    if (getCookie(GLOB_COOKIE)) {
      identityTracking(KNOWN)
    } else {
      identityTracking(UNKNOWN)
    }
  } else {
    identityTracking(UNKNOWN)
  }
}
