import { EventTracking } from './PageInteractions'
import { throttle } from '../utils/Throttle'
import { IsInViewport } from '../utils/IsInViewport'

/**
 * Helper class for storing chapter DOM Elements
 *
 * Chapter is identified by first chapter module with title.
 */
class Chapter {
  constructor(element) {
    this.children = []

    this.appendChild(element)
    this.title = element.querySelector('.ChapterModule-title').innerText
  }

  querySelectorAll(selector) {
    return this.children.reduce((acc, curr) => {
      acc.push(...curr.querySelectorAll(selector))
      return acc
    }, [])
  }

  querySelector(selector) {
    return this.children.map(child => child.querySelector(selector))[0]
  }

  appendChild(element) {
    if (
      typeof element.querySelectorAll === 'function' &&
      typeof element.querySelector === 'function'
    ) {
      this.children.push(element)
    } else {
      console.warn(
        'Cannot add element without query selector functions',
        element
      )
    }
  }

  contains(element) {
    return this.children.some(child => child.contains(element))
  }
}

/**
 * Implements tracking for Rich Media Pages
 */
export class RichMediaTracking {
  static selectors = {
    el: '.RichMediaPage'
  }

  constructor(el) {
    this.el = el
    this.currentChapter = ''

    /* ----- Set Event Listeners ----- */
    this.chapterNavigationClickedEvents()
    this.chapterViewedEvents()
    this.viewOpenedEvents()
    this.audioPlaybackStartedEvents()
    this.audioPlaybackPausedEvents()
  }

  /**
   * Chapter Navigation Clicked
   * Trigger when user chooses chapter via navigation header or hero section.
   * properties:
   * - chapter_title
   * - navigation_level
   *    - 'Rich Media Header' if user clicks chapter in header nav,
   *      or 'Rich Media Hero' if the user clicks Chapter in the hero section
   */
  chapterNavigationClickedEvents() {
    const createEventListener = (chapterTitle, navigationLevel) => () => {
      EventTracking('Chapter Navigation Clicked', {
        chapter_title: chapterTitle,
        navigation_level: navigationLevel
      })
    }

    this.headerChapterLinks.map(chapter => {
      chapter.element.addEventListener(
        'click',
        createEventListener(chapter.title, 'Rich Media Header')
      )
    })

    this.heroChapterLinks.map(chapter => {
      chapter.element.addEventListener(
        'click',
        createEventListener(chapter.title, 'Rich Media Hero')
      )
    })
  }

  /**
   * Chapter Viewed
   * When user views a chapter, i.e. as soon as a chapter's content
   * is visible on screen to the user.
   * properties:
   * - chapter_title
   */
  chapterViewedEvents() {
    const chapters = this.chapters

    window.addEventListener(
      'scroll',
      throttle(2100, () => {
        chapters
          .filter(chapter => IsInViewport(chapter.children[0]))
          .map(chapter => {
            if (this.currentChapter !== chapter.title) {
              EventTracking('Chapter Viewed', { chapter_title: chapter.title })
              this.currentChapter = chapter.title
            }
          })
      })
    )
  }

  /**
   * 360 View Opened
   * When user opens 360 view module.
   * properties:
   * - chapter_title
   */
  viewOpenedEvents() {
    document.addEventListener('fullscreenchange', event => {
      if (
        document.fullscreenElement /* Standard syntax */ ||
        document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
        document.mozFullScreenElement /* Firefox syntax */ ||
        document.msFullscreenElement /* IE/Edge syntax */
      ) {
        this.chapters
          .filter(chapter => chapter.contains(event.target))
          .map(chapter =>
            EventTracking('360 View Opened', { chapter_title: chapter.title })
          )
      }
    })
  }

  /**
   * Audio Playback Started
   * properties:
   * - chapter_title
   */
  audioPlaybackStartedEvents() {
    this.audioPlayers.map(audio => {
      audio.element.addEventListener('play', () =>
        EventTracking('Audio Playback Started', {
          chapter_title: audio.title
        })
      )
    })
  }

  /**
   * Audio Playback Paused
   * properties:
   * - chapter_title
   */
  audioPlaybackPausedEvents() {
    this.audioPlayers.map(audio => {
      audio.element.addEventListener('pause', () =>
        EventTracking('Audio Playback Paused', {
          chapter_title: audio.title
        })
      )
    })
  }

  /* ----- setters / getters ----- */

  get headerChapterLinks() {
    const nav = this.el.querySelector('.RichMediaPage-stickyHeader-chapters')

    if (!nav) {
      return []
    }

    return [...nav.querySelectorAll('.ChapterNavigationLink')]
      .filter(link => !!link) // is not null
      .map(link => ({ element: link, title: link.innerText }))
  }

  get heroChapterLinks() {
    const hero = this.el.querySelector('.RichMediaPage-chapterNavigation')

    if (!hero) {
      return []
    }

    return [...hero.querySelectorAll('.ChapterNavigationLink')]
      .filter(link => !!link) // is not null
      .map(link => ({ element: link, title: link.innerText }))
  }

  get chapters() {
    return [...this.el.querySelectorAll('.ChapterModule')].reduce(
      (acc, cur) => {
        if (cur.querySelector('.ChapterModule-title')) {
          const chapter = new Chapter(cur)
          acc.push(chapter)
        } else if (acc.length > 0) {
          acc[acc.length - 1].appendChild(cur)
        }

        return acc
      },
      []
    )
  }

  get audioPlayers() {
    // Memoize audio players to not calculate when called again.
    // If audio players are added after this is called, this logic will
    // need to be changed (will need to trigger set this._audioPlayers null)
    if (!this._audioPlayers) {
      this._audioPlayers = []

      this.chapters.forEach(chapter => {
        chapter
          .querySelectorAll('audio')
          .map(audioElement => ({
            element: audioElement,
            title: chapter.title
          }))
          .map(audio => this._audioPlayers.push(audio))
      })
    }

    return this._audioPlayers
  }
}
