/* eslint-disable */
import { throttle } from '../../utils/Throttle'
import { progressBarStart } from './HeroProgressBar'
import shave from 'shave'
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import MediaQueries from '../../utils/MediaQueries'
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'
import { accessTier } from '../../utils/UserData'
import { getUserProperties } from '../../segment/IdentityTracking'

const mq = new MediaQueries().mq
const heroVersionName = 'Nav Slide'

export class HeroNavSlider {
  constructor(el) {
    FixFlickity()

    this.element = el
    this.parent = this.element.parentNode
    this.cssSlideName = 'HeroNavSlide'
    this.slideList = this.element.querySelectorAll(`.${this.cssSlideName}`)
    this.infoCard = this.parent.querySelector('[data-hero-info-card]')
    this.count = this.slideList.length
    this.isPaused = false
    this.progressInterval = 6000
    this.progressTimer = ''
    this.truncateMaxHeight = 60
    this.truncateMaxTitleHeight = 60
    this.truncateMaxTitleHeightTablet = 80
    this.truncateMaxTitleHeightDesktop = 90

    // Only initialize the carousel if there is more than one slide
    if (this.count > 1) {
      this.initCarousel()
    }
  }

  initCarousel() {
    this.slideList.forEach((slide, i) => {
      let infoWrapper = slide.querySelector(
        `.${this.cssSlideName}-info-wrapper`
      )
      if (!this.infoCard.querySelector('[data-slide-ref="' + i + '"]')) {
        let progressWrap = document.createElement('div')
        let progressBar = document.createElement('div')

        progressWrap.classList.add(this.cssSlideName + '-progress')
        progressBar.style.width = 0
        progressWrap.appendChild(progressBar)
        infoWrapper.prepend(progressWrap)

        let clone = infoWrapper.cloneNode(true)
        clone.setAttribute('data-slide-ref', i)
        this.infoCard.appendChild(clone)
      }
    })

    this.infoCardList = this.infoCard.querySelectorAll('[data-slide-ref]')

    this.carousel = new Flickity(this.element, {
      contain: true,
      pageDots: false,
      prevNextButtons: true,
      autoPlay: false,
      wrapAround: true,
      on: {
        ready: () => {
          let activeSlide = this.infoCardList[0]
          this.infoCardList[0].classList.add('active')
          progressBarStart(this, activeSlide)
          this.implementTracking(
            this.infoCard,
            '[data-slide-ref]',
            'data-slide-ref'
          )
        }
      }
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })

    this.nav = new Flickity(this.infoCard, {
      asNavFor: '[data-hero-nav-slider]',
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      autoPlay: false,
      wrapAround: false,
      cellAlign: 'left'
    })

    this.carousel.on('change', () => {
      clearInterval(this.progressTimer)
      let activeSlide = this.infoCardList[this.carousel.selectedIndex]

      this.infoCardList.forEach((card, i) => {
        card.classList.remove('active')
      })

      this.infoCardList[this.carousel.selectedIndex].classList.add('active')
      progressBarStart(this, activeSlide)
    })

    this.parent.addEventListener('mouseover', e => {
      if (
        e.target.closest('[class*="-items-item"].is-selected') ||
        e.target.closest('[data-hero-info-card]')
      ) {
        this.isPaused = true
      }
    })

    this.parent.addEventListener('mouseout', e => {
      if (
        e.target.closest('[class*="-items-item"].is-selected') ||
        e.target.closest('[data-hero-info-card]')
      ) {
        this.isPaused = false
      }
    })

    this.infoCardList.forEach((card, i) => {
      window.setTimeout(() => {
        this.truncateTexts(card)

        if (this.infoCardList.length === i + 1) {
          window.dispatchEvent(new window.Event('resize'))
        }
      }, 500)

      // need to add this to maintain truncation at different breakpoints
      window.addEventListener(
        'resize',
        throttle(500, () => {
          this.truncateTexts(card)
        })
      )
    })
  }

  /**
   * When you clone a Node its event listeners do not get copied as well
   * Therefore tracking needs to be implemented after the node is copied to the cloned node itself
   *
   * @param  {object}   node The DOM node that will receive the event listeners
   * @param  {string}   parentNode The parent data-attr or class to the DOM node that contains a list
   * @param  {string}   parentNodeAttr The attribute on the parent node that keeps track of the current Index
   */
  implementTracking(node, parentNode, parentNodeAttr) {
    if (typeof analytics !== 'undefined') {
      const heroLinks = node.querySelectorAll(
        '.HeroNavSlide-info-title, .HeroNavSlide-info-ctaText'
      )

      const heroImages = this.element.querySelectorAll('.Image')
      heroLinks.forEach(link => {
        let currentIndex = link.closest(parentNode).getAttribute(parentNodeAttr)
        let heroProps = {
          homepage_featuredtext: link.innerText,
          hero_version: heroVersionName
        }

        if (heroImages[currentIndex].getAttribute('alt') !== '') {
          heroProps.homepage_imagename = heroImages[currentIndex].getAttribute(
            'alt'
          )
        }

        link.addEventListener('click', () => {
          const auth0Id = getPageProperties('data-page-properties')[
            'auth0ClientID'
          ]
          const tier = !accessTier ? 0 : accessTier
          const customerId = getUserProperties().customer_id || ''

          let cardType = 'hero'
          const card = node.querySelector('.active[data-type]')
          if (card) {
            cardType = card.getAttribute('data-type')
          }

          const props = {
            ...heroProps,
            card_type: cardType,
            carousel_style: heroVersionName,
            carousel_title: '',
            card_title: link.innerText,
            auth0_id: auth0Id,
            preferred_member_flag: tier,
            customer_id: customerId
          }

          EventTracking('Click Action', props)
        })
      })
    }
  }

  truncateTexts(el) {
    let title = el.querySelector('[class$="-title"]')
    if (mq['mq-viewport-lg2'].matches) {
      shave(title, this.truncateMaxTitleHeightDesktop)
    } else if (mq['mq-viewport-md'].matches) {
      shave(title, this.truncateMaxTitleHeightTablet)
    } else {
      shave(title, this.truncateMaxTitleHeight)
    }
  }
}
