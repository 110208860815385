/* eslint-disable no-new, no-unused-vars */
import { VideoPlayer } from './VideoPlayer'
import { EventTracking } from '../../segment/PageInteractions'
import { CardTitleTruncate } from '../../core/promo/CardTitleTruncate'
import { Tracker } from '../../utils/Events'
import { IsInViewportOverflow } from '../../utils/IsInViewport'
import { throttle } from '../../utils/Throttle'

/**
 * Brightcove Video Player. The Brightcove player uses VideoJS behind the scenes. Even though it uses
 * native "video" HTML tag in the .hbs, it runs it own's JS to re-create this tag with the proper src
 * values and then adds VideoJS on top of that. Because of that we re-load the Brightcove API on every
 * connectedCallback instead of checking to see if it exists. That API has no "reload" functionality and
 * instead only scans the page for the brightcove "video" tags when it's loaded. VideoJS complains about
 * this in the console, but until Brightcove fixes that, there is nothing we can do.
 */

export class BrightcoveRedactedPlayer extends VideoPlayer {
  playing = false
  player = null
  hasStarted = false

  static showEndCard(endCard) {
    endCard.setAttribute('data-show', true)
    endCard.querySelectorAll('.EndCard').forEach((card, i) => {
      const cardTruncate = new CardTitleTruncate(card)
    })
  }

  static hideEndCard(endCard) {
    endCard.removeAttribute('data-show')
  }

  connectedCallback() {
    super.init()
    this.listenerTracker = new Tracker()
    this.videoPlaylist = this.closest('ps-video-playlist')
    this.hasOverlay = this.closest('[data-has-video-overlay]') ? true : false

    // The following attributes are needed for the brightcove JS to load the proper JS/CSS for their player
    if (this.parentElement.getAttribute('data-brightspot-id') !== null) {
      this.player = this.querySelector('video')
      this.redact = this.parentElement.getAttribute('data-redact-content')
      this.videoTitle = this.parentElement.getAttribute('data-video-title')
      this.isAutoplay = this.parentElement.getAttribute('data-video-autoplay')
      this.hideControls = this.parentElement.getAttribute(
        'data-video-hidecontrols'
      )

      if (this.redact === 'false') {
        this.initializeDataAttributes(
          this.parentElement.getAttribute('data-brightspot-id')
        )
      } else {
        this.verify(this.parentElement.getAttribute('data-brightspot-id'))
      }
    }
  }

  initializeDataAttributes(videoId) {
    this.accountId = this.getAttribute('data-account') || false
    this.playerId = this.getAttribute('data-player') || false
    this.videoId = videoId || false

    if (this.accountId && this.playerId && this.videoId) {
      this.init()
    } else {
      // show sunscription module
      this.setAttribute('data-fully-loaded', 'false')
      console.error(
        'Brightcove Video Player: Cannot play video, no account, player or video ID found'
      )
      return false
    }
  }

  verify(brightspotId) {
    let action = '/bsp-api/redacted-video-id?videoId=' + brightspotId

    window
      .fetch(action, { credentials: 'include', mode: 'cors' })
      .then(response => {
        return response.text()
      })
      .then(data => {
        if (data) {
          try {
            const jsonData = JSON.parse(data)
            if (jsonData) {
              this.videoId = jsonData.brightcoveId || false
            } else {
              this.videoId = false
              console.error('Failed to process the Brightcove ID request')
            }
          } catch (e) {
            this.videoId = false
            console.error('Process returned with invalid JSON')
          }
        }

        if (!this.videoId) {
          this.videoId = this.getAttribute('preview-id')
          this.preview = true
        }
        this.initializeDataAttributes(this.videoId)
      })
  }

  // If we get removed, remove the current player from videoJS to prevent a bunch of unused players
  disconnectedCallback() {
    const redactPlayers = () => {
      if (window.videojs.getPlayers()['brightcove-video-' + this.videoId]) {
        delete window.videojs.getPlayers()['brightcove-video-' + this.videoId]
      }
    }
    if (window.videojs) {
      redactPlayers()
    } else {
      window.addEventListener('load', redactPlayers)
    }
    this.listenerTracker.removeListeners()
  }

  // Load the Brightcove API every time, as the page needs to be rescanned for their video tags :(
  // and also remove old listeners that were set from player being replaced (if on a playlist page)
  init() {
    this.listenerTracker.removeListeners()
    this.loadBrightcoveApi(this.accountId, this.playerId)
  }

  // Once the Brightcove API is loaded, we tie into the videoJS "loadedmetadata" event to kick off
  // our event binding. This is the recommended event per their documentation
  onBrightcoveAPIReady() {
    let self = this
    let options = {
      controlBar: {
        volumePanel: {
          inline: false,
          vertical: true
        }
      }
    }

    this.videoPlayer = this.querySelector('video')
    if (!this.hideControls) {
      this.player.setAttribute('controls', '')
    }
    // Set the brightcove videoId on the player
    this.player.setAttribute('id', 'brightcove-video-' + this.videoId)
    this.player.setAttribute('data-video-id', this.videoId)
    this.player.setAttribute('data-player', this.playerId)
    this.player.setAttribute('data-account', this.accountId)

    let brightcovePlayer = window.bc(this.videoPlayer, options)

    brightcovePlayer.on('loadedmetadata', function() {
      self.thePlayer = this
      self.scrollControl = self.scrollControl.bind(self)
      self.tieIntoEvents()
      self.addElements(self)
      self.setUpEndCards(self)
      self.setAttribute('data-fully-loaded', 'true')
      self.shareTracking(self)
      if (self.isScrollControlAllowed) {
        self.scrollControl()
      }

      if (self.hasOverlay) {
        self.setOverlayTrigger()
      }

      if (self.isScrollControlAllowed) {
        self.listenerTracker.addListener(
          window,
          'scroll',
          throttle(500, () => {
            self.scrollControl()
          }).bind(self)
        )
      }
    })
  }

  scrollControl() {
    if (
      this.videoPlaylist === null &&
      IsInViewportOverflow(this, 100, 100) &&
      this.hasStarted === false &&
      this.hasOverlay === false &&
      this.isAutoplay === true
    ) {
      this.thePlayer.play()
    }

    if (
      this.videoPlaylist === null &&
      !IsInViewportOverflow(this, 100, 100) &&
      this.playing === true
    ) {
      this.thePlayer.pause()
    }
  }

  setOverlayTrigger() {
    let overlayParent = this.closest('[data-has-video-overlay]')
    let overlayEl = overlayParent.querySelector('[data-video-overlay]')
    this.thePlayer.pause()
    overlayEl.classList.remove('player-on')
    let autoPlay = this.querySelector('.BrightcoveRedactedPlayer > div')
    let video = this.querySelector('.BrightcoveRedactedPlayer video')
    autoPlay.setAttribute('autoplay', false)
    autoPlay.classList.remove('vjs-has-started')
    video.removeAttribute('autoplay')

    overlayEl.classList.add('player-ready')
    this.listenerTracker.addListener(overlayEl, 'click', () => {
      this.thePlayer.play()
      overlayEl.classList.add('player-on')
      autoPlay.classList.add('vjs-has-started')
    })
  }

  addElements(player) {
    let seekBackEl = document.createElement('div')
    let seekForwardEl = document.createElement('div')
    let titleEl = document.createElement('div')
    let fullScreenEl = document.createElement('div')
    let controlBar = player.querySelector('.vjs-control-bar')
    let insertBeforeNode = player.querySelector('.vjs-volume-panel')
    let shareBtn = player.querySelector('.vjs-share-control')

    seekBackEl.className = 'seek-btn seek-backwords vjs-control'
    seekForwardEl.className = 'seek-btn seek-forwards vjs-control'
    fullScreenEl.className = 'fullscreen-overlay-btn'
    titleEl.className = 'BrightcoveRedactedPlayer-title'
    titleEl.innerHTML += this.videoTitle

    controlBar.insertBefore(seekBackEl, insertBeforeNode)
    controlBar.insertBefore(seekForwardEl, insertBeforeNode)
    player.appendChild(titleEl)
    player.appendChild(fullScreenEl)

    this.listenerTracker.addListener(
      seekBackEl,
      'click',
      this.seekBackwords.bind(this)
    )
    this.listenerTracker.addListener(
      seekForwardEl,
      'click',
      this.seekForwards.bind(this)
    )
    this.listenerTracker.addListener(
      fullScreenEl,
      'click',
      this.fullScreen.bind(this)
    )
    this.listenerTracker.addListener(
      shareBtn,
      'click',
      this.shareEvents.bind(this)
    )
  }

  shareTracking = link => event => {
    EventTracking(`Video Shared`, this.getVideoProps('external_link', link))
  }

  shareEvents() {
    // If the player wrapper has data-redact-content='true' we know it's a premium video.
    // The share code gets generated on each click of the share button, so we remove it each time
    if (
      this.parentElement.getAttribute('data-redact-content') === 'true' &&
      this.querySelector('.vjs-social-embed-code')
    ) {
      let embedInput = this.querySelector('.vjs-social-embed-code')
      embedInput.parentNode.removeChild(embedInput)
    }

    this.querySelectorAll('.vjs-social-share-links > a').forEach(shareLink => {
      const shareLinkTitle = shareLink.getAttribute('href')
      this.listenerTracker.addListener(
        shareLink,
        'click',
        this.shareTracking(shareLinkTitle)
      )
    })
  }

  fullScreen() {
    var eventClick = new window.Event('click')
    this.querySelector('.vjs-fullscreen-control').dispatchEvent(eventClick)
  }

  seekBackwords() {
    let currentTime = this.thePlayer.currentTime()
    this.thePlayer.currentTime(currentTime - 15)
    EventTracking(
      `Video Playback Seek Completed`,
      this.getVideoProps('seek_length', -15)
    )
  }

  seekForwards() {
    let currentTime = this.thePlayer.currentTime()
    this.thePlayer.currentTime(currentTime + 30)
    EventTracking(
      `Video Playback Seek Completed`,
      this.getVideoProps('seek_length', 30)
    )
  }

  getVideoDuration() {
    return parseInt(this.video.duration)
  }

  loadBrightcoveApi(accountId, playerId) {
    let tag = document.createElement('script')
    tag.src =
      'https://players.brightcove.net/' +
      accountId +
      '/' +
      playerId +
      '_default/index.min.js'
    this.listenerTracker.addListener(
      tag,
      'load',
      this.onBrightcoveAPIReady.bind(this)
    )

    let firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }

  // Here we tie into all the Brightcove based APIs. We are using the "on" syntax of the Brightcove events
  // vs native "addEventListener" of the native video tag, as brightcove does some special stuff with
  // timeupdate that the addEventListeners do not capture
  tieIntoEvents() {
    super.onVideoReady()

    this.thePlayer.on('playing', event => {
      this.onPlayerPlay(event)
    })

    this.thePlayer.on('pause', event => {
      this.onPlayerPause(event)
    })

    this.thePlayer.on('timeupdate', event => {
      this.onPlayerTimeUpdate(event)
    })

    this.thePlayer.on('ended', event => {
      this.onPlayerEnd(event)
    })

    this.listenerTracker.addListener(
      this,
      'Video:onVideoQuartileCompleted',
      this.trackPercentiles
    )
  }

  setUpEndCards(player) {
    let tempEndCardList = null
    let vidWrapper = player.closest('.VideoPlayerWrapper')
    if (vidWrapper) {
      this.playerWrapper = player.closest('.VideoPlayerWrapper')
      tempEndCardList = this.playerWrapper.querySelector('[data-end-card-list]')
    }

    if (tempEndCardList !== null) {
      let newEndCardList = tempEndCardList.cloneNode(true)
      player.querySelector('[data-end-card]').appendChild(newEndCardList)
    }
  }

  getVideoProps(extraKey, extraValue) {
    let videoProps = {
      video_player: 'Brightcove'
    }

    if (typeof extraKey !== 'undefined' && typeof extraValue !== 'undefined') {
      videoProps[extraKey] = extraValue
    }

    if (this.videoTitle !== null) {
      videoProps.video_title = this.videoTitle
    }

    if (!this.thePlayer.muted()) {
      videoProps.sound = Math.ceil(this.thePlayer.volume() / 0.01) * 1
    } else {
      videoProps.sound = 0
    }

    if (this.querySelector('.vjs-fullscreen') === null) {
      videoProps.full_screen = false
    } else {
      videoProps.full_screen = true
    }

    if (this.querySelector('.vjs-captions-menu-item.vjs-selected') !== null) {
      let captions = this.querySelector(
        '.vjs-captions-menu-item.vjs-selected > .vjs-menu-item-text'
      ).textContent
      videoProps.closed_captions = captions.split(/\n/)[0]
    } else {
      videoProps.closed_captions = 'none'
    }

    // const qualityObj = this.thePlayer.qualityLevels()
    // const levels = qualityObj.levels_
    // const selectedQualityIndex = qualityObj.selectedIndex_

    // if (typeof levels[selectedQualityIndex] !== 'undefined') {
    //   videoProps.quality = levels[selectedQualityIndex].height + 'p'
    videoProps.position = Math.round(this.thePlayer.currentTime())
    videoProps.total_length = this.getVideoDuration()
    // }

    // if Player is in a playlist page, check which playlist is showing
    if (
      this.videoPlaylist !== null &&
      this.videoPlaylist.querySelector(
        '.TabsPlaylist-tab[data-active="true"]'
      ) !== null
    ) {
      const activeTab = this.videoPlaylist.querySelector(
        '.TabsPlaylist-tab[data-active="true"]'
      )
      videoProps.playlist_display_name = activeTab.textContent.trim()

      if (
        activeTab.getAttribute('data-internal-name') !== null &&
        activeTab.getAttribute('data-internal-name') !== ''
      ) {
        videoProps.playlist_internal_name = activeTab.getAttribute(
          'data-internal-name'
        )
      }
    }

    // the player wrapper contains all the tracking needed from the backend, so if it exists
    // combine it with the other video props
    let videoTrackingProps = this.parentElement.getAttribute(
      'data-video-tracking-props'
    )
    if (videoTrackingProps !== null && videoTrackingProps !== '') {
      videoTrackingProps = JSON.parse(videoTrackingProps)
      videoProps = {
        ...videoTrackingProps,
        ...videoProps
      }
    }

    return videoProps
    // NOTE: Will need to account for redacted player tracking in city guides as
    // that player doesn't have a title or topic
  }

  trackPercentiles = e => {
    EventTracking(
      `Video ${
        e.detail.quartile === 100
          ? 'Playback Completed'
          : 'Watched ' + e.detail.quartile
      }`,
      this.getVideoProps()
    )
  }

  onPlayerPlay(event) {
    if (window.nextVideoTimer !== 'undefined') {
      clearInterval(window.nextVideoTimer)
    }

    if (this.preview) {
      this.setAttribute('data-fully-loaded', 'true')
    }

    if (
      this.querySelector(
        '.BrightcoveRedactedPlayer-nextContainer-timer'
      ).hasAttribute('data-hide')
    ) {
      this.querySelector(
        '.BrightcoveRedactedPlayer-nextContainer-timer'
      ).removeAttribute('data-hide')
    }

    if (
      this.querySelector('[data-end-card]') !== null &&
      this.querySelector('[data-end-card]').hasAttribute('data-show')
    ) {
      BrightcoveRedactedPlayer.hideEndCard(
        this.querySelector('[data-end-card]')
      )
    }

    if (
      this.querySelector('[data-next-video]') !== null &&
      this.querySelector('[data-next-video]').hasAttribute('data-show')
    ) {
      BrightcoveRedactedPlayer.hideEndCard(
        this.querySelector('[data-next-video]')
      )
    }

    if (!this.playing) {
      // to prevent multiple start events when seeking/buffering
      this.hasStarted = true
      this.playing = true
      super.onVideoStart(event)

      EventTracking(`Video Playback Started`, this.getVideoProps())
    } else {
      super.onVideoPlay(event)
      EventTracking(`Video Playback Resumed`, this.getVideoProps())
    }
  }

  onPlayerPause(event) {
    if (this.preview) {
      this.setAttribute('data-fully-loaded', 'false')
    }

    super.onVideoPause(event)
    EventTracking(`Video Playback Paused`, this.getVideoProps())
  }

  onPlayerTimeUpdate(event) {
    super.onVideoTimeUpdate({
      secondsElapsed: this.thePlayer.currentTime()
    })
  }

  onPlayerEnd(event) {
    this.playing = false
    super.onVideoEnd(event)

    if (this.preview) {
      this.reloadVideo()
    } else {
      // if not in a playlist
      if (
        this.videoPlaylist === null &&
        this.querySelector('[data-end-card]') !== null
      ) {
        BrightcoveRedactedPlayer.showEndCard(
          this.querySelector('[data-end-card]')
        )
      }
    }
  }

  reloadVideo() {
    this.setAttribute('data-fully-loaded', 'false')
    this.thePlayer.autoplay(false)
    this.thePlayer.hasStarted(false)
    this.thePlayer.trigger('loadstart')
  }

  // Implementing play/pause based on Brightcove's API
  play() {
    this.thePlayer.play()
  }

  pause() {
    this.thePlayer.pause()
  }

  get isScrollControlAllowed() {
    return this.hasAttribute('data-scroll-control')
  }
}
