import UserDataUnverified from '../../utils/UserDataUnverified'
import { EventTracking } from '../../segment/PageInteractions'
import SyndicationUtils from '../../utils/SyndicationUtils'

export class PageCTAModule {
  constructor(el) {
    this.el = el
    this.titleEl = this.el.querySelector('[class="Page-cta-title"]')
    this.placementData =
      JSON.parse(document.body.getAttribute('data-tracking') || '{}') || {}

    if (!UserDataUnverified.meetsAuthPrecondition()) {
      el.style.display = 'flex'

      if (
        typeof window.analytics !== 'undefined' ||
        SyndicationUtils.isIframe
      ) {
        const ctaLink = this.el.querySelector('a')
        ctaLink.addEventListener('click', e => {
          EventTracking('Login Button Clicked', {
            login_context: this.titleEl ? this.titleEl.innerText.trim() : '',
            placement:
              'content_type' in this.placementData
                ? this.placementData.content_type.trim()
                : ''
          })
        })
      }
    }
  }
}
