import { EventTracking } from './PageInteractions'

export class TravexTracking {
  constructor(el) {
    const eventLink = el.querySelector('.TravexLocation-eventDateRange')
    eventLink.addEventListener('click', () => {
      const eventName = el.querySelector('.TravexLocation-eventName')
      const eventLocationName = el.querySelector(
        '.TravexLocation-eventLocationName'
      )
      EventTracking('Add Calendar Event', {
        travex_event_name: eventName,
        travex_event_location_name: eventLocationName
      })
    })
  }
}
