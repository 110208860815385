/* global HTMLElement */
import { EventTracking } from '../../segment/PageInteractions'

export class TabsPlaylist extends HTMLElement {
  static visibleAttr = 'data-visible'
  static activeAttr = 'data-active'

  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.connectedCallbackExecuted = true
      this.playlistTabs = this.querySelectorAll('[data-tab]')
      this.playlistTabsPanel = this.querySelectorAll('[data-tab-panel]')
      this.init()
    }
  }

  init() {
    this.playlistTabs.forEach(tab => {
      tab.addEventListener('click', this.showTab.bind(this))
    })
  }

  setPanelsAndTabs() {
    this.playlistTabsPanel.forEach(panel => {
      panel.setAttribute(TabsPlaylist.visibleAttr, 'false')
    })

    this.playlistTabs.forEach(tab => {
      tab.setAttribute(TabsPlaylist.activeAttr, 'false')
    })
  }

  showTab(e) {
    if (e.currentTarget.getAttribute(TabsPlaylist.activeAttr) !== 'true') {
      const currentTab = e.currentTarget
      const id = currentTab.getAttribute('data-tab-id')
      const correspondingPanel = this.querySelector(`[data-panel-id='${id}']`)

      this.setPanelsAndTabs()
      currentTab.setAttribute(TabsPlaylist.activeAttr, 'true')
      correspondingPanel.setAttribute(TabsPlaylist.visibleAttr, 'true')

      EventTracking(`Click Action`, {
        playlist_display_name: currentTab.textContent.trim(),
        video_player: 'Brightcove'
      })
    }
  }
}
