const _getBodyJsonAttribute = attributeName => {
  // remove trailing commas
  let propsString = (document.body.getAttribute(attributeName) || '{}').replace(
    /,[\s]*}$/g,
    '}'
  )
  try {
    return JSON.parse(propsString)
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.warn(`"${propsString}" is not valid JSON`)
    } else {
      console.error('Failed to fetch page properties.', e)
    }
    return {}
  }
}
const _getPageProperties = () => _getBodyJsonAttribute('data-page-properties')
const _getTrackingProperties = () => _getBodyJsonAttribute('data-tracking')

export const getCustomerApiEndpoint = () =>
  _getPageProperties().vikingCustomerApi
export const getPageTrackingName = () => _getTrackingProperties().name

export const locales = {
  en: {
    englishName: 'English'
  },
  fr: {
    englishName: 'French'
  },
  de: {
    englishName: 'German'
  },
  ru: {
    englishName: 'Russian'
  },
  it: {
    englishName: 'Italian'
  },
  es: {
    englishName: 'Spanish'
  },
  zh: {
    englishName: 'Chinese'
  },
  'zh-Hant': {
    englishName: 'Traditional Chinese'
  },
  'zh-Hans': {
    englishName: 'Simplified Chinese'
  }
}

export const getPageLanguageName = () => {
  const documentLocale = document.documentElement.getAttribute('lang')

  // try first locales map of languages
  if (documentLocale) {
    const locale = locales[documentLocale]
    if (locale) {
      return locale.englishName
    }
  }

  // fall back to tag passed in data-tracking
  return _getTrackingProperties().language
}

export const getPageHeight = () =>
  document.height !== undefined ? document.height : document.body.offsetHeight
