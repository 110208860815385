import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'

export class HeroLedeCarousel {
  constructor(el) {
    FixFlickity()

    this.element = el
    let count = document.getElementsByClassName('HeroLede').length
    // Only initialize the carousel if there is more than one slide
    if (count > 1) {
      this.initCarousel()
    }
  }

  initCarousel() {
    this.carousel = new Flickity(this.element, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: true,
      prevNextButtons: true,
      autoPlay: 3000,
      wrapAround: true
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })

    this.element.addEventListener(
      'focus',
      () => {
        this.carousel.pausePlayer()
      },
      true
    )

    this.element.addEventListener(
      'blur',
      () => {
        setTimeout(() => {
          if (!this.element.contains(document.activeElement))
            this.carousel.unpausePlayer()
        }, 10)
      },
      true
    )

    /* fix flickity's broken aria by removing it */
    const fixAria = (selected = 0) => {
      this.element.removeAttribute('tabIndex')

      for (const pres of this.element.querySelectorAll(
        '.flickity-viewport, .flickity-slider'
      )) {
        pres.setAttribute('role', 'presentation')
      }

      this.element
        .querySelector('.flickity-page-dots')
        .setAttribute('aria-hidden', 'true')

      let current = 0

      for (const cell of this.carousel.cells) {
        cell.element.removeAttribute('aria-hidden')

        cell.element.setAttribute(
          'aria-label',
          `${current + 1} of ${this.carousel.cells.length}`
        )

        cell.element.removeAttribute('tabIndex')

        const tabIndex = current === selected ? '0' : '-1'

        for (const a of cell.element.querySelectorAll('a')) {
          a.setAttribute('tabIndex', tabIndex)
        }

        cell.element.setAttribute('tabIndex', tabIndex)

        current++
      }
    }

    this.carousel.on('settle', fixAria)

    fixAria()

    for (const dot of this.element.querySelectorAll('.dot')) {
      /* these are generated by flickity, so we can't change them to buttons */
      const button = document.createElement('button')

      button.addEventListener('click', e => {
        e.target.parentNode.click()
      })

      dot.append(button)
    }
  }
}
