/* global HTMLElement */

/*
Expected attributes for the ornament-ticket component:
<ornament-ticket class="Ornament" data-ornament="tickets" ticket-text="Tickets Required"
  expiration-date="1539783000186" day-left-text="1 Day Left" week-left-text="1 Week Left"
  days-left-text="# Days Left" expired-text="Expired">
</ornament-ticket>

Localized Traditional Chinese example:
<ornament-ticket class="Ornament" data-ornament="tickets" ticket-text="需要門票"
  expiration-date="1539783000186" day-left-text="還剩1天" week-left-text="還剩1週"
  days-left-text="還剩#天" expired-text="已過期">
</ornament-ticket>
*/

export class OrnamentTicket extends HTMLElement {
  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.connectedCallbackExecuted = true
      this.ticketText = this.getAttribute('ticket-text')
      this.expirationDate = this.getAttribute('expiration-date')
      this.formatTime()
    }
  }

  formatTime() {
    if (this.expirationDate !== null) {
      const expDate = parseInt(this.expirationDate)
      const now = Date.now()

      if (expDate < now) {
        this.setAttribute('data-expired', '')
        this.ticketText += ` - ${this.getAttribute('expired-text')}`
      } else {
        let diffInMil = expDate - now
        let diffInSec = (diffInMil / 1000) | 0
        diffInMil -= diffInSec * 1000
        let diffInMin = (diffInSec / 60) | 0
        diffInSec -= diffInMin * 60
        let diffInHrs = (diffInMin / 60) | 0
        diffInMin -= diffInHrs * 60
        let diffInDays = (diffInHrs / 24) | 0
        diffInHrs -= diffInDays * 24
        let diffInWeeks = (diffInDays / 7) | 0
        diffInDays -= diffInWeeks * 7

        if (diffInWeeks === 1 && diffInDays === 0) {
          this.ticketText += ` - ${this.getAttribute('week-left-text')}`
        } else if (diffInWeeks === 0 && diffInDays < 7 && diffInDays > 1) {
          let daysLeftText = this.getAttribute('days-left-text')
          this.ticketText += ` - ${daysLeftText.replace('#', diffInDays)}`
        } else if (diffInWeeks === 0 && diffInDays <= 1) {
          this.ticketText += ` - ${this.getAttribute('day-left-text')}`
        }
      }
    }

    this.createString()
  }

  createString() {
    const text = document.createTextNode(this.ticketText)
    this.appendChild(text)
  }
}
