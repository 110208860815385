import { EventTracking } from './PageInteractions'

export class HeroLedeWideTracking {
  constructor(el) {
    this.el = el
    if (typeof window.analytics !== 'undefined') {
      const cardLinks = this.el.querySelectorAll('a')
      cardLinks.forEach(link => {
        link.addEventListener('click', e => {
          const cardProps = this.getCardProps()
          EventTracking('Click Action', cardProps) // was Content Card Clicked
        })
      })
    }
  }

  getCardProps() {
    const cardType = this.el.getAttribute('data-type')
    let cardProps = {
      card_type: cardType
    }

    if (this.el.querySelector('.Card-details') !== null) {
      cardProps.card_details = this.el.querySelector('.Card-details').innerText
    }

    if (this.el.closest('[data-hero-carousel-wide]') !== null) {
      cardProps.carousel_style = 'Hero Two Wide'
    }

    switch (cardType) {
      case 'lot': {
        const cardTitleTemplate = []
        if (this.el.querySelector('.HeroLedeWide-lotNumber') !== null) {
          cardTitleTemplate.push(
            this.el.querySelector('.Card-lotNumber').innerText
          )
        }
        if (this.el.querySelector('.HeroLedeWide-artistName') !== null) {
          cardTitleTemplate.push(
            this.el.querySelector('.Card-artistName').innerText
          )
        }
        if (this.el.querySelector('.HeroLedeWide-title') !== null) {
          cardTitleTemplate.push(
            this.el.querySelector('.HeroLedeWide-title').innerText
          )
        }
        cardProps.card_title = cardTitleTemplate.join(' | ')
        break
      }
      default: {
        cardProps.card_title = this.el.querySelector(
          '.HeroLedeWide-info-title'
        ).innerText
        break
      }
    }

    return cardProps
  }
}
