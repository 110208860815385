/* global HTMLElement */

import { EventTracking } from './PageInteractions'
import qs from 'qs'

export class AnswerCardTracking extends HTMLElement {
  static selectors = {
    component: 'answer-card-tracking'
  }

  connectedCallback() {
    // ensure that this code only executes once, rather than each time the element is added back to the DOM
    if (!this.connectedCallbackExecuted) {
      this.init()
    }
  }

  init() {
    this.setLoadEvent()
    this.setListeners()
  }

  setLoadEvent() {
    const { query } = qs.parse(window.location.search.slice(1))
    const searchKeyword = query || ''
    EventTracking('Search Answer Card Loaded', {
      search_result_title: this.querySelector('.Card-title').innerText,
      search_keyword: searchKeyword,
      search_result_category: 'answer card'
    })
  }

  setListeners() {
    this.answerLinks = this.querySelectorAll('a')

    if (this.answerLinks !== null) {
      this.answerLinks.forEach(link => {
        link.addEventListener(
          'click',
          (this._ctaTrackEvent = e => this.ctaTrackEvent(link))
        )
      })
    }
  }

  ctaTrackEvent(link) {
    const card = this.closest('.Card')
    const cardTitle = card.querySelector('.Card-title').innerText
    const { query } = qs.parse(window.location.search.slice(1))
    const searchKeyword = query || ''

    EventTracking('Search Result Clicked', {
      cta_text: link.innerText,
      search_result_title: cardTitle,
      search_keyword: searchKeyword,
      search_result_category: 'answer card'
    })
  }

  disconnectedCallback() {
    if (this.answerCtas !== null) {
      this.answerCtas.forEach(cta => {
        cta.removeEventListener('click', this._ctaTrackEvent)
      })
    }
  }
}
