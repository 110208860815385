import {
  CART_FETCH_START,
  CART_FETCH_SUCCESS,
  CART_FETCH_FAIL
} from '../constants/cart'
import { callApi } from '../utils/ApiUtils'
import {
  productAdded,
  parseProductFromCart,
  productRemoved
} from '../../segment/RetailTracking'

export const removeItem = (item, endpoint) => async dispatch => {
  dispatch({
    type: CART_FETCH_START
  })

  const body = new window.FormData()
  body.append('orderItemID', item.orderItemID)
  body.append('returnJSONObjects', 'cart')

  const data = await callApi(`//${endpoint}/index.cfm/api/scope/removeOrderItem`,
    {
      method: 'POST',
      credentials: 'include',
      body
    }).catch(err => {
      dispatch({
        type: CART_FETCH_FAIL,
        payload: err
      })
    })
  if (data) {
    dispatch({
      type: CART_FETCH_SUCCESS,
      payload: data
    })

    // segment tracking
    productRemoved(item.numericPrice, item.currencyCode, item.skuCode, item.skuCode, item.name, item.quantity)
  }
  return data
}

export const addItem = (skuCode, endpoint) => async dispatch => {
  dispatch({
    type: CART_FETCH_START
  })

  // Makes sure only one item is added per cart
  await callApi(`//${endpoint}/index.cfm/api/scope/clearOrder`, {
    method: 'POST',
    credentials: 'include'
  })

  const body = new window.FormData()
  body.append('quantity', '1')
  body.append('orderFulfillmentID', '')
  body.append('skuCode', skuCode)
  body.append('orderCreatedSiteCode', 'marketplace')
  body.append('returnJSONObjects', 'cart')

  const data = await callApi(`//${endpoint}/index.cfm/api/scope/addOrderItem`,
    {
      method: 'POST',
      credentials: 'include',
      body
    }).catch(err => {
      dispatch({
        type: CART_FETCH_FAIL,
        payload: err
      })
    })

  if (data &&
    (!data.errors || Object.keys(data.errors).length === 0) &&
    (!data.cart.errors || Object.keys(data.cart.errors).length === 0)) {
    dispatch({
      type: CART_FETCH_SUCCESS,
      payload: data
    })

    // segment tracking
    const product = parseProductFromCart(skuCode, data.cart)
    productAdded(product.price, product.currency, product.sku, product.sku, product.name, product.quantity)
  }

  return data
}

export const reingest = (items, endpoint) => async dispatch => {
  window.fetch(`/slatwall/api/reingest?productIDs=${items.join(',')}`, { method: 'GET' })
}
