import { ORDER_SHIPPING_ADDRESS_SUCCESS, ORDER_SHIPPING_ADDRESS_START, GET_ADDRESS_OPTIONS_SUCCESS, ORDER_SHIPPING_ADDRESS_FAIL, VALIDATE_ADDRESS_SUCCESS, ORDER_GUEST_ACCOUNT_SUCCESS, ORDER_GUEST_ACCOUNT_FAIL, UPDATE_GUEST_ACCOUNT_SUCCESS, UPDATE_GUEST_ACCOUNT_FAIL } from '../constants/checkout'
import { CART_FETCH_SUCCESS } from '../constants/cart'

const initialState = {
  completed: false,
  options: {
    stateList: [{}],
    postalCode: {},
    city: {},
    street2Address: {},
    streetAddress: {},
    stateCode: {}
  },
  errors: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ORDER_SHIPPING_ADDRESS_START:
      return {
        ...state,
        ...action.payload,
        errors: {}
      }

    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case ORDER_GUEST_ACCOUNT_SUCCESS:
    case CART_FETCH_SUCCESS: // contains account & shipping details
    case ORDER_SHIPPING_ADDRESS_SUCCESS: // this event payload often also contains account details
    case UPDATE_GUEST_ACCOUNT_SUCCESS: // this event payload only contains account details
      let shipping = {}
      let account = {}

      if (action.payload.cart && action.payload.cart.orderFulfillments.length > 0) {
        shipping = {
          streetAddress: action.payload.cart.orderFulfillments[0].shippingAddress.streetAddress,
          street2Address: action.payload.cart.orderFulfillments[0].shippingAddress.street2Address,
          city: action.payload.cart.orderFulfillments[0].shippingAddress.city,
          countryCode: action.payload.cart.orderFulfillments[0].shippingAddress.countryCode,
          stateCode: action.payload.cart.orderFulfillments[0].shippingAddress.stateCode,
          postalCode: action.payload.cart.orderFulfillments[0].shippingAddress.postalCode,
          phoneNumber: action.payload.cart.orderFulfillments[0].shippingAddress.phoneNumber,
          completed: true
        }
      }

      if (action.payload.account) {
        account = {
          firstName: action.payload.account.firstName,
          lastName: action.payload.account.lastName,
          company: action.payload.account.company,
          emailAddress: action.payload.account.primaryEmailAddress.emailAddress,
          accountEmailAddressID: action.payload.account.primaryEmailAddress.accountEmailAddressID
        }
      }

      // return for both ORDER_SHIPPING_ADDRESS_SUCCESS & UPDATE_GUEST_ACCOUNT_SUCCESS
      return { ...state, ...shipping, ...account }

    case ORDER_SHIPPING_ADDRESS_FAIL:
    case ORDER_GUEST_ACCOUNT_FAIL:
    case UPDATE_GUEST_ACCOUNT_FAIL:
      return {
        ...state,
        errors: action.payload || {}
      }

    case GET_ADDRESS_OPTIONS_SUCCESS:
      const {
        postalCodeShowFlag,
        postalCodeRequiredFlag,
        postalCodeLabel,
        cityRequiredFlag,
        cityLabel,
        cityShowFlag,
        street2AddressLabel,
        street2AddressRequiredFlag,
        street2AddressShowFlag,
        streetAddressShowFlag,
        streetAddressLabel,
        streetAddressRequiredFlag,
        stateCodeLabel,
        stateCodeRequiredFlag,
        stateCodeShowFlag
      } = action.payload.addressOptions

      return {
        ...state,
        countryCode: action.payload.countryCode,
        options: {
          stateList: action.payload.stateCodeOptions,
          postalCode: {
            label: postalCodeLabel,
            required: postalCodeRequiredFlag,
            show: postalCodeShowFlag
          },
          city: {
            label: cityLabel,
            required: cityRequiredFlag,
            show: cityShowFlag
          },
          street2Address: {
            label: street2AddressLabel,
            required: street2AddressRequiredFlag,
            show: street2AddressShowFlag
          },
          streetAddress: {
            label: streetAddressLabel,
            required: streetAddressRequiredFlag,
            show: streetAddressShowFlag
          },
          stateCode: {
            label: stateCodeLabel,
            required: stateCodeRequiredFlag,
            show: stateCodeShowFlag
          }
        }
      }

    default:
      return state
  }
}
