import { DialogOverlay } from './DialogOverlay'
import UserData from '../utils/UserData'

export class OnboardingDialog extends DialogOverlay {
  static selector = '[data-onboarding-dialog]'
  static delay = 'data-delay'
  static hash = 'onboarding'
  static firstTimeUser = 'data-first-time-user'
  static anchorAttr = 'data-anchor'
  static endpoint = '/onboarding'

  constructor(el) {
    super(el)
    this.currentHash = window.location.hash
    this.anchorId = null
    this.getDialogAttributes()
    this.isFirstTimeUser = this.el.getAttribute(OnboardingDialog.firstTimeUser)
    this.shouldAnchor()
    if (this.anchorId === this.currentHash || this.isFirstTimeUser === 'true') {
      this.openDialogWithId(this.dialog)
    }
  }

  shouldAnchor() {
    if (this.el.hasAttribute(OnboardingDialog.anchorAttr)) {
      this.anchorId = this.el.getAttribute(DialogOverlay.selectors.dataId)
    }
  }

  getDialogAttributes() {
    if (this.el.hasAttribute(DialogOverlay.selectors.dataId)) {
      this.dialogId = this.el.getAttribute(DialogOverlay.selectors.dataId)
    }

    this.dialog = document.querySelector(this.dialogId)
  }

  openDialog() {
    super.openDialog()
    document
      .getElementsByTagName('body')[0]
      .removeAttribute(DialogOverlay.selectors.dataOpen)
  }

  closeDialog() {
    super.closeDialog()
    this.el.setAttribute(OnboardingDialog.firstTimeUser, 'false')
    this.postHttpRequest('true')
  }

  bindShowDialog() {
    return null // don't capture touch events
  }

  async postHttpRequest(onboardingSeen) {
    try {
      const auth0Token = await UserData.getAuth0Token()
      let xhr = new window.XMLHttpRequest()
      xhr.open('POST', OnboardingDialog.endpoint)
      xhr.setRequestHeader('Authorization', 'Bearer ' + auth0Token)
      xhr.send(onboardingSeen)
    } catch (e) {
      console.error(e)
    }
  }
}
