export class ConsentManagerTrigger {
  constructor(el) {
    el.addEventListener('click', this._hijackClick)
  }

  _hijackClick = e => {
    if (
      typeof window.analytics !== 'undefined' &&
      typeof window.consentManager !== 'undefined'
    ) {
      e.preventDefault()
      window.consentManager.openConsentManager()
    }
  }
}
