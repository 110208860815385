/* eslint-disable */

const isLessThan24Hours = timestamp => {
  const timestampDate = new Date(timestamp)
  const hoursDifference =
    Math.abs(new Date() - timestampDate) / (1000 * 60 * 60)
  return hoursDifference < 24
}

const pillTemplate = ({ position }) =>
  `<span class="content ${position}"><slot name='content'></slot></span>`

const prependZero = value => (value > 9 ? value : `0${value}`)

const getTimer = countDownDate => {
  const now = new Date().getTime()

  const distance = countDownDate - now

  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return distance > 0 ? { hours, minutes, seconds } : null
}

const localTime = target => {
  const targetUtc = new Date(target).toUTCString()
  const localDate = moment
    .tz(targetUtc, moment.tz.guess(true))
    .format('D MMMM YYYY - h:mm A')
  return `${localDate} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`
}

const countdownStyle = `
  #content-container {
    display: inline-block;
  }
`

const countdownTemplate = ({ hours, minutes, seconds } = {}) => `
${prependZero(hours)}:${prependZero(minutes)}:${prependZero(seconds)}
`

const buttons = isSaveAvailable => [
  isSaveAvailable
    ? {
        type: 'save',
        name: 'heart',
        icon: bool => (bool ? heartIconFilled : heartIcon)
      }
    : null,
  { type: 'share', name: 'share', icon: bool => shareIcon }
]

const isBeforeNow = (mils, timeZone) => {
  const options = {
    timeZone,
    hour12: false
  }
  const now = new Date(
    Intl.DateTimeFormat('en-US', {
      timeZone,
      hour12: false
    }).format(new Date())
  ).getTime()
  const target = new Date(
    Intl.DateTimeFormat('en-US', options).format(new Date(mils))
  ).getTime()
  return target < now
}

const getPageHeight = () =>
  document.height !== undefined ? document.height : document.body.offsetHeight

class SyndicationUtils {
  static get isIframe() {
    return window.parent && window.location !== window.parent.location
  }

  static events = {
    ANALYTICS: 'ANALYTICS',
    PAGE_HEIGHT: 'PAGE_HEIGHT',
    IS_FULLSCREEN: 'IS_FULLSCREEN'
  }

  static dispatch(event, payload, source = 'sothebys-bsp') {
    window.parent.postMessage(
      {
        source,
        type: event,
        payload
      },
      '*'
    )
  }

  static pageHeightListener() {
    let height = getPageHeight()
    SyndicationUtils.dispatch(SyndicationUtils.events.PAGE_HEIGHT, height)

    import(/* webpackChunkName: 'ResizeObserverPolyfill' */ '@juggle/resize-observer')
      .then(({ ResizeObserver }) => {
        const ro = new ResizeObserver(() => {
          if (height !== getPageHeight()) {
            height = getPageHeight()
            SyndicationUtils.dispatch(
              SyndicationUtils.events.PAGE_HEIGHT,
              height
            )
          }
        })

        ro.observe(document.body)
      })
      .catch(e => console.warn('Error loading ResizeObserverPolyfill', e))
  }
}

const getPageProperties = (attr = 'data-tracking') => {
  const trackingEl = document.querySelector(`[${attr}]`)

  if (trackingEl) {
    let trackingStr = trackingEl.getAttribute(attr).replace(/,[\s]*}$/g, '}')
    return JSON.parse(trackingStr)
  }
}

const _parseJWT = token => {
  return JSON.parse(window.atob(token.split('.')[1]))
}

function getCookie(name) {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

const getUserProperties = (err = () => {}) => {
  let properties = {}
  try {
    if (!getCookie(GLOB_COOKIE)) {
      throw new Error('No user session found.')
    }
    let user = _parseJWT(getCookie(GLOB_COOKIE))
    let jwtKeyDataUrl = `${
      getPageProperties('data-page-properties')['jwt_key_prefix']
    }`
    let appMetadata = `${jwtKeyDataUrl}/appMetadata`
    let userMetadata = `${jwtKeyDataUrl}/userMetadata`
    properties = {
      auth0_id: user.sub,
      preferred_member_flag: user[appMetadata].customerData.preferred,
      customer_id: user[appMetadata].customerData.partyId,
      firstName: user[userMetadata].firstName,
      lastName: user[userMetadata].lastName,
      name: user[userMetadata].name,
      title: user[userMetadata].title,
      email: user.email
    }
  } catch (ex) {
    err(ex)
  }
  return properties
}

const appendUserTrackingProperties = (properties = {}) => {
  properties = {
    ...properties,
    auth0_id: getUserProperties().auth0_id,
    preferred_member_flag: getUserProperties().preferred_member_flag,
    customer_id: getUserProperties().customer_id
  }
  if (properties.customer_id === 0) {
    delete properties.customer_id
  }

  return properties
}

const getLocationUrl = locationName => {
  const base = window.location.origin + '/en/about/locations/'
  const locationSlug = locationName.toLowerCase().replace(/\s+/g, '-')
  const searchParams = new URLSearchParams(window.location.search)
  const locale =
    searchParams.get('locale') || document.documentElement.lang || 'en'

  return `${base}${locationSlug}${`?locale=${locale}`}`
}

const auctionCardStyle = `
:host {
  --light-blue: #4864A1;
}

*:focus-visible {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.notUnderline {
  text-decoration: none;
  cursor: auto;
}

.serif {
  font-family: "MercuryDisplayRegular", "Mercury Display A", "Mercury Display B", MercuryDisplay, serif;
}

.text-sans {
  font-family: BentonSans, sans-serif;
  font-size: ${YMUtils.rem(14)};
  line-height: 1.57;
}

.text-sans-small {
  font-family: BentonSans, sans-serif;
  font-size: ${YMUtils.rem(12)};
  line-height: 1.5;
}

.bold-heavy {
  font-weight: 700;
}

.bold {
  font-weight: 500;
}

.wrapper {
  display: flex;
  flex-direction: column;
  color: #292929;
}

.wrapper-no-click {
  pointer-events: none;
}

.live-wrapper {
  position: absolute;
  top: ${YMUtils.rem(8)};
  left: ${YMUtils.rem(8)};
}
.closing-wrapper {
  position: absolute;
  bottom: ${YMUtils.rem(8)};
  left: ${YMUtils.rem(8)};
}

.media {
  position: relative;
  margin-bottom:${YMUtils.rem(12)};
  height: ${YMUtils.rem(180)};
}
@media only screen and (min-width: 1080px) {
  .size-large .media {
    height: ${YMUtils.rem(310)};
  }
}

.media img {
 height: 100%;
 object-fit: cover;
}


.header-wrapper {
  display: flex;
  justify-content: space-between;
  gap: ${YMUtils.rem(4)};
  margin-bottom:${YMUtils.rem(8)};
}

.size-large .header-wrapper {
gap:${YMUtils.rem(8)};
  margin-bottom:${YMUtils.rem(16)};
}

.headline {
  font-size: ${YMUtils.rem(20)};
  line-height: 1.2;
  letter-spacing: ${YMUtils.rem(0.25)};
  flex-shrink: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  color: #292929;
}

.buttons-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${YMUtils.rem(4)};
  width: fit-content;
  height: fit-content;
}

.button {
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${YMUtils.rem(8)};
}

.countdown-wrapper {
  margin-bottom: ${YMUtils.rem(8)};
  color: #00253E;
  font-family: BentonSans, sans-serif;
  font-size: ${YMUtils.rem(14)};
  line-height: 1.57;
}

.info-wrapper {
  position: relative;
  width: fit-content;
}
@media only screen and (min-width: 1080px) {
  .size-large .info-wrapper br {
    display: none;
  }

  .size-large .countdown-wrapper {
    font-size: ${YMUtils.rem(12)};
    line-height: 1.5;
    margin-bottom: ${YMUtils.rem(2)};
  }
}
@media only screen and (max-width: 1079px) {
  .info-wrapper .separator {
    display: none;
  }
}
.size-small .info-wrapper .separator {
  display: none;
}

#info-location {
  margin-top:${YMUtils.rem(2)};
}
.grey-80 {
  color: var(--grey-80);
}

.exhibition-hours-button {
  color: var(--light-blue);
  text-decoration: underline;
  width: fit-content;
  margin-top: ${YMUtils.rem(8)};
}
`

const shareTooltipStyle = `
  <style>
    .share-list {
      display: flex;
      flex-direction: column;
      gap:${YMUtils.rem(10)};
    }

    .share-list-element a {
      display: flex;
      gap: ${YMUtils.rem(8)};
      padding:${YMUtils.rem(5)} ${YMUtils.rem(8)};
      font-size: ${YMUtils.rem(12)};
      font-weight: 500;
      line-height: 1.5;
      background: transparent;
      text-decoration: none;
      color: #00253E;
      font-family: BentonSans, sans-serif;
    }

    .share-list-element button {
      display: flex;
      gap: ${YMUtils.rem(8)};
      padding:${YMUtils.rem(5)} ${YMUtils.rem(8)};
      font-size: ${YMUtils.rem(12)};
      font-weight: 500;
      line-height: 1.5;
      background: transparent;
      color: #00253E;
      font-family: BentonSans, sans-serif;
    }

    .share-list-element svg {
      width: ${YMUtils.rem(16)};
      height: ${YMUtils.rem(16)};
      object-fit: contain;
    }

    .share-list-element span {
      white-space: nowrap;
    }

    .bold {
      font-weight: 500;
    }
  </style>
`

const auctionCardDrawerStyle = `
.drawer-container {
  --dark-blue: #264c69;
  --light-blue: #4864A1;
  --dark: #292929;
  --red: #DD2415;
  --light-gray: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  font-family: BentonSans, sans-serif;
  color: var(--dark);
  background: rgba(0, 0, 0, 0.5);
}

.drawer-container * {
  font-family: BentonSans, sans-serif;
}

.drawer {
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 30px);
  overflow-y: auto;
  position: relative;
  margin-top: ${YMUtils.rem(30)};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-top-left-radius: ${YMUtils.rem(8)};
  border-top-right-radius: ${YMUtils.rem(8)};
  transform: translateY(100%);
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.drawer.drawer-visible {
  transform: translateY(0);
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid var(--light-gray);
  position: relative;
}

.drawer-header h2 {
  font-size: ${YMUtils.rem(16)};
  font-weight: 500;
}

.close-button-mobile {
  font-size: ${YMUtils.rem(14)};
  font-weight: 500;
  position: absolute;
  right: ${YMUtils.rem(16)};
  top: 50%;
  transform: translateY(-50%);
  color: var(--dark-blue);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-button-desktop {
  display: none;
}

.exhibition-details {
  padding: ${YMUtils.rem(10)};
}

.exhibition-details h3 {
  font-size: ${YMUtils.rem(16)};
  font-weight: 400;
  color: var(--dark-blue);
  margin-bottom: ${YMUtils.rem(16)};
}

.location-details {
  margin-bottom: 20px;
}

.location-details h4 {
  font-size: ${YMUtils.rem(14)};
  font-weight: 500;
  color: var(--light-blue);
  margin-bottom: ${YMUtils.rem(8)};
}

.location-address {
  font-size: ${YMUtils.rem(14)};
  font-weight: 500;
  margin-bottom: ${YMUtils.rem(8)};
}

.day-details {
  display: flex;
  border-bottom: 1px solid var(--light-gray);
  padding: ${YMUtils.rem(8)};
  font-size: ${YMUtils.rem(14)};
}

.day-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.day-date--month {
  color: var(--red);
  margin: 0 0 ${YMUtils.rem(4)} 0;
}

.day-date--day {
  margin: 0;
}

.day-schedule {
  margin-left: ${YMUtils.rem(16)};
  display: flex;
  flex-direction: column;
}

.day-schedule h5 {
  font-size: ${YMUtils.rem(14)};
  font-weight: 500;
  margin-bottom: ${YMUtils.rem(8)};
}

.day-schedule-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.day-schedule-list li:not(:last-child) {
  margin-bottom: ${YMUtils.rem(4)};
}

@media only screen and (min-width: 1024px) {
  .drawer {
    width: ${YMUtils.rem(400)};
    height: 100vh;
    margin-top: 0;
    border-radius: 0;
    transform: translateX(-100%);
  }

  .drawer.drawer-visible {
    transform: translateX(0);
  }

  .drawer-header {
    padding: ${YMUtils.rem(16)} 0;
    margin: 0 ${YMUtils.rem(16)};
    justify-content: flex-start;
  }

  .close-button-mobile {
    display: none;
  }

  .close-button-desktop {
    display: block;
    font-size: ${YMUtils.rem(30)};
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark-blue);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .exhibition-details {
    padding: ${YMUtils.rem(16)};
  }
}
`

const heartIcon = `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.335 5.104a5.586 5.586 0 0 0-8.102.001 5.448 5.448 0 0 0-.233.253 7.729 7.729 0 0 0-.232-.254 5.59 5.59 0 0 0-4.05-1.738 5.59 5.59 0 0 0-4.051 1.738 5.975 5.975 0 0 0-1.662 4.372c.067 1.631.812 3.154 2.092 4.28l7.41 6.685a.748.748 0 0 0 1.005-.001l7.384-6.678c1.287-1.132 2.032-2.655 2.099-4.287a5.972 5.972 0 0 0-1.66-4.37Zm-1.437 7.539-6.89 6.23-6.913-6.237c-.976-.86-1.542-2.003-1.591-3.222a4.468 4.468 0 0 1 1.242-3.269 4.1 4.1 0 0 1 2.97-1.279 4.1 4.1 0 0 1 2.971 1.28c.274.283.496.574.661.863.267.468 1.036.468 1.303 0 .165-.287.388-.578.662-.864a4.1 4.1 0 0 1 2.972-1.279c1.12 0 2.175.455 2.97 1.28a4.467 4.467 0 0 1 1.242 3.268c-.05 1.22-.615 2.364-1.6 3.229Z" fill="#00253E"/></svg>`
const heartIconFilled = `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.335 5.104a5.586 5.586 0 0 0-8.102.001 5.448 5.448 0 0 0-.233.253 7.729 7.729 0 0 0-.232-.254 5.59 5.59 0 0 0-4.05-1.738 5.59 5.59 0 0 0-4.051 1.738 5.975 5.975 0 0 0-1.662 4.372c.067 1.631.812 3.154 2.092 4.28l7.41 6.685a.748.748 0 0 0 1.005-.001l7.384-6.678c1.287-1.132 2.032-2.655 2.099-4.287a5.972 5.972 0 0 0-1.66-4.37Z" fill="#00253E"/></svg>`

const shareIcon = `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.842 8.816H15.5a.75.75 0 0 0 0 1.5h1.592V20.5H6.908V10.316H8.5a.75.75 0 0 0 0-1.5H6.158a.75.75 0 0 0-.75.75V21.25c0 .414.336.75.75.75h11.685a.75.75 0 0 0 .75-.75V9.566a.752.752 0 0 0-.751-.75Z" fill="#00253E"/><path d="m9.609 6.2 1.64-1.64v8.9a.75.75 0 0 0 1.5 0v-8.9l1.642 1.641a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L12.53 2.218a.749.749 0 0 0-1.06 0L8.549 5.14a.75.75 0 1 0 1.06 1.06Z" fill="#00253E"/></svg>`

const twitterIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.49815 6.97907L14.1884 1.33331H13.077L9.00442 6.23544L5.75166 1.33331H2L6.9188 8.7462L2 14.6666H3.11151L7.41225 9.48983L10.8474 14.6666H14.5991L9.49788 6.97907H9.49815ZM7.97579 8.81151L7.47741 8.07336L3.512 2.19977H5.21922L8.41935 6.93994L8.91772 7.6781L13.0775 13.8396H11.3703L7.97579 8.8118V8.81151Z" fill="#00253E"/>
</svg>
`
const facebookIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.33 7.665C14.33 3.98586 11.3441 1 7.665 1C3.98587 1 1 3.98586 1 7.665C1 10.9941 3.43674 13.7534 6.62853 14.2476V9.58693H4.92624V7.665H6.62853V6.19609C6.62853 4.52812 7.61695 3.60148 9.14077 3.60148C9.86836 3.60148 10.6234 3.73876 10.6234 3.73876V5.36554H9.78599C8.95544 5.36554 8.69461 5.88721 8.69461 6.40887V7.65813H10.5479L10.2527 9.58007H8.69461V14.2408C11.8933 13.7534 14.33 10.9941 14.33 7.665Z" fill="#00253E"/>
</svg>
`
const whatsappIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8144 2.19023C11.4045 0.778541 9.52942 0.000716229 7.5315 0C3.41462 0 0.0641025 3.35017 0.06267 7.46812C0.0619538 8.78454 0.406103 10.0695 1.05966 11.2018L0 15.0723L3.95932 14.0338C5.05013 14.629 6.27846 14.9423 7.52829 14.9427H7.5315C11.6477 14.9427 14.9986 11.5922 15 7.47421C15.0007 5.47846 14.2247 3.60192 12.8144 2.19023ZM7.5315 13.6814H7.529C6.41525 13.6811 5.32267 13.3817 4.36938 12.8162L4.14267 12.6816L1.79308 13.2979L2.42014 11.007L2.2726 10.7721C1.65127 9.78371 1.32288 8.64129 1.32359 7.4685C1.32502 4.04598 4.10972 1.26128 7.53404 1.26128C9.19208 1.262 10.7506 1.9084 11.9227 3.08194C13.0948 4.25512 13.7398 5.81508 13.7391 7.4735C13.7376 10.8964 10.953 13.6814 7.5315 13.6814ZM10.9365 9.03237C10.7499 8.93892 9.83242 8.48767 9.66121 8.42537C9.49004 8.36304 9.36579 8.33192 9.2415 8.51883C9.11725 8.70579 8.7595 9.12621 8.65063 9.25046C8.54175 9.37508 8.43287 9.3905 8.24629 9.297C8.05975 9.20354 7.45846 9.00658 6.74546 8.37092C6.19075 7.876 5.81613 7.26508 5.70729 7.07812C5.59842 6.89121 5.69583 6.79021 5.78892 6.69746C5.87271 6.61367 5.9755 6.47937 6.06896 6.3705C6.16246 6.26162 6.19325 6.18358 6.25554 6.05929C6.31787 5.93467 6.28671 5.82583 6.24017 5.73233C6.19358 5.63887 5.82046 4.72029 5.66467 4.34679C5.51317 3.98295 5.35921 4.03237 5.24496 4.02629C5.13608 4.02091 5.01183 4.01984 4.88721 4.01984C4.76258 4.01984 4.56058 4.0664 4.38942 4.25333C4.21825 4.44025 3.73621 4.89183 3.73621 5.81004C3.73621 6.72825 4.40483 7.61604 4.49829 7.74067C4.59175 7.86529 5.81433 9.75004 7.68621 10.5587C8.13138 10.751 8.47908 10.8659 8.75017 10.9519C9.19713 11.094 9.60392 11.074 9.9255 11.026C10.284 10.9723 11.0296 10.5744 11.185 10.1386C11.3404 9.70275 11.3404 9.32887 11.2939 9.25117C11.2473 9.17346 11.1227 9.12654 10.9361 9.03308L10.9365 9.03237Z" fill="#00253E"/>
</svg>
`
const emailIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9034 14.6667C10.8067 14.6667 10.7114 14.6387 10.628 14.584L6.82337 12.07H3.78071C2.43071 12.07 1.33337 10.972 1.33337 9.62268V3.78068C1.33337 2.43134 2.43071 1.33334 3.78071 1.33334H12.2194C13.5694 1.33334 14.6667 2.43134 14.6667 3.78068V9.62268C14.6667 10.972 13.5694 12.07 12.2194 12.07H11.4034V14.1667C11.4034 14.3507 11.3027 14.52 11.14 14.6073C11.0667 14.6467 10.9854 14.6667 10.9034 14.6667ZM3.78071 2.33334C2.98271 2.33334 2.33337 2.98268 2.33337 3.78068V9.62268C2.33337 10.4207 2.98271 11.07 3.78071 11.07H6.97404C7.07204 11.07 7.16804 11.0987 7.24937 11.1527L10.4034 13.2367V11.57C10.4034 11.294 10.6274 11.07 10.9034 11.07H12.2194C13.0174 11.07 13.6667 10.4207 13.6667 9.62268V3.78068C13.6667 2.98268 13.0174 2.33334 12.2194 2.33334H3.78071Z" fill="#00253E"/>
<path d="M8 7.2C7.86667 7.2 7.73933 7.14667 7.64667 7.05334C7.6 7.00667 7.56667 6.95334 7.54 6.89334C7.51333 6.83334 7.5 6.76667 7.5 6.7C7.5 6.56667 7.55333 6.44 7.64667 6.34667C7.78667 6.20667 8.00667 6.16667 8.19333 6.24C8.25333 6.26667 8.30667 6.3 8.35333 6.34667C8.44667 6.44 8.5 6.57334 8.5 6.7C8.5 6.76667 8.486 6.83334 8.45933 6.89334C8.43267 6.95334 8.4 7.00667 8.35333 7.05334C8.30667 7.1 8.25333 7.14 8.19333 7.16667C8.126 7.18667 8.06667 7.2 8 7.2Z" fill="#00253E"/>
<path d="M5.72668 7.20003C5.66002 7.20003 5.60002 7.18669 5.53335 7.16669C5.47335 7.14003 5.42002 7.10003 5.37335 7.05336C5.32735 7.00669 5.29335 6.95336 5.26668 6.89336C5.24002 6.83336 5.22668 6.76669 5.22668 6.70003C5.22668 6.57336 5.28002 6.44003 5.37335 6.34669C5.42002 6.30003 5.47335 6.26669 5.53335 6.24003C5.65335 6.19336 5.79335 6.18669 5.92002 6.24003C5.98002 6.26669 6.03335 6.30003 6.08002 6.34669C6.17335 6.44003 6.22668 6.57336 6.22668 6.70003C6.22668 6.76669 6.21268 6.83336 6.18668 6.89336C6.16668 6.95336 6.12602 7.00669 6.08002 7.05336C6.03335 7.10003 5.98002 7.14003 5.92002 7.16669C5.86002 7.18669 5.79268 7.20003 5.72668 7.20003Z" fill="#00253E"/>
<path d="M10.2733 7.20004C10.14 7.20004 10.0126 7.1467 9.91998 7.05337C9.87332 7.0067 9.83332 6.95337 9.81332 6.89337C9.78665 6.83337 9.77332 6.7667 9.77332 6.70004C9.77332 6.63337 9.78665 6.57337 9.81332 6.51337C9.83332 6.4467 9.87332 6.39337 9.91998 6.3467C10.0533 6.2067 10.28 6.16004 10.46 6.24004C10.5273 6.2667 10.5806 6.30004 10.6266 6.3467C10.7206 6.44004 10.774 6.57337 10.774 6.70004C10.774 6.83337 10.7206 6.96003 10.6266 7.05337C10.5806 7.10003 10.5273 7.14003 10.46 7.1667C10.4 7.1867 10.34 7.20004 10.2733 7.20004Z" fill="#00253E"/>
</svg>
`
const copyIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.57337 14.6667C3.70804 14.6667 2.89404 14.33 2.28204 13.718C1.67004 13.106 1.33337 12.292 1.33337 11.4267C1.33337 10.5614 1.67004 9.74736 2.28204 9.13536L5.12404 6.29403C5.73604 5.68203 6.54937 5.3447 7.41537 5.3447C8.28071 5.3447 9.09404 5.68203 9.70604 6.29403C9.90137 6.48936 9.90137 6.80603 9.70604 7.00136C9.51071 7.1967 9.19404 7.1967 8.99871 7.00136C8.15137 6.15403 6.67671 6.1547 5.83137 7.00136L2.98937 9.8427C2.11604 10.716 2.11604 12.1374 2.98937 13.0107C3.86337 13.884 5.28337 13.884 6.15737 13.0107L7.55337 11.6147C7.74871 11.4194 8.06537 11.4194 8.26071 11.6147C8.45604 11.81 8.45604 12.1267 8.26071 12.322L6.86471 13.718C6.25204 14.33 5.43871 14.6667 4.57337 14.6667Z" fill="#00253E"/>
<path d="M8.58475 10.6546C7.71942 10.6546 6.90608 10.3173 6.29408 9.70531C6.09875 9.50998 6.09875 9.19331 6.29408 8.99798C6.48942 8.80265 6.80608 8.80265 7.00142 8.99798C7.84808 9.84465 9.32275 9.84398 10.1687 8.99798L13.0108 6.15665C13.8841 5.28331 13.8841 3.86198 13.0108 2.98865C12.1368 2.11531 10.7167 2.11531 9.84275 2.98865L8.28475 4.54665C8.08942 4.74198 7.77275 4.74198 7.57742 4.54665C7.38208 4.35131 7.38208 4.03465 7.57742 3.83931L9.13542 2.28131C9.74808 1.66998 10.5614 1.33331 11.4268 1.33331C12.2921 1.33331 13.1061 1.66998 13.7181 2.28198C14.3301 2.89398 14.6667 3.70798 14.6667 4.57331C14.6667 5.43865 14.3301 6.25265 13.7181 6.86465L10.8761 9.70598C10.2641 10.318 9.45075 10.6546 8.58475 10.6546Z" fill="#00253E"/>
</svg>
`

const timeLocationTooltipStyle = `
<style>
  .location {
    display: flex;
    flex-direction: column;
    gap: ${YMUtils.rem(2)};
    white-space: nowrap;
  }

  .location p {
    margin: 0;
  }

  .text-sans {
    font-family: BentonSans, sans-serif;
    font-size: ${YMUtils.rem(14)};
    line-height: 1.57;
  }

  .text-sans-small {
    font-family: BentonSans, sans-serif;
    font-size: ${YMUtils.rem(12)};
    line-height: 1.5;
  }

  .bold-heavy {
    font-weight: 700;
  }
</style>
`

const timeLocationTooltipTemplate = ({ address, timeZone, date }) => `
${timeLocationTooltipStyle}
<div class="location">
  <p class="text-sans"><span class="bold-heavy">Your local time</span></p>
  <p class="text-sans-small">${localTime(date, timeZone)}</p>
  <p class="text-sans"><span class="bold-heavy">Address</span></p>
  <p class="text-sans-small">${address}</p>
</div>
`

const shareTooltipTemplate = buttons => `
${shareTooltipStyle}
<ul slot="content" class="share-list">
  ${YMUtils.mapAndJoin(
    buttons,
    button =>
      `<li class="share-list-element">
        ${YMUtils.if(
          button.url,
          `<a tabindex="0" href="${button.url}" target="_blank">${
            button.icon
          } <span class="bold">${button.name}</span></a>`
        )}
        ${YMUtils.if(
          button.action === 'copy',
          `<button tabindex="0" onclick="${button.payload}" target="_blank">${
            button.icon
          } <span class="bold">${button.name}</span></button>`
        )}
      </li>
      `
  )}
</ul>
`

const auctionCardDrawerTemplate = ({ title, auctionUrl, exhibitionHours }) => {
  return `
    <style>
      ${auctionCardDrawerStyle}
    </style>
    <div class="drawer-container">
      <div class="drawer">
        <div class="drawer-header">
            <h2>Exhibition hours</h2>
            <button class="close-button-mobile">Close</button>
            <button class="close-button-desktop">&times;</button>
        </div>
        <div class="exhibition-details">
            <a href="${auctionUrl}"><h3>${title}</h3></a>
            ${YMUtils.mapAndJoin(
              exhibitionHours,
              item =>
                `
              <div class="location-details">
                <a href="${getLocationUrl(
                  item.location
                )}" class="location-city">
                  <h4>${item.location}</h4>
                </a>
                <p class="location-address">Address: ${item.address}</p>
                ${YMUtils.mapAndJoin(
                  item.days,
                  day =>
                    `
                    <div class="day-details">
                      <div class="day-date">
                        <p class="day-date--month">${day.month}</p>
                        <p class="day-date--day">${day.day}</p>
                      </div>
                      <div class="day-schedule">
                        <h5>${day.dayOfWeek}</h5>
                        <ul class="day-schedule-list">
                          ${YMUtils.mapAndJoin(
                            day.times,
                            time =>
                              `
                              <li>${time}</li>
                            `
                          )}
                        </ul>
                      </div>
                    </div>
                  `
                )}
              </div>
              `
            )}
        </div>
      </div>
    </div>
  `
}

const auctionCardTemplate = ({
  size,
  auctionUrl,
  image,
  title,
  date,
  time,
  locationDetails,
  onlineStart,
  liveStart,
  end,
  exhibitionHours,
  eventStatus,
  isSaveAvailable,
  isFollowing,
  auctionType,
  saleNumber,
  saleType,
  vikingId
}) => {
  const isOnlineAuction = auctionType === 'ONLINE_AUCTIONS'
  const startDate = isOnlineAuction
    ? onlineStart || liveStart
    : liveStart || onlineStart
  const endDate = end
  const hasStarted = isBeforeNow(
    startDate,
    locationDetails ? locationDetails.timeZone || 'GMT' : 'GMT'
  )
  const hasEnded = isBeforeNow(
    endDate,
    locationDetails ? locationDetails.timeZone || 'GMT' : 'GMT'
  )
  const hasStartedLiveAuction = !isOnlineAuction && hasStarted
  const nowUTS = new Date().getTime()
  const UTCOneHour = 3600000
  const isLessThanADayToStart =
    startDate - nowUTS < UTCOneHour * 24 && startDate - nowUTS > 0
  const isPreAuction =
    (eventStatus === 'PRO' || eventStatus === 'OVR' || eventStatus === 'SCH') &&
    !hasStarted &&
    !hasEnded &&
    isLessThanADayToStart &&
    (!hasStartedLiveAuction || isOnlineAuction)
  const isInProgress =
    (eventStatus === 'PRO' || eventStatus === 'OVR') &&
    hasStarted &&
    !hasEnded &&
    (!hasStartedLiveAuction || isOnlineAuction)

  const preAuctionCountdown = `
    <p>
      <span class="bold">
        <sothebys-countdown target-date=${startDate}><sothebys-countdown>
      </span>
      ${isOnlineAuction ? ' until bidding opens' : ' until live auction'}
    </p>
  `

  const inProgressCountdown = `
    <p>
      <span class="bold">
        <sothebys-countdown target-date=${endDate}><sothebys-countdown>
      </span>
      ${
        isOnlineAuction
          ? ' until lots begin closing'
          : '  Live auction in progress'
      }
    </p>
  `

  const shareCTA = button =>
    button && auctionUrl !== ''
      ? `<button aria-label="${button.name}" tabindex="0" id="button-${
          button.name
        }" class="button">${button.icon(isFollowing)}</button>`
      : ''

  const saveCTA = `<follow-auction-actionlink
  class="Link"
  fullwidth
  auction-id="${vikingId}"
  item-type="auction"
  is-save-icon
  sale-number="${saleNumber}"
  sale-type="${saleType}"
  follow-label="{{format "/auction/AuctionActionLink" "save"}}"
  following-label="{{format "/auction/AuctionActionLink" "saved"}}"
  ></follow-auction-actionlink>`

  return `
  <article class="wrapper size-${size}">
    ${YMUtils.if(
      image.url,
      `
      <a tabindex="0" href="${auctionUrl}" class="media ${auctionUrl === '' &&
        'wrapper-no-click'}">
        <img src=${image.url} />
      </a>
    `
    )}

    <div class="header-wrapper">
        ${title &&
          `<h3><a tabindex="0" href="${auctionUrl}" class="headline serif ${auctionUrl ===
            '' && 'wrapper-no-click'}">${title}</a></h3>`}

        <div class="buttons-wrapper">
            ${YMUtils.mapAndJoin(
              buttons(isSaveAvailable),
              button =>
                button && button.type === 'save' ? saveCTA : shareCTA(button)
            )}
        </div>
    </div>

    ${YMUtils.if(
      isPreAuction,
      `<div class="countdown-wrapper">${preAuctionCountdown}</div>`
    )}

    ${YMUtils.if(
      isInProgress && isLessThan24Hours(endDate),
      `<div class="countdown-wrapper">${inProgressCountdown}</div>`
    )}

    ${`<div class="info-wrapper">
        <p class="grey-80 text-sans">${date}<span class="separator"> • </span> <br />
         ${
           time && locationDetails
             ? ` <button aria-label="Time and location" id="info-location" class="grey-80 ${
                 auctionUrl ? 'underline' : 'notUnderline'
               } text-sans">${time} • ${
                 locationDetails ? locationDetails.city || '' : ''
               }</button>`
             : ''
         }
        </p>
    </div>`}

    ${YMUtils.if(
      exhibitionHours.length && auctionUrl,
      `<button id="exhibition-hours" class="exhibition-hours-button">Exhibition hours</button>`
    )}
</article>
`
}

const parseAuctionData = element => {
  const auctionData = element.dataset.auctionData
    ? JSON.parse(element.dataset.auctionData)
    : {}
  const userData = element.dataset.userData
    ? JSON.parse(element.dataset.userData)
    : {}
  const imagesrc = element.getAttribute('imagesrc')

  return {
    id: element.dataset.uuid || '',
    title: auctionData.title || element.getAttribute('title') || '',
    image: {
      url: imagesrc
        ? imagesrc
        : 'https://d23o3nj70f956.cloudfront.net/dims4/default/8f5aa97/2147483647/strip/true/crop/566x269+0+145/resize/1440x685!/format/webp/quality/90/?url=https%3A%2F%2Fsothebys-brightspot.s3.amazonaws.com%2Fdotcom%2F41%2F35%2Ffe0816264f929713b6c92be67a96%2Fno-image-available-5b9e69e9-a386-49dd-8bdb-492a817-hj7o7j3z9z-1.png'
    },
    date: auctionData.date || '',
    time: auctionData.time || '',
    locationDetails: auctionData.locationDetails || null,
    onlineStart: auctionData.onlineStart || null,
    liveStart: auctionData.liveStart || null,
    end: auctionData.end || null,
    auctionUrl: auctionData.url || element.getAttribute('url') || '',
    size: element.getAttribute('size') || 'small',
    exhibitionHours: auctionData.exhibitionHours || [],
    eventStatus: auctionData.eventStatus || '',
    isSaveAvailable: auctionData.availableActions
      ? auctionData.availableActions.includes('save')
      : false,
    isFollowing: userData.isFollowing,
    auctionType: auctionData.auctionType || '',
    saleNumber: auctionData.saleNumber || '',
    saleType: auctionData.saleType || '',
    vikingId: auctionData.vikingId || ''
  }
}

const setupEvents = element => {
  const {
    id,
    auctionUrl,
    title,
    locationDetails,
    liveStart,
    onlineStart,
    date,
    auctionType,
    end,
    time
  } = parseAuctionData(element)

  if (!id || !auctionUrl || !title || !date || !time || !locationDetails) {
    return
  }

  const shareButton = element.shadowRoot.querySelector('#button-share')
  const locationButton = element.shadowRoot.querySelector('#info-location')
  const exhibitionHoursButton = element.shadowRoot.querySelector(
    '#exhibition-hours'
  )

  const socialButtons = [
    {
      name: 'Twitter',
      icon: twitterIcon,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
      )}&url=${encodeURIComponent(auctionUrl)}`
    },
    {
      name: 'Facebook',
      icon: facebookIcon,
      url: `https://www.facebook.com/sharer/sharer.php?u=${auctionUrl}`
    },
    {
      name: 'WhatsApp',
      icon: whatsappIcon,
      url: `whatsapp://send?text=${title}, ${auctionUrl}`
    },
    {
      name: 'Email',
      icon: emailIcon,
      url: `mailto:?Subject=${title}&body=${auctionUrl}`
    },
    {
      name: 'Copy link',
      icon: copyIcon,
      action: 'copy',
      payload: `
            (function(element){
              navigator.clipboard.writeText('${auctionUrl}')
              var textWrapper = element.querySelector('span')
              var originalText = textWrapper.innerText
              textWrapper.innerText = 'Link copied'
              setTimeout(() => {
                textWrapper.innerText = originalText
              }, 5000)
            })(this)
            `
    }
  ]

  tippy(shareButton, {
    content: shareTooltipTemplate(socialButtons),
    trigger: 'click',
    placement: 'bottom-end',
    allowHTML: true,
    theme: 'share',
    interactive: true,
    appendTo: document.body,
    popperOptions: {
      strategy: 'fixed'
    }
  })

  const address = [
    locationDetails.address1,
    locationDetails.city,
    locationDetails.country
  ]
    .filter(Boolean)
    .join(', ')
  const timeZone = locationDetails.timeZone

  address &&
    (liveStart || onlineStart) &&
    timeZone &&
    tippy(locationButton, {
      content: timeLocationTooltipTemplate({
        address: address || '',
        date:
          auctionType === 'ONLINE_AUCTIONS' && end
            ? end
            : liveStart || onlineStart || '',
        timeZone: timeZone || ''
      }),
      trigger: 'click',
      placement: 'bottom-start',
      allowHTML: true,
      theme: 'timelocation',
      interactive: true,
      appendTo: document.body,
      popperOptions: {
        strategy: 'fixed'
      }
    })

  const imageLink = element.shadowRoot.querySelector('a.media')
  const titleLink = element.shadowRoot.querySelector('a.headline')

  const segmentTrigger = () => {
    const properties = {
      card_type: 'auction_card',
      card_title: title,
      card_details: date + ' | ' + time + ' | ' + (locationDetails.city || ''),
      carousel_style: 'Dynamic Carousel Grid',
      carousel_title: ''
    }
    if (typeof window.analytics !== 'undefined') {
      window.analytics.track(
        'Content Card Clicked',
        appendUserTrackingProperties(properties)
      )
    } else if (SyndicationUtils.isIframe) {
      SyndicationUtils.dispatch(SyndicationUtils.events.ANALYTICS, {
        name: 'Content Card Clicked',
        properties: appendUserTrackingProperties(properties)
      })
    }
  }

  if (imageLink) {
    imageLink.addEventListener('click', segmentTrigger)
  }

  if (titleLink) {
    titleLink.addEventListener('click', segmentTrigger)
  }

  if (exhibitionHoursButton) {
    exhibitionHoursButton.addEventListener('click', () => {
      const drawerWrapper = document.createElement('div')
      const drawerId = `drawer-wrapper-${id}`
      drawerWrapper.id = drawerId
      const exhibitionHoursData = parseAuctionData(element)
      drawerWrapper.innerHTML = auctionCardDrawerTemplate(exhibitionHoursData)
      document.body.appendChild(drawerWrapper)

      requestAnimationFrame(() => {
        drawerWrapper.querySelector('.drawer').classList.add('drawer-visible')
      })

      const closeButtonMobile = document.querySelector(
        `#${drawerId} .close-button-mobile`
      )
      const closeButtonDesktop = document.querySelector(
        `#${drawerId} .close-button-desktop`
      )
      ;[closeButtonMobile, closeButtonDesktop].forEach(button => {
        button.addEventListener('click', () => {
          const drawerWrapperElement = document.querySelector(`#${drawerId}`)
          const drawerElement = drawerWrapperElement.querySelector('.drawer')
          drawerElement.classList.remove('drawer-visible')

          drawerElement.addEventListener('transitionend', () => {
            drawerWrapperElement.remove()
          })
        })
      })
    })
  }
}

export class AuctionCardComponent {
  static init() {
    YMUtils.spawn({
      componentTag: 'pill-indicator',
      renderer: obj => ({
        ...obj,
        component: YMUtils.createWebComponent({
          style: `
                .content {
                  padding:0 ${YMUtils.rem(8)};
                  border-radius: 9999px;
                  background: #DD2415;
                  font-family: BentonSans, sans-serif;
                  color: white;
                  font-size: ${YMUtils.rem(12)};
                  font-weight: 500;
                  line-height: 1.5;
                  position: absolute;
                  width: fit-content;
                  top: ${YMUtils.rem(8)};
                  z-index: 3;
                }

                .bottom-left {
                  bottom: ${YMUtils.rem(8)};
                  left: ${YMUtils.rem(8)};
                  top: unset;
                }

                .top-left {
                  top: ${YMUtils.rem(8)};
                  left: ${YMUtils.rem(8)};
                  bottom: unset;
                }
                `,
          connectedCallback: ({ element }) => {
            const position = element.getAttribute('position') || 'bottom-left'
            element.render(pillTemplate({ position }))
          }
        })
      })
    })

    YMUtils.spawn({
      componentTag: 'sothebys-countdown',
      renderer: obj => ({
        ...obj,
        component: YMUtils.createWebComponent({
          style: countdownStyle,
          connectedCallback: ({ element }) => {
            const dateUnixTimestamp = Number(
              element.getAttribute('target-date')
            )

            const target = new Date(dateUnixTimestamp).getTime()
            console.log('HELLO', getTimer(target))

            const render = () =>
              element.render(countdownTemplate(getTimer(target)))

            getTimer(target) && setInterval(render, 1000)
            getTimer(target) && render()
          }
        })
      })
    })

    YMUtils.spawn({
      componentTag: 'auction-card-component',
      renderer: obj => ({
        ...obj,
        component: YMUtils.createWebComponent({
          style: auctionCardStyle,
          deps: ['data-auction-data', 'data-user-data'],
          effect: ({ name, newValue, prevValue, element }) =>
            ({
              'data-auction-data': () =>
                element.render(auctionCardTemplate(parseAuctionData(element))),
              'data-user-data': () =>
                element.render(auctionCardTemplate(parseAuctionData(element)))
            }[name]()),
          events: ({ element }) => setupEvents(element),
          connectedCallback: ({ element }) =>
            element.render(auctionCardTemplate(parseAuctionData(element)))
        })
      })
    })
  }
}
