/**
 * NOTE: These are displayed in order of the checkout flow that needs to happen.
 */

export const GET_ADDRESS_OPTIONS_START = 'GET_ADDRESS_OPTIONS_START'
export const GET_ADDRESS_OPTIONS_SUCCESS = 'GET_ADDRESS_OPTIONS_SUCCESS'
export const GET_ADDRESS_OPTIONS_FAIL = 'GET_ADDRESS_OPTIONS_FAIL'

export const VALIDATE_ADDRESS_START = 'VALIDATE_ADDRESS_START'
export const VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS'
export const VALIDATE_ADDRESS_FAIL = 'VALIDATE_ADDRESS_FAIL'

export const ORDER_SHIPPING_ADDRESS_START = 'ORDER_SHIPPING_ADDRESS_START'
export const ORDER_SHIPPING_ADDRESS_SUCCESS = 'ORDER_SHIPPING_ADDRESS_SUCCESS'
export const ORDER_SHIPPING_ADDRESS_FAIL = 'ORDER_SHIPPING_ADDRESS_FAIL'

export const ORDER_SHIPPING_METHOD_START = 'ORDER_SHIPPING_METHOD_START'
export const ORDER_SHIPPING_METHOD_SUCCESS = 'ORDER_SHIPPING_METHOD_SUCCESS'
export const ORDER_SHIPPING_METHOD_FAIL = 'ORDER_SHIPPING_METHOD_FAIL'

export const ORDER_GUEST_ACCOUNT_START = 'ORDER_GUEST_ACCOUNT_START'
export const ORDER_GUEST_ACCOUNT_SUCCESS = 'ORDER_GUEST_ACCOUNT_SUCCESS'
export const ORDER_GUEST_ACCOUNT_FAIL = 'ORDER_GUEST_ACCOUNT_FAIL'

export const UPDATE_GUEST_ACCOUNT_START = 'UPDATE_GUEST_ACCOUNT_START'
export const UPDATE_GUEST_ACCOUNT_SUCCESS = 'UPDATE_GUEST_ACCOUNT_SUCCESS'
export const UPDATE_GUEST_ACCOUNT_FAIL = 'UPDATE_GUEST_ACCOUNT_FAIL'

export const ADD_PROMOTION_CODE_START = 'ADD_PROMOTION_CODE_START'
export const ADD_PROMOTION_CODE_SUCCESS = 'ADD_PROMOTION_CODE_SUCCESS'
export const ADD_PROMOTION_CODE_FAIL = 'ADD_PROMOTION_CODE_FAIL'

export const REMOVE_PROMOTION_CODE = 'REMOVE_PROMOTION_CODE'

export const ORDER_PAYMENT_START = 'ORDER_PAYMENT_START'
export const ORDER_PAYMENT_SUCCESS = 'ORDER_PAYMENT_SUCCESS'
export const ORDER_PAYMENT_FAIL = 'ORDER_PAYMENT_FAIL'

export const PLACE_ORDER_START = 'PLACE_ORDER_START'
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS'
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL'

export const SET_GET_STARTED_LINK = 'SET_GET_STARTED_LINK'
export const UNSET_GET_STARTED_LINK = 'UNSET_GET_STARTED_LINK'
