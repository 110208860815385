/* global HTMLElement */
import { EventTracking } from '../../segment/PageInteractions'
import { BrightcoveRedactedPlayer } from './BrightcoveRedactedPlayer'
import { Tracker } from '../../utils/Events'
import shave from 'shave'
import { throttle } from '../../utils/Throttle'

export class VideoPlaylist extends HTMLElement {
  connectedCallback() {
    this.listenerTracker = new Tracker()
    this.cacheElements()

    if (this.playlist) {
      this.handlePlaylistOpening()

      if (this.isVideoPage) {
        this.markCurrentPlaylistItem(window.location.href)
      }

      this.handlePlaylistItemClicks()
      this.reactToVideoEnd()
    }
  }

  cacheElements() {
    this.playlist = this.querySelector('[class$="-playlist"]')
    this.playlistItems = this.querySelectorAll('.VideoPlaylist-items-item')
    this.tabsContent = this.querySelector('[data-tabs-playlist-content]')
    this.playlistTabs = this.querySelectorAll('[data-tab-panel]')
    this.companionContent = null
    if (this.querySelector('[data-companion-content]') !== null) {
      this.companionContent = this.querySelector(
        '[data-companion-content]'
      ).cloneNode(true)
    }

    if (this.isVideoPage) {
      this.videoWrapper = this.querySelector('[data-video-wrapper]')
    } else {
      this.videoWrapper = this.querySelector('[data-video-player-wrapper]')
    }
  }

  get isVideoPage() {
    if (
      document.querySelector('html').classList.contains('VideoPlaylistPage')
    ) {
      return true
    } else {
      return false
    }
  }

  removeListeners() {
    this.listenerTracker.removeListeners()
  }

  onVideoEnd() {
    if (
      this.querySelector('ps-brightcoveplayer').getAttribute(
        'data-fully-loaded'
      ) === 'false'
    ) {
      return false
    }

    let currentPlaylistItem = this.playlist.querySelectorAll(
      '[data-current-item="true"]'
    )

    if (currentPlaylistItem !== null && currentPlaylistItem.length > 1) {
      let openPlaylist = this.playlist.querySelector('[data-visible="true"]')

      // Need to make sure we are grabbing the next video from the tab that is currently open.
      // (Only needed when there is 1 video in multiple tabs)
      if (openPlaylist !== null) {
        currentPlaylistItem = openPlaylist.querySelector(
          '[data-current-item="true"]'
        )
      }
    } else {
      currentPlaylistItem = this.playlist.querySelector(
        '[data-current-item="true"]'
      )
    }

    if (currentPlaylistItem !== null) {
      if (currentPlaylistItem.parentElement.nextElementSibling !== null) {
        let nextItem = currentPlaylistItem.parentElement.nextElementSibling.querySelector(
          '.PlaylistItem'
        )

        if (nextItem) {
          // Sometimes we only show a few of the playlist items, do not play hidden ones
          let nextItemUrl = nextItem.getAttribute('data-item-url')
          let fragmentUrl = nextItem.getAttribute('data-fragment-url')
          const loadNextVideo = e => {
            clearInterval(window.nextVideoTimer)
            this.loadVideo(nextItemUrl, fragmentUrl)
          }
          const cancelNextVideo = e => {
            clearInterval(window.nextVideoTimer)
            this.querySelector('[data-next-video-cancel]').setAttribute(
              'data-hide',
              'true'
            )
            this.querySelector(
              '.BrightcoveRedactedPlayer-nextContainer-timer'
            ).setAttribute('data-hide', 'true')
          }

          if (this.querySelector('[data-next-video]') !== null) {
            let endCardNext = this.querySelector('[data-next-video]')
            let nextItemTitle = nextItem.querySelector('.PlaylistItem-title')
              .textContent
            let nextItemCategory = nextItem.getAttribute('data-video-category')
            let nextCard = `<div class="EndCard" data-type="video">
              <div class="EndCard-info">
                  <div class="EndCard-info-wrapper">
                      <div class="EndCard-info-container">
                          <div class="EndCard-category">${nextItemCategory}</div>
                          <div class="EndCard-title" style="">${nextItemTitle}</div>
                      </div>
                  </div>
              </div>
              <div data-next-video-icon></div>
              <div data-next-video-cancel>${this.getAttribute(
                'data-cancel-text'
              )}</div>
            </div>`

            this.querySelector(
              '.BrightcoveRedactedPlayer-nextVideo-card'
            ).innerHTML = nextCard
            this.listenerTracker.addListener(
              this.querySelector('[data-next-video-icon]'),
              'click',
              loadNextVideo
            )
            this.listenerTracker.addListener(
              this.querySelector('[data-next-video-cancel]'),
              'click',
              cancelNextVideo
            )
            BrightcoveRedactedPlayer.showEndCard(endCardNext)

            let timeleft = 5
            window.nextVideoTimer = setInterval(() => {
              this.querySelector('[data-next-video-timer]').innerHTML = timeleft
              timeleft -= 1
              if (timeleft < 0) {
                clearInterval(window.nextVideoTimer)
                this.loadVideo(nextItemUrl, fragmentUrl)
                this.closePlaylist()
              }
            }, 1000)
          }
        }
      } else {
        BrightcoveRedactedPlayer.showEndCard(
          this.querySelector('[data-end-card]')
        )
      }
    }
  }

  // React to the Video Ended event. We grab the current item in the playlist and check for existence
  // of a next item, if it exists, we load it via our own API
  reactToVideoEnd() {
    this.listenerTracker.addListener(
      this.querySelector('[data-video-player]'),
      'Video:onVideoEnded',
      this.onVideoEnd.bind(this)
    )
  }

  setUpNextCard() {
    if (this.querySelector('[data-next-video]') !== null) {
      this.querySelector('[data-next-video]')
    }
  }

  // Handles clicks for each individual PlaylistItem. If you clicked on something that's not currently
  // playing, we load it. We also close the Playlist always, which just sets data attributes and the
  // desktop level CSS just ignores those
  handlePlaylistItemClicks() {
    this.playlistItems.forEach((item, i) => {
      const playlistItem = item.querySelector('.PlaylistItem')
      let itemUrl = playlistItem.getAttribute('data-item-url')
      let fragmentUrl = playlistItem.getAttribute('data-fragment-url')

      playlistItem.addEventListener('click', event => {
        event.preventDefault()

        // only setup the loadvideo if there is a URL, but always suppress
        // the click, which is why this conditional is in here
        if (itemUrl) {
          if (playlistItem.getAttribute('data-current-item') !== 'true') {
            this.loadVideo(itemUrl, fragmentUrl)

            if (
              document
                .getElementsByTagName('body')[0]
                .hasAttribute('data-toggle-video-playlist')
            ) {
              window.scroll(0, 0)
            }

            this.closePlaylist()

            let itemProps = {
              video_player: 'Brightcove',
              video_title: item
                .querySelector('.PlaylistItem-title')
                .textContent.trim(),
              playlist_display_name: item
                .closest('.VideoPlaylistPage-playlist')
                .querySelector('[data-active="true"]')
                .textContent.trim(),
              playlist_video_position: this.getPlaylistItemIndex(item)
            }

            if (
              item
                .querySelector('.PlaylistItem')
                .getAttribute('data-video-category') !== null
            ) {
              itemProps.department_category = item
                .querySelector('.PlaylistItem')
                .getAttribute('data-video-category')
                .trim()
            }

            EventTracking(`Video Selected`, itemProps)
          }
        }
      })
    })
  }

  getPlaylistItemIndex(elm) {
    return [...elm.parentNode.children].indexOf(elm) + 1
  }

  // While this sort of assumes that the videoWrapper is blank to look great, it doesn't need to be
  // as this will just append a loading icon and set the page in a loading state
  setLoadingState() {
    let loadingIcon = document.createElement('div')
    loadingIcon.classList.add('loading-icon')

    this.videoWrapper.appendChild(loadingIcon)
    this.setAttribute('data-loading', true)
  }

  removeLoadingState() {
    let loadingIcon = this.videoWrapper.querySelector('.loading-icon')
    loadingIcon.parentNode.removeChild(loadingIcon)
    this.removeAttribute('data-loading')
  }

  // Clears out the page to get it ready for a new upcoming video
  clearOldVideo() {
    this.videoWrapper.innerHTML = ''
  }

  // Helper function that calls API functions and deals with the getItem promise
  loadVideo(itemUrl, fragmentUrl) {
    this.removeListeners()
    this.clearOldVideo()
    this.setLoadingState()

    this.getItem(fragmentUrl).then(response => {
      this.removeLoadingState()
      this.insertVideo(response, itemUrl)
    })
  }

  // Simple fetch helper
  getItem(itemUrl) {
    return new Promise((resolve, reject) => {
      window
        .fetch(itemUrl, {
          credentials: 'include'
        })
        .then(response => {
          resolve(response.text())
        })
        .catch(() => {
          reject()
        })
    })
  }

  // Helper function to deal with an incoming video. It filters the response to make sure we just
  // have the video itself and then also deals with history entries as it has all the data from
  // the response. Finally, after we render the video, we re-check the playlist to mark the new item
  insertVideo(response, url) {
    // filter HTML response
    let filterDiv = document.createElement('div')
    filterDiv.innerHTML = response

    let videoPageWrapperSelector = '[data-video-player-wrapper]'

    if (this.isVideoPage) {
      videoPageWrapperSelector = '[data-video-wrapper]'
    }

    let videoPageFromResponse = filterDiv.querySelector(
      videoPageWrapperSelector
    ).innerHTML

    if (videoPageFromResponse) {
      this.videoWrapper.innerHTML = videoPageFromResponse
    }

    if (this.isVideoPage) {
      // replace the title and URL after rendering
      let newTitle = filterDiv.querySelector('title').innerHTML

      window.history.replaceState({}, newTitle, url)
      document.title = newTitle
    }

    if (filterDiv.querySelector('[data-companion-content]') !== null) {
      this.companionContent = filterDiv.querySelector(
        '[data-companion-content]'
      )
    } else {
      this.companionContent = null
    }

    // mark the item current now that we have the right URL
    this.markCurrentPlaylistItem(url)

    // redo this event, as we have a new video player
    this.reactToVideoEnd()
  }

  // We always run this handler and the desktop media queries just ignore the data attributes
  handlePlaylistOpening() {
    if (this.playlist) {
      let playlistHeader = this.playlist.querySelector(
        '.VideoPlaylistPage-playlist-header'
      )

      if (playlistHeader) {
        playlistHeader.addEventListener('click', () => {
          this.togglePlaylist()
        })
      }
    }
  }

  // this checks the playlist items against the current URL and marks the right one
  markCurrentPlaylistItem(url, filterDiv) {
    // firstFlag ensures the scrollTo will only be triggered once per click
    let firstFlag = false
    this.playlistItems.forEach((item, i) => {
      const playlistItem = item.querySelector('.PlaylistItem')
      playlistItem.removeAttribute('data-current-item')
      if (item.hasAttribute('data-expand')) {
        item.removeAttribute('data-expand')
      }

      let itemUrl = playlistItem.getAttribute('data-item-url')
      if (itemUrl) {
        if (url.indexOf(itemUrl) > -1) {
          playlistItem.setAttribute('data-current-item', true)

          // if the response has companion content and current playlist item doesn't
          // already have companion content
          if (this.companionContent !== null) {
            item.setAttribute('data-expand', true)
            if (item.querySelector('[data-companion-content]') === null) {
              const companionContentClone = this.companionContent.cloneNode(
                true
              )
              const shaveCompanionContent = () =>
                this.shaveCompanionContent(companionContentClone)
              item.appendChild(companionContentClone)

              if (document.readyState !== 'complete') {
                // sometimes we need to re-shave when fonts loaded
                this.listenerTracker.addListener(
                  window,
                  'load',
                  shaveCompanionContent
                )
              }

              shaveCompanionContent()
              this.listenerTracker.addListener(
                window,
                'resize',
                throttle(200, shaveCompanionContent)
              )
            }
          }

          if (firstFlag === false && item.offsetParent !== null && i > 0) {
            firstFlag = true
            this.tabsContent.scrollTop = playlistItem.offsetTop - 34
          }
        }
      }
    })
  }

  shaveCompanionContent(companionContent) {
    if (!(companionContent instanceof HTMLElement)) {
      console.warn('Passed companion content must be an Element!')
      return
    }

    Array.from(
      companionContent.querySelectorAll('.CompanionContentItem-title')
    ).map(title => shave(title, 54))
  }

  // API functions to deal with playlist opening and closing. These data attributes can always be set
  // and the desktop media queries just ignore them
  openPlaylist() {
    document.body.setAttribute('data-toggle-video-playlist', true)
    this.setAttribute('data-toggle-video-playlist', true)
  }

  closePlaylist() {
    document.body.removeAttribute('data-toggle-video-playlist')
    this.removeAttribute('data-toggle-video-playlist')
  }

  togglePlaylist() {
    if (document.body.getAttribute('data-toggle-video-playlist')) {
      this.closePlaylist()
    } else {
      this.openPlaylist()
    }
  }
}
