/* global HTMLElement */
import { throttle } from '../utils/Throttle.js'
import { Tracker } from '../utils/Events'

/*
Expected attributes for the dynamic header component:
<body>
  <dynamic-header>
    <div>
      ...
    </div>
    <button data-menu-toggle>
      ...
    </button>
    <div data-menu data-show-menu="boolean">
      <ul>
        <li data-chapter-link>
          ...
        </li>
      </ul>
    </div>
    <span data-progress-bar>
    </span>
  </dynamic-header>
</body>
*/

const DEFAULT_HEADER_HEIGHT = 120
const OFFSET = 120

export class DynamicHeader extends HTMLElement {
  static selectors = {
    dynamicHeader: 'dynamic-header',
    headerVisibility: 'data-visible',
    mainHeader: 'PageHeader',
    menuButtons: '[data-menu-toggle]',
    actionButton: '[data-action-toggle]',
    menu: '[data-menu]',
    menuVisibility: 'data-show',
    progressBar: '[data-progress-bar]',
    chapterLinks: '[data-chapter-link]',
    dropdownOrientation: 'data-rotate'
  }

  constructor() {
    super()
    this.listenerTracker = new Tracker()
    this.permanentListeners = new Tracker()
    this.menuButton = this.querySelector(DynamicHeader.selectors.menuButton)
    this.menuButtons = [
      ...this.querySelectorAll(DynamicHeader.selectors.menuButtons)
    ]
    this.menus = [...this.querySelectorAll(DynamicHeader.selectors.menu)]
    this.progressBar = this.querySelector(DynamicHeader.selectors.progressBar)
    this.chaptersLinks = [
      ...this.querySelectorAll(DynamicHeader.selectors.chapterLinks)
    ]
    this.mainContent = document.querySelector('main[class$="-main"]')
  }

  connectedCallback() {
    this.collectListeners()
    this.initHeader()

    this.permanentListeners.addListener(
      window,
      'scroll',
      throttle(250, () => this.isMainHeaderVisible(this.getMainHeaderHeight()))
    )

    this.permanentListeners.addListener(
      window,
      'scroll',
      throttle(50, () => this.updateProgressBar())
    )
  }

  initHeader() {
    this.isMainHeaderVisible()
    this.updateProgressBar()
  }

  /**
   * Gets the height of the main header on the page.
   * Could have been set to a constant value but this will minimize overhead when Sotheby's redesigns the current header.
   *
   * @returns {number} the client height of the header if it exists
   */
  getMainHeaderHeight = () => {
    let mainHeader = document.getElementsByClassName(
      DynamicHeader.selectors.mainHeader
    )[0]
    let mainHeaderHeight
    mainHeader
      ? (mainHeaderHeight = mainHeader.getBoundingClientRect().height)
      : null

    return mainHeaderHeight
  }

  /**
   * Determines whether or not the main header is visible on the page by
   * calculating the page offset and comparing it to the main header clientHeight.
   * If no header.clientHeight is detected we are falling back to a default function argument for the comparison.
   *
   * @param {number} headerHeight The clientHeight of the main header.
   */
  isMainHeaderVisible(headerHeight = DEFAULT_HEADER_HEIGHT) {
    window.pageYOffset > headerHeight
      ? this.toggleHeaderVisibility(true)
      : this.toggleHeaderVisibility(false)
  }

  /**
   * Toggle's sticky header visibility
   *
   * @param {boolean} isVisible Header visibility.
   */
  toggleHeaderVisibility = isVisible =>
    this.setAttribute(
      DynamicHeader.selectors.headerVisibility,
      isVisible.toString()
    )

  /**
   *  Toggle's sticky header subNavigation visibility
   *
   * @param {HTMLElement} menuToggle the targeted menu
   */
  toggleSubNavVisibility = ({ menuToggle }) => {
    // we need to check if a another menu is already open.
    // If so we need to close it.
    let alreadyOpenedMenus = this.menus.filter(
      menu => menu.dataset.show === 'true' && menu.dataset.menu !== menuToggle
    )
    alreadyOpenedMenus.forEach(menu =>
      menu.setAttribute(DynamicHeader.selectors.menuVisibility, 'false')
    )
    alreadyOpenedMenus
      ? this.menuButtons
          .filter(button => button.dataset.rotate === '')
          .forEach(button =>
            button.removeAttribute(DynamicHeader.selectors.dropdownOrientation)
          )
      : null

    let [selectedMenu] = this.menus.filter(
      menu => menu.dataset.menu === menuToggle
    )
    let [button] = this.menuButtons.filter(
      button => button.dataset.menuToggle === menuToggle
    )
    let currentVisibility = selectedMenu.getAttribute(
      DynamicHeader.selectors.menuVisibility
    )
    currentVisibility === 'false' || currentVisibility === ''
      ? (selectedMenu.setAttribute(
          DynamicHeader.selectors.menuVisibility,
          'true'
        ),
        button.setAttribute(DynamicHeader.selectors.dropdownOrientation, ''))
      : (selectedMenu.setAttribute(
          DynamicHeader.selectors.menuVisibility,
          'false'
        ),
        button.removeAttribute(DynamicHeader.selectors.dropdownOrientation))
  }

  /**
   * Closes all subNavigation menus
   */
  closeAllSubNavs = () => {
    let alreadyOpenedMenus = this.menus.filter(
      menu => menu.dataset.show === 'true'
    )
    alreadyOpenedMenus.forEach(menu =>
      menu.setAttribute(DynamicHeader.selectors.menuVisibility, 'false')
    )
    alreadyOpenedMenus
      ? this.menuButtons
          .filter(button => button.dataset.rotate === '')
          .forEach(button =>
            button.removeAttribute(DynamicHeader.selectors.dropdownOrientation)
          )
      : null
  }

  /**
   *  Updates progress bar width by getting pageYoffset and dividing it by the height of the main content.
   */
  updateProgressBar = () => {
    let windowScroll = window.scrollY || window.pageYOffset
    let contentHeight = this.mainContent.offsetHeight
    let scrollPercentage = Math.ceil((windowScroll / contentHeight) * 100)
    this.progressBar.style.width = `${scrollPercentage}%`
  }

  /**
   * Smooth scrolls to selected chapter from list
   *
   * @param {HTMLElement} chapter list item that is clicked
   */
  scrollToChapter(chapter) {
    let chapterID = chapter.getElementsByTagName('a')[0].getAttribute('href')
    let chapterLocation = document.getElementById(
      `${chapterID.substring(1, chapterID.length)}`
    )
    this.listenerTracker.removeListeners()

    window.scrollTo({
      top: this.getOffset(chapterLocation),
      left: 0,
      behavior: 'smooth'
    })

    setTimeout(() => {
      this.collectListeners()
    }, 1000)
  }

  /**
   * Helper method that returns the location of the chapter on the page after accounting for the page offset and headerHeight
   *
   * @param {HTMLElement} chapter that we need to locate its position
   * @returns {number} The distance of the element from the top of the viewport minus the constant offset and headerHeight
   */
  getOffset = chapter => {
    const RECT = chapter.getBoundingClientRect()
    const SCROLL_TOP = window.pageYOffset || document.documentElement.scrollTop
    return RECT.top + SCROLL_TOP - (OFFSET + this.clientHeight)
  }

  /**
   * Adds event listeners to component
   */
  collectListeners = () => {
    this.menuButtons.forEach(button =>
      this.listenerTracker.addListener(button, 'click', () =>
        this.toggleSubNavVisibility(button.dataset)
      )
    )
    this.chaptersLinks.forEach(chapterLink =>
      this.listenerTracker.addListener(chapterLink, 'click', e => {
        e.preventDefault()
        this.closeAllSubNavs()
        this.scrollToChapter(chapterLink)
      })
    )
  }
}
