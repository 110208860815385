import shave from 'shave'
import MediaQueries from '../utils/MediaQueries'

/* global HTMLElement */
const mq = new MediaQueries().mq
export class FeaturedLotModule extends HTMLElement {
  connectedCallback() {
    let info = this.querySelector('.FeaturedLotModule-description')

    import(/* webpackChunkName: 'ResizeObserverPolyfill' */ '@juggle/resize-observer')
      .then(({ ResizeObserver }) => {
        const ro = new ResizeObserver(() => {
          this.truncate(info)
        })

        ro.observe(info)
      })
      .catch(e => console.warn('Error loading ResizeObserverPolyfill', e))
  }

  truncate(info) {
    let MaxHeight
    if (mq['mq-viewport-md'].matches) {
      MaxHeight = this.lotPromoHeight - this.titleHeight
    } else {
      MaxHeight = 140
    }
    shave(info, MaxHeight)
  }

  get lotPromoHeight() {
    const promo = this.querySelector('.FeaturedLotPromo')

    if (promo) {
      return promo.offsetHeight
    }
  }

  get titleHeight() {
    const title = this.querySelector('.FeaturedLotModule-title')

    if (title) {
      const style = title.currentStyle || window.getComputedStyle(title)
      return title.offsetHeight + window.parseInt(style.marginBottom)
    }
  }
}
