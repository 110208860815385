import { EventTracking } from './PageInteractions'
import SyndicationUtils from '../utils/SyndicationUtils'

export class PromoTracking {
  constructor(el) {
    this.el = el
    if (typeof window.analytics !== 'undefined' || SyndicationUtils.isIframe) {
      const parent = this.el.parentElement
      const promoLink = parent.nodeName === 'A' ? parent : null
      if (promoLink) {
        promoLink.addEventListener('click', e => {
          EventTracking('Click Action', {
            // was 'Content Card Clicked'
            card_details: this.el.querySelector('.PromoImageOnLeft-details')
              .innerText,
            card_title: this.el.querySelector('.PromoImageOnLeft-title')
              .innerText,
            card_type: this.el.querySelector('.PromoImageOnLeft-category')
              .innerText
          })
        })
      }
    }
  }
}
