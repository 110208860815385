import React from 'react'

export class ReactAlgoliaGeographicSearchModule {
  constructor(el) {
    const bsLocalization = JSON.parse(el.getAttribute('data-localization'))
    const bsProps = JSON.parse(el.getAttribute('data-props'))
    const mapProviderName = el.getAttribute('data-map-provider-name')
    let mapProviderConfig = undefined
    if (mapProviderName) {
      mapProviderConfig = JSON.parse(
        el.getAttribute('data-map-provider-config')
      )
    }

    const render = () => {
      Promise.all([
        import(/* webpackChunkName: 'AlgoliaGeographicSearchPage' */ './search/AlgoliaGeographicSearchPage.bs.js'),
        import(/* webpackChunkName: 'ReactDOM' */ 'react-dom')
      ])
        .then(([module, ReactDOM]) => {
          const AlgoliaGeographicSearchPage = module.default

          ReactDOM.render(
            <AlgoliaGeographicSearchPage
              bsLocalization={bsLocalization}
              bsProps={bsProps}
              mapProviderName={mapProviderName}
              mapProviderConfig={mapProviderConfig}
            />,
            el
          )
        })
        .catch(e =>
          console.warn('Error loading AlgoliaGeographicSearchPage', e)
        )
    }

    if (document.readyState === 'complete') {
      render()
    } else {
      window.addEventListener('load', render)
    }
  }
}
