import { throttle } from '../../utils/Throttle'

/* global HTMLElement */
export class MosaicFigureModule extends HTMLElement {
  connectedCallback() {
    // We only adjust the caption width for "comparison" elements
    let comparison = this.querySelector(
      '.MosaicFigure-container[data-comparison]'
    )
    if (!comparison) {
      return
    }

    this.items = this.querySelectorAll('.MosaicFigure-items-item')

    window.addEventListener(
      'resize',
      throttle(250, () => {
        this.setCaptionWidth()
      })
    )

    setTimeout(() => {
      this.setCaptionWidth()
    }, 250)
  }

  setCaptionWidth() {
    if (!this.items || this.items.length < 1) {
      return
    }

    var colGap = 0
    var itemWidths = 0
    for (var i = 0; i < this.items.length; i++) {
      itemWidths += this.items[i].offsetWidth
      if (i != 0) {
        colGap += 16
      }
    }

    let captionEl = this.querySelector('.MosaicFigure-caption')
    if (captionEl) {
      captionEl.style.width =
        Math.min(document.body.clientWidth, itemWidths + colGap) + 'px'
    }
  }
}
