import {
  CART_FETCH_SUCCESS,
  LOCAL_STORAGE_UPDATED
} from '../constants/cart'
import formatCurrency from '../../utils/Currency'
import { SET_GET_STARTED_LINK, UNSET_GET_STARTED_LINK } from '../constants/checkout'

const initialState = {
  orderSummary: '',
  subTotal: '',
  shipping: '',
  taxes: '',
  total: '',
  currency: '',
  getStartedLink: ''
}

export const parseRequirements = ({ orderRequirementsList }) =>
  orderRequirementsList && orderRequirementsList.split(',') || []

const parseEligiblePaymentMethods = ({ eligiblePaymentMethods }) =>
  Array.isArray(eligiblePaymentMethods)
    ? eligiblePaymentMethods.reduce((cur, acc) => acc.push({
      name: cur.paymentMethod.paymentMethodName,
      id: cur.paymentMethod.paymentMethodID,
      maximumAmount: cur.maximumAmount
    }), [])
    : []

export default (state = initialState, action) => {
  switch (action.type) {

    case CART_FETCH_SUCCESS:
      return {
        ...state,
        currencyCode: action.payload.cart.currencyCode,
        fulfillmentMethodID: action.payload.cart.orderFulfillments.length > 0 &&
          action.payload.cart.orderFulfillments[0] &&
          action.payload.cart.orderFulfillments[0].fulfillmentMethod.fulfillmentMethodID,
        subTotal: formatCurrency(action.payload.cart.subtotal, action.payload.cart.currencyCode),
        subTotalNumeric: action.payload.cart.subtotal,
        shipping: parseRequirements(action.payload.cart).includes('fulfillment')
          ? '--'
          : action.payload.cart.fulfillmentChargeAfterDiscountTotal
            ? formatCurrency(action.payload.cart.fulfillmentChargeAfterDiscountTotal, action.payload.cart.currencyCode)
            : 'FREE',
        shippingNumeric: action.payload.cart.fulfillmentChargeAfterDiscountTotal,
        taxes: parseRequirements(action.payload.cart).includes('fulfillment')
          ? '--'
          : formatCurrency(action.payload.cart.taxTotal, action.payload.cart.currencyCode),
        taxesNumeric: action.payload.cart.taxTotal,
        total: formatCurrency(action.payload.cart.total, action.payload.cart.currencyCode),
        discount: formatCurrency(action.payload.cart.discountTotal, action.payload.cart.currencyCode),
        discountNumeric: action.payload.cart.discountTotal,
        totalNumber: action.payload.cart.total,
        requirements: parseRequirements(action.payload.cart),
        eligiblePaymentMethods: parseEligiblePaymentMethods(action.payload.cart),
        orderID: action.payload.orderID ? action.payload.orderID : state.orderID
      }

    case SET_GET_STARTED_LINK:
      return {
        ...state,
        getStartedLink: action.payload
      }

    case UNSET_GET_STARTED_LINK:
      return {
        ...state,
        getStartedLink: ''
      }

    case LOCAL_STORAGE_UPDATED:
      return action.payload.order || {}

    default:
      return state
  }
}
