import { EventTracking } from './PageInteractions'

/* global HTMLElement */

export class ProductTracking extends HTMLElement {
  static selectors = {
    component: 'product-tracking'
  }

  static attributes = {
    priceString: 'price-string',
    skuCode: 'sku-code',
    name: 'name'
  }

  connectedCallback() {
    productViewed(
      this.price,
      this.currency,
      this.skuCode,
      this.skuCode,
      this.name
    )
  }

  get price() {
    return parsePriceString(
      this.getAttribute(ProductTracking.attributes.priceString)
    ).price
  }

  get currency() {
    return parsePriceString(
      this.getAttribute(ProductTracking.attributes.priceString)
    ).currency
  }

  get skuCode() {
    return this.getAttribute(ProductTracking.attributes.skuCode)
  }

  get name() {
    return this.getAttribute(ProductTracking.attributes.name)
  }
}

export const productPropertyBuilder = (
  price,
  currency,
  productId,
  sku,
  name
) => ({
  price,
  currency,
  product_id: productId,
  sku,
  name
})

export const quantifiedProductPropertyBuilder = (
  price,
  currency,
  productId,
  sku,
  name,
  quantity
) => ({
  ...productPropertyBuilder(price, currency, productId, sku, name),
  quantity
})

export function parseProductFromCart(skuCode, cartResponse) {
  let item =
    Array.isArray(cartResponse.orderItems) &&
    cartResponse.orderItems.filter(
      item => item.sku && item.sku.skuCode === skuCode
    )

  if (!item || item.length !== 1) {
    return {}
  }

  item = item[0]

  return quantifiedProductPropertyBuilder(
    item.extendedPriceAfterDiscount,
    item.currencyCode,
    item.sku && item.sku.skuCode,
    item.sku && item.sku.skuCode,
    item.sku &&
      (item.sku.product.productWebName || item.sku.product.productName),
    item.quantity
  )
}

/**
 * Brightspot sends the number string as a float + currency with a
 * delimeter of a whitespace. This function can get the currency and
 * price from that price string coming from BSP.
 *
 * @param {String} priceString from BSP
 *
 * @returns {Object} with price and currency properties
 */
export function parsePriceString(priceString) {
  const parsedString = priceString.split(' ')

  if (parsedString.length === 2) {
    return {
      price: parseFloat(parsedString[0].replace(/,/g, '')),
      currency: parsedString[1]
    }
  }

  return {}
}

/**
 * Tracks product viewed interactions
 *
 * @param {Number} price of the product
 * @param {String} currency of the product price
 * @param {String} productId of the product, currently same as sku
 * @param {String} sku code of the product
 * @param {String} name of the product
 */
export function productViewed(price, currency, productId, sku, name) {
  const properties = productPropertyBuilder(
    price,
    currency,
    productId,
    sku,
    name
  )
  EventTracking('Product Viewed', properties)
}

/**
 * Tracks product added interactions
 *
 * @param {Number} price of the product
 * @param {String} currency of the product price
 * @param {String} productId of the product, currently same as sku
 * @param {String} sku code of the product
 * @param {String} name of the product
 * @param {Number} quantity of the product
 */
export function productAdded(price, currency, productId, sku, name, quantity) {
  const properties = quantifiedProductPropertyBuilder(
    price,
    currency,
    productId,
    sku,
    name,
    quantity
  )
  EventTracking('Product Added', properties)
}

/**
 * Tracks product removed interactions
 *
 * @param {Number} price of the product
 * @param {String} currency of the product price
 * @param {String} productId of the product, currently same as sku
 * @param {String} sku code of the product
 * @param {String} name of the product
 * @param {Number} quantity of the product
 */
export function productRemoved(
  price,
  currency,
  productId,
  sku,
  name,
  quantity
) {
  const properties = quantifiedProductPropertyBuilder(
    price,
    currency,
    productId,
    sku,
    name,
    quantity
  )
  EventTracking('Product Removed', properties)
}

/**
 * Tracks when checkout has started
 *
 * @param {Number} total of transaction w/o shipping or taxes
 * @param {String} currency of the cart
 * @param {Array} products in cart
 */
export function checkoutStarted(total, currency, products) {
  EventTracking('Checkout Started', { total, currency, products })
}

/**
 * Tracks checkout steps viewed
 *
 * @param {Number} step of checkout viewed
 * @param {Number} total of transaction w/o shipping or taxes
 * @param {String} currency of the cart
 * @param {Array} products in cart
 */
export function checkoutStepViewed(props) {
  EventTracking('Checkout Step Viewed', props)
}

/**
 * Tracks checkout steps completed
 *
 * @param {Number} step of checkout completed
 * @param {Number} total of transaction w/o shipping or taxes
 * @param {String} currency of the cart
 * @param {Array} products in cart
 */
export function checkoutStepCompleted(props) {
  EventTracking('Checkout Step Completed', props)
}

/**
 * Tracks when payment info has been entered
 *
 * @param {String} orderId id of the order
 * @param {Number} total of transaction w/o shipping or taxes
 * @param {String} currency of the cart
 * @param {Number} revenue of transaction (w/o shipping or taxes or coupons / discounts)
 * @param {Number} shipping price for fulfillment
 * @param {Number} tax for transaction
 * @param {Number} discount for order
 * @param {Array} coupon code applied
 * @param {Array} products in cart
 */
export function paymentInfoEntered(
  orderId,
  total,
  currency,
  revenue,
  shipping,
  tax,
  discount,
  coupon,
  products
) {
  EventTracking('Payment Info Entered', {
    order_id: orderId,
    total,
    currency,
    revenue,
    shipping,
    tax,
    discount,
    coupon,
    products
  })
}

/**
 * Tracks when payment info has been entered
 *
 * @param {String} orderId id of the order
 * @param {Number} total of transaction w/o shipping or taxes
 * @param {String} currency of the cart
 * @param {Number} revenue of transaction (w/o shipping or taxes or coupons / discounts)
 * @param {Number} shipping price for fulfillment
 * @param {Number} tax for transaction
 * @param {Number} discount for order
 * @param {Array} coupon code applied
 * @param {Array} products in cart
 */
export function orderCompleted(
  orderId,
  total,
  currency,
  revenue,
  shipping,
  tax,
  discount,
  coupon,
  products
) {
  EventTracking('Order Completed', {
    order_id: orderId,
    total,
    currency,
    revenue,
    shipping,
    tax,
    discount,
    coupon,
    products
  })
}

/**
 * Tracks when a coupon is applied
 *
 * @param {String} couponCode
 */
export function couponApplied(couponCode) {
  EventTracking('Coupon Applied', {
    coupon_name: couponCode
  })
}

/**
 * Tracks when a coupon is denied
 *
 * @param {String} couponCode
 */
export function couponDenied(couponCode) {
  EventTracking('Coupon Denied', {
    coupon_name: couponCode
  })
}

/**
 * Tracks when a coupon is entered
 *
 * @param {String} couponCode
 */
export function couponEntered(couponCode) {
  EventTracking('Coupon Entered', {
    coupon_name: couponCode
  })
}

/**
 * Tracks when a coupon is removed
 *
 * @param {String} couponCode
 */
export function couponRemoved(couponCode) {
  EventTracking('Coupon Removed', {
    coupon_name: couponCode
  })
}

/**
 * Tracks when a product/subscription promotion is clicked
 *
 * @param {String} ctaText of promotion
 */
export function promotionClicked(ctaText) {
  EventTracking('Promotion Clicked', {
    cta_text: ctaText
  })
}
