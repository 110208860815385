import { getPageProperties } from '../segment/PageInteractions'

export const FALLBACK_ROOT_DOMAIN_ATTR = 'data-root-domain'

/**
 * Creates a cookie based on given params
 *
 * @param  {string}    name The name of the cookie
 * @param  {string}    value The value of the cookie
 * @param  {number}    UTC timestamp when cookie expires
 *
 * @returns {Object}    Returns true if element is in the viewport
 */
export function createCookie(name, value, timestamp, domain) {
  let expires = ''
  let dm = ''
  if (timestamp) {
    let date = ''
    if (timestamp === 'max') {
      // sets the expiration date to 12/31/2030
      date = new Date(1924923600000)
    } else {
      date = new Date(parseInt(timestamp))
    }
    expires = '; expires=' + date.toUTCString()
  }
  if (domain) {
    dm = `;domain=${domain}`
  }
  document.cookie = name + '=' + value + expires + dm + '; path=/'
}

/**
 * Checks whether or not a cookie exists
 *
 * @param  {string}    name The name of the cookie
 *
 * @return {Boolean}   Returns true if the cookie exists
 */
export function hasCookie(name) {
  return getCookie(name) != null
}

/**
 * Checks whether or not a cookie exists
 *
 * @param  {string}   name The name of the cookie
 *
 * @return {Object}   Returns the cookie as an object if it exists
 */
export function getCookie(name) {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

/**
 * Removes cookie by expiring it
 *
 * @param  {string}   name The name of the cookie
 */
export function eraseCookie(name, domain) {
  // sets the expiration date to 01/01/1980
  createCookie(name, '', 315550800000, domain)
}

/**
 * If you want to just set the days and aren't worried about the date:
 *
 * @param  {number}   days from now
 *
 * Example: createCookie('cookieName', 'cookieValue', daysInUTC(30))
 *
 */
export function daysInUTC(days) {
  let date = new Date()
  return date.setDate(date.getDate() + parseInt(days))
}

/**
 * Gets the root domain for cookies. This is the domain the back-end
 * uses for restrictions.
 *
 * @return {string} root domainn sent from bsp, or undefined
 */
export function getRootCookieDomain() {
  const { domain } = getPageProperties('data-page-properties')
  const fallbackDomain =
    document.querySelector(`[${FALLBACK_ROOT_DOMAIN_ATTR}]`) &&
    document
      .querySelector(`[${FALLBACK_ROOT_DOMAIN_ATTR}]`)
      .getAttribute(FALLBACK_ROOT_DOMAIN_ATTR)
  return domain || fallbackDomain || {}
}
