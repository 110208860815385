import { throttle } from './Throttle'
import MediaQueries from '../utils/MediaQueries'
const mq = new MediaQueries().mq

export class ReadMore {
  static selectors = {
    readMoreAttr: 'data-read-more-collapse',
    readMoreText: '[data-read-more-content]',
    tabletThreshold: 400,
    desktopThreshold: 285,
    link: '[data-link]',
    openedLink: '[data-link-opened]'
  }

  constructor(el) {
    this.el = el

    if (ReadMore.selectors.readMoreAttr) {
      this.readMore = this.el.querySelector(ReadMore.selectors.link)
      this.readLess = this.el.querySelector(ReadMore.selectors.openedLink)
      this.text = this.el.querySelector(ReadMore.selectors.readMoreText)

      this.determineShowHide()

      window.addEventListener(
        'resize',
        throttle(250, () => {
          this.determineShowHide()
        })
      )

      this.readMore &&
        this.readMore.addEventListener('click', e => {
          this.showHideText('', 'false')
        })

      this.readLess &&
        this.readLess.addEventListener('click', e => {
          this.el.setAttribute(ReadMore.selectors.readMoreAttr, 'true')
        })
    }
  }

  determineShowHide() {
    // hide text when plugin is registered and larger than container
    // and if not already triggered
    if (this.el.getAttribute(ReadMore.selectors.readMoreAttr) !== 'false') {
      if (mq['mq-viewport-lg'].matches) {
        this.showHideText(ReadMore.selectors.desktopThreshold, 'true')
      } else if (mq['mq-viewport-md'].matches) {
        this.showHideText(ReadMore.selectors.tabletThreshold, 'true')
      }
    }
  }

  showHideText(threshold, toggle) {
    if (this.text && this.text.clientHeight > threshold) {
      this.el.setAttribute(ReadMore.selectors.readMoreAttr, toggle)
    }
  }
}
