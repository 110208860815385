import { getPageProperties } from '../segment/PageInteractions'
import UserDataUnverified from './UserDataUnverified'
import { DOMContentLoaded } from './DOMContentLoaded'

export default class UserData extends UserDataUnverified {
  // ----- Singleton Instance -----

  /**
   * Gets singleton instance of the class.
   *
   * @description used to get the singleton instance of the class. Can be called from static methods
   *  to work with singleton instance data. Stores singleton instance in window global variable.
   *  If this fails, then it falls back to restoring from static class variable.
   * @return {UserData} instance
   */
  static get instance() {
    // Try to use object if already created.
    // Try window object first, fallback to static from class (if window isn't available)
    const cache = window.__BPS_USER_DATA_UTIL__ || UserData.__instance__

    // not checking against instanceof UserData because UserData class name is mangled after minification
    // this class extends UserDataUnverified, so we will copy its values to a new instance of this class
    const instance =
      cache instanceof Object
        ? Object.assign(new UserData(), cache)
        : new UserData()

    // set new cache
    window.__BPS_USER_DATA_UTIL__ = instance // store in window object
    UserData.__instance__ = instance // store in static class

    return instance
  }

  /**
   * Gets verified accesses JWT.
   *
   * @async
   * @see UserData#getUnverifiedAccesses for an unverified version
   *  which does not require a public key.
   * @function getAccesses
   * @requires UserData#configureAuth0 to make sure Auth0 is configured
   * @param {string?} publicKey to verify the accesses JWT against
   * @param {string?} issuer of the certificate
   * @returns {Promise<array>} resolves to array of access strings
   */
  static getAccesses = async (publicKey, issuer) => {
    if (UserData.instance.verifiedAccessesCache != null) {
      return UserData.instance.verifiedAccessesCache
    }

    if (!UserData.meetsAuthPrecondition()) {
      // check sub-auth cookie
      return [] // not logged in, no accesses
    }

    if (UserData.instance.accessesErrorCache) {
      throw new Error(`Cached error: ${UserData.instance.accessesErrorCache}`)
    }

    const jwt = await UserData.instance._getAccessesJWT()
    const decoded = await UserData.instance._verifyJWT(jwt, publicKey, issuer)

    if (!decoded) {
      UserData.clearAccesses()
      let reason = 'JWT verification failed'
      if (!jwt) {
        reason += 'JWT not returned from bsp.accesses call'
      }
      UserData.instance.accessesErrorCache = reason
      throw new Error(reason)
    } else {
      const parsed = UserData.instance._parseJWT(decoded)
      UserData.instance.verifiedAccessesCache = parsed
      return parsed
    }
  }

  /**
   * Returns the list of the user's accesses.
   *
   * @async
   * @function accessList
   * @return {Promise<array>} resolves to array of access strings
   */
  static accessList = async () => {
    await DOMContentLoaded // wait for dom to finish parsing

    // ensure Auth0 is configured before getting accesses
    if (!UserData.isAuth0Configured()) {
      const { auth0Domain, auth0ClientID, auth0Audience } = getPageProperties(
        'data-page-properties'
      )
      UserData.configureAuth0(auth0Domain, auth0ClientID, auth0Audience)
    }

    return await UserData.getAccesses()
  }

  /**
   * Returns user's preferred tier.
   *
   * @async
   * @function accessTier
   * @return {Promise<number>} number of user's preferred tier
   */
  static accessTier = async () => {
    const userAccesses = await UserData.accessList()

    let tier = null
    if (userAccesses.includes(UserData.accesses.PREFERRED)) {
      tier = 3
    }
    if (userAccesses.includes(UserData.accesses.TIER2)) {
      tier = 2
    }
    if (userAccesses.includes(UserData.accesses.TIER1)) {
      tier = 1
    }
    return tier
  }

  /**
   * Checks if user has given access. Pass each access as a separate parameter, will
   * only return true if all of the accesses passed are in the user's accesses.
   *
   * @async
   * @function hasAccess
   * @param {string} accesses to query if the user has this access. Can accept multiple.
   * @throws {error} if access queried for not part of UserData.accesses, or
   *  any error while getting user accesses.
   * @return {Promise<boolean>} true if the user has this access.
   */
  static hasAccess = async (...accesses) => {
    // ensure access queried for is valid
    const allowedAccesses = Object.values(UserData.accesses)
    if (accesses.filter(a => !allowedAccesses.includes(a)).length > 0) {
      throw new Error(
        `Access not valid. Must be one of the following: ${allowedAccesses.join(
          ', '
        )}.`
      )
    }

    const userAccesses = await UserData.accessList()

    return accesses.filter(a => !userAccesses.includes(a)).length === 0
  }

  constructor() {
    super()

    this.options = {
      // set default options
      origin: ''
    }
  }

  // ----- Helper Methods -----

  _verifyJWT = async (jwt, publicKey, issuer) => {
    if (!jwt) {
      return null
    }

    if (!publicKey) {
      const body = document.querySelector('body')
      if (body == null) {
        return null
      }

      publicKey = body.getAttribute(UserData.RESTRICTABLE_PUB_KEY_ATTR)
    }

    if (!issuer) {
      const { jwtIssuer } =
        getPageProperties('data-page-properties', false) || {}
      issuer = jwtIssuer
    }

    if (!publicKey || !issuer) {
      console.warn('Public key or Issuer is null', publicKey, issuer)
      return null
    }

    const {
      KJUR,
      KEYUTIL
    } = await import(/* webpackChunkName: 'JSRSASign' */ 'jsrsasign')

    try {
      // surround with try/catch since KEYUTIL & KJUR could be undefined
      const pubKey = KEYUTIL.getKey(publicKey)
      const valid = KJUR.jws.JWS.verifyJWT(jwt, pubKey, {
        alg: ['RS256'],
        iss: [issuer],
        aud: issuer,
        gracePeriod: 1 * 60 * 5 // accept 5 mins slow or fast
      })
      return valid ? jwt : null
    } catch (e) {
      console.warn(e)
      return null
    }
  }
}
