const MODULE_TITLE = '[data-collapsible-title]'
const IS_COLLAPSED_ATTR = 'data-is-collapsed'

export class CollapsibleFilter {
  constructor(el) {
    this.element = el
    const collapsibleTitle = this.element.querySelector(MODULE_TITLE)
    const filterGroup = this.element.getAttribute(
      'data-collapsible-filter-group'
    )
    this.filterGroupFilters = filterGroup
      ? document.querySelectorAll(
          `[data-collapsible-filter-group="${filterGroup}"]`
        )
      : null

    // Collapse all grouped filters if the user clicks anywhere on the body (except CollapsibleFilter pieces).
    document.body.addEventListener('click', e => {
      const shouldIgnoreClick = !!e.target.closest('[data-collapsible-filter]')
      if (shouldIgnoreClick) {
        return false
      }
      this.collapseAllOtherGroupedFilters()
    })

    collapsibleTitle.addEventListener('click', e => {
      e.preventDefault()

      // If this filter is part of a group, force-close any open filters in this group.
      // (But don't force-close the filter that was clicked!)
      this.collapseAllOtherGroupedFilters()

      if (this.element.getAttribute(IS_COLLAPSED_ATTR) === 'false') {
        this.element.setAttribute(IS_COLLAPSED_ATTR, 'true')
      } else {
        this.element.setAttribute(IS_COLLAPSED_ATTR, 'false')
      }
    })
  }

  collapseAllOtherGroupedFilters() {
    if (this.filterGroupFilters && this.filterGroupFilters.length) {
      this.filterGroupFilters.forEach(filter => {
        if (filter != this.element) {
          filter.setAttribute(IS_COLLAPSED_ATTR, 'false')
        }
      })
    }
  }
}
