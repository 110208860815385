/* global HTMLElement */

export class PersonalizedContent extends HTMLElement {
  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.connectedCallbackExecuted = true
      this.loadUrl = this.getAttribute('data-load-url')
      this.loadFragment()
    }
  }

  loadFragment() {
    window
      .fetch(this.loadUrl, { credentials: 'include', mode: 'cors' })
      .then(response => {
        return response.text()
      })
      .then(body => {
        this.innerHTML = body
      })
  }
}
