/* global HTMLElement */
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'

const TOGGLE_ATTR = 'data-toggle'
const SEGMENT_EVENT = 'View Type Selected'

export class SearchStyleToggle extends HTMLElement {
  static attributes = {
    toggle: TOGGLE_ATTR,
    listToggle: `${TOGGLE_ATTR}="list"`,
    gridToggle: `${TOGGLE_ATTR}="grid"`,
    searchStyle: 'data-search-style'
  }

  static events = {
    styleUpdated: 'SEARCH_VIEW_STYLE_UPDATED'
  }

  connectedCallback() {
    this.listToggle = this.querySelector(
      `[${SearchStyleToggle.attributes.listToggle}]`
    )
    this.gridToggle = this.querySelectorAll(
      `[${SearchStyleToggle.attributes.gridToggle}]`
    )
    this.searchMain = this.closest(
      `[${SearchStyleToggle.attributes.searchStyle}]`
    )
    this.styleUpdatedEvent = new window.CustomEvent(
      SearchStyleToggle.events.styleUpdated
    )

    this.init()
  }

  init() {
    this.listToggle.addEventListener('click', () => {
      this.toggle(this.listToggle)
    })

    this.gridToggle.forEach(item => {
      item.addEventListener('click', () => {
        this.toggle(item)
      })
    })
  }

  toggle(toggled) {
    const toggleStyle = toggled.getAttribute(
      SearchStyleToggle.attributes.toggle
    )

    if (toggleStyle) {
      this.searchMain.setAttribute(
        SearchStyleToggle.attributes.searchStyle,
        toggleStyle
      )
      window.dispatchEvent(this.styleUpdatedEvent)
      this.sendToggleAnalytics(toggleStyle)
    }
  }

  sendToggleAnalytics(style) {
    const pageTrackingProps = getPageProperties()
    const selection = style[0].toUpperCase() + style.slice(1)

    if (pageTrackingProps) {
      const toggleProps = {
        view_selection: `${pageTrackingProps.name}: ${selection}`
      }

      EventTracking(SEGMENT_EVENT, toggleProps)
    }
  }
}
