import * as PageUtils from './PageUtils'

/**
 * Tools to communicate with parent window if content lives within an iframe.
 */
export default class SyndicationUtils {
  static get isIframe() {
    return window.parent && window.location !== window.parent.location
  }

  static events = {
    ANALYTICS: 'ANALYTICS',
    PAGE_HEIGHT: 'PAGE_HEIGHT',
    IS_FULLSCREEN: 'IS_FULLSCREEN'
  }

  static dispatch(event, payload, source = 'sothebys-bsp') {
    window.parent.postMessage(
      {
        source,
        type: event,
        payload
      },
      '*'
    )
  }

  static pageHeightListener() {
    let height = PageUtils.getPageHeight()
    SyndicationUtils.dispatch(SyndicationUtils.events.PAGE_HEIGHT, height)

    import(/* webpackChunkName: 'ResizeObserverPolyfill' */ '@juggle/resize-observer')
      .then(({ ResizeObserver }) => {
        const ro = new ResizeObserver(() => {
          if (height !== PageUtils.getPageHeight()) {
            height = PageUtils.getPageHeight()
            SyndicationUtils.dispatch(
              SyndicationUtils.events.PAGE_HEIGHT,
              height
            )
          }
        })

        ro.observe(document.body)
      })
      .catch(e => console.warn('Error loading ResizeObserverPolyfill', e))
  }
}
