import { PriceDisplay } from './PriceDisplay'
import { CheckoutButton } from '../product/CheckoutButton'

/* global HTMLElement */

export class PriceChoice extends HTMLElement {
  static selectors = {
    component: 'price-choice',
    input: 'input'
  }

  static attributes = {
    highestPrice: 'highest-price',
    price: 'price',
    renewalPrice: 'renewal-price',
    skuCode: 'sku-code'
  }

  static events = {
    click: 'click'
  }

  connectedCallback() {
    this.addEventListener(PriceChoice.events.click, this.displayPricingInfo)
  }

  disconnectedCallback() {
    this.removeEventListener(PriceChoice.events.click, this.displayPricingInfo)
  }

  displayPricingInfo() {
    this.displays.map(display => {
      display.setAttribute(PriceDisplay.attributes.price, this.price)
      display.setAttribute(
        PriceDisplay.attributes.renewalPrice,
        this.renewalPrice
      )
    })

    this.checkoutButtons.map(button => {
      button.setAttribute(CheckoutButton.attributes.skuCode, this.skuCode)
    })
  }

  get displays() {
    return (
      [...document.querySelectorAll(PriceDisplay.selectors.component)] || []
    )
  }

  get checkoutButtons() {
    return (
      [...document.querySelectorAll(CheckoutButton.selectors.component)] || []
    )
  }

  get highestPrice() {
    return this.getAttribute(PriceChoice.attributes.highestPrice)
  }

  get price() {
    return this.getAttribute(PriceChoice.attributes.price)
  }

  get renewalPrice() {
    return this.getAttribute(PriceChoice.attributes.renewalPrice)
  }

  get skuCode() {
    return this.getAttribute(PriceChoice.attributes.skuCode)
  }
}
