/* eslint-disable */
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'
import { accessTier } from '../../utils/UserData'
import { getUserProperties } from '../../segment/IdentityTracking'

export class CatalogHeroCarousel {
  constructor(el) {
    FixFlickity()

    this.element = el
    let count = document.getElementsByClassName('CatalogHeroSlide').length
    // Only initialize the carousel if there is more than one slide
    if (count > 1) {
      this.initCarousel()
    }

    if (typeof analytics !== 'undefined') {
      this.initAnalytics()
    }
  }

  initCarousel() {
    let seconds
    let carousel = document.getElementsByClassName('CatalogHeroCarousel')[0]
    let autoPlay = carousel.hasAttribute('data-slider-time')
    if (autoPlay) {
      seconds = 5000
      seconds = carousel.getAttribute('data-slider-time')
      seconds = seconds * 1000 // Convert to Milliseconds
      console.log(seconds)
    } else {
      seconds = false // Default to false
    }
    this.carousel = new Flickity(this.element, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      autoPlay: seconds
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }

  initAnalytics() {
    if (!this.element) {
      return
    }

    const heroVersionName = 'Catalog Hero'
    const heroLinks = this.element.querySelectorAll('a')
    const heroImage = this.element.querySelector('.Image')
    const heroNameContainer = this.element.querySelector(
      '.CatalogHeroSlide-info-name'
    )
    const saleNumber = this.element.getAttribute('sale-number')

    let heroProps = {
      homepage_featuredtext: heroNameContainer && heroNameContainer.innerText,
      sale_id: saleNumber
    }

    if (heroImage.getAttribute('alt') !== '') {
      heroProps['homepage_imagename'] = heroImage.getAttribute('alt')
    }

    heroLinks.forEach(link => {
      link.addEventListener('click', e => {
        const auth0Id = getPageProperties('data-page-properties')[
          'auth0ClientID'
        ]
        const tier = !accessTier ? 0 : accessTier
        const customerId = getUserProperties().customer_id

        let cardType = 'hero'
        const card = this.element.querySelector(
          '[class$="is-selected"] [data-type]'
        )
        if (card) {
          cardType = card.getAttribute('data-type')
        }

        const props = {
          ...heroProps,
          card_type: cardType,
          carousel_style: heroVersionName,
          carousel_title: '',
          card_title: link.innerText,
          auth0_id: auth0Id,
          preferred_member_flag: tier,
          customer_id: customerId
        }

        EventTracking('Click Action', props)
      })
    })
  }
}
