import { combineReducers } from 'redux'
import account from './account'
import banner from './banner'
import billing from './billing'
import brightspot from './brightspot'
import order from './order'
import loading from './loading'
import payment from './payment'
import products from './products'
import shipping from './shipping'

/**
 * Describes the state of the app. Access these with the connect module, using "mapStateToProps".
 */
export default function (bspProps) {
  return combineReducers({
    account,
    banner,
    billing,
    order,
    loading,
    payment,
    products,
    shipping,
    brightspot,
    lastAction: (s, a) => a.type // keeps track of the last action passed
  })
}
