/* global HTMLElement */
import { Tracker } from './Events'
import MediaQueries from './MediaQueries'

/*
Expected attributes for the chapter-module component:
<body>
  <div data-chapter-module-helper>
    <chapter-module data-visible>
      <div data-chapter-heading>
        ...
      </div>
      <ul data-chapter-list>
        <li data-chapter-link>
          ...
        </li>
      </ul>
    </chapter-module>
  </div>
</body>
*/

const mq = new MediaQueries().mq
const MIN_VIEWPORT = 'mq-viewport-md'
const OFFSET = 120

export class ChapterModule extends HTMLElement {
  static selectors = {
    chapterModule: 'chapter-module',
    chapterLinks: '[data-chapter-link]',
    chapterListHeading: '[data-chapter-heading]',
    chapterList: '[data-chapter-list]',
    chapterListVisibility: 'data-visible',
    helper: '[data-chapter-module-helper]'
  }

  constructor() {
    super()
    this.listenerTracker = new Tracker()
    this.chaptersLinks = [
      ...this.querySelectorAll(ChapterModule.selectors.chapterLinks)
    ]
    this.chapterListHeading = this.querySelector(
      ChapterModule.selectors.chapterListHeading
    )
    this.chapterList = this.querySelector(ChapterModule.selectors.chapterList)
    this.helper = document.querySelector(ChapterModule.selectors.helper)
  }

  connectedCallback() {
    this.collectListeners()
  }

  /**
   * Toggles the visibility off the chapter module list contents for mobile viewports
   */
  toggleChapterVisibility = () => {
    if (!mq[MIN_VIEWPORT].matches) {
      this.getAttribute(ChapterModule.selectors.chapterListVisibility) ===
      'true'
        ? this.removeMargin()
        : this.addMargin()
    }
  }

  /**
   * Smooth scrolls to selected chapter from list
   *
   * @param {HTMLElement} chapter list item that is clicked
   */
  scrollToChapter(chapter) {
    let chapterID = chapter.getElementsByTagName('a')[0].getAttribute('href')
    let chapterLocation = document.getElementById(
      `${chapterID.substring(1, chapterID.length)}`
    )
    this.listenerTracker.removeListeners()

    window.scrollTo({
      top: this.getOffset(chapterLocation),
      left: 0,
      behavior: 'smooth'
    })

    setTimeout(() => {
      this.collectListeners()
    }, 1000)
  }

  /**
   * Helper method that returns the location of the chapter on the page after accounting for the page offset
   *
   * @param {HTMLElement} chapter that we need to locate its position
   * @returns {number} The distance of the element from the top of the viewport minus the constant offset
   */
  getOffset = chapter => {
    const RECT = chapter.getBoundingClientRect()
    const SCROLL_TOP = window.pageYOffset || document.documentElement.scrollTop
    return RECT.top + SCROLL_TOP - OFFSET
  }

  /**
   * Changes chapter list visibility to true and adjusts necessary margin to prevent list from overlapping the text below it.
   * The list height changes dynamically so we need to consider that when setting the margin.
   */
  addMargin() {
    this.setAttribute(ChapterModule.selectors.chapterListVisibility, 'true')
    this.helper.style.marginBottom = `${this.chapterList.clientHeight + 56}px`
  }

  /**
   * Hides chapter list and removes extra margin
   */
  removeMargin = () => {
    this.helper.removeAttribute('style')
    this.setAttribute(ChapterModule.selectors.chapterListVisibility, 'false')
  }

  /**
   * Adds event listeners to component
   */
  collectListeners = () => {
    this.listenerTracker.addListener(this.chapterListHeading, 'click', () => {
      this.toggleChapterVisibility()
    })
    this.chaptersLinks.forEach(chapter =>
      this.listenerTracker.addListener(chapter, 'click', e => {
        e.preventDefault()
        this.toggleChapterVisibility()
        this.scrollToChapter(chapter)
      })
    )
  }
}
