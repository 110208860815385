import UserData from '../utils/UserData'
import { getCustomerApiEndpoint, getPageTrackingName } from '../utils/PageUtils'
import { followedAuctionsQuery, isFollowingAuctionsQuery } from './user'
import {
  followAuctionMutation,
  unfollowAuctionMutation,
  auctionEnrollmentStatusAndPaddleIdQuery
} from './auctions'

const CUSTOMER_API_ENDPOINT = () => {
  const endpoint = getCustomerApiEndpoint()
  if (!endpoint) {
    throw new Error('No Customer API endpoint configured for Viking')
  }
  return endpoint
}

const _handleResponse = response => {
  if (response.ok) {
    return response.json()
  }
  throw new Error('Network response was not ok.')
}

const _followAuction = async (query, auctionId, saleId) =>
  window
    .fetch(CUSTOMER_API_ENDPOINT(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await UserData.getAuth0Token()}`
      },
      body: JSON.stringify({
        query,
        variables: {
          auctionId: auctionId || null, // needs to be a valid UUID or null
          saleId: saleId || null, // needs to be a non-empty string or null
          followOrigin: getPageTrackingName(),
          preferredMember: await UserData.hasAccess(UserData.accesses.PREFERRED)
        }
      }),
      cache: 'no-cache'
    })
    .then(_handleResponse)
    .then(res => {
      const error = res.error || res.errors
      if (error) {
        console.error(error)
      }
      return res
    })

export const followAuction = (auctionId, saleId) =>
  _followAuction(followAuctionMutation, auctionId, saleId).then(
    res => res.data && res.data.followAuction
  )

export const unFollowAuction = (auctionId, saleId) =>
  _followAuction(unfollowAuctionMutation, auctionId, saleId).then(
    res => res.data && res.data.unFollowAuction
  )

export const getFollowedAuctions = async () => {
  return window
    .fetch(CUSTOMER_API_ENDPOINT(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await UserData.getAuth0Token()}`
      },
      body: JSON.stringify({
        operationName: 'FollowedAuctions',
        query: followedAuctionsQuery
      }),
      cache: 'no-cache'
    })
    .then(_handleResponse)
    .then(res => {
      if (res.error) {
        throw new Error('graphql error:', res.error)
      }
      if (res.data && res.data.user && res.data.user.followedAuctionIds) {
        return res.data.user.followedAuctionIds
      }
      throw new Error('Unexpected response for followed auctions:', res)
    })
}

export const isFollowingAuctions = async auctionIds =>
  window
    .fetch(CUSTOMER_API_ENDPOINT(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await UserData.getAuth0Token()}`
      },
      body: JSON.stringify({
        query: isFollowingAuctionsQuery,
        variables: {
          auctionIds
        }
      }),
      cache: 'no-cache'
    })
    .then(_handleResponse)
    .then(resp => resp && resp.data)
    .then(data => data && data.user)
    .then(user => user && user.followInfo)
    .then(followInfo => followInfo && followInfo.auctions)
    .then(
      auctionsFollowInfo => auctionsFollowInfo && auctionsFollowInfo.isFollowing
    )

export const isFollowingAuction = async auctionId =>
  isFollowingAuctions([auctionId]).then(isFollowingInfos => {
    if (!Array.isArray(isFollowingInfos)) {
      return false
    }
    const auctionFollowInfo = isFollowingInfos[0]
    return (
      auctionFollowInfo.auctionId === auctionId && auctionFollowInfo.isFollowing
    )
  })

export const getAuctionEnrollmentStatusAndPaddleId = async auctionId =>
  window
    .fetch(CUSTOMER_API_ENDPOINT(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await UserData.getAuth0Token()}`
      },
      body: JSON.stringify({
        query: auctionEnrollmentStatusAndPaddleIdQuery,
        variables: {
          auctionId
        }
      }),
      cache: 'no-cache'
    })
    .then(_handleResponse)
    .then(resp => resp && resp.data)
    .then(data => data && data.auction)
