import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'

export class CarouselWithOverlay {
  constructor(el) {
    FixFlickity()

    this.element = el
    this.initCarousel()
  }

  initCarousel() {
    this.carousel = new Flickity(this.element, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      groupCells: false
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }
}
