/* global HTMLElement */
import React from 'react'

import {
  isFollowingAuction,
  followAuction,
  unFollowAuction
} from '../graphql/CustomerApiUtils'
import { EventTracking } from '../segment/PageInteractions'
import { getPageTrackingName } from '../utils/PageUtils'
import UserData from '../utils/UserData'

export class FollowAuctionActionLink extends HTMLElement {
  static selector = 'follow-auction-actionlink'

  constructor() {
    super()
    /**
     * To follow/unfollow an auction, we need either
     * a) auctionId AND saleNumber for Viking auctions
     * b) saleId for Invaluable auctions
     */
    if (this.isSaveIcon) {
      this.attachShadow({ mode: 'open' })
    }
    if (!this.hasRequiredVikingInfo() && !this.hasRequiredInvaluableInfo()) {
      console.warn(
        [
          'To follow/unfollow an auction, FollowAuctionActionLink requires either',
          'a) auctionId AND saleNumber for Viking auctions or',
          'b) saleId for Invaluable auctions'
        ].join(' ')
      )
    }
    if (!this.itemType) {
      console.warn(`Missing required attribute 'itemType'`)
    }
  }

  hasRequiredVikingInfo = () => this.auctionId && this.saleNumber

  hasRequiredInvaluableInfo = () => this.saleNumber

  getAuctionIdForQuery = () =>
    this.hasRequiredVikingInfo() ? this.auctionId : this.saleNumber

  connectedCallback() {
    if (!UserData.meetsAuthPrecondition()) {
      this.render(false)
    } else {
      this.render(true)
      const auctionIdForQuery = this.getAuctionIdForQuery()

      isFollowingAuction(auctionIdForQuery)
        .then(isFollowing => {
          this.isFollowingAuction = isFollowing
          this.render(false)
        })
        .catch(err => {
          /*
            Render nothing if there's an error from Viking -- in which case the
            button serves no purpose
           */
          console.error('Error fetching followed auctions from Viking:', err)
          this.renderNone()
        })
    }
  }

  render = (loading, error = '') => {
    if (this.isSaveIcon) {
      const svgCode = this.isFollowingAuction
        ? `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.335 5.104a5.586 5.586 0 0 0-8.102.001 5.448 5.448 0 0 0-.233.253 7.729 7.729 0 0 0-.232-.254 5.59 5.59 0 0 0-4.05-1.738 5.59 5.59 0 0 0-4.051 1.738 5.975 5.975 0 0 0-1.662 4.372c.067 1.631.812 3.154 2.092 4.28l7.41 6.685a.748.748 0 0 0 1.005-.001l7.384-6.678c1.287-1.132 2.032-2.655 2.099-4.287a5.972 5.972 0 0 0-1.66-4.37Z" fill="#00253E"/></svg>`
        : `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.335 5.104a5.586 5.586 0 0 0-8.102.001 5.448 5.448 0 0 0-.233.253 7.729 7.729 0 0 0-.232-.254 5.59 5.59 0 0 0-4.05-1.738 5.59 5.59 0 0 0-4.051 1.738 5.975 5.975 0 0 0-1.662 4.372c.067 1.631.812 3.154 2.092 4.28l7.41 6.685a.748.748 0 0 0 1.005-.001l7.384-6.678c1.287-1.132 2.032-2.655 2.099-4.287a5.972 5.972 0 0 0-1.66-4.37Zm-1.437 7.539-6.89 6.23-6.913-6.237c-.976-.86-1.542-2.003-1.591-3.222a4.468 4.468 0 0 1 1.242-3.269 4.1 4.1 0 0 1 2.97-1.279 4.1 4.1 0 0 1 2.971 1.28c.274.283.496.574.661.863.267.468 1.036.468 1.303 0 .165-.287.388-.578.662-.864a4.1 4.1 0 0 1 2.972-1.279c1.12 0 2.175.455 2.97 1.28a4.467 4.467 0 0 1 1.242 3.268c-.05 1.22-.615 2.364-1.6 3.229Z" fill="#00253E"/></svg>`

      this.shadowRoot.innerHTML = `
        <button id="saveCTA${
          this.auctionId
        }" type="button" style="background-color: transparent; border: none; cursor: pointer; padding: 0;">
          ${svgCode}
        </button>
      `
      this.shadowRoot
        .querySelector(`#saveCTA${this.auctionId}`)
        .addEventListener('click', () => this.handleClick())

      return null
    }
    Promise.all([
      import(/* webpackChunkName: 'ReactDOM' */ 'react-dom'),
      import(/* webpackChunkName: 'ReactButton' */ '../cart/components/button/Button')
    ])
      .then(([ReactDOM, ButtonModule]) => {
        const Button = ButtonModule.default
        ReactDOM.render(
          <Button
            {...(this.isFollowingAuction ? {} : { secondary: true })}
            action={() => this.handleClick()}
            loading={loading}
            fullWidth={this.fullwidth}
            error={error}
          >
            {this.isFollowingAuction
              ? this.followingLabelText
              : this.followLabelText}
          </Button>,
          this
        )
      })
      .catch(e =>
        console.warn('Failed to fetch resources for follow auction button', e)
      )
  }

  renderNone() {
    import(/* webpackChunkName: 'ReactDOM' */ 'react-dom').then(ReactDOM => {
      ReactDOM.render(null, this)
    })
  }

  handleClick() {
    if (UserData.meetsAuthPrecondition()) {
      this.render(true)
      const act = this.isFollowingAuction ? unFollowAuction : followAuction

      act(this.auctionId, this.saleNumber).then(isSuccess => {
        const trackingEventName = this.isFollowingAuction
          ? 'Item Unfollowed'
          : 'Item Followed'

        if (isSuccess) {
          this.isFollowingAuction = !this.isFollowingAuction
          EventTracking(
            trackingEventName,
            this.getFollowActionEventTrackingProps()
          )
        }
        this.render(false)
      })
    } else {
      this.navigateToLoginPage()
    }
  }

  getFollowActionEventTrackingProps = () => ({
    sale_id: this.saleNumber,
    item_type: this.itemType,
    follow_origin: this.getFollowOriginTrackingValue(),
    sale_type: this.getSaleTypeTrackingValue()
  })

  getSaleTypeTrackingValue = () => {
    switch (this.saleType) {
      case 'live' || 'live auction':
        return 'live'
      case 'online' || 'online auction':
        return 'online only'
      default:
        return this.saleType
    }
  }

  getFollowOriginTrackingValue = () => {
    const pageTrackingName = getPageTrackingName()
    if (/search results/i.test(pageTrackingName)) {
      return 'search results'
    }
    if (/calendar/i.test(pageTrackingName)) {
      return 'calendar'
    }
    if (/digital catalogues/i.test(pageTrackingName)) {
      return 'digital catalogue'
    }
    return pageTrackingName
  }

  navigateToLoginPage() {
    window.location.assign(
      `${window.location.origin}/api/auth0login?resource=${
        window.location.href
      }`
    )
  }

  get auctionId() {
    return this.getAttribute('auction-id')
  }

  get itemType() {
    return this.getAttribute('item-type')
  }

  get saleNumber() {
    return this.getAttribute('sale-number')
  }

  get saleType() {
    const saleType = this.getAttribute('sale-type')
    return saleType && saleType.toLowerCase()
  }

  get followLabelText() {
    return this.getAttribute('follow-label')
  }

  get followingLabelText() {
    return this.getAttribute('following-label')
  }

  get fullwidth() {
    return this.hasAttribute('fullwidth')
  }

  get isSaveIcon() {
    return this.hasAttribute('is-save-icon')
  }
}
