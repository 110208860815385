import { DialogOverlay } from './DialogOverlay'
import { DialogFormEmail } from './DialogFormEmail'
import { createCookie, hasCookie } from '../utils/CookieJar'

export class NotificationDialog extends DialogOverlay {
  static cookie = 'acceptedCookieUsage'

  constructor(el) {
    super(el)
    this.getDialogAttributes()

    if (!hasCookie(NotificationDialog.cookie)) {
      super.openDialogWithId(this.dialog)
    }

    // Fix for SOTHEXT-5146 - empty consent manager dialog
    const consentManagerTarget = document.getElementById('ConsentManagerTarget')
    consentManagerTarget.addEventListener(
      'consentManagerChanged',
      e => {
        // Fix for SOTHEXT-5433 - don't close filter dialog
        const openFilterDialog = document.querySelector('#filterOverlay[open]')

        if (!openFilterDialog) {
          this.closeDialog()
        }
      },
      false
    )
  }

  getDialogAttributes() {
    if (this.el.hasAttribute(DialogOverlay.selectors.dataId)) {
      this.dialogId = this.el.getAttribute(DialogOverlay.selectors.dataId)
    }

    this.dialog = document.querySelector(this.dialogId)
  }

  async openDialog() {
    await super.openDialog()
    document
      .getElementsByTagName('body')[0]
      .removeAttribute(DialogOverlay.selectors.dataOpen)
  }

  closeDialog() {
    super.closeDialog()
    createCookie(NotificationDialog.cookie, 'true', 'max')

    if (
      !hasCookie(DialogFormEmail.cookie) &&
      document.querySelector(DialogFormEmail.selector)
    ) {
      const emailDialog = document.querySelector(DialogFormEmail.selector)
      const emailDialogDelay = emailDialog.getAttribute(DialogFormEmail.delay)
      const emailDialogObject = new DialogFormEmail(emailDialog)
      setTimeout(
        () => emailDialogObject.openDialogWithId(emailDialog),
        emailDialogDelay * 1000
      )
    }
  }

  bindShowDialog() {
    return null // don't capture touch events
  }
}
