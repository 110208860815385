import { throttle } from '../../utils/Throttle'
import shave from 'shave'
const MAX_HEIGHT = 80

export class HeroTruncate {
  constructor(el) {
    this.el = el
    window.setTimeout(() => {
      this.truncate(this.el)
    }, 250)
    // need to add this to maintain truncation at different breakpoints
    window.addEventListener(
      'resize',
      throttle(250, () => {
        this.truncate(this.el)
      })
    )
  }

  truncate(target) {
    shave(target, MAX_HEIGHT)
  }
}
