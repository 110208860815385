import React from 'react'
import { addItem } from '../cart/actions/item'
import { dispatch } from '../cart/CartStore'

/* global HTMLElement */

export class CheckoutButton extends HTMLElement {
  static selectors = {
    component: 'checkout-button'
  }

  static attributes = {
    text: 'text',
    skuCode: 'sku-code',
    cartPageUrl: 'cart-page-url',
    slatwallHost: 'slatwall-host',
    stockCount: 'stock-count'
  }

  static get observedAttributes () {
    return Object.values(CheckoutButton.attributes)
  }

  connectedCallback () {
    this.loading = false

    this.render(false)
  }

  attributeChangedCallback () {
    this.render(false)
  }

  render = (loading, error = '') => {
    Promise.all([
      import(/* webpackChunkName: 'ReactDOM' */ 'react-dom'),
      import(/* webpackChunkName: 'ReactButton' */ '../cart/components/button/Button')
    ]).then(([ReactDOM, ButtonModule]) => {
      const Button = ButtonModule.default
      ReactDOM.render(
        <Button
          action={this.addProduct}
          loading={loading}
          fullWidth
          disabled={!(this.props.stockCount > 0)}
          error={error}
        >
          {this.props.text}
        </Button>, this)
    }).catch(e => console.warn('Failed to fetch resources for Checkout Button', e))
  }

  addProduct = async () => {
    this.render(true)
    let data = false

    try {
      data = await addItem(this.skuCode, this.slatwallHost)(dispatch)
    } catch (e) {
      this.render(false, 'Item no longer available.')
      console.error('Failed to add order item.', e)
    }

    if (data &&
        (!data.errors || Object.keys(data.errors).length === 0) &&
        (!data.cart.errors || Object.keys(data.cart.errors).length === 0) &&
        (Array.isArray(data.successfulActions) && data.successfulActions.filter(action => action.includes('addOrderItem')))) {
      window.location.href = this.props.cartPageUrl
    } else {
      this.render(false, 'Item no longer available.')
      console.warn('Failed to add order item.', data)
    }
  }

  /**
   * Get props, supports old bsp props way of encoding props. New attrs get priority.
   */
  get props () {
    let props = {}

    try { // Error in props formatting should not cause entire app to crash
      props = JSON.parse(this.getAttribute('data-bsp-props'))
    } catch (trace) {
      console.warn('Brightspot props could not be loaded.', trace)
    }

    return {
      text: this.text || props.text,
      skuCode: this.skuCode || props.skuCode,
      cartPageUrl: this.cartPageUrl || props.cartPageUrl,
      slatwallHost: this.slatwallHost || props.slatwallHost,
      stockCount: this.stockCount || props.stockCount
    }
  }

  get text () {
    return this.getAttribute(CheckoutButton.attributes.text)
  }

  get skuCode () {
    return this.getAttribute(CheckoutButton.attributes.skuCode)
  }

  get cartPageUrl () {
    return this.getAttribute(CheckoutButton.attributes.cartPageUrl)
  }

  get slatwallHost () {
    return this.getAttribute(CheckoutButton.attributes.slatwallHost)
  }

  get stockCount () {
    return this.getAttribute(CheckoutButton.attributes.stockCount)
  }
}
