export const callApi = (endpoint, options, timeout = 15) => new Promise((resolve, reject) => {
  window.fetch(endpoint, options)
    .then(res => res.json())
    .then(data => resolve(data))
    .catch(error => reject(new Error(error)))

  if (timeout) {
    window.setTimeout(() => reject(new Error(`No response from server after ${timeout} seconds.`)), timeout * 1000)
  }
})
