/* global HTMLElement */
import { throttle } from './Throttle'
import { Tracker } from './Events'
import MediaQueries from './MediaQueries'

const mq = new MediaQueries().mq
const MIN_VIEWPORT = 'mq-viewport-md'

export class Parallax extends HTMLElement {
  static selectors = {
    parallaxElement: 'parallax-widget',
    parallaxTarget: '[data-parallax]'
  }

  connectedCallback() {
    this.listenerTracker = new Tracker()
    this.targetImage = this.querySelector(Parallax.selectors.parallaxTarget)
    this.windowHeight = window.innerHeight
    this.initParallax()
  }

  initParallax() {
    this.collectListeners()
  }

  /**
   * Determines whether or not the current distance to top of the container
   * is shorter than the bottom of the current page location
   *
   * @param top           relative top of container
   * @param scrollTop     current scroll-y position on page
   * @param windowHeight  optional parameter falls back to inner window height
   * @returns {boolean}
   */
  isTopOfContainerInView = (
    { top, scrollTop },
    windowHeight = this.windowHeight
  ) => top < scrollTop + windowHeight

  /**
   * Determines whether or not the bottom of the parallax container has scrolled past the top of the viewport
   *
   * @param bottom        relative bottom of container
   * @param scrollTop     current scroll-y position on page
   * @returns {boolean}
   */
  isBottomOfContainerOutOfView = ({ bottom, scrollTop }) => bottom > scrollTop

  /**
   * Calculates the percentage of the image height that has been scrolled within the container
   *
   * @param fullscreen        data attribute on parallax widget, is true when fullscreenLead is being used
   * @param windowBottom      bottom of window
   * @param top               relative top of container
   * @param containerHeight   height of targeted container
   * @returns {number}
   */
  findPercentScrolled = (
    { fullscreen },
    { windowBottom, top, containerHeight }
  ) => {
    let percentage
    if (mq[MIN_VIEWPORT].matches) {
      fullscreen === 'true'
        ? (percentage = (windowBottom - containerHeight) / containerHeight)
        : (percentage =
            (windowBottom - top) / (containerHeight + this.windowHeight))
    } else {
      percentage =
        ((windowBottom - top) / (containerHeight + this.windowHeight)) * 0.5
    }
    return percentage
  }

  /**
   * Gets current window location and positions the image within a container based on percent scrolled
   *
   * Moved the majority of variables in here to prevent incorrect calculations
   * that occur before the DOM is fully rendered
   */
  updateParallax = () => {
    let parallaxProps = {}
    parallaxProps.containerHeight = this.parentNode.offsetHeight
    parallaxProps.imageHeight = this.querySelector(
      Parallax.selectors.parallaxTarget
    ).offsetHeight
    parallaxProps.parallaxDistance =
      parallaxProps.imageHeight - parallaxProps.containerHeight
    parallaxProps.top = this.parentNode.offsetTop
    parallaxProps.bottom = parallaxProps.top + parallaxProps.containerHeight
    parallaxProps.scrollTop = window.pageYOffset
    parallaxProps.windowBottom = parallaxProps.scrollTop + this.windowHeight

    let percentScrolled = this.findPercentScrolled(this.dataset, parallaxProps)
    let parallax = parallaxProps.parallaxDistance * percentScrolled

    if (
      this.isBottomOfContainerOutOfView(parallaxProps) &&
      this.isTopOfContainerInView(parallaxProps)
    ) {
      this.targetImage.style.transform = `translateY(${parallax}px)`
    }
  }

  collectListeners = () => {
    this.listenerTracker.addListener(
      window,
      'scroll',
      throttle(5, () => this.updateParallax())
    )
    this.listenerTracker.addListener(
      window,
      'resize',
      throttle(250, () => this.updateParallax())
    )
  }
}
