/* global HTMLElement */
import React from 'react'
import { EventTracking } from '../segment/PageInteractions'

import { getAuctionEnrollmentStatusAndPaddleId } from '../graphql/CustomerApiUtils'
import UserData from '../utils/UserData'

export class RegisterToBidAuctionLink extends HTMLElement {
  static selector = 'registertobid-auction-link'

  constructor() {
    super()
    /**
     * To get paddle ID, we need either (assuming its similar to follow)
     * a) auctionId AND saleNumber for Viking auctions
     * b) saleId for Invaluable auctions
     */
    if (!this.hasRequiredVikingInfo() && !this.hasRequiredInvaluableInfo()) {
      console.warn(
        [
          'To display paddle and register to bid action, ResigterToBidAuctionLink requires either:',
          'a) auctionId AND saleNumber for Viking auctions or',
          'b) saleId for Invaluable auctions'
        ].join('\n')
      )
    }
    if (!this.itemType) {
      console.warn(`Missing required attribute 'itemType'`)
    }
  }

  hasRequiredVikingInfo = () => this.auctionId && this.saleNumber

  hasRequiredInvaluableInfo = () => this.saleNumber

  getAuctionIdForQuery = () =>
    this.hasRequiredVikingInfo() ? this.auctionId : this.saleNumber

  connectedCallback() {
    this.mountPoint = document.createElement('span')
    this.appendChild(this.mountPoint)

    if (!UserData.meetsAuthPrecondition()) {
      this.renderButton(false)
    } else {
      this.renderButton(true)
      const auctionIdForQuery = this.getAuctionIdForQuery()

      getAuctionEnrollmentStatusAndPaddleId(auctionIdForQuery)
        .then(({ enrollmentStatus, paddleId }) => {
          switch (enrollmentStatus) {
            case 'Approved':
              this.renderPaddle(paddleId)
              break
            case 'Pending':
            case 'InReview':
            case 'Denied':
            case 'None':
              this.renderPending()
              break
            default:
              this.renderButton(false)
          }
        })
        .catch(err => {
          console.error('Error fetching auction details from Viking:', err)
          this.renderButton(false)
        })
    }
  }

  renderPending = () => {
    import(/* webpackChunkName: 'ReactDOM' */ 'react-dom')
      .then(ReactDOM => {
        ReactDOM.render(
          <span className="RegisterToBidAuctionLink-label">
            <span data-icon="info" />
            <span data-viewport-md>{this.pendingText.tablet}</span>
            <span data-viewport-sm>{this.pendingText.mobile}</span>
          </span>,
          this.mountPoint
        )
      })
      .catch(e =>
        console.warn(
          'Failed to fetch resources for RegisterToBidAuctionLink',
          e
        )
      )
  }

  renderPaddle = paddleId => {
    import(/* webpackChunkName: 'ReactDOM' */ 'react-dom')
      .then(ReactDOM => {
        ReactDOM.render(
          <span className="RegisterToBidAuctionLink-label">
            <span data-icon="paddle" />
            <span data-viewport-md>{this.paddleText.tablet + paddleId}</span>
            <span data-viewport-sm>{this.paddleText.mobile + paddleId}</span>
          </span>,
          this.mountPoint
        )
      })
      .catch(e =>
        console.warn(
          'Failed to fetch resources for RegisterToBidAuctionLink',
          e
        )
      )
  }

  renderButton = (loading, error = '') => {
    Promise.all([
      import(/* webpackChunkName: 'ReactDOM' */ 'react-dom'),
      import(/* webpackChunkName: 'ReactButton' */ '../cart/components/button/Button')
    ])
      .then(([ReactDOM, ButtonModule]) => {
        const Button = ButtonModule.default
        ReactDOM.render(
          <Button
            action={() => this.handleClick()}
            loading={loading}
            fullWidth={this.fullwidth}
            error={error}
          >
            <span>
              <span data-viewport-md>{this.registerToBidText.tablet}</span>
              <span data-viewport-sm>{this.registerToBidText.mobile}</span>
            </span>
          </Button>,
          this.mountPoint
        )
      })
      .catch(e =>
        console.warn(
          'Failed to fetch resources for RegisterToBidAuctionLink',
          e
        )
      )
  }

  renderNone() {
    import(/* webpackChunkName: 'ReactDOM' */ 'react-dom').then(ReactDOM => {
      ReactDOM.render(null, this.mountPoint)
    })
  }

  handleClick() {
    if (UserData.meetsAuthPrecondition()) {
      this.navigateToAuctionPage()
      EventTracking(
        'Auction Registration Button Clicked',
        this.getRegisterToBidEventTrackingProps()
      )
    } else {
      this.navigateToLoginPage()
    }
  }

  getRegisterToBidEventTrackingProps = () => ({
    sale_id: this.saleNumber,
    registration_source: 'web',
    auction_registration_origin: 'digital catalogue'
  })

  navigateToAuctionPage() {
    window.location.assign(this.auctionPageUrl)
  }

  navigateToLoginPage() {
    window.location.assign(
      `${window.location.origin}/api/auth0login?resource=${
        window.location.href
      }`
    )
  }

  get auctionId() {
    return this.getAttribute('auction-id')
  }

  get itemType() {
    return this.getAttribute('item-type')
  }

  get saleNumber() {
    return this.getAttribute('sale-number')
  }

  get fullwidth() {
    return this.hasAttribute('fullwidth')
  }

  get auctionPageUrl() {
    return this.getAttribute('auction-page-url')
  }

  get registerToBidText() {
    return {
      mobile: 'Register',
      tablet: 'Register To Bid'
    }
  }

  get paddleText() {
    return {
      mobile: '#',
      tablet: 'Paddle #'
    }
  }

  get pendingText() {
    return {
      mobile: 'Pending',
      tablet: 'Registration Pending'
    }
  }
}
