/* global HTMLElement */
import { Tracker } from '../utils/Events'
import { EventTracking } from '../segment/PageInteractions'

/*
Expected attributes for the hotspot-module component:
<body>
  <hotspot-module>

    <ul>
      <li data-pin data-pin-id="#">
        ...
      </li>
    </ul>

    <ul>
      <li data-info data-item-id="#">
        ...
        <div data-close>
          ...
        </div>
      </li>
    </ul>

  </hotspot-module>
</body>
*/

export class HotspotModule extends HTMLElement {
  static selectors = {
    hotspotModule: 'hotspot-module',
    pin: '[data-pin]',
    infoPane: '[data-info]',
    visibility: 'data-is-visible',
    closeButton: '[data-close]',
    title: '[data-hotspot-title]',
    itemTitle: '[data-hotspot-item-title]'
  }

  connectedCallback() {
    this.listenerTracker = new Tracker()
    this.pins = [...this.querySelectorAll(HotspotModule.selectors.pin)]
    this.items = [...this.querySelectorAll(HotspotModule.selectors.infoPane)]
    this.closeButtons = [
      ...this.querySelectorAll(HotspotModule.selectors.closeButton)
    ]
    this.collectListeners()
  }

  /**
   * Toggles visibility of info panes
   *
   * @param {number} pinId the ID of the selected pane that should be toggled
   */
  toggleVisibility = ({ pinId }) => {
    this.closeAnyOpenInfoPanes(pinId)
    let [currentPane] = this.items.filter(item => item.dataset.itemId === pinId)
    currentPane.dataset.isVisible === 'true'
      ? this.hidePane(currentPane)
      : this.showPane(currentPane)
  }

  /**
   * Closes any opened info panes if they are not the selected pane.
   * Added this to prevent multiple panes being opened at the same time.
   *
   * @param {number} currentItemId this ID of the current Pane
   */
  closeAnyOpenInfoPanes = currentItemId => {
    let openItems = this.items.find(
      item =>
        item.dataset.isVisible === 'true' &&
        item.dataset.itemId !== currentItemId
    )
    openItems
      ? openItems.setAttribute(HotspotModule.selectors.visibility, 'false')
      : null
  }

  /**
   * Hides selected pane
   *
   * @param {HTMLElement} item the pane to be closed
   */
  hidePane = item => {
    item.setAttribute(HotspotModule.selectors.visibility, 'false')
  }

  /**
   * Shows the selected pane
   *
   * @param {HTMLElement} item the pane to be opened
   */
  showPane = item => {
    item.setAttribute(HotspotModule.selectors.visibility, 'true')
  }

  /**
   * Adds event listeners to component
   */
  collectListeners = () => {
    this.pins.forEach(pin =>
      this.listenerTracker.addListener(pin, 'click', () => {
        this.toggleVisibility(pin.dataset)
        let [currentPane] = this.items.filter(
          item => item.dataset.itemId === pin.dataset.pinId
        )
        EventTracking(`Hotspot Opened`, this.getHotspotProps(currentPane))
      })
    )

    this.closeButtons.forEach(button =>
      this.listenerTracker.addListener(button, 'click', () =>
        this.hidePane(button.parentElement)
      )
    )

    this.items.forEach(item => {
      let ctas = item.querySelectorAll('a') || false
      if (ctas) {
        ctas.forEach(cta =>
          this.listenerTracker.addListener(cta, 'click', () =>
            EventTracking(`Hotspot Clicked`, this.getHotspotProps(item))
          )
        )
      }
    })
  }

  getHotspotProps(pin) {
    let hotspotProps = {}
    if (this.querySelector(HotspotModule.selectors.title)) {
      hotspotProps.hotspot_component_title = this.querySelector(
        HotspotModule.selectors.title
      ).innerText
    }

    if (pin.querySelector(HotspotModule.selectors.itemTitle)) {
      hotspotProps.hotspot_content_title = pin.querySelector(
        HotspotModule.selectors.itemTitle
      ).innerText
    }

    return hotspotProps
  }
}
