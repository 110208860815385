/* global HTMLElement */
import { EventTracking } from './PageInteractions'
import { dispatch } from '../cart/CartStore'
import { setGetStartedLink } from '../cart/actions/cart'

export class SubscriptionCtaTracking extends HTMLElement {
  static selectors = {
    component: 'subscription-cta-tracking'
  }

  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.init()
    }
  }

  init() {
    this.querySelector('a').addEventListener('click', this.ctaTrackEvent)
  }

  ctaTrackEvent() {
    EventTracking('Promotion Clicked')
    dispatch(setGetStartedLink(window.location.href))
  }
}
