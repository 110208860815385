import { BSP_PROPS_LOADED } from '../constants/brightspot'

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case BSP_PROPS_LOADED:
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}
