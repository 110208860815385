import Input from '../input/Input.js'
import { EventTracking } from '../../../segment/PageInteractions'

export class Select extends Input {
  static selectors = {
    multipleAtrr: '[data-desktop-hide]',
    onboardingAttr: 'data-onboarding'
  }

  bindEvents() {
    let disabledOption = this.input.querySelector(Select.selectors.multipleAtrr)

    // Add validate handlers
    if (this.requiresValidation()) {
      this.input.addEventListener('blur', event => {
        setTimeout(() => this.validate(), 10)
      })
    }

    this.input.addEventListener('change', e => {
      this.checkValue(this.input.value)
    })

    this.input.addEventListener('focus', e => {
      disabledOption ? disabledOption.removeAttribute('selected') : null
    })

    if (this.element.parentNode.hasAttribute(Select.selectors.onboardingAttr)) {
      this.input.addEventListener('focusout', e => {
        let selectedValues = [...this.input.selectedOptions].map(
          ({ label }) => label
        )
        let props = {}
        this.input.hasAttribute('multiple')
          ? (props.location_preferences_selection = selectedValues.join(' | '))
          : (props.primary_location_selection = selectedValues.join())
        EventTracking('Studios Location Preferences Selected', props)
      })
    }
  }

  checkValue(value) {
    if (value === '') {
      this.removePlaceHolder()
    } else {
      this.showPlaceHolder()
    }
  }

  showPlaceHolder() {
    this.input.setAttribute('placeholder', 'true')
  }

  removePlaceHolder() {
    this.input.removeAttribute('placeholder')
  }
}
