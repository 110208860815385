import { EventTracking } from './PageInteractions'

export class TileGridTracking {
  constructor(el) {
    const listItems = el.querySelectorAll('.TileGrid-items-item')
    const htmlEl = document.getElementsByTagName('html')[0]
    const lang = htmlEl.getAttribute('lang')

    listItems.forEach((item, i) => {
      item.addEventListener('click', e => {
        const clickedPromo = e.currentTarget
        const category = clickedPromo.querySelector('.PromoTile-title')
          .innerHTML
        EventTracking('Tile Grid Module Clicked', {
          tile_grid_category: category,
          tile_grid_position: i + 1,
          tile_grid_locale: lang
        })
      })
    })
  }
}
