/* global HTMLElement */
import { throttle } from './Throttle.js'
import { Tracker } from './Events'

/*
Expected attributes for the back-to-top component:
<body>
  <div data-back-to-top-trigger>
    ...
  </div>

  <back-to-top data-visible="false">
    ...
  </back-to-top>
</body>
*/

export class BackToTop extends HTMLElement {
  static selectors = {
    backToTop: 'back-to-top',
    displayTriggerElement: '[data-back-to-top-trigger]'
  }

  constructor() {
    super()
    this.trigger = document.querySelector(
      BackToTop.selectors.displayTriggerElement
    )
    this.listenerTracker = new Tracker()
  }

  connectedCallback() {
    this.collectListeners()
  }

  /**
   * Sets window top to 0 when back to top button is clicked with a smooth scroll behavior
   */
  scrollToTop() {
    this.listenerTracker.removeListeners()
    this.dataset.visible = 'false'
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setTimeout(() => {
      this.collectListeners()
    }, 1000)
  }

  /**
   * Determines whether or not back to top button is visible on page by changing its data
   * attribute after checking if it's listener is visible in the viewport
   */
  showBackToTop = () => {
    this.dataset.visible = (!BackToTop.isTriggerVisibleInViewport(
      this.trigger
    )).toString()
  }

  /**
   * Returns true if any part of the target element is visible in the viewport
   *
   * @param {HTMLElement} element that is designated to trigger visibility of back to top button
   * @returns {boolean} if the target element is visible in the viewport
   */
  static isTriggerVisibleInViewport(element) {
    if (!element) {
      return false
    }

    const COORDINATE_Y = element.offsetTop
    const HEIGHT = element.offsetHeight
    const MAX_HEIGHT = COORDINATE_Y + HEIGHT

    return (
      COORDINATE_Y < window.pageYOffset + window.innerHeight &&
      MAX_HEIGHT >= window.pageYOffset
    )
  }

  /**
   * Adds event listeners to component
   */
  collectListeners = () => {
    this.listenerTracker.addListener(this, 'click', () => this.scrollToTop())
    this.listenerTracker.addListener(
      window,
      'scroll',
      throttle(500, () => {
        this.showBackToTop()
      })
    )
  }
}
