import { EventTracking } from './PageInteractions'

export class TabsTracking {
  constructor(el) {
    const listItems = el.querySelectorAll('.TabsHorizontalNavigation-tab')
    const listForm = el.querySelectorAll('.TabItem [class$="Form"]')
    const htmlEl = document.getElementsByTagName('html')[0]
    const lang = htmlEl.getAttribute('lang')

    listItems.forEach((item, i) => {
      item.addEventListener('click', e => {
        const clickedTab = e.currentTarget
        const tabName = clickedTab.innerHTML

        EventTracking('Click Action', {
          tab_name: tabName,
          tab_position: i + 1,
          tab_locale: lang
        })
      })
    })

    listForm.forEach((el, i) => {
      const form = el.querySelector('form')
      const formTitle = el.querySelector('[class$="Form-title"]')
      const formDescr = el.querySelector('[class$="Form-description"]')

      form.addEventListener('submit', e => {
        const formName = formTitle.innerHTML
        const formDescriptionField = formDescr.innerHTML

        EventTracking('Form Submission', {
          form_name: formName,
          form_description_field: formDescriptionField
        })
      })
    })
  }
}
