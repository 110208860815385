import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import { EventTracking } from '../../segment/PageInteractions'

export class IframeCarousel {
  constructor(el) {
    FixFlickity()

    this.el = el
    let count = this.el.getAttribute('data-length')

    let iframe = this.el.querySelector('iframe')

    if (iframe) {
      iframe.onload = () =>
        EventTracking('Mei Moses Chart Loaded', {
          mei_moses_chart_name: 'Sotheby’s Mei Moses Index'
        })
    }

    // Only initialize the carousel if there is more than one slide
    if (count > 1) {
      this.initCarousel()
    }
  }

  initCarousel() {
    this.carousel = new Flickity(this.el, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: false,
      prevNextButtons: true
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })

    this.carousel.on('change', index => {
      EventTracking('Click Action', {
        mei_moses_chart_name: 'Sotheby’s Mei Moses Index',
        mei_moses_chart_page: index + 1
      })
    })
  }
}
