import { ACCOUNT_LOGIN_SUCCESS } from '../constants/account'

const initialState = {
  accountID: '',
  company: '',
  phone: '',
  firstName: '',
  lastName: '',
  email: ''
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        accountID: payload.accountID,
        company: payload.company,
        phone: payload.primaryPhoneNumber && payload.primaryPhoneNumber.phoneNumber,
        firstName: payload.firstName,
        lastName: payload.lastNAme,
        email: payload.primaryEmailAddress && payload.primaryEmailAddress.emailAddress
      }
    default:
      return state
  }
}
