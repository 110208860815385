export const followAuctionMutation = `mutation FollowAuction($auctionId: String, $saleId: String, $followOrigin: String!, $preferredMember: Boolean!) {
  followAuction(auctionId: $auctionId, saleId: $saleId, followOrigin: $followOrigin, preferredMember: $preferredMember)
}`

export const unfollowAuctionMutation = `mutation UnfollowAuction($auctionId: String, $saleId: String, $followOrigin: String!, $preferredMember: Boolean!) {
  unFollowAuction(auctionId: $auctionId, saleId: $saleId, followOrigin: $followOrigin, preferredMember: $preferredMember)
}`

export const auctionEnrollmentStatusAndPaddleIdQuery = `query auctionEnrollmentQuery($auctionId: String!) {
  auction(auctionId: $auctionId) {
      enrollmentStatus
      paddleId
  }
}`
