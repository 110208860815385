import { throttle } from '../../utils/Throttle'
import MediaQueries from '../../utils/MediaQueries'
const mq = new MediaQueries().mq
const READ_MORE_ATTR = 'data-read-more'
const BIO_HEIGHT_THRESHOLD = 300

export class ArtistPage {
  constructor(el) {
    this.el = el

    if (this.el.querySelector('[class$="-bioText"]') !== null) {
      this.readMore = this.el.querySelector(
        '[class$="-biography-link"]:not([data-read-less-link])'
      )
      this.readLess = this.el.querySelector('[data-read-less-link]')
      this.text = this.el.querySelector('[class$="-bioText"]')

      this.determineShowHide()

      window.addEventListener(
        'resize',
        throttle(250, () => {
          this.determineShowHide()
        })
      )

      this.readMore.addEventListener('click', e => {
        this.showHideText('false')
      })

      if (this.readLess) {
        this.readLess.addEventListener('click', e => {
          this.showHideText('true')
        })
      }
    }
  }

  determineShowHide() {
    // hide text when plugin is registered and larger than container
    // and if not already triggered
    if (
      mq['mq-viewport-md'].matches &&
      this.text &&
      this.text.clientHeight > BIO_HEIGHT_THRESHOLD &&
      this.el.getAttribute(READ_MORE_ATTR) !== 'false'
    ) {
      this.showHideText('true')
    }
  }

  showHideText(hide) {
    this.el.setAttribute(READ_MORE_ATTR, hide)
  }
}
