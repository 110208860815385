import { DialogOverlay } from '../../dialog/DialogOverlay'

/* global HTMLElement */

export class QuickCardExpandable extends HTMLElement {
  static selectors = {
    open: '[data-open]',
    close: '[data-close]'
  }

  connectedCallback() {
    this.openElement = this.querySelector(QuickCardExpandable.selectors.open)
    this.closeElement = this.querySelector(QuickCardExpandable.selectors.close)
    this.dialog = this.closest('.DialogOverlay')

    this.openElement.addEventListener('click', this.open)
    this.closeElement.addEventListener('click', this.close)
    this.dialog &&
      this.dialog.addEventListener(DialogOverlay.events.close, this.close)
  }

  disconnectedCallback() {
    this.openElement.removeEventListener('click', this.open)
    this.closeElement.removeEventListener('click', this.close)
    this.closeDialog.removeEventListener('click', this.close)
    this.dialog &&
      this.dialog.removeEventListener(DialogOverlay.events.close, this.close)
  }

  open = () => {
    this.setAttribute('expanded', true)
    this.closest('.QuickCard').setAttribute('expanded', true)
  }

  close = () => {
    this.setAttribute('expanded', false)
    this.closest('.QuickCard').setAttribute('expanded', false)
  }
}
