/* eslint-disable no-unused-vars */
/*
Dialog id being set on an object assumes that a dialog already exists on the page
and we are referencing that particular dialog to be opened. With dialog url we
will be constructing the dialog mark up in js, appending it to the DOM
before the closing body tag, injecting the content from the referenced url,
registering it to the polyfill, then showing it.
If calling an already existing element on the page:
<a href="#" data-dialog-overlay data-dialog-id="#EXAMPLE_ID">dialog link</a>
<dialog class="DialogOverlay" id="EXAMPLE_ID">
    <div class="DialogOverlay-main">
      CONTENT HERE
    </div>
    <div class="DialogOverlay-close" data-close-dialog>Close</div>
</dialog>
If ajaxing in content:
<a href="URL" data-dialog-overlay data-dialog-url-selector=".SELECTOR_IN_AJAX">dialog link</a>
if data-dialog-url-selector isn't set then it will just output whatever is returned.
*/
import { EventTracking } from '../segment/PageInteractions'
import { GetUrlParams } from '../utils/UrlUtils'
import noScroll from 'no-scroll'

window.dialogOverlayOpenOnLoad = false

export class DialogOverlay {
  static dialogOverlayInstances = new WeakMap()

  static cssDialogOverlayClass = '.DialogOverlay'
  static selectors = {
    dataUrl: 'data-dialog-url-selector', // selector for ajaxed content
    dataClose: '[data-close-dialog]',
    dataId: 'data-dialog-id',
    dataOpen: 'data-dialog-overlay-open',
    dataDialogOverlay: 'data-dialog-overlay',
    dataSegmentEvent: 'data-segment-event',
    formContainer: '[data-form], [data-form-type]',
    dataAjaxSuccessful: 'data-ajax-successful',
    dialogOverlay: DialogOverlay.cssDialogOverlayClass,
    main: `${DialogOverlay.cssDialogOverlayClass}-main`,
    close: `${DialogOverlay.cssDialogOverlayClass}-close`,
    flickityButtons: '.flickity-button',
    noScroll: 'data-no-scroll'
  }

  static eventPrefix = 'DialogOverlay:'
  static events = {
    open: `${DialogOverlay.eventPrefix}open`,
    close: `${DialogOverlay.eventPrefix}close`
  }

  constructor(el) {
    this.el = el
    this.state = {}
    this.dialog = null
    this.dialogId = null
    this.dialogUrl = null
    this.openEvent = new window.CustomEvent(DialogOverlay.events.open)
    this.closeEvent = new window.CustomEvent(DialogOverlay.events.close)
    this.dialogUrlSelector = null
    this.closeDialogMethod = null
    this.flickityButtons = null
    this.openExistingDialog()
    DialogOverlay.dialogOverlayInstances.set(this.el, this)
    this.bindShowDialog()
    this.noScroll = this.el.hasAttribute(DialogOverlay.selectors.noScroll)

    if (this.el.querySelectorAll(DialogOverlay.selectors.flickityButtons)) {
      this.flickityButtons = [
        ...this.el.querySelectorAll(DialogOverlay.selectors.flickityButtons)
      ]
      this.bindClickedFlickityButton()
    }
  }

  openExistingDialog() {
    // open dialog if hash exists and ensure it only gets fired once
    // if multiple DialogOverlay plugins are on a page
    if (window.location.hash && !window.dialogOverlayOpenOnLoad) {
      const hash = window.location.hash
      const hashId = hash.substr(1)

      try {
        const hashEl = document.querySelector(hash)
        if (
          hashEl &&
          hashId === hashEl.id &&
          hashEl.classList.contains(
            DialogOverlay.selectors.dialogOverlay.substr(1)
          )
        ) {
          this.openDialogWithId(hashEl)
          window.dialogOverlayOpenOnLoad = true
        }
      } catch (error) {
        // ignoring error thrown by using invalid selector in #querySelector
      }
    }
  }

  getDialogAttributes() {
    if (this.el.hasAttribute(DialogOverlay.selectors.dataId)) {
      this.dialogId = this.el.getAttribute(DialogOverlay.selectors.dataId)
    }

    if (this.el.href !== '') {
      this.dialogUrl = this.el.href
    }

    if (this.el.hasAttribute(DialogOverlay.selectors.dataUrl)) {
      this.dialogUrlSelector = this.el.getAttribute(
        DialogOverlay.selectors.dataUrl
      )
    }

    // dialog id takes precedence over url on same object
    if (this.dialogId !== null) {
      this.openDialogWithId()
    } else if (this.dialogUrl !== null) {
      this.constructDialogHtml(this.dialogUrl)
    } else {
      console.log('href and/or data-dialog-id not set!')
    }
  }

  bindClickedFlickityButton() {
    this.flickityButtons.forEach(button => {
      button.addEventListener('click', e => {
        e.stopPropagation()
      })
    })
  }

  bindShowDialog() {
    this.el.addEventListener('click', e => {
      e.preventDefault()
      this.getDialogAttributes()
    })
  }

  bindCloseDialog() {
    this.closeDialogMethod = this.closeDialog.bind(this)
    const closeBtns = this.dialog.querySelectorAll(
      DialogOverlay.selectors.dataClose
    )
    closeBtns.forEach(closeBtn => {
      closeBtn.addEventListener('click', this.closeDialogMethod)
    })
  }

  closeDialog() {
    this.dialog.close()
    document
      .getElementsByTagName('body')[0]
      .removeAttribute('data-dialog-overlay-open')
    const closeBtns = this.dialog.querySelectorAll(
      DialogOverlay.selectors.dataClose
    )
    closeBtns.forEach(closeBtn => {
      closeBtn.removeEventListener('click', this.closeDialogMethod)
    })
    this.hijackFormClick(this.dialog, this.removeEventListenClick)
    this.hideAjaxSuccess()
    if (this.noScroll) {
      // maintain height
      noScroll.off()
    }
    this.state.isOpen = false
    this.dialog.dispatchEvent(this.closeEvent)
  }

  hideAjaxSuccess() {
    if (
      this.dialog.querySelectorAll(
        `[${DialogOverlay.selectors.dataAjaxSuccessful}]`
      ) !== null
    ) {
      this.dialog
        .querySelectorAll(`[${DialogOverlay.selectors.dataAjaxSuccessful}]`)
        .forEach(formContainer => {
          formContainer.removeAttribute(
            `${DialogOverlay.selectors.dataAjaxSuccessful}`
          )
        })
    }
  }

  openDialogWithId(dialog) {
    if (typeof dialog !== 'undefined') {
      this.dialog = dialog
    } else {
      this.dialog = document.querySelector(this.dialogId)
    }

    this.openDialog()
  }

  async openDialog() {
    if (!this.state.isOpen) {
      const dialogPolyfillModule = await import(/* webpackChunkName: "polyfills~DialogPolyfill" */ 'dialog-polyfill')
      const dialogPolyfill = dialogPolyfillModule.default
      dialogPolyfill.registerDialog(this.dialog)
      this.dialog.show()
      if (this.noScroll) {
        // no scroll, maintains scrollbar width
        noScroll.on()
      } else {
        // fallback no scroll
        // dialogOverlay data attribute may have a value such as "mobileOnly" to that needs
        // to be applied to dataOpen data attribute in order to make CSS selector more specific.
        // if there is no value for dialogOverlay data attribute, dataOpen attribute will be
        // set to ''
        document
          .getElementsByTagName('body')[0]
          .setAttribute(
            DialogOverlay.selectors.dataOpen,
            this.el.getAttribute(DialogOverlay.selectors.dataDialogOverlay)
          )
      }
      this.state.isOpen = true
      this.dialog.dispatchEvent(this.openEvent)
      this.hijackFormClick(this.dialog, this.addEventListenClick)
      this.bindCloseDialog()
      if (this.el.hasAttribute(DialogOverlay.selectors.dataSegmentEvent)) {
        EventTracking(
          this.el.getAttribute(DialogOverlay.selectors.dataSegmentEvent),
          { dialog_cta: this.el.textContent }
        )
      }
    }
  }

  constructDialogHtml(url) {
    // Count overlays on the page, create +1 to make sure a unique id is set
    // in case there's more than one
    const overlayCount =
      document.querySelectorAll(DialogOverlay.selectors.dialogOverlay).length +
      1
    const generatedDialogId = 'dynamicOverlay' + overlayCount
    const generatedDialogIdWithHash = '#' + generatedDialogId
    const closeAttribute = DialogOverlay.selectors.dataClose.substr(
      1,
      DialogOverlay.selectors.dataClose.length - 2
    )
    let dialogString = `<dialog id="${generatedDialogId}" class="${DialogOverlay.selectors.dialogOverlay.substr(
      1
    )}">
        <div class="${DialogOverlay.selectors.main.substr(1)}">{{content}}</div>
        <button class="${DialogOverlay.selectors.close.substr(
          1
        )}" ${closeAttribute} role="button" tabindex="0">Close</button>
    </dialog>`

    window
      .fetch(url, { credentials: 'include', mode: 'cors' })
      .then(response => {
        return response.text()
      })
      .then(body => {
        if (this.dialogUrlSelector) {
          let parser = new window.DOMParser()
          let bodyHtml = parser.parseFromString(body, 'text/html')
          let bodySelector = bodyHtml.querySelector(this.dialogUrlSelector)
          let bodyHtmlString = bodySelector.outerHTML
          dialogString = dialogString.replace('{{content}}', bodyHtmlString)
        } else {
          dialogString = dialogString.replace('{{content}}', body)
        }
        document.body.insertAdjacentHTML('beforeend', dialogString)
        this.el.setAttribute(
          DialogOverlay.selectors.dataId,
          generatedDialogIdWithHash
        )
        this.dialogId = generatedDialogIdWithHash
        this.openDialogWithId()
      })
  }

  ajaxSubmission(action, formContainer, form) {
    let locale = '?locale='
    // Check for lang param first, then check the html lang attr to pass language for localized error message
    // Passing as `?locale=en` or `?locale=zh-Hant`
    if (GetUrlParams('locale') !== null) {
      locale += GetUrlParams('locale')
    } else if (document.querySelector('html').getAttribute('lang') !== null) {
      locale += document.querySelector('html').getAttribute('lang')
    }

    const formData = new window.FormData(form)
    window
      .fetch(action + locale, {
        credentials: 'include',
        method: 'POST',
        body: formData,
        mode: 'cors'
      })
      .then(response => {
        return response.text()
      })
      .then(data => {
        const jsonData = JSON.parse(data)
        if (jsonData.success) {
          formContainer.setAttribute(
            `${DialogOverlay.selectors.dataAjaxSuccessful}`,
            ''
          )
          import(/* webpackChunkName: 'Form' */ '../core/form/Form')
            .then(({ Form }) => {
              const formUtils = new Form(formContainer)
              formUtils.trackSubmission(true)
            })
            .catch(e => console.warn('Error loading Form', e))
        } else if (typeof jsonData.message !== 'undefined') {
          const errorDiv = document.createElement('div')
          const errorMessageClass = formContainer.classList[0] + '-message'

          if (formContainer.querySelector(`.${errorMessageClass}`) !== null) {
            formContainer.querySelector(`.${errorMessageClass}`).innerHTML =
              jsonData.message
          } else {
            errorDiv.className = errorMessageClass
            errorDiv.innerHTML = jsonData.message
            form.parentNode.insertBefore(errorDiv, form)
          }
        }
      })
  }

  submitClick = (action, formContainer, form) => e => {
    e.preventDefault()
    this.ajaxSubmission(action, formContainer, form)
  }

  removeEventListenClick = formContainer => {
    const submitBtn = formContainer.querySelector('[type="submit"]')
    submitBtn.removeEventListener('click', this._submitClick)
  }

  addEventListenClick = (formContainer, action, form) => {
    const submitBtn = formContainer.querySelector('[type="submit"]')

    submitBtn.addEventListener(
      'click',
      (this._submitClick = this.submitClick(action, formContainer, form))
    )
  }

  hijackFormClick(dialog, addOrRemoveEvent) {
    if (
      dialog.querySelectorAll(DialogOverlay.selectors.formContainer) !== null
    ) {
      dialog
        .querySelectorAll(DialogOverlay.selectors.formContainer)
        .forEach(dialogFormContainer => {
          const form = dialogFormContainer.querySelector('form')
          if (form.hasAttribute('action')) {
            addOrRemoveEvent(
              dialogFormContainer,
              form.getAttribute('action'),
              form
            )
          }
        })
    }
  }
}
