import {
  CART_FETCH_SUCCESS,
  CART_FETCH_START,
  CART_FETCH_FAIL
} from '../constants/cart'

import { errorBanner } from './banner'
import { ORDER_PAYMENT_SUCCESS, UNSET_GET_STARTED_LINK, SET_GET_STARTED_LINK } from '../constants/checkout'
import { callApi } from '../utils/ApiUtils'

export const fetchCart = (slatwallEndpoint) => async dispatch => {
  dispatch({
    type: CART_FETCH_START
  })

  // Build form for API call
  const form = new window.FormData()
  form.append('returnJSONObjects', 'cart,account')
  const options = { method: 'POST', credentials: 'include', body: form }

  try {
    const data = await callApi(`//${slatwallEndpoint}/index.cfm/api/scope/getCart`, options)

    // Form sent successfully
    dispatch({
      type: CART_FETCH_SUCCESS,
      payload: data
    })
    if (data.cart.orderPayments.length > 0) {
      dispatch({
        type: ORDER_PAYMENT_SUCCESS,
        payload: data.cart.orderPayments[data.cart.orderPayments.length - 1]
      })
    }
  } catch (e) { // Error with form request
    dispatch(errorBanner(e.message))
    dispatch({
      type: CART_FETCH_FAIL,
      payload: e.message
    })
  }
}

export const unsetGetStartedLink = () => ({
  type: UNSET_GET_STARTED_LINK
})

export const setGetStartedLink = link => ({
  type: SET_GET_STARTED_LINK,
  payload: link
})
