import Flickity from 'flickity'

/**
 * Fixes Flickity 2.3.0 cell margins
 *
 * To fix SOTHEXT-5297 we upgraded Flickity to 2.3.0, but that version breaks the Timeline Module's cell margins.
 * This fix restores the Flickity 2.2.x cell positioning behavior (using `left:` instead of `transform:`)
 */
export default function FixFlickity() {
  Flickity.Cell.prototype.renderPosition = function(x) {
    // render position of cell with in slider
    var side = this.parent.originSide
    this.element.style[side] = this.parent.getPositionValue(x)
  }
}
