import { EventTracking } from '../segment/PageInteractions'

export class LotRecommendationsModule extends HTMLElement {
  static selectors = {
    component: 'ps-recommended-lots'
  }

  connectedCallback() {
    if (this.fragmentUrl) {
      this.loadFeaturedLots(this.fragmentUrl)
    }
  }

  loadFeaturedLots = fragmentUrl => {
    // Add a unique url parameter to prevent the request from getting cached by Akamai
    const requestUrl = [fragmentUrl, '_bspDynamicModule='].join(
      fragmentUrl.indexOf('?') === -1 ? '?' : '&'
    )

    this.getContent(requestUrl)
      .then(content => {
        this.innerHTML = content
        this.parseRecommendationIds()
        if (this.recommendationIds) {
          this.initClickTracking()
          this.sendEventTracking('Recommendation Loaded')
        }
      })
      .catch(console.warn)
  }

  // Simple ajax
  getContent(fragmentUrl) {
    return new Promise((resolve, reject) => {
      window
        .fetch(fragmentUrl, {
          credentials: 'include'
        })
        .then(response => {
          resolve(response.text())
        })
        .catch(() => {
          reject()
        })
    })
  }

  initClickTracking() {
    // We assume lots are contained in lists
    const lotEls = [...this.querySelectorAll('[role="listitem"]')]

    lotEls.forEach(el => {
      el.addEventListener(
        'click',
        evt => {
          const index = Array.from(el.parentNode.children).indexOf(el)
          this.sendEventTracking('Recommendation Clicked', index)
        },
        false
      )
    })
  }

  sendEventTracking(name, selectedIndexId) {
    let props = {
      recommendation_trait_name: 'tg_web_recs_v2',
      recommendation_trait_content: this.recommendationIds.join(','),
      recommendation_num: this.recommendationIds.length,
      recommendation_source: 'personas'
    }

    if (!isNaN(selectedIndexId)) {
      // TODO: could this ever be out of bounds?
      props.recommendation_selected = this.recommendationIds[selectedIndexId]
    }

    // console.log(name, props)
    EventTracking(name, props)
  }

  parseRecommendationIds() {
    let recommendationIds

    try {
      const recommendationsEl = this.querySelector(
        '[data-recommendation-trait-content]'
      )
      recommendationIds = JSON.parse(
        recommendationsEl.dataset.recommendationTraitContent
      )
    } catch (ex) {
      // We don't have enough information for tracking -- do nothing
    }

    if (recommendationIds && recommendationIds.length) {
      this.recommendationIds = recommendationIds
    }
  }

  get fragmentUrl() {
    return this.getAttribute('data-fragment-url')
  }
}
