import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes.js'
import { EventTracking } from '../../segment/PageInteractions.js'

export class HeroCarouselWide {
  constructor(el) {
    FixFlickity()

    this.element = el
    let count = document.getElementsByClassName('HeroLedeWide').length
    this.carouselContainer = this.element.closest('[data-collapsible-module]')

    // Only initialize the carousel if there is more than one slide
    if (count > 1) {
      this.initCarousel()
    }
  }

  initCarousel() {
    this.carousel = new Flickity(this.element, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: false,
      prevNextButtons: true,
      autoPlay: false
    })

    // analytics on change
    if (typeof analytics !== 'undefined') {
      let carouselProps = {
        carousel_style: 'Hero Two Wide'
      }

      if (this.carouselContainer !== null) {
        if (
          this.carouselContainer.querySelector('[class$="Carousel-title"]') !==
          null
        ) {
          carouselProps.carousel_title = this.carouselContainer.querySelector(
            '[class$="Carousel-title"]'
          ).innerText
        }
      }

      this.carousel.on('change', () => {
        EventTracking('Click Action', carouselProps)
      })
    }

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }
}
