import { throttle } from '../utils/Throttle'
/* global HTMLElement */

/*
** Accompanying script for the FixedFigure module. Applies styling for enabling fixed figure functionality within CMS Previews,
** Emulates `position: fixed` when the FixedFigure is used within a syndicated Viking iframe.
*/
export class FixedFigureModule extends HTMLElement {
  minBreakpointW = 768
  isIframe = false

  _shouldInitFixedEmulation() {
    const isVikingPage = !!window.top.BSP_LOT_IFRAME_URL // TODO: It'd be nice to have an "official" way of determining if we're on a Viking Lot page
    return this.isIframe && isVikingPage
  }

  disconnectedCallback() {
    if (!this._shouldInitFixedEmulation()) {
      return
    }

    window.top.removeEventListener('resize', () => {
      this.onResize()
    })

    window.top.removeEventListener('scroll', () => {
      this.onScroll()
    })
  }

  connectedCallback() {
    this.isIframe = window.location !== window.parent.location

    this.imgEl = this.querySelector('img')
    if (!this.imgEl) {
      return
    }

    // Add .in-preview to fix FixedFigure in CMS previews
    if (this.isIframe) {
      const previewFrameEl = window.top.document.querySelector('.PreviewFrame')
      const sharePreviewEl = window.top.document.querySelector('.SharePreview')
      const isCMSPreview = previewFrameEl && !sharePreviewEl
      if (isCMSPreview && !this.imgEl.classList.contains('in-preview')) {
        this.imgEl.classList.add('in-preview')
      }
    }

    // Only initialize `position: fixed` emulation when we're inside an iframe whose parent isn't fixed and actually has vertical scrolling.
    if (!this._shouldInitFixedEmulation()) {
      return
    }

    // TODO: We need to use some sort of Viking-specific <iframe> ID or class, eg: `.VikingFrame` rather than just using `iframe``
    this.frameParentEl = window.top.document.querySelector(
      'iframe'
    ).parentElement
    if (!this.frameParentEl) {
      console.error('[FixedFigure] Error: no parent iframe element found')
      return
    }

    // Add .in-iframe class to apply specific iframe-only styles (See FixedFigure.less)
    const figureEl = this.querySelector('.FixedFigure')
    if (!figureEl.classList.contains('in-iframe')) {
      figureEl.classList.add('in-iframe')
    }

    window.top.addEventListener('resize', () => {
      this.onResize()
    })

    window.top.addEventListener('scroll', () => {
      this.onScroll()
    })
  }

  onResize() {
    throttle(500, () => {
      this.checkImgTop()
    })
  }

  onScroll() {
    var clientWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (clientWidth >= this.minBreakpointW) {
      this.updateImgTop()
    }
  }

  checkImgTop() {
    if (!this.imgEl) {
      return
    }
    const clientWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (clientWidth < this.minBreakpointW && this.imgEl.style.top !== '0px') {
      this.imgEl.style.top = '0px'
    } else if (
      clientWidth >= this.minBreakpointW &&
      this.imgEl.style.top === '0px'
    ) {
      this.updateImgTop()
    }
  }

  updateImgTop() {
    if (!this.imgEl) {
      return
    }
    const scrollFromTop = window.top.document.documentElement.scrollTop - 80
    this.imgEl.style.top = `${scrollFromTop - this.frameParentEl.offsetTop}px`
  }
}
