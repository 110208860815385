import { throttle } from '../../utils/Throttle'
import MediaQueries from '../../utils/MediaQueries'

/* global HTMLElement */
const mq = new MediaQueries().mq
export class HeroSeries extends HTMLElement {
  MOBILE_MAX_CHARS = 50
  maxDynamicTitleFontSizes = {
    default: {
      lg2: 64,
      md: 48,
      sm: 32
    },
    withGradient: {
      lg2: 64,
      md: 32,
      sm: 32
    }
  }
  minDynamicTitleFontSizes = {
    lg2: 32,
    md: 32,
    sm: 28
  }

  connectedCallback() {
    const titleEl = this.querySelector('.HeroSeries-title')
    const locationDateEl = this.querySelector('.HeroSeries-locationDate')

    this.initTruncation(titleEl)
    this.initTruncation(locationDateEl)

    this._adjustFontToFit(titleEl)
    window.addEventListener(
      'resize',
      throttle(250, () => {
        this._adjustFontToFit(titleEl)
      })
    )
  }

  disconnectedCallback() {
    const titleEl = this.querySelector('.HeroSeries-title')

    window.removeEventListener(
      'resize',
      throttle(250, () => {
        this._adjustFontToFit(titleEl)
      })
    )
  }

  _shorten(text, maxLength, delimiter, overflow) {
    delimiter = delimiter || '&hellip;'
    overflow = overflow || false
    var ret = text
    console.log('Hero ret.len: ' + ret.length + ', maxLen: ' + maxLength)
    if (ret.length > maxLength) {
      var breakpoint = overflow
        ? maxLength + ret.substr(maxLength).indexOf(' ')
        : ret.substr(0, maxLength).lastIndexOf(' ')
      ret = ret.substr(0, breakpoint) + delimiter
    }
    return ret
  }

  _adjustFontToFit(el) {
    const heroEl = this.querySelector('.HeroSeries')
    if (!heroEl) {
      return
    }
    const isGradient = heroEl.hasAttribute('data-with-gradient')
    const titleSpanEl = el.querySelector('span:first-of-type')
    if (!titleSpanEl) {
      return
    }

    var fontSize = isGradient
      ? this.maxDynamicTitleFontSizes['withGradient'][this.breakpoint]
      : this.maxDynamicTitleFontSizes['default'][this.breakpoint]
    const maxWidth = el.clientWidth
    const maxHeight = el.clientHeight
    console.log(`Hero max title w: ${maxWidth}, h: ${maxHeight}`)

    const minFontSize = this.minDynamicTitleFontSizes[this.breakpoint]
    var textHeight
    var textWidth
    do {
      titleSpanEl.style.fontSize = fontSize + 'px'
      textWidth = titleSpanEl.offsetWidth
      textHeight = titleSpanEl.offsetHeight
      fontSize = fontSize - 1
    } while (
      (textHeight > maxHeight || textWidth > maxWidth) &&
      fontSize > minFontSize
    )
  }

  get breakpoint() {
    if (mq['mq-viewport-lg2'].matches) {
      return 'lg2'
    } else if (mq['mq-viewport-md'].matches) {
      return 'md'
    } else if (mq['mq-viewport-sm'].matches) {
      return 'sm'
    }
    return null
  }

  initTruncation(el) {
    if (!el) {
      console.error('Hero error: missing element')
      return
    }

    if (!mq['mq-viewport-md'].matches) {
      console.log('Hero truncating...')

      if (!el.hasAttribute('data-shortened')) {
        const str = new DOMParser()
          .parseFromString(el.innerHTML, 'text/html')
          .documentElement.textContent.trim()
        const strShortened = this._shorten(str, this.MOBILE_MAX_CHARS)
        el.innerHTML = '<span>' + strShortened + '</span>'
        el.setAttribute('data-shortened', 'true')
      }
    }
  }
}
