/* eslint-disable */
import { EventTracking, getPageProperties } from './PageInteractions'
import { accessTier } from '../utils/UserData'
import { getUserProperties } from './IdentityTracking'

const heroVersionName = 'Hero Lede'

export class HeroLedeTracking {
  constructor(el) {
    this.el = el

    if (typeof analytics !== 'undefined') {
      const heroLinks = this.el.querySelectorAll('a')
      const heroImage = this.el.querySelector('.Image')

      let heroProps = {
        homepage_featuredtext: this.el.querySelector('.HeroLede-info-title')
          .innerText
      }

      if (heroImage.getAttribute('alt') !== '') {
        heroProps.homepage_imagename = heroImage.getAttribute('alt')
      }

      heroLinks.forEach(link => {
        link.addEventListener('click', e => {
          const auth0Id = getPageProperties('data-page-properties')[
            'auth0ClientID'
          ]
          const tier = !accessTier ? 0 : accessTier
          const customerId = getUserProperties().customer_id || ''

          const cardType = this.el.getAttribute('data-type') || ''

          const props = {
            ...heroProps,
            card_type: cardType,
            carousel_style: heroVersionName,
            carousel_title: '',
            card_title: link.innerText,
            auth0_id: auth0Id,
            preferred_member_flag: tier,
            customer_id: customerId
          }

          EventTracking('Click Action', props)
        })
      })
    }
  }
}
