import { EventTracking, getPageProperties } from '../segment/PageInteractions'
const OPEN_ATTR = 'data-opened'

export class Faq {
  constructor(el) {
    this.el = el
    this.question = el.querySelectorAll('.FaqItem-question')
    this.pageName = getPageProperties().name

    this.question.forEach(item => {
      item.addEventListener(
        'click',
        e => {
          this.handleToggle(e, item)
        },
        true
      )
    })
  }

  handleToggle(e, clickedItem) {
    e.preventDefault()

    if (clickedItem.parentNode.getAttribute(OPEN_ATTR) === 'true') {
      clickedItem.parentNode.removeAttribute(OPEN_ATTR)
    } else {
      clickedItem.parentNode.setAttribute(OPEN_ATTR, true)
    }

    EventTracking('Click Action', {
      faq_text: `${this.pageName}: ${clickedItem.innerText}`
    })
  }
}
