import { OPEN_BANNER, CLOSE_BANNER } from '../constants/banner'

const initialState = {
  open: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case OPEN_BANNER:
      return { ...state, open: true, ...payload }

    case CLOSE_BANNER:
      return { ...state, open: false, ...payload }

    default:
      return state
  }
}
