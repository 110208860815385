const polyfills = []

if (!window.HTMLPictureElement) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~PictureTagPolyfill" */ './PictureTagPolyfill')
  )
}

if (!('name' in Function.prototype && 'name' in function x() {})) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~FunctionNamePolyfill" */ './FunctionNamePolyfill')
  )
}

if (
  window.navigator.userAgent.indexOf('MSIE ') > 0 ||
  !!navigator.userAgent.match(/Trident.*rv\:11\./)
) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ArrayFindIndexPolyfill" */ './ArrayFindIndexPolyfill')
  )
  polyfills.push(
    import(/* webpackChunkName: "polyfills~NodeRemovePolyfill" */ './NodeRemovePolyfill')
  )
  window.addEventListener('load', async () => {
    const {
      IE11Warning
    } = await import(/* webpackChunkName: 'polyfills~IE11Warning' */ '../IE11Warning')
    IE11Warning()
  })
}

if (
  !(
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  )
) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~IntersectionObserverPolyfill" */ './IntersectionObserverPolyfill')
  )
}

if (!Array.from) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ArrayFromPolyfill" */ './ArrayFromPolyfill')
  )
}

if (typeof Object.assign !== 'function') {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ObjectAssignPolyfill" */ './ObjectAssignPolyfill')
  )
}

if (typeof NodeList.prototype.forEach !== 'function') {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ForEachPolyfill" */ './ForEachPolyfill')
  )
}

if (!window.fetch) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~FetchPolyfill" */ './FetchPolyfill')
  )
}

if (typeof window.CustomEvent !== 'function') {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~CustomEventPolyfill" */ './CustomEventPolyfill')
  )
}

if (window.Element && !Element.prototype.closest) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ClosestPolyfill" */ './ClosestPolyfill')
  )
}

if (
  !Array.prototype.map ||
  !Array.prototype.filter ||
  !Array.prototype.filter
) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~FunctionalArrayPolyfills" */ './FunctionalArrayPolyfills')
  )
}

if (
  !('scrollBehavior' in document.documentElement.style) ||
  window.__forceSmoothScrollPolyfill__ === true
) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~SmoothScrollPolyfill" */ './SmoothScrollPolyfill')
  )
}

let nativeURLSearchParams =
    self.URLSearchParams && self.URLSearchParams.prototype.get
      ? self.URLSearchParams
      : null,
  isSupportObjectConstructor =
    nativeURLSearchParams &&
    new nativeURLSearchParams({ a: 1 }).toString() === 'a=1',
  // There is a bug in safari 10.1 (and earlier) that incorrectly decodes `%2B` as an empty space and not a plus.
  decodesPlusesCorrectly =
    nativeURLSearchParams &&
    new nativeURLSearchParams('s=%2B').get('s') === '+',
  // Fix bug in Edge which cannot encode ' &' correctly
  encodesAmpersandsCorrectly = nativeURLSearchParams
    ? (function() {
        var ampersandTest = new nativeURLSearchParams()
        ampersandTest.append('s', ' &')
        return ampersandTest.toString() === 's=+%26'
      })()
    : true

if (
  !nativeURLSearchParams ||
  !isSupportObjectConstructor ||
  !decodesPlusesCorrectly ||
  !encodesAmpersandsCorrectly
) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~URLSearchParamsPolyfill" */ 'url-search-params-polyfill')
  )
}

if (!('children' in document)) {
  polyfills.push(
    import(/* webpackChunkName: "polyfills~ChildrenPolyfill" */ './ChildrenPolyfill')
  )
}

export default polyfills
