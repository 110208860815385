/* global HTMLElement */

import { EventEmitter } from 'events'

/**
 * This component hits an API to check the status of an auction CTA, based on Brightspot UUID, user's
 * authentication state, and various other variables. API response for the given auction will determine
 * what text should appear on the CTA.
 *
 * @attr uuid: BSP UUID to query API for status
 * @attr id-type: Type of Lot/Auction such as Viking, Invaluable, etc.
 * @attr api-endpoint: API URL
 * @attr localized-text: Any localizable values. Useful for Algolia responses (no BSP backend available).
 * @attr disabled: Option to disable individual dynamic-cta, making it a static CTA

   Example use of this component:
      <dynamic-cta class="{{blockName}}" uuid="{{uuid}}" id-type="{{idType}}" api-endpoint="{{apiEndpoint}}"
              localized-text='{"registerToBid": "{{format "registerToBid"}}",
                                 "placeBid": "{{format "placeBid"}}",
                                 "preview": "{{format "preview"}}",
                                 "viewResults": "{{format "viewResults"}}",
                                 "closed": "{{format "closed"}}",
                                 "follow": "{{format "follow"}}"}',
                                 "bidNow": "{{format "bidNow"}}"}' disabled="false">
                      <a class="{{blockName}}-link" href="{{url}}">{{format "viewAuction"}}</a>
      </dynamic-cta>
 */
const MAX_RETRY_COUNT = 3 // max retries for an individual dynamic-cta that has no entry in a JSON response
export class DynamicCta extends HTMLElement {
  static UUIDS = new Set() // unique UUIDs to call API with
  static LOCK = false // to block additional API calls while one is in progress
  static API_ENDPOINT = ''
  static EMITTER = new EventEmitter()

  static EVENTS = {
    dynamicCtasLoaded: 'dynamic_ctas_loaded',
    ctaResultsReceived: 'cta_results_received'
  }

  static ATTRIBUTES = {
    uuid: 'uuid',
    updated: 'updated',
    disabled: 'disabled'
  }

  /**
   * This method needs to be called from any component that loads dynamic-cta
   * elements onto DOM. A call to this method triggers the API batch call process.
   */
  static dynamicCtasLoaded() {
    DynamicCta.EMITTER.emit(DynamicCta.EVENTS.dynamicCtasLoaded)
  }

  connectedCallback() {
    // first check is emergency turn off setting that can be configured per page in CMS with extra js
    if (
      !window.dynamicCtaDisabled &&
      this.getAttribute(DynamicCta.ATTRIBUTES.disabled) !== 'true'
    ) {
      // ensure that this code only executes once, rather than each time the element is added back to the DOM
      if (!this.connectedCallbackExecuted) {
        this.connectedCallbackExecuted = true
        this.processJson = this.processJson.bind(this)
        this.retryCount = 0
        this.init()
      }
    } else {
      // still need to set this attribute to transition text to visible
      this.setAttribute(DynamicCta.ATTRIBUTES.updated, '')
    }
  }

  init() {
    this.uuid = this.getAttribute(DynamicCta.ATTRIBUTES.uuid)

    if (!this.uuid) {
      console.error('dynamic-cta elements require a uuid attribute')
    }

    this.globalInit()

    DynamicCta.UUIDS.add(this.uuid)

    DynamicCta.EMITTER.on(
      DynamicCta.EVENTS.ctaResultsReceived,
      this.processJson
    )
  }

  /**
   * One time setup for all dynamic-cta elements on a page.
   */
  globalInit() {
    if (
      DynamicCta.EMITTER.listenerCount(DynamicCta.EVENTS.dynamicCtasLoaded) ===
      0
    ) {
      if (!DynamicCta.API_ENDPOINT) {
        DynamicCta.API_ENDPOINT = this.getAttribute('api-endpoint')

        if (!DynamicCta.API_ENDPOINT) {
          console.error(
            'dynamic-cta elements require an api-endpoint attribute'
          )
        }
      }

      DynamicCta.EMITTER.on(DynamicCta.EVENTS.dynamicCtasLoaded, _countCtas)
      DynamicCta.EMITTER.setMaxListeners(Infinity)
    }
  }

  /**
   * Remove actions that are explicitly handled elsewhere. I.e. actions handled by components that
   * don't follow the typical pattern of DynamicCtas, don't use the extra fields (urls, titles, etc.)
   * that are provided by the asset.actions.json endpoints (API_ENDPOINT).
   */
  actionFilter = action => {
    const ignoredActions = [
      'follow' // handled by the FollowAuctionActionLink component
    ]

    return (
      action &&
      typeof action === 'string' &&
      !ignoredActions.includes(action.toLowerCase())
    )
  }

  processJson(json) {
    const uuid = json[this.uuid]
    if (uuid) {
      const { availableActions, url } = uuid

      if (availableActions) {
        availableActions.filter(this.actionFilter).forEach(action => {
          const localizedText = this.localizedText[action]
          if (localizedText) {
            this.link = localizedText
          }
        })
      }

      if (url) {
        this.link.setAttribute('href', url)
      }

      this.elementUpdated()
    } else {
      if (this.retryCount < MAX_RETRY_COUNT) {
        this.retryCount++
      } else {
        this.elementUpdated()
        console.error(`Expected ${this.uuid} event from Auction API call.`)
      }
    }
  }

  elementUpdated() {
    // set updated attribute on dynamic-cta to avoid re-updating and also to display text
    this.setAttribute(DynamicCta.ATTRIBUTES.updated, '')
    DynamicCta.UUIDS.delete(this.uuid)
    DynamicCta.EMITTER.off(
      DynamicCta.EVENTS.ctaResultsReceived,
      this.processJson
    )
  }

  set link(text) {
    this.link.innerText = text
  }

  get link() {
    if (!this._link) {
      const childrenLinks = this.getElementsByTagName('a')
      if (childrenLinks.length === 1) {
        this._link = childrenLinks[0]
      } else {
        console.error(
          'dynamic-cta elements must contain one and only one child a tag'
        )
      }
    }

    return this._link
  }

  get localizedText() {
    if (!this._localizedText) {
      let localizedJson = this.getAttribute('localized-text')
      if (localizedJson) {
        this._localizedText = JSON.parse(localizedJson)
      } else {
        console.error('dynamic-cta elements require a localized-text attribute')
      }
    }
    return this._localizedText
  }
}

/**
 * Find all Dynamic CTAs with unique uuids in DOM (that have not already been updated by API) for
 * API call. Once the same number of unique Dynamic CTAs are initialized, batch call can fire.
 */
const _countCtas = () => {
  const uuids = [
    ...document.querySelectorAll(
      `dynamic-cta:not([${DynamicCta.ATTRIBUTES.updated}])`
    )
  ].map(node => node.getAttribute(DynamicCta.ATTRIBUTES.uuid))
  _batchQueue(new Set(uuids).size)
}

const _batchQueue = ctaCount => {
  if (DynamicCta.UUIDS.size === ctaCount && DynamicCta.UUIDS.size > 0) {
    _callApi()
  }
}

const _callApi = () => {
  // avoid multiple API calls at once
  if (!DynamicCta.LOCK) {
    DynamicCta.LOCK = true

    window
      .fetch(`${DynamicCta.API_ENDPOINT}?id=${[...DynamicCta.UUIDS].join(',')}`)
      .then(response => response.json())
      .then(json =>
        DynamicCta.EMITTER.emit(DynamicCta.EVENTS.ctaResultsReceived, json)
      )

    // throttle API calls then check if another is needed,
    // in case an update was throttled
    setTimeout(() => {
      DynamicCta.LOCK = false
      _countCtas()
    }, 1000)
  }
}
