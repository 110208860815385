export default class MediaQueries {
  constructor() {
    this.mq = this.constructor.mq
    this.breakPoints = this.constructor.breakPoints
  }

  static get breakPoints() {
    return {
      sm: 568,
      md: 768,
      lg: 1024,
      lg2: 1200,
      lg4: 1380,
      xl: 1440,
      xxl: 2000
    }
  }
  static get mediaQueries() {
    let bk = this.breakPoints
    return {
      'mq-viewport-sm': `only screen and (min-width: ${bk.sm}px)`,
      'mq-viewport-md': `only screen and (min-width: ${bk.md}px)`,
      'mq-viewport-lg': `only screen and (min-width: ${bk.lg}px)`,
      'mq-viewport-lg2': `only screen and (min-width: ${bk.lg2}px)`,
      'mq-viewport-lg4': `only screen and (min-width: ${bk.lg2}px)`,
      'mq-viewport-xl': `only screen and (min-width: ${bk.xl}px)`,
      'mq-viewport-xxl': `only screen and (min-width: ${bk.xxl}px)`
    }
  }

  static get mq() {
    let mq = this.mediaQueries
    let w = window
    let returnee = {}
    for (var key in mq) {
      if (mq.hasOwnProperty(key)) {
        returnee[key] = w.matchMedia(mq[key])
      }
    }
    return returnee
  }

  static isDesktop() {
    const mediaQuery = new MediaQueries()
    const mediumMq = mediaQuery.mq['mq-viewport-lg']
    return mediumMq.matches
  }

  static isMedium() {
    const mediaQuery = new MediaQueries()
    const mediumMq = mediaQuery.mq['mq-viewport-lg']
    const largeMq = mediaQuery.mq['mq-viewport-lg2']
    return mediumMq.matches && !largeMq.matches
  }

  static isLarge() {
    const mediaQuery = new MediaQueries()
    const largeMq = mediaQuery.mq['mq-viewport-lg2']
    const xLargeMq = mediaQuery.mq['mq-viewport-xl']
    return largeMq.matches && !xLargeMq.matches
  }
}
