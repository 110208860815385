import { EventTracking, getPageProperties } from './PageInteractions'

const LINK_BUTTON_ATTR = 'data-link-button-id'

export class LinkButtonTracking {
  constructor(el) {
    this.sameBtnCountText = ''
    this.addUniqueAttribute(el)
    el.addEventListener('click', e => {
      this.handleClick(e)
    })
  }

  addUniqueAttribute(el) {
    const r = Math.random()
      .toString(36)
      .substring(7)
    el.setAttribute(LINK_BUTTON_ATTR, r)
  }

  handleClick(e) {
    const currentBtn = e.currentTarget
    const currentBtnText = currentBtn.innerHTML
    const currentBtnAttr = currentBtn.getAttribute(LINK_BUTTON_ATTR)

    this.checkLinkButtons(currentBtnText, currentBtnAttr)
    EventTracking('Click Action', {
      link_button: `${getPageProperties().name}: ${currentBtnText}${
        this.sameBtnCountText
      }`
    })
  }

  contains(selector, text) {
    const elements = document.querySelectorAll(selector)
    return Array.prototype.filter.call(elements, element => {
      return RegExp(text).test(element.textContent)
    })
  }

  checkLinkButtons(currentBtnText, currentBtnAttr) {
    const matchingButtons = this.contains('.LinkButton', currentBtnText)
    if (matchingButtons.length > 1) {
      window.Array.from(matchingButtons).forEach((pageBtn, i) => {
        if (pageBtn.getAttribute(LINK_BUTTON_ATTR) === currentBtnAttr) {
          const btnNum = i + 1
          this.sameBtnCountText = ': ' + btnNum
        }
      })
    }
  }
}
