import { DialogOverlay } from './DialogOverlay'
import { createCookie, hasCookie } from '../utils/CookieJar'
import { EventTracking } from '../segment/PageInteractions'

export class DialogFormEmail extends DialogOverlay {
  static selector = '[data-form-dialog-email]'
  static cookie = 'displayFormModal'
  static success = 'data-submission-successful'
  static delay = 'data-delay'

  constructor(el) {
    super(el)
    this.getDialogAttributes()

    if (
      this.form.hasAttribute(DialogFormEmail.success) &&
      !hasCookie(DialogFormEmail.cookie)
    ) {
      this.openDialogWithId(this.dialog)
      EventTracking('Email Signup', { email_signup: true })
    }
  }

  getDialogAttributes() {
    if (this.el.hasAttribute(DialogOverlay.selectors.dataId)) {
      this.dialogId = this.el.getAttribute(DialogOverlay.selectors.dataId)
    }

    this.dialog = document.querySelector(this.dialogId)
    this.form = this.dialog.querySelector('.FormModal')
  }

  async openDialog() {
    await super.openDialog()
    document
      .getElementsByTagName('body')[0]
      .removeAttribute(DialogOverlay.selectors.dataOpen)
  }

  closeDialog() {
    super.closeDialog()
    createCookie(DialogFormEmail.cookie, 'true', 'max')
  }

  bindShowDialog() {
    return null // don't capture touch events
  }
}
