export class SearchFilterDateRange {
  static selectors = {
    el: '.data-date-range',
    calendarEl: '[data-date-range-calendar]'
  }

  static events = {
    dateSubmit: 'searchFilterDateRange.dateSubmit'
  }

  constructor(el) {
    this.init = this.init.bind(this)
    this.pickerStateChange = this.pickerStateChange.bind(this)
    this.calendarVisibility = this.calendarVisibility.bind(this)

    this.init(el).then(() => {
      document.addEventListener('click', this.calendarVisibility)
      this.cal.on('statechange', this.pickerStateChange)
    })
  }

  async init(el) {
    const {
      DateRangePicker
    } = await import(/* webpackChunkName: 'TinyDatePicker' */ 'tiny-date-picker')
    this.el = el
    this.calElement = this.el.querySelector(
      SearchFilterDateRange.selectors.calendarEl
    )
    this.cal = DateRangePicker(this.calElement)
    this.from = this.el.querySelector('[name="from"]')
    this.to = this.el.querySelector('[name="to"]')
    this.submitInput = this.el.querySelector('[type="checkbox"]')

    this.from.readOnly = true
    this.to.readOnly = true
  }

  pickerStateChange(e, picker) {
    if (picker.state.start && !picker.state.end) {
      this.from.setAttribute('value', picker.state.start.toLocaleDateString())
      this.to.setAttribute('value', '')
      this.to.focus()
    } else {
      this.from.setAttribute('value', picker.state.start.toLocaleDateString())
      this.to.setAttribute('value', picker.state.end.toLocaleDateString())
      this.calElement.setAttribute('data-hidden', '')
      this.submitInput.setAttribute(
        'value',
        picker.state.start.getTime() + '-' + picker.state.end.getTime()
      )
      this.submitInput.setAttribute('checked', '')
      this.el.dispatchEvent(
        new window.Event(SearchFilterDateRange.events.dateSubmit)
      )
    }
  }

  calendarVisibility(e) {
    let target = e.target
    if (
      target === this.to ||
      target === this.from ||
      (target &&
        target.className &&
        target.className.includes &&
        target.className.includes('dp'))
    ) {
      this.calElement.removeAttribute('data-hidden')
      this.calElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else {
      this.calElement.setAttribute('data-hidden', '')
    }
  }
}
