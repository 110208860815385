export const followedAuctionsQuery = `query FollowedAuctions {
  user {
    followedAuctionIds
  }
}`

export const isFollowingAuctionsQuery = `query userQuery($auctionIds: [String!]!) {
  user {
    followInfo {
      auctions {
        isFollowing(auctionIds: $auctionIds) {
          auctionId
          isFollowing
        }
      }
    }
  }
}`
