import {
  CART_FETCH_START,
  CART_FETCH_SUCCESS,
  CART_FETCH_FAIL
} from '../constants/cart'
import {
  ADD_PROMOTION_CODE_START,
  ADD_PROMOTION_CODE_SUCCESS,
  ADD_PROMOTION_CODE_FAIL,
  ORDER_SHIPPING_ADDRESS_START,
  ORDER_PAYMENT_START,
  ORDER_PAYMENT_SUCCESS,
  ORDER_PAYMENT_FAIL,
  ORDER_SHIPPING_METHOD_FAIL,
  ORDER_SHIPPING_METHOD_SUCCESS,
  ORDER_GUEST_ACCOUNT_START,
  ORDER_GUEST_ACCOUNT_SUCCESS,
  ORDER_GUEST_ACCOUNT_FAIL,
  ORDER_SHIPPING_METHOD_START,
  VALIDATE_ADDRESS_START,
  ORDER_SHIPPING_ADDRESS_FAIL,
  VALIDATE_ADDRESS_FAIL,
  UPDATE_GUEST_ACCOUNT_START,
  UPDATE_GUEST_ACCOUNT_SUCCESS,
  UPDATE_GUEST_ACCOUNT_FAIL,
  GET_ADDRESS_OPTIONS_START,
  GET_ADDRESS_OPTIONS_FAIL,
  GET_ADDRESS_OPTIONS_SUCCESS,
  PLACE_ORDER_START,
  PLACE_ORDER_FAIL,
  PLACE_ORDER_SUCCESS
} from '../constants/checkout'
import { ACCOUNT_LOGIN_STARTED } from '../constants/account'

const initialState = {
  cart: false,
  options: false,
  shipping: false,
  payment: false,
  confirm: false
}

/**
 * Keeps state of async actions.
 */
export default (state = initialState, action) => {
  switch (action.type) {

    case ADD_PROMOTION_CODE_START:
      return {
        ...state,
        promotionCode: true
      }

    case ADD_PROMOTION_CODE_SUCCESS:
    case ADD_PROMOTION_CODE_FAIL:
      return {
        ...state,
        promotionCode: false
      }

    case CART_FETCH_START:
    case ACCOUNT_LOGIN_STARTED:
      return {
        ...state,
        cart: true
      }

    case CART_FETCH_SUCCESS:
    case CART_FETCH_FAIL:
      return {
        ...state,
        cart: false
      }

    case GET_ADDRESS_OPTIONS_START:
      return {
        ...state,
        options: true
      }

    case GET_ADDRESS_OPTIONS_FAIL:
    case GET_ADDRESS_OPTIONS_SUCCESS:
      return {
        ...state,
        options: false
      }

    case VALIDATE_ADDRESS_START:
      return {
        ...state,
        shipping: 'Validating address.'
      }

    case ORDER_SHIPPING_ADDRESS_START:
      return {
        ...state,
        shipping: 'Saving address.'
      }

    case ORDER_GUEST_ACCOUNT_START:
      return {
        ...state,
        shipping: 'Creating guest account.'
      }

    case UPDATE_GUEST_ACCOUNT_START:
      return {
        ...state,
        shipping: 'Updating your account information.'
      }

    case ORDER_SHIPPING_METHOD_START:
      return {
        ...state,
        shipping: 'Calculating shippment details.'
      }

    case ORDER_SHIPPING_METHOD_SUCCESS:
    case UPDATE_GUEST_ACCOUNT_SUCCESS:
    case ORDER_GUEST_ACCOUNT_SUCCESS:
    case ORDER_SHIPPING_METHOD_FAIL:
    case ORDER_GUEST_ACCOUNT_FAIL:
    case ORDER_SHIPPING_ADDRESS_FAIL:
    case VALIDATE_ADDRESS_FAIL:
    case UPDATE_GUEST_ACCOUNT_FAIL:
      return {
        ...state,
        shipping: false
      }

    case ORDER_PAYMENT_START:
      return {
        ...state,
        payment: 'Adding payment information.'
      }

    case ORDER_PAYMENT_SUCCESS:
    case ORDER_PAYMENT_FAIL:
      return {
        ...state,
        payment: false
      }

    case PLACE_ORDER_START:
      return {
        ...state,
        confirm: true
      }

    case PLACE_ORDER_FAIL:
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        confirm: false
      }

    default:
      return state
  }
}
