import {
  CART_FETCH_SUCCESS,
  LOCAL_STORAGE_UPDATED
} from '../constants/cart'
import formatCurrency from '../../utils/Currency'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {

    case CART_FETCH_SUCCESS:
      return action.payload.cart.orderItems
        ? action.payload.cart.orderItems.map(item => ({
          price: formatCurrency(item.extendedPriceAfterDiscount, item.currencyCode),
          numericPrice: item.extendedPriceAfterDiscount,
          currencyCode: item.currencyCode,
          renewalPrice: item.calculatedRenewalPrice ? formatCurrency(item.calculatedRenewalPrice + item.calculatedRenewalDiscountAmount, item.currencyCode) : null,
          renewalDiscount: item.calculatedRenewalDiscountAmount ? formatCurrency(item.calculatedRenewalDiscountAmount, item.currencyCode) : null,
          renewalTotal: item.calculatedRenewalPrice ? formatCurrency(item.calculatedRenewalPrice, item.currencyCode) : null,
          subscriptionTerm: parseSubscriptionTerm(item),
          isFreeTrial: item.calculatedRenewalPrice && item.extendedPriceAfterDiscount === 0,
          quantity: item.quantity,
          image: item.sku.imagePath,
          name: parseProductName(item),
          skuName: parseSkuName(item),
          orderItemID: item.orderItemID,
          productID: item.sku && item.sku.product && item.sku.product.productID,
          skuCode: item.sku && item.sku.skuCode
        })) : null

    case LOCAL_STORAGE_UPDATED:
      return action.payload.products || []

    default:
      return state
  }
}

const parseProductName = item => item.sku.product.productWebName || item.sku.product.productName

const parseSkuName = item => {
  const name = parseProductName(item)
  const skuName = item.sku.calculatedSkuDefinition || item.sku.skuName
  return name === skuName ? undefined : skuName
}

const parseSubscriptionTerm = item => {
  if (
    !item.sku ||
    !item.sku.subscriptionTerm ||
    !item.sku.subscriptionTerm.initialTerm
  ) {
    return undefined
  }

  const term = item.sku.subscriptionTerm.initialTerm
  const getNumericValue = str => (isNaN(str) || !str) ? 0 : parseInt(str)

  return {
    name: term.termName,
    length: getNumericValue(term.termHours) +
      (getNumericValue(term.termDays) * 24) +
      (getNumericValue(term.termMonths) * 30 * 24) +
      (getNumericValue(term.termYears) * 12 * 30 * 24),
    rawLength: {
      hours: getNumericValue(term.termHours),
      days: getNumericValue(term.termDays),
      months: getNumericValue(term.termMonths),
      years: getNumericValue(term.termYears)
    }
  }
}
