/* global HTMLElement */

import UserDataUnverified from '../utils/UserDataUnverified'

/**
 * DynamicSalesPrice retrieves sale prices from an API based on UUIDs and
 * authentication state.
 */
export class DynamicSalePrice {
  static hostname = window.location.host || ''
  static DEBUG = DynamicSalePrice.hostname.includes('localhost')
  static UUIDS = new Set() // unique UUIDs to call API with
  static API_ENDPOINT = DynamicSalePrice.DEBUG
    ? '/example/API_asset.saleresults.json'
    : '/data/api/asset.saleresults.json'
  static ATTRIBUTES = {
    prefix: 'data-dynamic-sale-price-prefix',
    type: 'data-dynamic-sale-price-type',
    updated: 'data-dynamic-sale-price-updated',
    uuid: 'data-dynamic-sale-price-uuid',
    soldText: 'data-dynamic-sale-price-sold-text'
  }

  /**
   * This method needs to be called from any component that requires displaying dynamic sale prices. A call to
   * this method triggers the API batch call process.
   */
  static dynamicPricesLoaded() {
    if (!UserDataUnverified.meetsAuthPrecondition()) {
      DynamicSalePrice._log('Not authed, aborting.')
      return
    }
    DynamicSalePrice._gatherPrices()
  }

  static init() {
    DynamicSalePrice.dynamicPricesLoaded()
  }

  static _htmlString(type, priceData, pricePrefix) {
    const { date, salePrice, currency, cardSalePrice, cardDetails } = priceData
    var localizedPrice
    switch (type) {
      case 'LotDateSalePrice':
        localizedPrice = salePrice === null ? null : salePrice.toLocaleString()
        var html = `${localizedPrice} ${currency}`
        if (pricePrefix) {
          html = `${pricePrefix}: ${html}`
        }
        if (date) {
          html = `${date} | ${html}`
        }
        return html
      case 'CardSalePrice':
        return cardSalePrice
      case 'CardDetails':
        return cardDetails
      default:
        localizedPrice = salePrice === null ? null : salePrice.toLocaleString()
        return `${localizedPrice} ${currency}`
    }
  }

  static _log(str, obj = null) {
    if (!DynamicSalePrice.DEBUG || !window.console || !str) {
      return
    }
    if (obj) {
      console.log('[DynamicPrices] ' + str, obj)
    } else {
      console.log('[DynamicPrices] ' + str)
    }
  }

  static _processJson(json) {
    DynamicSalePrice._log('Processing json...', json)

    DynamicSalePrice.UUIDS.forEach(uuid => {
      DynamicSalePrice._log(`Processing uuid: ${uuid}`)

      const priceData = json[uuid]
      if (!priceData) {
        DynamicSalePrice._log(
          `Error: couldn't find price data for uuid: ${uuid}`
        )
        return
      }

      const elements = document.querySelectorAll(
        `[${DynamicSalePrice.ATTRIBUTES.uuid}="${uuid}"]:not([${
          DynamicSalePrice.ATTRIBUTES.updated
        }]`
      )
      elements.forEach(el => {
        const type =
          el.getAttribute(DynamicSalePrice.ATTRIBUTES.type) ||
          'SalePriceCurrency'
        const prefix = el.getAttribute(DynamicSalePrice.ATTRIBUTES.prefix)

        var html = DynamicSalePrice._htmlString(type, priceData, prefix)
        el.innerHTML = html

        if (type === 'ShowPreviousSibling') {
          const prevSibling = el.previousElementSibling
          if (prevSibling) {
            prevSibling.style.display =
              prevSibling.tagName.toLowerCase() === 'span' ? 'inline' : 'block'
          }
        } else if (type === 'UpdatePreviousSibling') {
          const prevSibling = el.previousElementSibling
          if (prevSibling) {
            prevSibling.innerHTML = prevSibling.getAttribute(
              DynamicSalePrice.ATTRIBUTES.soldText
            )
          }
        } else if (type === 'UpdatePreviousSiblingShowNextSibling') {
          const prevSibling = el.previousElementSibling
          if (prevSibling) {
            prevSibling.innerHTML = prevSibling.getAttribute(
              DynamicSalePrice.ATTRIBUTES.soldText
            )

            if (!prevSibling.classList.contains('shown')) {
              prevSibling.classList.add('shown')
            }
          }
          const nextSibling = el.nextElementSibling
          if (nextSibling) {
            nextSibling.style.visibility = 'visible'
          }
        }

        el.setAttribute(DynamicSalePrice.ATTRIBUTES.updated, '')
        DynamicSalePrice.UUIDS.delete(uuid)
      })
    })
  }

  /**
   * Gather all dynamic sale price elements that haven't been updated and store their UUIDs in `DynamicSalePrice.UUIDS`.
   * If any are found, call the API endpoint to fetch their respective prices.
   */
  static _gatherPrices = () => {
    DynamicSalePrice._log('Gathering price uuids...')
    const uuids = [
      ...document.querySelectorAll(
        `[${DynamicSalePrice.ATTRIBUTES.uuid}]:not([${
          DynamicSalePrice.ATTRIBUTES.updated
        }])`
      )
    ].map(node => {
      if (node.style.display === 'none') {
        return null
      }
      return node.getAttribute(DynamicSalePrice.ATTRIBUTES.uuid)
    })

    uuids.forEach(uuid => DynamicSalePrice.UUIDS.add(uuid))

    DynamicSalePrice._log(`Found ${DynamicSalePrice.UUIDS.size}.`)
    if (DynamicSalePrice.UUIDS.size > 0) {
      DynamicSalePrice._callApi()
    }
  }

  /**
   * Fetch sale prices for any stored UUIDs, process the JSON and display them on the page.
   */
  static _callApi = () => {
    DynamicSalePrice._log('Calling API w/ stored uuids...')
    window
      .fetch(
        `${DynamicSalePrice.API_ENDPOINT}?id=${[...DynamicSalePrice.UUIDS].join(
          ','
        )}`
      )
      .then(response => response.json())
      .then(json => DynamicSalePrice._processJson(json))
  }
}
