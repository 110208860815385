const SHOW_ALL_ATTR = 'data-display-all'

export class SeeAllFilters {
  constructor(el) {
    this.el = el
    let filterList = el.previousElementSibling
    let filterListCount = filterList.children.length

    if (filterListCount <= 5) {
      filterList.setAttribute(SHOW_ALL_ATTR, '')
    } else {
      this.el.addEventListener('click', e => {
        e.preventDefault()
        filterList.setAttribute(SHOW_ALL_ATTR, '')
      })
    }
  }
}
