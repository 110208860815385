import Flickity from 'flickity'
import FixFlickity from '../FlickityFixes'
import { DialogOverlay } from '../dialog/DialogOverlay'
import { Tracker } from '../utils/Events'
import { EventTracking } from '../segment/PageInteractions'

export class OnboardingModule {
  static selectors = {
    slide: '[data-slide]',
    navArrow: '.flickity-button'
  }

  constructor(el) {
    FixFlickity()

    this.el = el
    this.dialog = this.el.closest(DialogOverlay.selectors.dialogOverlay)
    this.listenerTracker = new Tracker()
    let count = this.el.querySelectorAll(OnboardingModule.selectors.slide)
      .length
    if (count >= 1) {
      this.initCarousel(count)
      this.navArrows = this.el.querySelectorAll(
        OnboardingModule.selectors.navArrow
      )
      this.collectListeners()
    }

    // This event must be added because the carousel is initialized while hidden.
    // Therefore when the dialog overlay is opened the carousel must be resized to
    // make sure it displays properly.
    if (this.dialog) {
      this.dialog.addEventListener(DialogOverlay.events.open, () => {
        if (this.carousel) {
          this.carousel.resize()
        }
      })
    }
  }

  initCarousel(count) {
    this.carousel = new Flickity(this.el, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: count > 1,
      prevNextButtons: true,
      autoPlay: false,
      wrapAround: true,
      cellSelector: OnboardingModule.selectors.slide
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }

  collectListeners() {
    this.navArrows.forEach(arrow =>
      this.listenerTracker.addListener(arrow, 'click', e => {
        EventTracking('Onboarding Module Interaction')
      })
    )
  }
}
