/**
 * Functions for Segment tracking.
 */

import { throttle } from '../utils/Throttle'
import { appendUserTrackingProperties, indentifyUser } from './IdentityTracking'
import { Delegate } from '../utils/Delegate'
import { GetUrlParams } from '../utils/UrlUtils'
// need unverified module without dynamic loading because header uses this module
import UserDataUnverified from '../utils/UserDataUnverified'
import { getPageLanguageName } from '../utils/PageUtils'
import SyndicationUtils from '../utils/SyndicationUtils'

const state = {
  lastPercentage: 0,
  documentHeight: null,
  lastExitLink: window.location,
  previousPage: {
    set: data =>
      window.sessionStorage.setItem('previous_page', JSON.stringify(data)),
    get: () => JSON.parse(window.sessionStorage.getItem('previous_page')) || {},
    remove: () => window.sessionStorage.removeItem('previous_page')
  }
}

const _removeSubdomain = name =>
  name.split('.').length > 2
    ? name
        .split('.')
        .slice(1)
        .join('.')
    : name

const _getPagePercentage = h =>
  ((document.documentElement['scrollTop'] || document.body['scrollTop']) /
    (h - document.documentElement.clientHeight)) *
  100

const _trackPagePercentage = breakpoints => {
  state.documentHeight =
    state.documentHeight ||
    document.documentElement['scrollHeight'] ||
    document.body['scrollHeight']
  const percentage = Math.ceil(_getPagePercentage(state.documentHeight))
  if (
    percentage > state.lastPercentage &&
    breakpoints.indexOf(state.lastPercentage) < breakpoints.length
  ) {
    const nextPercentage =
      breakpoints[breakpoints.indexOf(state.lastPercentage) + 1]
    if (percentage >= nextPercentage) {
      EventTracking(`Percent Page Viewed ${nextPercentage}`, {
        page_progress: nextPercentage
      })
      state.lastPercentage = nextPercentage
    }
  }
}

const _getNavLinkTrackingProps = (link, navigationLevel) => {
  return {
    cta_text: link.innerText,
    cta_link_url: _getFullUrl(link.getAttribute('href')),
    navigation_level: navigationLevel
  }
}

/**
 * If passed a path with no domain, append current domain to link
 * to return the full URL. If domain already specified, do nothing.
 * @param path - full url or url path
 */
const _getFullUrl = path => {
  return path.charAt(0) === '/' ? window.location.origin + path : path
}

const _getCmpTrackingProperty = () => {
  const cmpCode = GetUrlParams('cmp')
  return cmpCode
    ? {
        cmp_trackingcode: cmpCode
      }
    : undefined
}

const _getRakutenReferralProperty = () => {
  const urlParams = GetUrlParams()
  const ranMid = urlParams.get('ranMID')
  const ranSiteId = urlParams.get('ranSiteID')

  if (ranMid || ranSiteId) {
    const clickDate = urlParams.get('clickdate')
    return {
      rakuten_referral_data: `${ranSiteId}::${clickDate}`
    }
  }
}

export const getPageProperties = (attr = 'data-tracking') => {
  const trackingEl = document.querySelector(`[${attr}]`)

  if (trackingEl) {
    // ensure JSON string does not end with trailing comma
    let trackingStr = trackingEl.getAttribute(attr).replace(/,[\s]*}$/g, '}')
    return JSON.parse(trackingStr)
  }
}

/**
 * Get properties relating to preferred users. Overrides preferred_content
 * property from page properties based on whether they're logged in or not.
 *
 * @async
 * @function getPreferredProperties
 * @return {object} preferred-related properties
 */
export const getPreferredProperties = async () => {
  const isPreferredContent = getPageProperties()['preferred_content']
  if (isPreferredContent === 'false') {
    // return false property if not preferred content
    return {
      preferred_content: isPreferredContent
    }
  }

  // from here on, we know it is preferred content, is the user preferred?
  let hasPreferred
  try {
    hasPreferred = await UserDataUnverified.hasUnverifiedAccess(
      UserDataUnverified.accesses.PREFERRED
    )
  } catch (e) {
    hasPreferred = false
  }

  return {
    preferred_content: hasPreferred
  }
}

/**
 * Track percentage of page viewed
 * @param breakpoints array of integers of scroll percentages to report on
 * @return throttled function to be execute on scroll
 */
export const TrackPercentPageViewed = throttle(250, _trackPagePercentage)

/**
 * Track url of link (internal or external)
 * @param e event that is passed to if attached to an event listener
 * @param link (optional) needs to have href & hostname to test versus current host, if not the document's
 *        active element will be used.
 * @return function to track link url
 */
export const TrackLink = (e, link) => {
  let exitLink = null
  if (link) {
    // shortcut getting link from path if link provided
    exitLink = link
  } else {
    let target = e.target
    while (target !== document.body) {
      if (target.nodeName === 'A') {
        // traverse click path for link node
        exitLink = target
      }
      target = target.parentElement
    }
  }

  // if there is no exit link, or if it is the same as previous reported, don't do anything
  if (
    !exitLink ||
    !(exitLink.hostname && exitLink.href) ||
    state.lastExitLink.href === exitLink.href
  ) {
    return null
  }

  // only report link that is different from website hostname
  if (
    _removeSubdomain(exitLink.hostname) !==
      _removeSubdomain(window.location.hostname) ||
    exitLink.hostname !== window.location.hostname
  ) {
    EventTracking('Exit Link Clicked', { external_link: exitLink.href })
    state.lastExitLink = exitLink // store so switching tabs wont trigger more of same exit links
  } else {
    EventTracking('Click Action', { internal_link: exitLink.href })
    state.previousPage.set({
      ...getPageProperties(),
      url: window.location.href,
      cta:
        (exitLink.querySelector('[class$="-ctaText"]') &&
          exitLink.querySelector('[class$="-ctaText"]').innerText) ||
        exitLink.innerText ||
        undefined
    })
  }
}

/**
 * Track url of link (internal or external)
 */
export const LinkTracking = () => {
  Delegate('body', 'click', 'a', TrackLink)
}

/**
 * Finds an element with a tracking attribute then retrieves its JSON blob that
 * contains all of the page info that should be page tracked via segment. This
 * info is then sent to segment.
 */
export const PageTracking = () => {
  if (typeof window.analytics !== 'undefined') {
    const previous = state.previousPage.get()
    state.previousPage.remove()
    getPreferredProperties()
      .then(preferredTracking => {
        const pageTracking = {
          ...getPageProperties(),
          ...preferredTracking,
          ..._getCmpTrackingProperty(),
          ..._getRakutenReferralProperty(),
          previous_cta: previous.cta,
          previous_page_url: previous.url,
          previous_pagename: previous.name,
          language: getPageLanguageName()
        }

        indentifyUser()
        window.analytics.page(
          pageTracking.name,
          appendUserTrackingProperties(pageTracking)
        )
      })
      .catch(e => console.warn(e))
  }
}

export const EventTracking = (name, properties = {}) => {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.track(name, appendUserTrackingProperties(properties))
  } else if (SyndicationUtils.isIframe) {
    SyndicationUtils.dispatch(SyndicationUtils.events.ANALYTICS, {
      name,
      properties: appendUserTrackingProperties(properties)
    })
  }
}

export const NavigationTracking = () => {
  const trackingProperty = 'Navigation Clicked'
  const navLinkSelector = '.NavigationLink'
  const mainNavItemSelector = '.SothebysNavigationItem'
  const secondaryNavItemSelector = '.SothebysTopNavigationItem'

  Delegate('body', 'click', `${mainNavItemSelector} ${navLinkSelector}`, e => {
    const navLink = e.target
    const mainNavItem = navLink.closest(mainNavItemSelector)
    const mainNavItemTitle = mainNavItem.querySelector(
      `${mainNavItemSelector}-title .NavigationItemTitle [data-text-content]`
    )
    const navigationLevel = mainNavItemTitle
      ? `Main Navigation Item - ${mainNavItemTitle.innerText}`
      : ''

    EventTracking(
      trackingProperty,
      _getNavLinkTrackingProps(navLink, navigationLevel)
    )
  })

  Delegate(
    'body',
    'click',
    `${mainNavItemSelector} ${mainNavItemSelector}-title a`,
    e => {
      const navLink = e.target
      const mainNavItemTitle = navLink.getAttribute('data-text-content')
      const navigationLevel = mainNavItemTitle
        ? `Main Navigation Item - ${
            mainNavItemTitle ? mainNavItemTitle.toUpperCase() : ''
          }`
        : ''

      if (navigationLevel) {
        EventTracking(
          trackingProperty,
          _getNavLinkTrackingProps(navLink, navigationLevel)
        )
      }
    }
  )

  Delegate(
    'body',
    'click',
    `${mainNavItemSelector} ${secondaryNavItemSelector}-title a`,
    e => {
      const navLink = e.target
      const mainNavItem = navLink.closest(mainNavItemSelector)
      const mainNavItemTitle = mainNavItem.querySelector(
        `${mainNavItemSelector}-title .NavigationItemTitle [data-text-content]`
      )
      const navigationLevel = mainNavItemTitle
        ? `Main Navigation Item - ${mainNavItemTitle.innerText}`
        : ''

      EventTracking(
        trackingProperty,
        _getNavLinkTrackingProps(navLink, navigationLevel)
      )
    }
  )

  Delegate(
    'body',
    'click',
    `.SothebysHatNavigationItem ${navLinkSelector}`,
    e => {
      EventTracking(
        trackingProperty,
        _getNavLinkTrackingProps(e.target, 'Top Level Navigation')
      )
    }
  )

  Delegate(
    'body',
    'click',
    '.SothebysFooter .LinkListItem [data-text-content]',
    e => {
      EventTracking(
        trackingProperty,
        _getNavLinkTrackingProps(e.target, 'Footer')
      )
    }
  )
}
