/* Standardized Form Types */
export const EVENT_FORM_TYPE = 'Event Form'
export const HOMEPAGE_FORM_TYPE = 'Homepage'
export const NEWSLETTER_FORM_TYPE = 'Newsletter'
export const SWEEPS_FORM_TYPE = 'Sweeps'
export const TRAVEX_FORM_TYPE = 'Travex Form'

/**
 * Loads standardized forms dynamically. If not a standardized form, it will
 * load the custom form module.
 */
export class FormLoader {
  static calssSelector = '.data-form-loader'

  constructor(el) {
    const type = el.getAttribute('data-form-type')

    switch (type) {
      case EVENT_FORM_TYPE:
        import(/* webpackChunkName: 'EventForm' */ './EventForm')
          .then(({ EventForm }) => new EventForm(el))
          .catch(e => console.warn('Error loading EventForm', e))
        break
      case HOMEPAGE_FORM_TYPE:
        import(/* webpackChunkName: 'HomepageForm' */ './HomepageForm')
          .then(({ HomepageForm }) => new HomepageForm(el))
          .catch(e => console.warn('Error loading HomepageForm', e))
        break
      case NEWSLETTER_FORM_TYPE:
        import(/* webpackChunkName: 'NewsletterForm' */ './NewsletterForm')
          .then(({ NewsletterForm }) => new NewsletterForm(el))
          .catch(e => console.warn('Error loading NewsletterForm', e))
        break
      case SWEEPS_FORM_TYPE:
        import(/* webpackChunkName: 'SweepsForm' */ './SweepsForm')
          .then(({ SweepsForm }) => new SweepsForm(el))
          .catch(e => console.warn('Error loading SweepsForm', e))
        break
      case TRAVEX_FORM_TYPE:
        import(/* webpackChunkName: 'TravexForm' */ './TravexForm')
          .then(({ TravexForm }) => new TravexForm(el))
          .catch(e => console.warn('Error loading TravexForm', e))
        break
      default:
        if (el.hasAttribute('data-form')) {
          import(/* webpackChunkName: 'Form' */ './Form')
            .then(({ Form }) => new Form(el))
            .catch(e => console.warn('Error loading Form', e))
        }
    }
  }
}
