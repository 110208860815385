import { DialogOverlay } from './DialogOverlay'
import { ImageCarousel } from '../core/list/ImageCarousel'
import { SearchStyleToggle } from '../core/search/SearchStyleToggle'
import { EventTracking, getPageProperties } from '../segment/PageInteractions'
import { Tracker } from '../utils/Events'

export class QuickCardDialog extends DialogOverlay {
  constructor(el) {
    super(el)

    this.listenerTracker = new Tracker()
  }

  async openDialog() {
    await super.openDialog()

    const carousel = this.dialog.querySelector(ImageCarousel.selectors.carousel)
    if (carousel != null) {
      carousel.initCarousel()
    }

    try {
      this.segmentAnalytics()
    } catch (e) {
      console.warn('Error sending analytics.', e)
    }
  }

  closeDialog() {
    super.closeDialog()
    this.listenerTracker.removeListeners()
  }

  constructDialogHtml(url) {
    // Count overlays on the page, create +1 to make sure a unique id is set
    // in case there's more than one
    const overlayCount =
      document.querySelectorAll(DialogOverlay.selectors.dialogOverlay).length +
      1
    const generatedDialogId = 'dynamicOverlay' + overlayCount
    const generatedDialogIdWithHash = '#' + generatedDialogId
    const closeAttribute = DialogOverlay.selectors.dataClose.substr(
      1,
      DialogOverlay.selectors.dataClose.length - 2
    )
    let dialogString = `<dialog id="${generatedDialogId}" class="${DialogOverlay.selectors.dialogOverlay.substr(
      1
    )}" data-overlay-container data-transparent>
        <div class="${DialogOverlay.selectors.main.substr(1)}">
          <div class="QuickCardModule-overlayContainer">
            <div class="QuickCardModule-content">
              {{content}}
              <button class="${DialogOverlay.selectors.close.substr(
                1
              )}" ${closeAttribute}>Close</button>
            </div>
          </div>
        </div>
      </dialog>`

    window
      .fetch(url, { credentials: 'include', mode: 'cors' })
      .then(response => {
        return response.text()
      })
      .then(body => {
        if (this.dialogUrlSelector) {
          let parser = new window.DOMParser()
          let bodyHtml = parser.parseFromString(body, 'text/html')
          let bodySelector = bodyHtml.querySelector(this.dialogUrlSelector)
          let bodyHtmlString = bodySelector.outerHTML
          dialogString = dialogString.replace('{{content}}', bodyHtmlString)
        } else {
          dialogString = dialogString.replace('{{content}}', body)
        }
        document.body.insertAdjacentHTML('beforeend', dialogString)
        this.el.setAttribute(
          DialogOverlay.selectors.dataId,
          generatedDialogIdWithHash
        )
        this.dialogId = generatedDialogIdWithHash

        Promise.resolve().then(() => {
          this.dialog
            .querySelector('.QuickCard-title')
            .setAttribute('id', `${generatedDialogId}-title`)
          this.dialog.setAttribute(
            'aria-labelledby',
            `${generatedDialogId}-title`
          )

          this.dialog.addEventListener('open', () => {
            this.dialog.querySelector('.QuickCard').focus()
          })

          this.dialog.addEventListener('close', () => {
            this.el.focus()
          })
        })

        this.openDialogWithId()
      })
  }

  segmentAnalytics() {
    EventTracking('Quick View Opened', {
      sale_id: this.saleId,
      view_selection: this.viewSelection
    })

    // click interactions to track {selector: name}
    const quickViewClickInteractions = {
      '.QuickCard-addToCalendar': 'add to calendar',
      ".QuickCardShare[expanded='true']": 'share',
      ".QuickCardHours[expanded='true']": 'view exhibition hours',
      'image-carousel': 'click image carousel'
    }

    // create an event function to track click
    const interactionTracking = type => event =>
      EventTracking('Quick View Interaction', {
        quickview_interaction_type: type,
        sale_id: this.saleId
      })

    Object.keys(quickViewClickInteractions).map(selector =>
      this.listenerTracker.addListener(
        this.dialog,
        'click',
        interactionTracking(quickViewClickInteractions[selector]),
        selector
      )
    )

    const ctaClickListener = e =>
      EventTracking('Quick View Clicked', {
        cta_text: e.target.innerText,
        sale_id: this.saleId
      })

    this.listenerTracker.addListener(
      this.dialog,
      'click',
      ctaClickListener,
      '.QuickCard-cta'
    )
  }

  get viewSelection() {
    let viewSelection = ''
    const pageTrackingProps = getPageProperties()
    const card = this.el.closest(
      `[${SearchStyleToggle.attributes.searchStyle}]`
    )

    if (pageTrackingProps) {
      viewSelection += `${pageTrackingProps.name}: `
    }

    if (card) {
      const style = card.getAttribute(SearchStyleToggle.attributes.searchStyle)

      if (style.length > 1) {
        viewSelection += style[0].toUpperCase() + style.slice(1)
      }
    }

    return viewSelection
  }

  get saleId() {
    if (
      this.dialog &&
      this.dialog.querySelector('.QuickCard') &&
      this.dialog.querySelector('.QuickCard').hasAttribute('data-sale-number')
    ) {
      return this.dialog
        .querySelector('.QuickCard')
        .getAttribute('data-sale-number')
    }

    return undefined
  }
}
