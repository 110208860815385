/* global HTMLElement */
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'

export class ImageCarousel extends HTMLElement {
  static selectors = {
    carousel: 'image-carousel',
    image: '[data-carousel-image]'
  }

  constructor() {
    super()
    FixFlickity()
  }

  connectedCallback() {
    this.initCarousel()
    this.images.map(image =>
      image.addEventListener('load', this.resizeCarousel)
    )
  }

  disconnectedCallback() {
    this.images.map(image =>
      image.removeEventListener('load', this.resizeCarousel)
    )
  }

  initCarousel() {
    if (this.items.length <= 1) {
      return null
    }

    if (this.carousel) {
      this.carousel.destroy()
      delete this.carousel
    }

    this.carousel = new Flickity(this, {
      cellAlign: 'left',
      contain: true,
      pageDots: true,
      wrapAround: true,
      prevNextButtons: true
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }

  resizeCarousel = () => {
    if (this.carousel) {
      this.carousel.resize()
    }
  }

  /*
   * Items that the carousel displays, ie. slides.
   *
   * @returns {Array} items
   */
  get items() {
    return [...this.querySelectorAll(ImageCarousel.selectors.image)] || []
  }

  /*
   * All of the images in the carousel that are in containers. There can be more
   * than one image per container (ie. different breakpoints), this gets all of them.
   *
   * @returns {Array} images
   */
  get images() {
    return (
      [...this.querySelectorAll(`${ImageCarousel.selectors.image} img`)] || []
    )
  }
}
