/**
 * Uses `Number.prototype.toLocaleString()` to format an amount in a given
 * currency. It uses the navigator language to format the price for that
 * locale. If given no currency, or if amount is not a number, it will just
 * return the amount.
 *
 * Browser compatibility: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Browser_compatibility
 *
 * @param {number} amount to be formatted into the price
 * @param {string} currency Currency Code of the price, eg. USD will output $
 */
export default function formatCurrency(amount, currency) {
  if (amount != null && typeof amount === 'number' && !!currency) {
    return amount.toLocaleString(navigator.language, {
      style: 'currency',
      currency: currency
    })
  }
  return amount
}
