/* global HTMLElement */
import { throttle } from '../utils/Throttle'
import { DynamicCta } from '../cta/DynamicCta'

export class CatalogNavigation extends HTMLElement {
  constructor() {
    super()

    // signal that dynamic ctas are all in DOM and should batch call
    DynamicCta.dynamicCtasLoaded()

    window.addEventListener(
      'scroll',
      throttle(250, () => {
        this.stickyHeader()
      })
    )
    let nav = this.querySelector('.CatalogNavigation-navigation')
    nav.addEventListener(
      'scroll',
      throttle(250, () => {
        this.wrapperGradient(nav)
      })
    )
  }

  wrapperGradient(nav) {
    let scrollPosition = nav.scrollLeft
    let wrapper = this.querySelector('.CatalogNavigation-wrapper')
    if (scrollPosition >= 10) {
      wrapper.classList.add('scrolled')
    } else {
      wrapper.classList.remove('scrolled')
    }
  }

  stickyHeader() {
    var viewportOffset = this.getBoundingClientRect()
    if (viewportOffset.top <= 0) {
      this.classList.add('sticky')
    } else {
      this.classList.remove('sticky')
    }
  }
}
