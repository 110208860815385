/* eslint-disable */
import objectFitImages from 'object-fit-images'
import lazySizes from 'lazysizes'
import plugins, { loadWhenInViewport } from 'pluginRegistry'
import localizationQueryParamAppender from 'localizationQueryParamAppender'
import UserData from './utils/UserData'

import { AddToBag } from './product/AddToBag'
import { ArtistPage } from './core/page/ArtistPage'
import { PageCTAModule } from './core/page/PageCTAModule'
import { AutofillInputs } from './core/form/AutofillInputs'
import { BackToTop } from './utils/BackToTop'
import { BrightcoveVideoPlayer } from './core/video/BrightcoveVideoPlayer'
import { BrightcoveRedactedPlayer } from './core/video/BrightcoveRedactedPlayer'
import { CollapsibleFilter } from './utils/CollapsibleFilter'
import { CardCarousel } from './core/list/CardCarousel'
import { CardTitleTruncate } from './core/promo/CardTitleTruncate'
import { ImageCarousel } from './core/list/ImageCarousel'
import { CatalogNavigation } from './catalog/CatalogNavigation'
import { CarouselWithOverlay } from './core/list/CarouselWithOverlay'
import { CartPageLoader } from './cart/CartPageLoader'
import { ChapterModule } from './utils/ChapterModule'
import { CheckoutButton } from './product/CheckoutButton'
import { CollapsibleModule } from './utils/CollapsibleModule'
import { ConsentManagerTrigger } from './segment/ConsentManagerTrigger'
import { CopyLink } from './core/link/CopyLink'
import { DateRangeInput } from './core/form/input/DateRangeInput'
import { DynamicCard } from './core/promo/DynamicCard'
import { DynamicCarouselGrid } from './core/list/DynamicCarouselGrid'
import { DynamicCta } from './cta/DynamicCta'
import { DynamicTitle } from './core/container/DynamicTitle'
import { DynamicHeader } from './header/DynamicHeader'
import { DynamicSalePrice } from './price/DynamicSalePrice'
import { SearchFilterDateRange } from './core/search/SearchFilterDateRange'
import { SearchFilterPriceRange } from './core/search/SearchFilterPriceRange'
import { DialogOverlay } from './dialog/DialogOverlay'
import { DialogFormEmail } from './dialog/DialogFormEmail'
import { EmailInput } from './core/form/input/EmailInput'
import { NumberInput } from './core/form/input/NumberInput'
import { Faq } from './faq/Faq'
import { FeaturedLotModule } from './module/FeaturedLotModule'
import { FollowAuctionActionLink } from './auction/FollowAuctionActionLink'
import { FormLoader } from './core/form/FormLoader'
import { GoogleDfp } from './core/dfp/GoogleDfp'
import { HeaderCarousel } from './core/list/HeaderCarousel'
import { HeroCarouselWide } from './core/list/HeroLedeWide'
import { HeroLedeCarousel } from './core/list/HeroLedeCarousel'
import { CatalogHeroCarousel } from './core/list/CatalogHeroCarousel'
import { HeroCornerInfoSlider } from './core/list/HeroCornerInfoSlider'
import { HeroSeries } from './core/promo/HeroSeries'
import { HeroSideInfoSlider } from './core/list/HeroSideInfoSlider'
import { HeroNavSlider } from './core/list/HeroNavSlider'
import { HeroTruncate } from './core/promo/HeroTruncate'
import { HeroWideTruncate } from './core/promo/HeroLedeWide'
import { HotspotModule } from './module/HotspotModule'
import { IframeCarousel } from './core/list/IframeCarousel'
import { LocationFilter } from './core/search/LocationFilter'
import { LotRecommendationsModule } from './lot/LotRecommendationsModule'
import { PreferredTierGatedListModule } from './core/list/PreferredTierGatedListModule'
import { FixedFigureModule } from './module/FixedFigureModule'
import { MosaicFigureModule } from './module/mosaic/MosaicFigureModule'
import { MosaicListModule } from './module/mosaic/MosaicListModule'
import { NavigationBar } from './utils/NavigationBar'
import { NotificationDialog } from './dialog/NotificationDialog'
import { OnboardingDialog } from './dialog/OnboardingDialog'
import { OnboardingModule } from './module/OnboardingModule'
import { OrnamentTicket } from './preferred/OrnamentTicket'
import { Parallax } from './utils/Parallax'
import { PasswordInput } from './core/form/input/PasswordInput'
import { PersonalizedContent } from './personalized/PersonalizedContent'
import { PhoneNumberInput } from './core/form/input/PhoneNumberInput'
import { PriceChoice } from './price/PriceChoice'
import { PriceDisplay } from './price/PriceDisplay'
import { QuickCardDialog } from './dialog/QuickCardDialog'
import { QuickCardExpandable } from './auction/quickcard/QuickCardExpandable'
import { ReadMore } from './utils/ReadMore'
import { RegisterToBidAuctionLink } from './auction/RegisterToBidAuctionLink'
import { SearchModuleLoader } from './core/search/SearchModuleLoader'
import { AuctionsModuleLoader } from './core/page/AuctionsModuleLoader'
import { LotPageModuleLoader } from './core/page/LotPageModuleLoader'
import { SeeAllFilters } from './core/search/SeeAllFilters'
import { Select } from './core/form/select/Select'
import { SkuSelect } from './product/SkuSelect'
import { ShowPasswordInput } from './core/form/input/PasswordInputShow'
import { SlideshowCarouselLoader } from './core/gallery/SlideshowCarouselLoader'
import { VerticalNavGalleryLoader } from './core/gallery/VerticalNavGalleryLoader'
import { Tabs } from './core/tab/Tabs'
import { TabsPlaylist } from './core/tab/TabsPlaylist'
import { TextArea } from './core/form/input/TextArea'
import { TextInput } from './core/form/input/TextInput'
import { TimelineModule } from './timeline/TimelineModule'
import { TranslationModule } from './utils/TranslationModule'
import { TravellingExhibitionShowing } from './auction/TravellingExhibitionShowing'
import { SearchStyleToggle } from './core/search/SearchStyleToggle'
import { UserInfo } from './core/form/user-info/UserInfo'
import { VerifyMuseumPage } from './preferred/VerifyMuseumPage'
import { VerifyPromo } from './preferred/VerifyPromo'
import { VideoPlaylist } from './core/video/VideoPlaylist'
import { QuantityDisplay } from './product/QuantityDisplay'
import { ZoomableImage } from './zoomableimage/ZoomableImage'
import { AuctionsStyleToggle } from './core/page/AuctionPageToggle'

import { ReactAlgoliaGeographicSearchModule } from './react/ReactAlgoliaGeographicSearchModule'
import { ReactSearchModule } from './react/ReactSearchModule'
import { ReactMapboxMapSingleLocation } from './react/ReactMapboxMapSingleLocation'

// Segment Tracking
import {
  PageTracking,
  LinkTracking,
  getPageProperties
} from './segment/PageInteractions'
import { AnswerCardTracking } from './segment/AnswerCardTracking'
import { CardTracking } from './segment/CardTracking'
import { CompanionTracking } from './segment/CompanionTracking'
import { HeroLedeTracking } from './segment/HeroLedeTracking'
import { HeroLedeWideTracking } from './segment/HeroLedeWideTracking'
import { LinkButtonTracking } from './segment/LinkButtonTracking'
import { PromoTracking } from './segment/PromoTracking'
import { SubscriptionCtaTracking } from './segment/SubscriptionCtaTracking'
import { TileGridTracking } from './segment/TileGridTracking'
import { TabsTracking } from './segment/TabsTracking'
import { TravexTracking } from './segment/TravexTracking'
import { CecilBeatonTracking } from './segment/CecilBeatonTracking'
import { ProductTracking } from './segment/RetailTracking'
import { RichMediaTracking } from './segment/RichMediaTracking'
import SyndicationUtils from './utils/SyndicationUtils'
import { AuctionCardComponent } from './core/promo/AuctionCardComponent'

export default function init() {
  localizationQueryParamAppender()

  document.addEventListener('DOMContentLoaded', function() {
    objectFitImages()
  })

  // Preferred redaction on algolia
  DynamicCard.init()

  AuctionCardComponent.init()

  window.customElements.define(AddToBag.selectors.component, AddToBag)
  window.customElements.define(BackToTop.selectors.backToTop, BackToTop)
  window.customElements.define(
    CheckoutButton.selectors.component,
    CheckoutButton
  )
  window.customElements.define(ImageCarousel.selectors.carousel, ImageCarousel)
  window.customElements.define(
    LocationFilter.selectors.component,
    LocationFilter
  )
  window.customElements.define('copy-link', CopyLink)
  window.customElements.define('dynamic-cta', DynamicCta)
  window.customElements.define('ps-catalognavigation', CatalogNavigation)
  window.customElements.define('ornament-ticket', OrnamentTicket)
  window.customElements.define('personalized-content', PersonalizedContent)
  window.customElements.define('quick-card-expandable', QuickCardExpandable)
  plugins.register(ArtistPage, '.data-read-more')
  plugins.register(PageCTAModule, '.Page-cta')
  plugins.register(AutofillInputs, AutofillInputs.selectors.el)
  plugins.register(CardCarousel, '.data-card-carousel', loadWhenInViewport(200))
  plugins.register(DynamicCarouselGrid, '[data-dynamic-carousel-grid]')
  plugins.register(CardTitleTruncate, '.Card', loadWhenInViewport(200))
  plugins.register(CarouselWithOverlay, '.data-overlay-carousel')
  plugins.register(CartPageLoader, '.CartPage-app')
  plugins.register(CollapsibleModule, '[data-collapsible-module]')
  plugins.register(CollapsibleFilter, '.data-collapsible-filter')
  plugins.register(ConsentManagerTrigger, '[data-consent-manager-trigger]')
  plugins.register(DateRangeInput, DateRangeInput.selectors.el)
  plugins.register(SearchFilterDateRange, SearchFilterDateRange.selectors.el)
  plugins.register(SearchFilterPriceRange, SearchFilterPriceRange.selectors.el)
  plugins.register(DialogOverlay, '[data-dialog-overlay]')
  plugins.register(DialogFormEmail, DialogFormEmail.selector)
  plugins.register(EmailInput, '.EmailInput')
  plugins.register(NumberInput, '.NumberInput')
  plugins.register(Faq, '.FaqListCollapsible, .FaqListWithStars')
  plugins.register(FormLoader, FormLoader.calssSelector)
  plugins.register(GoogleDfp, '.GoogleDfpAd')
  plugins.register(HeaderCarousel, '.data-header-carousel')
  plugins.register(HeroCarouselWide, '.data-hero-carousel-wide')
  plugins.register(HeroLedeCarousel, '.data-hero-carousel')
  plugins.register(CatalogHeroCarousel, '.data-catalog-carousel')
  plugins.register(HeroCornerInfoSlider, '.data-hero-corner-slider')
  plugins.register(HeroSideInfoSlider, '.data-hero-side-slider')
  plugins.register(HeroNavSlider, '.data-hero-nav-slider')
  plugins.register(HeroTruncate, '.data-hero-truncate')
  plugins.register(IframeCarousel, '.data-iframe-carousel')
  plugins.register(HeroWideTruncate, '.data-hero-wide-truncate')
  plugins.register(NavigationBar, '[data-page-nav]')
  plugins.register(NotificationDialog, '.data-notification-dialog')
  plugins.register(OnboardingDialog, '.data-onboarding-dialog')
  plugins.register(OnboardingModule, '.data-onboarding-slides')
  plugins.register(PasswordInput, '.PasswordInput')
  plugins.register(PhoneNumberInput, '.PhoneNumberInput')
  plugins.register(QuickCardDialog, '.data-quickcard-dialog')
  plugins.register(ReadMore, '[data-read-more-collapse]')
  plugins.register(SearchModuleLoader, '.data-search-module')
  plugins.register(AuctionsModuleLoader, '.data-auctions-module')
  plugins.register(LotPageModuleLoader, '.data-lot-page-module')
  plugins.register(SeeAllFilters, '.SearchFilterCheckboxes-seeAll')
  plugins.register(Select, '.Select')
  plugins.register(ShowPasswordInput, '.data-show-password')
  plugins.register(SlideshowCarouselLoader, '.data-slideshow-carousel')
  plugins.register(VerticalNavGalleryLoader, '.data-vertical-nav-gallery')
  plugins.register(Tabs, '.Tabs, .TabsHorizontalNavigation')
  plugins.register(TextArea, '.TextArea')
  plugins.register(TextInput, '.TextInput')
  plugins.register(TranslationModule, '.data-translation-bar')
  plugins.register(TravellingExhibitionShowing, '.TravellingExhibitionShowing')
  plugins.register(UserInfo, UserInfo.selectors.el)
  plugins.register(
    VerifyMuseumPage,
    '.ExhibitionDetailPage-body, .MuseumDetailPage-body'
  )
  plugins.register(VerifyPromo, '.data-type-museum, .data-type-exhibition')
  plugins.register(ZoomableImage, '[data-zoomable-image]')

  // react algolia
  plugins.register(
    ReactAlgoliaGeographicSearchModule,
    '[data-react-algolia-geographic-search-page-module]'
  )
  plugins.register(ReactSearchModule, '[data-react-search-module]')
  plugins.register(
    ReactMapboxMapSingleLocation,
    '[data-react-mapbox-map-single-location]'
  )

  DynamicSalePrice.init()

  // video
  plugins.register(BrightcoveVideoPlayer, '[data-brightcove-player]')

  window.lazySizesConfig = window.lazySizesConfig || {}
  window.lazySizesConfig.expand = 200
  lazySizes.init()

  plugins.register(CardTracking, '.Card')
  plugins.register(CompanionTracking, '.CompanionContentItem')
  plugins.register(PromoTracking, '.PromoImageOnLeft')
  plugins.register(HeroLedeTracking, '.HeroLede')
  plugins.register(HeroLedeWideTracking, '.HeroLedeWide')
  plugins.register(LinkButtonTracking, '.LinkButton')
  plugins.register(
    CecilBeatonTracking,
    '.CecilBeatonPage-buyBtn, .CecilBeatonPage-editorialBtn, .CecilBeatonPage-licenseBtn'
  )
  plugins.register(TileGridTracking, '.TileGrid')
  plugins.register(TabsTracking, '.TabsHorizontalNavigation')
  plugins.register(TravexTracking, '.TravexLocation')
  plugins.register(RichMediaTracking, RichMediaTracking.selectors.el)

  window.addEventListener('load', () => {
    if (typeof window.analytics === 'undefined' && SyndicationUtils.isIframe) {
      LinkTracking()
      SyndicationUtils.pageHeightListener()
    }
  })

  // registers custom elements that depend on their rendered children for information.
  // in the future, we can look on adding this dependency through MutationObserver
  // for new CustomElements.
  const registerCustomElements = () => {
    window.customElements.define(
      RegisterToBidAuctionLink.selector,
      RegisterToBidAuctionLink
    )
    window.customElements.define(
      FollowAuctionActionLink.selector,
      FollowAuctionActionLink
    )
    window.customElements.define(
      ChapterModule.selectors.chapterModule,
      ChapterModule
    )
    window.customElements.define(
      DynamicHeader.selectors.dynamicHeader,
      DynamicHeader
    )
    window.customElements.define('featured-lot-module', FeaturedLotModule)
    window.customElements.define('hero-series', HeroSeries)
    window.customElements.define(
      HotspotModule.selectors.hotspotModule,
      HotspotModule
    )
    window.customElements.define(Parallax.selectors.parallaxElement, Parallax)
    window.customElements.define(PriceChoice.selectors.component, PriceChoice)
    window.customElements.define(PriceDisplay.selectors.component, PriceDisplay)
    window.customElements.define(
      QuantityDisplay.selectors.component,
      QuantityDisplay
    )
    window.customElements.define(SkuSelect.selectors.component, SkuSelect)
    window.customElements.define(
      TimelineModule.selectors.timelineModule,
      TimelineModule
    )
    window.customElements.define('dynamic-title', DynamicTitle)
    window.customElements.define(
      'ps-brightcoveplayer',
      BrightcoveRedactedPlayer
    )
    window.customElements.define('ps-video-playlist', VideoPlaylist)
    window.customElements.define('search-style-toggle', SearchStyleToggle)
    window.customElements.define('tabs-playlist', TabsPlaylist)
    window.customElements.define('fixed-figure', FixedFigureModule)
    window.customElements.define('mosaic-figure', MosaicFigureModule)
    window.customElements.define('mosaic-list-module', MosaicListModule)
    window.customElements.define(
      LotRecommendationsModule.selectors.component,
      LotRecommendationsModule
    )
    window.customElements.define(
      PreferredTierGatedListModule.selectors.component,
      PreferredTierGatedListModule
    )
    window.customElements.define(
      'auctions-list-grid-toggle',
      AuctionsStyleToggle
    )

    // tracking
    window.customElements.define(
      AnswerCardTracking.selectors.component,
      AnswerCardTracking
    )
    window.customElements.define(
      ProductTracking.selectors.component,
      ProductTracking
    )
    window.customElements.define(
      SubscriptionCtaTracking.selectors.component,
      SubscriptionCtaTracking
    )
  }

  const onLoad = () => {
    const { auth0Domain, auth0ClientID, auth0Audience } =
      getPageProperties('data-page-properties', false) || {}

    UserData.configureAuth0(auth0Domain, auth0ClientID, auth0Audience)

    registerCustomElements()

    // Segment page tracking
    PageTracking()
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', onLoad)
  } else {
    onLoad()
  }
}
