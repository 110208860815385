/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  isFollowingAuctions,
  isFollowingAuction,
  followAuction,
  unFollowAuction
} from '../../graphql/CustomerApiUtils'
import { GetUrlParams } from '../../utils/UrlUtils'
import UserData from '../../utils/UserData'

// Local testing
const exampleFetchData = {
  '0000018c-5f23-d10e-a3cf-ff2340fb0000': {
    availableActions: [
      'viewLots',
      'addToCalendar',
      'save',
      'exhibitionHours',
      'bid'
    ],
    saleType: 'live',
    calendarUrl: null,
    title: 'Counter test 2',
    when: '3 February 2024 | 1:30 PM EST',
    saleNumber: 'YM24A',
    url: 'https://staging.sothebys.com/en/buy/auction/2024/counter-test-2',
    onlineStartTS: 1706641200000,
    vikingId: 'eee8b956-0ccc-49d5-8e07-a9849f9a8811',
    liveStartTS: 1706985000000,
    eventStatus: 'PRO',
    locationDetails: {
      country: 'U.S.A.',
      address2: null,
      city: 'New York',
      address1: '1334 York Avenue',
      stateProvince: 'New York',
      timeZone: 'America/New_York'
    },
    endTS: 1706997600000,
    location: 'New York',
    auctionType: {
      name: 'STANDARD_AUCTION',
      ordinal: 0,
      typeCode: 'A',
      eventType: null,
      displayName: 'Main Sales',
      displayType: {
        name: 'AUCTION',
        ordinal: 0,
        displayName: 'Auction'
      },
      eventCategory: {
        name: 'LIVE',
        ordinal: 1,
        displayName: 'Live'
      }
    },
    exhibitionHours: [
      {
        endLot: null,
        startLot: null,
        endDate: 1703624400000,
        locationDetails: {
          country: 'U.S.A.',
          address2: null,
          city: 'New York',
          address1: '1334 York Avenue',
          stateProvince: 'New York',
          timeZone: 'America/New_York'
        },
        location: 'New York',
        startDate: 1703602800000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1703790000000,
        locationDetails: {
          country: 'U.S.A.',
          address2: null,
          city: 'New York',
          address1: '1334 York Avenue',
          stateProvince: 'New York',
          timeZone: 'America/New_York'
        },
        location: 'New York',
        startDate: 1703739600000
      }
    ]
  },
  '0000018c-5f24-d10e-a3cf-ff2442950000': {
    availableActions: ['preview', 'addToCalendar', 'save', 'exhibitionHours'],
    saleType: 'online',
    calendarUrl: null,
    title: 'Counter test 3',
    when: '3–8 February 2024 | 9:00 AM GMT',
    saleNumber: 'YM24B',
    url: 'https://staging.sothebys.com/en/buy/auction/2024/counter-test-3',
    onlineStartTS: 1706995200000,
    vikingId: 'afeac43c-29bc-41ec-a0fb-1d0d687a82ea',
    liveStartTS: null,
    eventStatus: 'SCH',
    locationDetails: {
      country: 'UK',
      address2: null,
      city: 'London',
      address1: '34-35 New Bond Street',
      stateProvince: null,
      timeZone: 'Europe/London'
    },
    endTS: 1707382800000,
    location: 'London',
    auctionType: {
      name: 'ONLINE_AUCTIONS',
      ordinal: 14,
      typeCode: 'O',
      eventType: null,
      displayName: 'Online Auctions',
      displayType: {
        name: 'AUCTION',
        ordinal: 0,
        displayName: 'Auction'
      },
      eventCategory: {
        name: 'ONLINE',
        ordinal: 2,
        displayName: 'Online'
      }
    },
    exhibitionHours: []
  },
  '0000018c-4abb-d3f5-a5ae-7eff6edf0000': {
    availableActions: [
      'preview',
      'viewLots',
      'addToCalendar',
      'save',
      'exhibitionHours'
    ],
    saleType: 'live',
    calendarUrl: null,
    title: 'Counter test',
    when: '3 February 2024 | 1:00 PM EST',
    saleNumber: 'YM23D',
    url: 'https://staging.sothebys.com/en/buy/auction/2023/counter-test',
    onlineStartTS: 1706983200000,
    vikingId: '40b3ea5c-5337-4bd9-b468-76c241338218',
    liveStartTS: 1706983200000,
    eventStatus: 'SCH',
    locationDetails: {
      country: 'U.S.A.',
      address2: null,
      city: 'New York',
      address1: '1334 York Avenue',
      stateProvince: 'New York',
      timeZone: 'America/New_York'
    },
    endTS: 1706983200000,
    location: 'New York',
    auctionType: {
      name: 'STANDARD_AUCTION',
      ordinal: 0,
      typeCode: 'A',
      eventType: null,
      displayName: 'Main Sales',
      displayType: {
        name: 'AUCTION',
        ordinal: 0,
        displayName: 'Auction'
      },
      eventCategory: {
        name: 'LIVE',
        ordinal: 1,
        displayName: 'Live'
      }
    },
    exhibitionHours: [
      {
        endLot: null,
        startLot: null,
        endDate: 1702591200000,
        locationDetails: {
          country: 'U.S.A.',
          address2: null,
          city: 'New York',
          address1: '1334 York Avenue',
          stateProvince: 'New York',
          timeZone: 'America/New_York'
        },
        location: 'New York',
        startDate: 1702479600000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1702591200000,
        locationDetails: {
          country: 'U.S.A.',
          address2: null,
          city: 'New York',
          address1: '1334 York Avenue',
          stateProvince: 'New York',
          timeZone: 'America/New_York'
        },
        location: 'New York',
        startDate: 1702566000000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1702695600000,
        locationDetails: {
          country: 'U.S.A.',
          address2: null,
          city: 'New York',
          address1: '1334 York Avenue',
          stateProvince: 'New York',
          timeZone: 'America/New_York'
        },
        location: 'New York',
        startDate: 1702652400000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1702962000000,
        locationDetails: {
          country: 'China',
          address2: null,
          city: 'Hong Kong',
          address1:
            '5/F One Pacific Place, 88 Queensway<br>金鐘道88號太古廣場一期五樓',
          stateProvince: null,
          timeZone: 'Asia/Hong_Kong'
        },
        location: 'Hong Kong',
        startDate: 1702951200000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1703062800000,
        locationDetails: {
          country: 'China',
          address2: null,
          city: 'Hong Kong',
          address1:
            '5/F One Pacific Place, 88 Queensway<br>金鐘道88號太古廣場一期五樓',
          stateProvince: null,
          timeZone: 'Asia/Hong_Kong'
        },
        location: 'Hong Kong',
        startDate: 1703037600000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1706166000000,
        locationDetails: {
          country: 'China',
          address2: null,
          city: 'Hong Kong',
          address1:
            '5/F One Pacific Place, 88 Queensway<br>金鐘道88號太古廣場一期五樓',
          stateProvince: null,
          timeZone: 'Asia/Hong_Kong'
        },
        location: 'Hong Kong',
        startDate: 1706122800000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1705485600000,
        locationDetails: {
          country: 'United Arab Emirates',
          address2: null,
          city: 'Dubai',
          address1:
            "Sotheby's\r\nUnit 1, Level B2\r\nGate Village Building 3\r\nDIFC, Dubai\r\nPO Box. 507092",
          stateProvince: null,
          timeZone: 'Asia/Dubai'
        },
        location: 'Dubai',
        startDate: 1705438800000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1706616000000,
        locationDetails: {
          country: 'France',
          address2: 'CS 10010, 75384 PARIS CEDEX 08',
          city: 'Paris',
          address1: '76, rue du Faubourg Saint-Honoré',
          stateProvince: null,
          timeZone: 'Europe/Paris'
        },
        location: 'Paris',
        startDate: 1706612400000
      },
      {
        endLot: null,
        startLot: null,
        endDate: 1706014800000,
        locationDetails: {
          country: 'Switzerland',
          address2: null,
          city: 'Geneva',
          address1: '2, Rue François-Diday',
          stateProvince: null,
          timeZone: 'Europe/Zurich'
        },
        location: 'Geneva',
        startDate: 1705964400000
      }
    ]
  }
}

export class DynamicCarouselGrid {
  constructor(el) {
    this.el = el

    this.initData()
    this.initSaveListener()
  }

  getLocale() {
    let locale = GetUrlParams('locale') || document.documentElement.lang || 'en'
    const localeMap = {
      en: 'en-US',
      'zh-hant': 'zh-HK',
      'zh-hans': 'zh-CN',
      fr: 'fr-FR'
    }

    return localeMap[locale] || locale
  }

  getShortTimezone(timeZone) {
    return moment
      ? moment.tz.zone(timeZone).abbr(new Date().getTime())
      : Intl.DateTimeFormat('en-US', {
          timeZone,
          timeZoneName: 'short'
        }).resolvedOptions().timeZone
  }

  formatDayOfWeek(date, timeZone) {
    return Intl.DateTimeFormat(this.getLocale(), { weekday: 'long', timeZone })
      .formatToParts(date)
      .find(part => part.type === 'weekday').value
  }

  formatTime(date, timeZone) {
    return Intl.DateTimeFormat(this.getLocale(), {
      hour: 'numeric',
      minute: 'numeric',
      timeZone
    }).format(date)
  }

  formatMonth(date, timeZone) {
    return Intl.DateTimeFormat(this.getLocale(), { month: 'short', timeZone })
      .formatToParts(date)
      .find(part => part.type === 'month').value
  }

  formatDayNumber(date, timeZone) {
    return Intl.DateTimeFormat(this.getLocale(), { day: 'numeric', timeZone })
      .formatToParts(date)
      .find(part => part.type === 'day').value
  }

  parseAuctionData(data) {
    const exhibitionHours = !data.exhibitionHours
      ? null
      : data.exhibitionHours.reduce((acc, cur) => {
          const location = cur.location

          // Unlikely to default since API already defaults to 'GMT'.
          const timeZone = cur.locationDetails.timeZone || 'GMT'

          // Each location is uniquely identified by the location name, so we can add multiple days to the same location
          !acc[location] &&
            (acc[location] = {
              location,
              address: `${cur.locationDetails.address1}, ${
                cur.locationDetails.city
              }`,
              days: {}
            })

          const startDate = new Date(cur.startDate)
          const endDate = new Date(cur.endDate)
          const startDay = this.formatDayNumber(startDate, timeZone)

          // Each day is uniquely identified by the day number, so we can add multiple times to the same day
          !acc[location].days[startDay] &&
            (acc[location].days[startDay] = {
              dayOfWeek: this.formatDayOfWeek(startDate, timeZone),
              day: startDay,
              month: this.formatMonth(startDate, timeZone),
              times: []
            })

          // Times are formatted as "9:00 AM – 5:30 PM EST"
          const time = `${this.formatTime(
            startDate,
            timeZone
          )} - ${this.formatTime(endDate, timeZone)} ${this.getShortTimezone(
            timeZone,
            timeZone
          )}`

          // Days can have multiple exhibition times
          !acc[location].days[startDay].times.includes(time) &&
            acc[location].days[startDay].times.push(time)

          return acc
        }, {})

    const [date, time] = data.when.split(' | ')

    return {
      title: data.title,
      availableActions: data.availableActions || [],
      url: data.url,
      onlineStart: data.onlineStartTS,
      liveStart: data.liveStartTS,
      end: data.endTS,
      locationDetails: data.locationDetails,
      date,
      time,
      eventStatus: data.eventStatus,
      auctionType: data.auctionType ? data.auctionType.name : null,
      saleNumber: data.saleNumber,
      saleType: data.saleType,
      vikingId: data.vikingId,
      exhibitionHours: !exhibitionHours
        ? []
        : Object.entries(exhibitionHours).map(([, locationData]) => {
            return {
              ...locationData,
              days: Object.values(locationData.days)
            }
          })
    }
  }

  placeholderIsFollowingAuctions(uuids) {
    return uuids.reduce((a, c) => {
      a[c] = c === '0000018c-5f23-d10e-a3cf-ff2340fb0000' ? true : false
      return a
    }, {})
  }

  initSaveListener() {
    const followHandler = e => {
      alert(`following handler for: ${e.detail.uuid}`)
    }
    const unfollowHandler = e => {
      alert(`following handler for: ${e.detail.uuid}`)
    }
    window.addEventListener('auction:card:follow', followHandler)
    window.addEventListener('auction:card:unfollow', unfollowHandler)
  }

  initData() {
    const items = Array.from(this.el.querySelectorAll('auction-card-component'))
    const ids = items
      .filter(itm => itm !== '' && itm)
      .map(item => item.dataset.uuid)
      .join(',')
      .replace(/^,|,$/g, '')
      .replace(/,,+/g, ',')
    const savedAuctionMap = this.placeholderIsFollowingAuctions(
      items.map(item => item.dataset.uuid)
    )

    //Local testing
    // items.forEach(item => {
    //   const id = item.dataset.uuid
    //   //placeholder function for save functionality #02598
    //   item.dataset.userData = JSON.stringify({
    //     isFollowing: savedAuctionMap[id]
    //   })
    //   //placeholder function for save functionality #02598
    //   const data = exampleFetchData[id]

    //   if (data) {
    //     const parsedAuctionData = this.parseAuctionData(data)
    //     item.dataset.auctionData = JSON.stringify(parsedAuctionData)
    //   }
    // })

    window
      .fetch(`/data/api/asset.actions.json?id=${ids}`)
      .then(response => response.json())
      .then(json => {
        items.forEach(item => {
          const id = item.dataset.uuid
          //placeholder function for save functionality #02598
          item.dataset.userData = JSON.stringify({
            isFollowing: savedAuctionMap[id]
          })
          //placeholder function for save functionality #02598
          const data = json[id]

          if (data) {
            const parsedAuctionData = this.parseAuctionData(data)
            item.dataset.auctionData = JSON.stringify(parsedAuctionData)
          }
        })
      })
  }
}
