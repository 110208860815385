/* global HTMLElement */

export class QuantityDisplay extends HTMLElement {
  static selectors = {
    component: 'quantity-display'
  }

  static attributes = {
    quantity: 'quantity',
    quantityText: 'quantity-text',
    static: 'static',
    hightlight: 'data-highlight',
    hidden: 'data-hidden'
  }

  static get observedAttributes () {
    return [QuantityDisplay.attributes.quantity, QuantityDisplay.attributes.quantityText]
  }

  connectedCallback () {
    if (this.quantityText !== null) {
      this.quantityElement.innerText = this.quantityText
    }
    this.setDisplayState()
  }

  attributeChangedCallback () {
    if (!this.isStatic && this.quantityText !== null) {
      this.quantityElement.innerText = this.quantityText
    }
    this.setDisplayState()
  }

  setDisplayState () {
    if (this.quantity === 0) {
      this.setAttribute(QuantityDisplay.attributes.hightlight, '')
      this.removeAttribute(QuantityDisplay.attributes.hidden)
    } else if (this.quantity === 1) {
      this.setAttribute(QuantityDisplay.attributes.hidden, '')
      this.removeAttribute(QuantityDisplay.attributes.hightlight)
    } else {
      this.removeAttribute(QuantityDisplay.attributes.hightlight)
      this.removeAttribute(QuantityDisplay.attributes.hidden)
    }
  }

  get quantity () {
    return parseInt(this.getAttribute(QuantityDisplay.attributes.quantity))
  }

  get quantityText () {
    return this.getAttribute(QuantityDisplay.attributes.quantityText)
  }

  get quantityElement () {
    return this.querySelector('span')
  }

  get isStatic () {
    return !(this.getAttribute(QuantityDisplay.attributes.static) === 'false')
  }
}
