/* global HTMLElement */

/**
 * This class gets extended by all the child players. The child players have their own listeners
 * to the video actions depending on what the video technology is used and call the super functions to actually
 * dispatch these events so things like the video page or analytics can capture them
 */

export class VideoPlayer extends HTMLElement {
  settings = {
    autoplay: false,
    muted: false,
    seekSeconds: 0
  }

  intervals = {
    playingInterval: 5,
    playbackPosition: 0,
    quartilePosition: -1
  }

  playerId = null

  /** returns JSON data for current video  **/
  get video() {
    return {
      topic: this.settings.videoTopic,
      duration: this.settings.duration
    }
  }

  init() {
    this.playerId = this.querySelector('[data-player]')
    this.settings.videoId = this.querySelector('[data-video-id]')
    this.settings.videoTopic =
      document.querySelector('[data-video-topic]') !== null
        ? document.querySelector('[data-video-topic]').textContent.trim()
        : null
    this.settings.duration = this.getAttribute('data-video-duration')
    this.settings.autoplay = this.getAttribute('data-autoplay') || false
    this.settings.muted = this.getAttribute('data-muted')
    this.settings.seekSeconds = this.getAttribute('data-seek-seconds') || 0
    this.settings.dfpUrl = this.getAttribute('data-dfp-url')
  }

  getVideoDuration() {
    // abstract: implement in child class
  }

  /**
   * The follow functionst dispatches events when certain video events happen.
   * The child classes call these super functions
   */

  onVideoReady() {
    let customEvent = new window.CustomEvent('Video:onVideoLoaded', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoStart() {
    this.resetIntervals()

    this.removeAttribute('data-playback-paused')
    this.setAttribute('data-playback-started', true)
    this.setAttribute('data-playback-playing', true)

    let customEvent = new window.CustomEvent('Video:onVideoPlaybackStarted', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoPlay() {
    this.removeAttribute('data-playback-paused')
    this.setAttribute('data-playback-playing', true)

    let customEvent = new window.CustomEvent('Video:onVideoPlaybackPlay', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoTimeUpdate(event) {
    const secondsElapsed = event.secondsElapsed

    let customEvent = new window.CustomEvent(
      'Video:onVideoPlaybackTimeUpdate',
      {
        bubbles: true,
        detail: {
          playerId: this.playerId,
          video: this.video,
          secondsElapsed: secondsElapsed
        }
      }
    )

    this.dispatchEvent(customEvent)

    this.sendVideoPlaybackIntervalEvents(secondsElapsed)
  }

  onVideoMute() {
    this.setAttribute('data-muted', true)

    let customEvent = new window.CustomEvent('Video:onVideoPlaybackMuted', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoUnMute() {
    this.removeAttribute('data-muted')

    let customEvent = new window.CustomEvent('Video:onVideoPlaybackUnMuted', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoPause() {
    this.setAttribute('data-playback-paused', true)
    this.removeAttribute('data-playback-playing')

    let customEvent = new window.CustomEvent('Video:onVideoPlaybackPaused', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  onVideoEnd() {
    let customEvent = new window.CustomEvent('Video:onVideoEnded', {
      bubbles: true,
      detail: {
        playerId: this.playerId,
        video: this.video
      }
    })

    this.dispatchEvent(customEvent)
  }

  /**
   * API to play and pause video, these are going to actually be implemented in the child classes
   */

  play() {
    // abstract: implement in child class
  }

  pause() {
    // abstract: implement in child class
  }

  playPause() {
    if (this.getAttribute('data-playback-paused')) {
      this.play()
    } else {
      this.pause()
    }
  }

  /**
   * Interval functions. This happens on the time update and sends events every
   * "playingInterval" and does all the math for percentile events as well
   */

  resetIntervals() {
    this.intervals = {
      playingInterval: 5,
      playbackPosition: 0,
      quartilePosition: -1
    }
  }

  sendVideoPlaybackIntervalEvents(secondsElapsed) {
    const duration = this.getVideoDuration()

    if (!duration || duration === 0) {
      return
    }

    const percentComplete = (secondsElapsed / duration) * 100

    // don't send extra events if the video didn't naturally pass the interval
    while (
      secondsElapsed >
      this.intervals.playbackPosition + this.intervals.playingInterval * 2
    ) {
      this.intervals.playbackPosition += this.intervals.playingInterval
    }

    if (
      secondsElapsed >=
      this.intervals.playbackPosition + this.intervals.playingInterval
    ) {
      this.intervals.playbackPosition += this.intervals.playingInterval

      let customEvent = new window.CustomEvent(
        'Video:onVideoTimeIntervalUpdate',
        {
          bubbles: true,
          detail: {
            playerId: this.playerId,
            video: this.video,
            secondsElapsed: secondsElapsed
          }
        }
      )

      this.dispatchEvent(customEvent)
    }

    let quartileEvent = null

    if (percentComplete >= this.intervals.quartilePosition) {
      // when resuming, make sure we don't repeat quartile events
      // once percentile updates correctly after seek, figure out next valid event position
      if (this.intervals.quartilePosition === -1) {
        if (percentComplete >= this.intervals.quartilePosition + 25) {
          this.intervals.quartilePosition = 0
          while (percentComplete >= this.intervals.quartilePosition) {
            this.intervals.quartilePosition += 25
          }
        }
      } else {
        quartileEvent = this.intervals.quartilePosition

        this.intervals.quartilePosition += 25
        if (this.intervals.quartilePosition === 100) {
          this.intervals.quartilePosition = 95
        }
      }
    }

    if (quartileEvent !== null) {
      // to ensure 100% is always reached, call at 95% instead
      if (quartileEvent === 95) {
        quartileEvent = 100
      }

      let customEvent = new window.CustomEvent(
        'Video:onVideoQuartileCompleted',
        {
          bubbles: true,
          detail: {
            playerId: this.playerId,
            video: this.video,
            secondsElapsed: secondsElapsed,
            quartile: quartileEvent
          }
        }
      )

      this.dispatchEvent(customEvent)
    }
  }
}
