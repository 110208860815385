import { getUserProperties } from '../../../segment/IdentityTracking'

export class UserInfo {
  static selectors = {
    el: '.UserInfo'
  }

  constructor(el) {
    this.el = el
    const inputs = el.querySelectorAll('input')

    inputs.forEach(input => {
      switch (input.getAttribute('name')) {
        case 'name': {
          input.setAttribute('value', getUserProperties().name)
          break
        }
        case 'email': {
          input.setAttribute('value', getUserProperties().email)
          break
        }
        case 'partyId': {
          input.setAttribute('value', getUserProperties().customer_id)
          break
        }
      }
    })
  }
}
