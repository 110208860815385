/* eslint-disable no-useless-return */
const FOCUS_WITHIN = 'data-focus-within'
const PLACEHOLDER_SHOWN = 'data-placeholder-shown'

export default class Input {
  constructor(element) {
    this.element = element
    this.inputName = this.element.classList[0]
    this.input = this.element.querySelector('.' + this.inputName + '-input')
    this.label = this.element.getElementsByTagName('label')
    this.bindEvents()

    // :focus-within and :placeholder-shown are not supported in IE11 and edge
    // Use data attributes to mimic the functionality instead
    document.addEventListener('click', event => {
      let activeElement = document.activeElement
      const element = this.element
      const input = this.input

      if (activeElement === this.input) {
        element.setAttribute(FOCUS_WITHIN, 'true')
      } else {
        element.removeAttribute(FOCUS_WITHIN)
      }

      if (this.input.value === '') {
        input.setAttribute(PLACEHOLDER_SHOWN, 'false')
      } else {
        input.setAttribute(PLACEHOLDER_SHOWN, 'true')
      }
    })
  }

  bindEvents() {
    // Add validate handlers
    if (this.requiresValidation()) {
      this.input.addEventListener('input', event => {
        setTimeout(() => this.validate(), 10)
      })
    }
  }

  validate() {
    const element = this.element
    const label = this.label
    const fragment = label[0]
    const validity = this.input.validity

    if (validity.valid) {
      element.removeAttribute('data-error')
      element.removeAttribute('data-error-message')
      element.setAttribute('data-valid', 'true')
      return
    }

    // TODO: localize....
    let error = 'error'
    let errorMessage = 'Field contains an invalid value.'
    if (validity.badInput) {
      error = 'badInput'
      console.log('bad input')
    } else if (validity.patternMismatch) {
      error = 'patternMismatch'
      console.log('pattern mismatch')
    } else if (validity.rangeOverflow) {
      error = 'rangeOverflow'
      console.log('range overflow')
    } else if (validity.rangeUnderflow) {
      error = 'rangeUnderflow'
      console.log('range underflow')
    } else if (validity.stepMismatch) {
      error = 'stepMismatch'
      console.log('step mistamtch')
    } else if (validity.tooLong) {
      error = 'tooLong'
      errorMessage = 'Field is too long.'
    } else if (validity.tooShort) {
      error = 'tooShort'
      errorMessage = 'Field is too short.'
    } else if (validity.typeMismatch) {
      error = 'typeMismatch'
      errorMessage = 'Field does not match correct type.'
    } else if (validity.valueMissing) {
      error = 'valueMissing'
      errorMessage = 'Field is required.'
    }

    element.setAttribute('data-error', error)
    element.setAttribute('data-error-message', errorMessage)
    element.setAttribute('data-valid', 'false')

    if (this.element.querySelectorAll(`.${this.inputName}-errors`).length > 0) {
      return
    } else {
      this.showErrorMessage(fragment, errorMessage)
    }
  }

  requiresValidation() {
    const input = this.input
    return input.hasAttribute('required')
  }

  showErrorMessage(fragment, text) {
    const errorMessage = document.createElement('div')
    errorMessage.textContent = text
    errorMessage.className = this.inputName + '-errors'
    fragment.appendChild(errorMessage)
  }
}
