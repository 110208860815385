/* eslint-disable no-unused-vars */

import MediaQueries from './MediaQueries'
import Flickity from 'flickity'
import FixFlickity from '../FlickityFixes'
import { CardCarousel } from '../core/list/CardCarousel'
const mq = new MediaQueries().mq
const MODULE_TITLE = '[data-collapsible-title]'
const IS_COLLAPSED_ATTR = 'data-is-collapsed'

export class CollapsibleModule {
  constructor(el) {
    FixFlickity()

    this.element = el
    const collapsibleTitle = this.element.querySelector(MODULE_TITLE)

    if (!collapsibleTitle) {
      // null check
      return
    }

    collapsibleTitle.setAttribute('role', 'button')

    collapsibleTitle.setAttribute('tabindex', '0')

    collapsibleTitle.setAttribute('aria-expanded', 'true')

    collapsibleTitle.addEventListener('click', e => {
      e.preventDefault()
      // prevent interaction of collapsing if on the md breakpoint or above
      if (!mq['mq-viewport-md'].matches) {
        let carousels = this.element.querySelectorAll('[data-card-carousel]')
        if (this.element.getAttribute(IS_COLLAPSED_ATTR) === 'false') {
          this.element.setAttribute(IS_COLLAPSED_ATTR, 'true')

          collapsibleTitle.setAttribute('aria-expanded', 'false')
        } else {
          this.element.setAttribute(IS_COLLAPSED_ATTR, 'false')

          collapsibleTitle.setAttribute('aria-expanded', 'true')
          this.destroyCarousels(carousels)
          this.initCarousels(carousels)
        }
      }
    })
  }

  destroyCarousels(carousels) {
    carousels.forEach(carouselEl => {
      let carousel = new Flickity(carouselEl)
      carousel.destroy()
    })
  }

  initCarousels(carousels) {
    carousels.forEach(carouselEl => {
      if (carouselEl.offsetParent !== null) {
        let carousel = new CardCarousel(carouselEl)
      }
    })
  }
}
