import UserData from '../utils/UserData'

const PREFERRED_PROMO = 'data-preferred-promo'
const PREFERRED_LEDE = 'data-preferred-lede'

export class VerifyPromo {
  constructor(el) {
    this.el = el
    const promoType = this.el.getAttribute('data-type')

    if (promoType === 'museum' || promoType === 'exhibition') {
      // Redact the necessary preferred and studios content from Museum and Museum Exhibition promos
      UserData.hasUnverifiedAccess(UserData.accesses.PREFERRED)
        .then(hasPreferred => {
          if (!hasPreferred) {
            this.redactPreferredContent()
          }
        })
        .catch(() => this.redactPreferredContent())

      this.el.style.opacity = 1
    }
  }

  redactPreferredContent() {
    // Redact all preferred specific content
    this.el.removeAttribute(PREFERRED_PROMO)
    this.el.removeAttribute(PREFERRED_LEDE)

    let ornament = this.el.querySelector('.Card-ornament')
    if (ornament) {
      ornament.parentNode.removeChild(ornament)
    }
  }
}
