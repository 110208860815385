import Input from './Input.js'

export class EmailInput extends Input {
  validate() {
    super.validate()
    const element = this.element
    const validity = this.input.validity

    if (validity.valid) {
      element.removeAttribute('data-error')
      element.removeAttribute('data-error-message')
      element.setAttribute('data-valid', 'true')
      return
    }

    // TODO: localize....
    let error = 'error'
    let errorMessage = 'Field contains an invalid value.'
    if (validity.badInput) {
      error = 'badInput'
      console.log('bad input')
    } else if (validity.patternMismatch) {
      error = 'patternMismatch'
      console.log('pattern mismatch')
    } else if (validity.rangeOverflow) {
      error = 'rangeOverflow'
      console.log('range overflow')
    } else if (validity.rangeUnderflow) {
      error = 'rangeUnderflow'
      console.log('range underflow')
    } else if (validity.stepMismatch) {
      error = 'stepMismatch'
      console.log('step mistamtch')
    } else if (validity.tooLong) {
      error = 'tooLong'
      errorMessage = 'Field is too long.'
    } else if (validity.tooShort) {
      error = 'tooShort'
      errorMessage = 'Password must be at least 4 characters.'
    } else if (validity.typeMismatch) {
      error = 'typeMismatch'
      errorMessage = 'This Email Address is not Valid'
    } else if (validity.valueMissing) {
      error = 'valueMissing'
      errorMessage = 'Field is required.'
    }

    element.setAttribute('data-error', error)
    element.setAttribute('data-error-message', errorMessage)
    element.setAttribute('data-valid', 'false')

    let errors = element.getElementsByTagName('div')
    let currentErrorMessage = errors[errors.length - 1]
    currentErrorMessage.innerHTML = errorMessage
  }
}
