import { EventTracking } from '../../segment/PageInteractions'
import SyndicationUtils from '../../utils/SyndicationUtils'

export class BrightcoveVideoPlayer {
  constructor(el) {
    this.el = el
    window.BrightcoveVideoPlayerLoaded = false
    this.loadPlayer(this.el)
  }

  loadPlayer(el) {
    let accountId = el.getAttribute('data-account-id')
    let playerId = el.getAttribute('data-player-id')
    let videoId = el.getAttribute('data-video-id')
    let videoPosterUrl = el.getAttribute('poster')
    let playerHTML =
      '<video poster="' +
      videoPosterUrl +
      '" data-video-id="' +
      videoId +
      '"  data-account="' +
      accountId +
      '" data-player="' +
      playerId +
      '" data-embed="default" class="video-js" controls></video>'
    let videoDiv = el.querySelector('.BrightcoveVideoPlayer-video')

    videoDiv.innerHTML = playerHTML

    let s = document.createElement('script')
    s.id = 'brightcoveScriptId'
    // http://players.brightcove.net/104524641001/default_default/index.min.js
    s.src =
      '//players.brightcove.net/' +
      accountId +
      '/' +
      playerId +
      '_default/index.min.js'
    // Add the script tag to the document
    document.body.appendChild(s)

    window.addEventListener('load', () => {
      if (
        typeof window.analytics !== 'undefined' ||
        SyndicationUtils.isIframe
      ) {
        this.trackVideo(videoDiv, videoDiv.querySelector('video'))
      }
    })
  }

  hmsToSecondsOnly(str) {
    const p = str.split(':')
    let s = 0
    let m = 1

    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10)
      m *= 60
    }

    return s
  }

  trackVideo(videoDiv, videoObj) {
    const trackName = 'Video Started'
    const trackStatus = 'Video Watched'
    let videoProps = {
      video_player: 'Brightcove'
    }
    let flag1 = false
    let flag2 = false
    let flag3 = false
    let flag4 = false

    if (videoObj.closest('.VideoEnhancement') !== null) {
      const videoEnhancement = videoObj.closest('.VideoEnhancement')
      if (videoEnhancement.querySelector('.VideoEnhancement-title')) {
        videoProps.video_title = videoEnhancement.querySelector(
          '.VideoEnhancement-title'
        ).innerText
      }
    } else if (videoObj.closest('.VideoPage-player') !== null) {
      videoProps.video_title = document.querySelector(
        '.VideoPage-headline'
      ).innerText
      if (document.querySelector('.VideoPage-topicName') !== null) {
        videoProps.topic = document.querySelector(
          '.VideoPage-topicName'
        ).innerText
      }
    }

    videoObj.addEventListener('timeupdate', e => {
      const currentTime = videoObj.currentTime
      const duration = videoObj.duration
      const percentage = window.Math.round((currentTime / duration) * 100)

      if (percentage === 0 && !flag1) {
        flag1 = true
        EventTracking(trackName, videoProps)
      }

      if (percentage > 0 && percentage >= 25 && percentage < 50 && !flag2) {
        flag2 = true
        videoProps.video_progress = '25%'
        EventTracking(`${trackStatus} 25`, videoProps)
      }

      if (percentage >= 50 && percentage < 75 && !flag3) {
        flag3 = true
        videoProps.video_progress = '50%'
        EventTracking(`${trackStatus} 50`, videoProps)
      }

      if (percentage >= 75 && !flag4) {
        flag4 = true
        videoProps.video_progress = '75%'
        EventTracking(`${trackStatus} 75`, videoProps)
      }
    })

    videoObj.addEventListener('ended', () => {
      videoProps.video_progress = '100%'
      EventTracking(`${trackStatus} 100`, videoProps)
    })
  }
}
