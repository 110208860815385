/* global HTMLElement */

export class MosaicListModule extends HTMLElement {
  connectedCallback() {
    this.promo = this.querySelectorAll('.MosaicDoubleImageLong').length
    if (this.promo >= 1) {
      this.addIdentifier()
    }
  }

  addIdentifier() {
    let els = this.querySelectorAll('.MosaicDoubleImageLong')
    for (var i = 0; i < els.length; i++) {
      els[i].parentElement.parentElement.setAttribute('data-dbl-image-long', '')
    }
  }
}
