/* global HTMLElement */
import React from 'react'
import { dispatch } from '../cart/CartStore'
import { addItem } from '../cart/actions/item'

/**
 * Add To Bag button
 *
 * @example
  <add-to-bag
    text="Add To Bag"
    error-text="Failed to add to bag. Please try again."
    success-text="Added to Bag!"
    sku-code="2-year-studios-access-with-free-trial-1"
    slatwall-host="localhost:9000"
    stock-count="4">
  </add-to-bag>
 */
export class AddToBag extends HTMLElement {

  static selectors = {
    component: 'add-to-bag'
  }

  static attributes = {
    skuCode: 'sku-code',
    slatwallHost: 'slatwall-host',
    stockCount: 'stock-count',
    errorText: 'error-text',
    successText: 'success-text',
    text: 'text'
  }

  connectedCallback () {
    this.loading = false
    this.render(false)
  }

  render = (loading, success, error = '') => {
    Promise.all([
      import(/* webpackChunkName: 'ReactDOM' */ 'react-dom'),
      import(/* webpackChunkName: 'ReactButton' */ '../cart/components/button/Button')
    ]).then(([ReactDOM, ButtonModule]) => {
      const Button = ButtonModule.default
      ReactDOM.render(
        <Button
          action={this.addToBag}
          loading={loading}
          fullWidth
          disabled={!(this.stockCount > 0)}
          error={error}>
          {this.text}
          <span class='AddToBag-feedback' data-hidden={success ? 'false' : 'true'}>{success}</span>
        </Button>, this)
    }).catch(e => console.warn('Failed to fetch resources for Add To Bag', e))
  }

  addToBag = async () => {
    this.render(true)
    const res = await addItem(this.skuCode, this.slatwallHost)(dispatch)

    if (res && (!res.errors || Object.keys(res.errors).length === 0)) {
      this.render(false, this.successText)
      window.setTimeout(() => this.render(false), 3000)
    } else {
      this.render(false, null, this.errorText)
    }
  }

  get skuCode () {
    return this.getAttribute(AddToBag.attributes.skuCode)
  }

  get slatwallHost () {
    return this.getAttribute(AddToBag.attributes.slatwallHost)
  }

  get stockCount () {
    return this.getAttribute(AddToBag.attributes.stockCount)
  }

  get text () {
    return this.getAttribute(AddToBag.attributes.text)
  }

  get errorText () {
    return this.getAttribute(AddToBag.attributes.errorText)
  }

  get successText () {
    return this.getAttribute(AddToBag.attributes.successText)
  }
}
