import Flickity from 'flickity'
import FixFlickity from '../FlickityFixes'

export class ZoomableImage {
  constructor(el) {
    FixFlickity()

    this.image = el
    this.largestImageSrc = this.getLargestImage()
    if (this.largestImageSrc) {
      this.init()
    }
  }

  init() {
    this.clickEvent = evt => {
      if (evt.target === this.image) {
        this.open()
      }
    }
    this.clickEvent = this.clickEvent.bind(this)
    this.applyClickToImage()
    this.image.addEventListener('mouseenter', evt => this.applyClickToImage())

    // CSS may begin applying styles now
    this.image.setAttribute('data-zoomable-image', 'true')
  }

  applyClickToImage() {
    // With all of the lazy-loading of libraries, it's hard to know when this
    // image is inside a carousel. In order for clicks and carousel swipes to
    // play nice together we ideally want to use Flickity's staticClick event.
    // We also don't know when a carousel has been destroyed and we should go
    // back to using the click event. For safety we'll check every time the
    // mouse enters the image.
    // See: https://flickity.metafizzy.co/events.html#staticclick
    const carousel = this.image.closest('.flickity-enabled.is-draggable')

    if (carousel) {
      const f = Flickity.data(carousel)
      this.image.removeEventListener('click', this.clickEvent)
      f.on('staticClick', this.clickEvent)
    } else {
      this.image.addEventListener('click', this.clickEvent)
    }
  }

  getLargestImage() {
    // Zoomable images will need to have a 2880w URL added to their srcset,
    // otherwise we won't apply the zooming behavior. Client training may also
    // be necessary to keep them uploading large images for these modules.
    try {
      const imageSrcSet =
        this.image.srcset || this.image.getAttribute('data-srcset')
      const parsed = srcsetParse(imageSrcSet)
      for (let i = 0; i < parsed.length; i++) {
        if (parsed[i].width === 2880) {
          return parsed[i].url
        }
      }
    } catch (ex) {
      // No need to do anything
    }
  }

  generateZoomedImage() {
    if (!this.takeover) {
      this.takeover = document.createElement('div')
      this.takeover.setAttribute('data-zoomable-image-zoomed', '')
      this.takeover.innerHTML = `
        <div data-zoomable-image-fixed>
          <img src="${this.largestImageSrc}">
        </div>
        <div data-zoomable-image-pan>
          <img src="${this.largestImageSrc}">
        </div>
      `
      this.panningImage = this.takeover.querySelector(
        '[data-zoomable-image-pan] img'
      )
      this.takeover.addEventListener('click', evt => this.close(evt))
      this.takeover.addEventListener('mousemove', evt =>
        this.panZoomedImage(evt)
      )
      this.takeover.addEventListener('mouseout', evt =>
        this.resetZoomedImage(evt)
      )
    }
  }

  panZoomedImage(evt) {
    const imageX = this.panningImage.naturalWidth / 2
    const imageY = this.panningImage.naturalHeight / 2
    const wrapperX = window.innerWidth / 2
    const wrapperY = window.innerHeight / 2
    const mouseXFromCenter = evt.pageX - wrapperX
    const mouseYFromCenter = evt.pageY - wrapperY
    const xToFill = imageX - wrapperX
    const yToFill = imageY - wrapperY

    // If image isn't larger than wrapper, do nothing
    if (xToFill > 0 || yToFill > 0) {
      const translateX = -Math.floor((mouseXFromCenter / wrapperX) * xToFill)
      const translateY = -Math.floor((mouseYFromCenter / wrapperY) * yToFill)
      this.panningImage.style.transform =
        'translate(' + translateX + 'px,' + translateY + 'px)'
      this.panningImage.style.opacity = 1
    }
  }

  resetZoomedImage() {
    this.panningImage.style.removeProperty('opacity')
  }

  open() {
    this.generateZoomedImage()
    document.body.append(this.takeover)
  }

  close() {
    this.takeover.remove()
    this.resetZoomedImage()
  }
}

// Pulled the parser from https://github.com/sindresorhus/srcset
// TODO: bring in the library as a dependency if we ever need all of it
const srcsetParse = string => {
  const integerRegex = /^\d+$/

  function deepUnique(array) {
    return array.sort().filter((element, index) => {
      return JSON.stringify(element) !== JSON.stringify(array[index - 1])
    })
  }

  return deepUnique(
    string.split(',').map(part => {
      const result = {}

      part
        .trim()
        .split(/\s+/)
        .forEach((element, index) => {
          if (index === 0) {
            result.url = element
            return
          }

          const value = element.slice(0, element.length - 1)
          const postfix = element[element.length - 1]
          const integerValue = parseInt(value, 10)
          const floatValue = parseFloat(value)

          if (postfix === 'w' && integerRegex.test(value)) {
            result.width = integerValue
          } else if (postfix === 'h' && integerRegex.test(value)) {
            result.height = integerValue
          } else if (postfix === 'x' && !Number.isNaN(floatValue)) {
            result.density = floatValue
          } else {
            throw new Error(`Invalid srcset descriptor: ${element}`)
          }
        })

      return result
    })
  )
}
