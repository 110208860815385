export class SearchFilterPriceRange {
  static selectors = {
    el: '.data-price-range',
    submitButton: '[data-price-range-submit]'
  }

  static attributes = {
    checked: 'checked',
    disabled: 'disabled'
  }

  static events = {
    submit: 'searchFilterPriceRange.submit'
  }

  constructor(el) {
    this.init = this.init.bind(this)
    this.setListeners = this.setListeners.bind(this)
    this.setValue = this.setValue.bind(this)

    this.init(el)
  }

  init(el) {
    this.el = el
    this.search = this.el.querySelector(
      SearchFilterPriceRange.selectors.submitButton
    )
    this.from = this.el.querySelector('[name="from"]')
    this.to = this.el.querySelector('[name="to"]')
    this.submitInput = this.el.querySelector('[type="checkbox"]')
    this.search.setAttribute(SearchFilterPriceRange.attributes.disabled, true)
    this.setListeners()
  }

  setListeners() {
    this.from.addEventListener('input', () => this.setValue())
    this.to.addEventListener('input', () => this.setValue())
    this.search.addEventListener('click', () => {
      if (
        !this.search.getAttribute(SearchFilterPriceRange.attributes.disabled)
      ) {
        this.submitInput.setAttribute(
          SearchFilterPriceRange.attributes.checked,
          ''
        )
        this.el.dispatchEvent(
          new window.Event(SearchFilterPriceRange.events.submit)
        )
      }
    })
  }

  setValue() {
    this.submitInput.value = this.from.value + '-' + this.to.value
    if (
      this.from.value &&
      this.from.checkValidity() &&
      this.to.value &&
      this.to.checkValidity()
    ) {
      this.search.removeAttribute(SearchFilterPriceRange.attributes.disabled)
    } else {
      this.search.setAttribute(SearchFilterPriceRange.attributes.disabled, true)
    }
  }
}
