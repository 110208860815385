/* global HTMLElement */

export class PriceDisplay extends HTMLElement {
  static selectors = {
    component: 'price-display',
    price: '.PriceDisplay-price',
    renewalPrice: '.PriceDisplay-renewalPrice'
  }

  static attributes = {
    price: 'price',
    renewalPrice: 'renewal-price',
    static: 'static',
    hideRenewalPrice: 'hide-renewal-price',
    hidePrice: 'hide-price'
  }

  static get observedAttributes() {
    return Object.values(PriceDisplay.attributes)
  }

  connectedCallback() {
    this.setFormattedPriceFromAttributes()
  }

  attributeChangedCallback() {
    if (!this.isStatic) {
      this.setFormattedPriceFromAttributes()
    }
  }

  setFormattedPriceFromAttributes() {
    if (!this.hidePrice) {
      this.priceElement.innerText = this.price
    }

    try {
      if (parseInt(this.renewalPrice) > 0 && !this.hideRenewalPrice) {
        this.renewalPriceElement.innerText = this.renewalPrice
      }
    } catch (e) {
      console.warn(`Failed to parse currency ${this.renewalPrice}`)
    }
  }

  get price() {
    return this.getAttribute(PriceDisplay.attributes.price)
  }

  get renewalPrice() {
    return this.getAttribute(PriceDisplay.attributes.renewalPrice)
  }

  get priceElement() {
    return this.querySelector(PriceDisplay.selectors.price)
  }

  get renewalPriceElement() {
    return this.querySelector(PriceDisplay.selectors.renewalPrice)
  }

  get isStatic() {
    return this.getAttribute(PriceDisplay.attributes.static) === 'true'
  }

  get hideRenewalPrice() {
    return (
      this.getAttribute(PriceDisplay.attributes.hideRenewalPrice) === 'true'
    )
  }

  get hidePrice() {
    return this.getAttribute(PriceDisplay.attributes.hidePrice) === 'true'
  }
}
