/* eslint-disable no-useless-return */
import { throttle } from '../../utils/Throttle'
import MediaQueries from '../../utils/MediaQueries'
import { SearchStyleToggle } from '../search/SearchStyleToggle'
import shave from 'shave'
const mq = new MediaQueries().mq
const HEIGHT_SMALL = 56
const HEIGHT_LARGE = 68
const TITLE_WORK_HEIGHT_LARGE = 56
const TITLE_WORK_HEIGHT_SMALL = 36
const DETAILS_HEIGHT_2_SMALL = 32
const DETAILS_HEIGHT_2_LARGE = 48

export class CardTitleTruncate {
  constructor(el) {
    this.el = el
    this.cardType = this.el.getAttribute('data-type')
    this.searchCard = this.el.hasAttribute('data-hit')
    let title = this.el.querySelector('[class$="-title"]')
    let titleWorkTxt = this.el.querySelector('[class$="-titleWorkTxt"]')
    let details = this.el.querySelector('[class$="-details"]')

    // Title truncation
    if (
      (this.cardType === 'auction' && !this.searchCard) ||
      (this.cardType === 'museum' && !this.searchCard) ||
      (this.cardType === 'slideshow' && !this.searchCard) ||
      (this.cardType === 'video' && !this.searchCard) ||
      (this.cardType === 'story' && !this.searchCard) ||
      (this.cardType === 'invitation' && !this.searchCard) ||
      (this.cardType === 'artfair' && !this.searchCard)
    ) {
      this.truncateTitle(title)

      window.addEventListener(
        'resize',
        throttle(1000, () => {
          this.truncateTitle(title)
        })
      )

      window.addEventListener(SearchStyleToggle.events.styleUpdated, () => {
        this.truncateTitle(title)
      })
    }

    // Title work text truncation
    if (this.cardType === 'lot' && !this.searchCard) {
      this.truncateTitleWorkTxt(titleWorkTxt)

      window.addEventListener(
        'resize',
        throttle(1000, () => {
          this.truncateTitleWorkTxt(titleWorkTxt)
        })
      )

      window.addEventListener(SearchStyleToggle.events.styleUpdated, () => {
        this.truncateTitleWorkTxt(titleWorkTxt)
      })
    }

    // Details truncation
    if (this.cardType === 'auction' && !this.searchCard) {
      this.truncateDetails(details)

      window.addEventListener(
        'resize',
        throttle(1000, () => {
          this.truncateDetails(details)
        })
      )

      window.addEventListener(SearchStyleToggle.events.styleUpdated, () => {
        this.truncateDetails(details)
      })
    }
  }

  truncateTitle(title) {
    if (!title) {
      return
    } else if (mq['mq-viewport-lg'].matches) {
      if (
        this.cardType === 'auction' ||
        this.cardType === 'museum' ||
        (this.cardType === 'invitation' && !this.searchCard) ||
        (this.cardType === 'artfair' && !this.searchCard)
      ) {
        shave(title, HEIGHT_LARGE)
      } else {
        shave(title, HEIGHT_SMALL)
      }
    } else {
      shave(title, HEIGHT_SMALL)
    }
  }

  truncateTitleWorkTxt(titleWorkTxt) {
    if (!titleWorkTxt) {
      return
    }
    if (mq['mq-viewport-lg'].matches) {
      shave(titleWorkTxt, TITLE_WORK_HEIGHT_LARGE)
    } else {
      shave(titleWorkTxt, TITLE_WORK_HEIGHT_SMALL)
    }
  }

  truncateDetails(details) {
    if (!details) {
      return
    } else if (mq['mq-viewport-lg'].matches) {
      shave(details, DETAILS_HEIGHT_2_LARGE)
    } else {
      shave(details, DETAILS_HEIGHT_2_SMALL)
    }
  }
}
