/* global HTMLElement */
import { Tracker } from '../../utils/Events'

export class LocationFilter extends HTMLElement {
  static selectors = {
    component: 'location-filter',
    checkbox: '[data-locations-checkbox]'
  }

  static events = {
    locationsSubmit: 'LocationFilter.myPreferencesSelected'
  }

  connectedCallback() {
    if (!this.connectedCallbackExecuted) {
      this.listenerTracker = new Tracker()
      this.preferencesCheckbox = this.querySelector(
        LocationFilter.selectors.checkbox
      )
      if (typeof window.preferencesCheckboxChecked !== 'undefined') {
        this.preferencesCheckbox.checked = window.preferencesCheckboxChecked
      }

      this.getLocations()
    }
  }

  getLocations() {
    if (typeof window.premiumLocations === 'undefined') {
      const premiumLocationsUrl = this.getAttribute(
        'data-premium-locations-url'
      )
      window
        .fetch(premiumLocationsUrl, { credentials: 'include', mode: 'cors' })
        .then(response => {
          return response.json()
        })
        .then(json => {
          if (json.premiumLocations.length > 0) {
            // No need to make another network call to get locations when we can set it to the window
            // since filters get overwritten after every search interaction.
            window.premiumLocations = json.premiumLocations
          }
        })
    }

    this.parentNode.removeAttribute('data-hide')
    this.setListeners()
  }

  setListeners() {
    this.listenerTracker.addListener(
      this.querySelector(LocationFilter.selectors.checkbox),
      'click',
      this.processJson.bind(this)
    )
  }

  processJson() {
    if (typeof window.premiumLocations !== 'undefined') {
      window.preferencesCheckboxChecked = this.preferencesCheckbox.checked
      window.premiumLocations.forEach(locationUuid => {
        this.selectCorrespondingCheckbox(locationUuid)
      })

      this.dispatchEvent(
        new window.Event(LocationFilter.events.locationsSubmit)
      )
    }
  }

  selectCorrespondingCheckbox(locationUuid) {
    const input = document.getElementById(locationUuid)
    if (input !== null) {
      input.checked = window.preferencesCheckboxChecked
    }
  }

  disconnectedCallback() {
    this.listenerTracker.removeListeners()
  }
}
