import { PriceDisplay } from '../price/PriceDisplay'
import { CheckoutButton } from '../product/CheckoutButton'
import { QuantityDisplay } from './QuantityDisplay'

/* global HTMLElement */

/**
 * Allows dynamic selection of SKUS.
 *
 * Handles changing the following:
 * - sku code for checkout-button element
 * - price for price-display element
 * - quantity for quantity-display element
 */
export class SkuSelect extends HTMLElement {
  static selectors = {
    component: 'sku-select',
    select: 'select'
  }

  static attributes = {
    price: 'data-price',
    skuCode: 'data-sku-code',
    quantity: 'data-quantity',
    quantityText: 'data-quantity-text',
    quantityClass: 'data-quantity-class'
  }

  static events = {
    change: 'change'
  }

  connectedCallback () {
    this.selectElement.addEventListener(SkuSelect.events.change, this.select.bind(this))
    this.select()
  }

  disconnectedCallback () {
    this.selectElement.removeEventListener(SkuSelect.events.change, this.select)
  }

  select () {
    this.quantityDispalys.map(display => {
      display.setAttribute(QuantityDisplay.attributes.quantity, this.quantity)
      display.setAttribute(QuantityDisplay.attributes.quantityText, this.quantityText)
      display.setAttribute('class', this.quantityClass)
    })
    this.priceDisplays.map(display => display.setAttribute(PriceDisplay.attributes.price, this.price))
    this.checkoutButtons.map(button => {
      button.setAttribute(CheckoutButton.attributes.skuCode, this.skuCode)
      button.setAttribute(CheckoutButton.attributes.stockCount, this.quantity)
    })
  }

  get quantityDispalys () {
    return [...document.querySelectorAll(QuantityDisplay.selectors.component)]
  }

  get priceDisplays () {
    return [...document.querySelectorAll(PriceDisplay.selectors.component)]
  }

  get checkoutButtons () {
    return [...document.querySelectorAll(CheckoutButton.selectors.component)]
  }

  get selectElement () {
    return this.querySelector(SkuSelect.selectors.select)
  }

  get selectedOption () {
    const selected = [...this.selectElement.selectedOptions]

    return selected.length > 0 ? selected[0] : null
  }

  get price () {
    return this.selectedOption ? this.selectedOption.getAttribute(SkuSelect.attributes.price) : null
  }

  get skuCode () {
    return this.selectedOption ? this.selectedOption.getAttribute(SkuSelect.attributes.skuCode) : null
  }

  get quantity () {
    return this.selectedOption ? this.selectedOption.getAttribute(SkuSelect.attributes.quantity) : null
  }

  get quantityText () {
    return this.selectedOption ? this.selectedOption.getAttribute(SkuSelect.attributes.quantityText) : null
  }

  get quantityClass () {
    return this.selectedOption ? this.selectedOption.getAttribute(SkuSelect.attributes.quantityClass) : null
  }
}
