import { OPEN_BANNER, CLOSE_BANNER } from '../constants/banner'

/**
 *
 * @param {string} style of banner. Can be 'success' or 'error'
 * @param {string} message to output in the banner
 * @param {boolean} permanent if the banner should not display the close button
 */
export const openBanner = (style, message, permanent = false) => ({
  type: OPEN_BANNER,
  payload: {
    style,
    message,
    permanent
  }
})

export const closeBanner = () => ({
  type: CLOSE_BANNER,
  payload: {
    style: undefined,
    message: undefined,
    permanent: undefined
  }
})

export const errorBanner = (message, permanent = false) => openBanner('error', message, permanent)

export const successBanner = (message, permanent = false) => openBanner('success', message, permanent)
