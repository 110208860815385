import UserData from '../utils/UserData'

const PREFERRED_VERSION = 'data-preferred-version'
const PREFERRED_ASIDE = 'data-preferred-aside'
const PREFERRED_TITLE = 'data-preferred-title'
const STUDIOS_PAGE = 'data-studios-page'
const STUDIOS_ASIDE = 'data-studios-aside'

export class VerifyMuseumPage {
  constructor(el) {
    this.el = el
    this.className = document.querySelector('html').classList[0]
    this.parent = document.querySelector('main')
    this.main = this.el.querySelector('.' + this.className + '-main')
    this.content = this.el.querySelector('.' + this.className + '-content')
    this.aside = this.el.querySelector('.' + this.className + '-aside')
    this.pageHeading = this.el.querySelector(
      '.' + this.className + '-pageHeading'
    )

    // Determines the page's access level
    this.prefVersion = this.el.getAttribute(PREFERRED_VERSION)

    // Determine the User's access level and redact the page accordingly
    UserData.hasAccess(UserData.accesses.PREFERRED)
      .then(hasPreferred => {
        if (this.prefVersion && hasPreferred) {
          this.showPreferredContent()
        } else {
          this.showPublicContent()
        }
      })
      .catch(() => this.showPublicContent())

    let info = this.el.querySelector('.' + this.className + '-info')
    if (info !== null) {
      info.style.opacity = 1
    }

    let below = this.el.querySelector('.' + this.className + '-below')
    if (below !== null) {
      below.style.opacity = 1
    }

    // Set the data attribute that will trigger everything on the page to display
    this.parent.setAttribute('data-fully-loaded', '')
  }

  showPublicContent() {
    this.redactPreferredContent()

    // Mapbox should not be shown on the public Museum page
    let map = document.querySelector('.MapboxMapSingleLocation')
    if (map !== null) {
      map.parentNode.removeChild(map)
    }

    // Any user that is authenticated should never see the preferred tag on the public version of the page.
    UserData.hasAccess(UserData.accesses.AUTHENTICATED).then(
      isAuthenticated => {
        if (isAuthenticated) {
          let preferredTag = this.el.querySelector(
            '.' + this.className + '-preferredTag'
          )
          if (preferredTag) {
            preferredTag.parentNode.removeChild(preferredTag)
          }
        }
      }
    )

    if (this.premVersion) {
      // Load the subscription module (if present)
      this.content.setAttribute('data-load-subscription', '')
    }
  }

  showPreferredContent() {
    this.redactPublicContent()

    this.el.removeAttribute(STUDIOS_PAGE)
    if (this.aside) {
      this.aside.removeAttribute(STUDIOS_ASIDE)
    }
  }

  redactPublicContent() {
    // Redact all public specific content
    let admission = this.el.querySelector('.' + this.className + '-admission')
    if (admission) {
      admission.parentNode.removeChild(admission)
    }

    let cta = this.el.querySelector('.' + this.className + '-cta')
    if (cta) {
      cta.parentNode.removeChild(cta)
    }

    let preferredTag = this.el.querySelector(
      '.' + this.className + '-preferredTag'
    )
    if (preferredTag) {
      preferredTag.parentNode.removeChild(preferredTag)
    }
  }

  redactPreferredContent() {
    // Redact all preferred specific content
    let breadcrumbs = this.el.querySelector(
      '.' + this.className + '-breadcrumbs'
    )
    if (breadcrumbs) {
      breadcrumbs.parentNode.removeChild(breadcrumbs)
    }

    let notes = this.el.querySelector('.' + this.className + '-preferredNotes')
    if (notes) {
      notes.parentNode.removeChild(notes)
    }

    let specialNotes = this.el.querySelector(
      '.' + this.className + '-specialNotes'
    )
    if (specialNotes) {
      specialNotes.parentNode.removeChild(specialNotes)
    }

    let ornament = this.el.querySelector('.' + this.className + '-ornament')
    if (ornament) {
      ornament.parentNode.removeChild(ornament)
    }

    let ctaDialog = this.el.querySelector('.' + this.className + '-ctaDialog')
    if (ctaDialog) {
      ctaDialog.parentNode.removeChild(ctaDialog)
    }

    if (this.aside) {
      this.aside.removeAttribute(PREFERRED_ASIDE)
    }

    if (this.pageHeading) {
      this.pageHeading.removeAttribute(PREFERRED_TITLE)
    }

    // Remove the preferred ornament config from the 'data-map-promo-info' attribute and set another attribute so that
    // the golden slash and dates can be hidden through styling
    let map = document.querySelector('.MapboxMapSingleLocation')
    if (map) {
      let mapConfig = map.querySelector('[data-map-promo-info]')
      if (mapConfig) {
        let configData = JSON.parse(
          mapConfig.getAttribute('data-map-promo-info')
        )
        if (configData) {
          delete configData['ornamentData']
          mapConfig.setAttribute(
            'data-map-promo-info',
            JSON.stringify(configData)
          )
        }
        mapConfig.removeAttribute('data-map-promo-info-ornaments')
      }

      // Mapbox should only show up on Preferred and Studios content so if we are redacting Preferred information
      // we can assume that the promo should be set to the studios version.
      map.setAttribute('data-studios-promo', '')
    }
  }
}
