const TOGGLE = '[data-toggle]'
const IS_COLLAPSED_ATTR = 'data-is-collapsed'
const LINK = '.TranslationLink'

export class TranslationModule {
  constructor(el) {
    this.element = el
    const collapsibleTitle = this.element.querySelector(TOGGLE)
    this.Links = document.querySelectorAll(LINK)

    collapsibleTitle.addEventListener('click', e => {
      e.preventDefault()
      if (this.element.getAttribute(IS_COLLAPSED_ATTR) === 'false') {
        this.element.setAttribute(IS_COLLAPSED_ATTR, 'true')
      } else {
        this.element.setAttribute(IS_COLLAPSED_ATTR, 'false')
      }
    })
  }
}
