/**
 * Loads the Preferred Tier Gated List Module data dynamically
 *
 * TODO: There used to be some event tracking code in here when we copied it from the Lot Recommendations Module
 *       Do we need that back?
 */

export class PreferredTierGatedListModule extends HTMLElement {
  static selectors = {
    component: 'preferred-tier-gated-list'
  }

  connectedCallback() {
    if (this.fragmentUrl) {
      this.loadPreferredTierGatedListContent(this.fragmentUrl)
    }
  }

  loadPreferredTierGatedListContent = fragmentUrl => {
    this.getContent(fragmentUrl)
      .then(content => {
        this.innerHTML = content
      })
      .catch(data => {
        console.warn(data)
      })
  }

  // Simple ajax
  getContent(fragmentUrl) {
    return new Promise((resolve, reject) => {
      window
        .fetch(fragmentUrl, {
          credentials: 'include'
        })
        .then(response => {
          resolve(response.text())
        })
        .catch(() => {
          reject()
        })
    })
  }

  get fragmentUrl() {
    return this.getAttribute('data-fragment-url')
  }
}
