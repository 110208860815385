import { EventTracking, getPageProperties } from './PageInteractions'
import { LinkButtonTracking } from './LinkButtonTracking'
import { indentifyUser } from './IdentityTracking'

const LINK_BUTTON_ATTR = 'data-link-button-id'

export class CecilBeatonTracking extends LinkButtonTracking {
  handleClick(e) {
    const currentBtn = e.currentTarget
    const currentBtnText = currentBtn.innerHTML
    const currentBtnAttr = currentBtn.getAttribute(LINK_BUTTON_ATTR)
    this.checkLinkButtons(currentBtnText, currentBtnAttr)
    indentifyUser()

    EventTracking('Click Action', {
      link_button: `${getPageProperties().name}: ${currentBtnText}${
        this.sameBtnCountText
      }`,
      method: 'request',
      request_type: currentBtnText
    })
  }
}
