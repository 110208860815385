export function progressBarStart(hero, active) {
  let progressBar = active.querySelector(`.${hero.cssSlideName}-progress > div`)
  let progress = 0
  progressBar.style.width = progress

  hero.progressTimer = setInterval(() => {
    if (!hero.isPaused) {
      if (progress >= hero.progressInterval) {
        clearInterval(hero.progressTimer)
        hero.carousel.next()
      } else {
        progress += 10
        let width = (progress / hero.progressInterval) * 100
        if (width <= 100) {
          progressBar.style.width = width + '%'
        }
      }
    }
  }, 10)
}
