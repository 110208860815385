import {
  ORDER_PAYMENT_START,
  ORDER_PAYMENT_SUCCESS,
  ORDER_PAYMENT_FAIL
} from '../constants/checkout'

const initialState = {
  errors: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case ORDER_PAYMENT_START:
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName
      }

    case ORDER_PAYMENT_FAIL:
      return {
        ...state,
        errors: payload || {}
      }

    case ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        streetAddress: payload.billingAddress.streetAddress,
        street2Address: payload.billingAddress.street2Address,
        city: payload.billingAddress.city,
        countryCode: payload.billingAddress.countryCode,
        stateCode: payload.billingAddress.stateCode,
        postalCode: payload.billingAddress.postalCode,
        errors: {}
      }

    default:
      return state
  }
}
