import { throttle } from './Throttle'
import { IsInViewportOverflow } from './IsInViewport'

const IS_ANCHOR = '[data-anchorable]'
const OFFSET = 40
const IS_STICKY = 'data-sticky'

export class NavigationBar {
  constructor(el) {
    this.el = el
    this.tabs = this.el.querySelectorAll(IS_ANCHOR)
    this.nav = document.getElementById('navbar')
    if (this.nav) {
      this.isSticky = this.nav.hasAttribute(IS_STICKY)
    }

    const TITLES = this.getNavTitles()
    this.initNav(TITLES)
  }

  initNav(TITLES) {
    this.buildNavbar(TITLES)
  }

  getNavTitles() {
    const TAB_TITLES = [...this.tabs].map(
      currentTab => currentTab.dataset.navTitle
    )
    return TAB_TITLES
  }

  // build single tab

  buildNewTab(tabName) {
    const NEW_TAB = document.createElement('a')
    NEW_TAB.textContent = tabName
    NEW_TAB.className = 'tabTitle'
    NEW_TAB.href = '#' + tabName
    NEW_TAB.addEventListener('click', e => {
      e.preventDefault()
      this.scrollToSection(
        document.querySelector(`[data-nav-title="${tabName}"]`)
      )
    })
    return NEW_TAB
  }

  // build navbar

  buildNavbar(TAB_TITLES) {
    const NAVIGATION_LIST = document.createDocumentFragment()
    TAB_TITLES.forEach(title => {
      const CURRENT_TAB = this.buildNewTab(title)
      NAVIGATION_LIST.appendChild(CURRENT_TAB)
    })
    this.nav.appendChild(NAVIGATION_LIST)

    /* SOTHEXT-3957 */
    if (this.nav.closest('[class$="-info-container"]') !== null) {
      this.nav
        .closest('[class$="-info-container"]')
        .setAttribute('data-offset-pagenav', '')
    }

    if (!this.isSticky) {
      return
    }

    this.nav.children[0] > 0 &&
      this.nav.children[0].setAttribute('data-current', '')

    window.addEventListener(
      'scroll',
      throttle(100, () => {
        if (IsInViewportOverflow(this.nav.parentElement, 154)) {
          // this is more tailored to art movements
          this.nav.removeAttribute('data-sticky-on')
        } else {
          this.nav.setAttribute('data-sticky-on', '')
        }

        const possibleCurrentTab = [...this.tabs]
          .filter(tab => IsInViewportOverflow(tab))
          .reverse()
          .reduce((acc, curr) => curr, {})

        if (possibleCurrentTab && possibleCurrentTab.dataset) {
          [...this.tabs]
            .map(
              currentTab => currentTab.dataset && currentTab.dataset.navTitle
            )
            .map(title => this.nav.querySelector(`[href="#${title}"]`))
            .filter(el => !!el)
            .map(tab => tab.removeAttribute('data-current'))

          const navTitleTab = this.nav.querySelector(
            `[href="#${possibleCurrentTab.dataset &&
              possibleCurrentTab.dataset.navTitle}"]`
          )
          navTitleTab && navTitleTab.setAttribute('data-current', '')
        }
      })
    )
  }

  // get scroll position destination

  getOffset(el) {
    const RECT = el.getBoundingClientRect()
    const SCROLL_TOP = window.pageYOffset || document.documentElement.scrollTop
    return RECT.top + SCROLL_TOP - OFFSET
  }

  scrollToSection(section) {
    window.scroll({
      top: this.getOffset(section),
      left: 0,
      behavior: 'smooth'
    })
  }
}
