import { EventTracking } from './PageInteractions'
import MediaQueries from '../utils/MediaQueries'
import qs from 'qs'
import SyndicationUtils from '../utils/SyndicationUtils'
const mq = new MediaQueries().mq

export class CardTracking {
  constructor(el) {
    this.el = el
    if (typeof window.analytics !== 'undefined' || SyndicationUtils.isIframe) {
      const cardLinks = this.el.querySelectorAll('a')
      cardLinks.forEach(link => {
        link.addEventListener('click', e => {
          const cardProps = this.getCardProps()
          // We don't want Content Card Clicked being passed for Answer cards.
          // Currently all other Algolia cards are also firing Content Card Clicked.
          // There may be a ticket in the future that would make sure none of the algolia cards
          // pass 'Content Card Clicked' but keeping it the way it is for now.
          if (this.el.getAttribute('data-type') !== 'answer') {
            EventTracking('Click Action', cardProps)
          }

          // Answer Cards are handled in AnswerCardTracking.js
          if (
            this.el.hasAttribute('data-hit') &&
            this.el.getAttribute('data-type') !== 'answer'
          ) {
            const { query } = qs.parse(window.location.search.slice(1))
            const searchKeyword = query || ''

            if (this.el.hasAttribute('data-location-tracking')) {
              EventTracking('Search Result Clicked', {
                search_result_title: cardProps.card_title,
                search_keyword: searchKeyword,
                search_result_category: cardProps.card_type,
                sale_id: cardProps.saleId,
                auction_name: cardProps.auctionName,
                item_location_barcode: cardProps.itemLocation,
                search_result_position: cardProps.position,
                search_filters:
                  cardProps.filters && cardProps.filters.departments
              })
            } else {
              EventTracking('Search Result Clicked', {
                search_result_title: cardProps.card_title,
                search_keyword: searchKeyword,
                search_result_category: cardProps.card_type
              })
            }
          }
        })
      })
    }
  }

  getCardProps() {
    const cardType = this.el.getAttribute('data-type')
    let cardProps = {
      card_type: cardType
    }

    const locationTracking = this.el.getAttribute('data-location-tracking')

    try {
      if (locationTracking) {
        const {
          saleId,
          auctionName,
          itemLocation,
          position,
          filters
        } = JSON.parse(locationTracking)
        cardProps = {
          ...cardProps,
          saleId,
          auctionName,
          itemLocation,
          position,
          filters
        }
      }
    } catch (e) {
      console.warn('Error parsing card tracking properties.', e)
    }

    if (this.el.querySelector('.Card-details') !== null) {
      cardProps.card_details = this.el.querySelector('.Card-details').innerText
    }

    if (this.el.closest('[data-card-carousel]') !== null) {
      let carousel = this.el.closest('[data-card-carousel]')

      // Get Carousel Style
      if (mq['mq-viewport-lg'].matches) {
        cardProps.carousel_style = 'Mosaic'
        if (carousel.hasAttribute('data-two-wide')) {
          cardProps.carousel_style = 'Two Wide'
        }

        if (carousel.hasAttribute('data-four-wide')) {
          cardProps.carousel_style = 'Four Wide'
        }
      } else {
        cardProps.carousel_style = 'Mobile'
      }

      // Get Carousel Title
      let carouselContainer = this.el.closest('[data-collapsible-module]')
      if (
        carouselContainer.querySelector('[class$="Carousel-title"]') !== null
      ) {
        cardProps.carousel_title = carouselContainer.querySelector(
          '[class$="Carousel-title"]'
        ).innerText
      } else if (this.el.closest('.Tabs-panel') !== null) {
        if (carouselContainer.closest('.Tabs-panel') !== null) {
          cardProps.carousel_title = carouselContainer
            .closest('.Tabs-panel')
            .getAttribute('id')
        }
      }
    }

    switch (cardType) {
      case 'lot': {
        const cardTitleTemplate = []
        if (this.el.querySelector('.Card-lotNumber') !== null) {
          cardTitleTemplate.push(
            this.el.querySelector('.Card-lotNumber').innerText
          )
        }
        if (this.el.querySelector('.Card-artistName') !== null) {
          cardTitleTemplate.push(
            this.el.querySelector('.Card-artistName').innerText
          )
        }
        if (this.el.querySelector('.Card-title') !== null) {
          cardTitleTemplate.push(this.el.querySelector('.Card-title').innerText)
        }
        cardProps.card_title = cardTitleTemplate.join(' | ')
        break
      }
      default: {
        cardProps.card_title = ''

        const titleWorkTxt = this.el.querySelector('.Card-titleWorkTxt')
        const artistName = this.el.querySelector('.Card-artistName')
        const title = this.el.querySelector('.Card-title')

        if (titleWorkTxt !== null) {
          cardProps.card_title = titleWorkTxt.innerText
        } else if (artistName !== null) {
          cardProps.card_title = artistName.innerText
        } else if (title !== null) {
          cardProps.card_title = title.innerText
        }
        break
      }
    }
    return cardProps
  }
}
