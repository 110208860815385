import { DelegateListener } from './Delegate'

/**
 * Adds listeners to target element, returning a list containing the new listener
 * for tracking puroposes (so it can be removed later). Optionally accepts a selector
 * parameter to use delegation when listening for the event.
 *
 * @example <caption>prints "hello!" on all clicks on nodes with '.Card' class</caption>
 * this.listenerList = addTrackedListener(
 *  document.body,
 *  'click',
 *  () => console.log('hello!'),
 *  this.listenerList,
 *  '.Card'
 * )
 *
 * @param {HTMLElement | Window} target element where one will add the listener
 * @param {string} name of the event to listen for
 * @param {function} listener of the event
 * @param {array} list where to track listener
 * @param {string} selector (optional) to find in event path using delegation
 *
 * @return new list of listeners
 */
export function addTrackedListener(target, name, listener, list, selector) {
  if (!Array.isArray(list)) {
    list = []
  }

  list = list.slice() // work on new list which will be returned

  // use delegate event listener if selector is present
  listener =
    typeof selector === 'string'
      ? DelegateListener(target, name, selector, listener)
      : listener

  // attach event listener
  target.addEventListener(name, listener)

  // track event listener
  list.push({
    target,
    name,
    listener
  })

  return list
}

/**
 * Removes event listeners tracked with Events#addTrackedListener()
 *
 * @param {array} list with event listeners to remove
 */
export function removeTrackedListeners(list) {
  list.map(event => {
    event.target.removeEventListener(event.name, event.listener)
  })
}

/**
 * Tracker class to keep track of event listeners.
 * Handles adding and clearing all listeners in tracker.
 */
export class Tracker {
  constructor() {
    this.list = []
  }

  /**
   * Adds listener to specified target and keeps track of it. Optionally
   * accepts a selector parameter to use delegation when listening for the event.
   *
   * @param {HTMLElement | Window} target element where one will add the listener
   * @param {string} name of the event to listen for
   * @param {function} listener of the event
   * @param {string} [selector] (optional) to find in event path using delegation
   */
  addListener = (target, name, listener, selector) => {
    this.list = addTrackedListener(target, name, listener, this.list, selector)
  }

  /**
   * Removes all event listeners stored by this trackerfrom their target elements.
   */
  removeListeners = () => {
    removeTrackedListeners(this.list)
    delete this.list
  }
}
