import { EventEmitter } from 'events'
import { VerifyPromo } from '../../preferred/VerifyPromo'

/**
 * This class is primarily used in order to redact promos within the Algolia search. Only results found through
 * filters should use this class.
 */
export class DynamicCard {
  static EMITTER = new EventEmitter()

  static EVENTS = {
    dynamicCardLoaded: 'dynamic_card_loaded'
  }

  /**
   * This method can be called anywhere in order to redact or verify all elements with the 'data-redact-promo'
   */
  static dynamicCardLoaded() {
    DynamicCard.EMITTER.emit(DynamicCard.EVENTS.dynamicCardLoaded)
  }

  /**
   * Make sure that the EMITTER has not been hit yet so that we know to initialize it
   */
  static init() {
    if (
      DynamicCard.EMITTER.listenerCount(
        DynamicCard.EVENTS.dynamicCardLoaded
      ) === 0
    ) {
      DynamicCard.EMITTER.on(DynamicCard.EVENTS.dynamicCardLoaded, _updateCard)
      DynamicCard.EMITTER.setMaxListeners(Infinity)
    }
  }
}

/**
 * Find all Card elements with the data attribute 'data-redact-promo' and verufy if they should be redacted or not
 */
const _updateCard = () => {
  [...document.querySelectorAll(`[data-redact-promo]`)].forEach(
    node => new VerifyPromo(node)
  )
}
