const PASSWORD_HIDE = '.PasswordInput-input-hidden'
const PASSWORD_SHOW = '.PasswordInput-input-visible'
const PASSWORD_TOGGLE = 'data-show-password'
const INPUT = '.PasswordInput-input'

export class ShowPasswordInput {
  constructor(el) {
    this.el = el
    this.parent = this.el.parentElement // need to find siblings with this plugin
    this.isVisible = this.parent.querySelector(PASSWORD_SHOW)
    this.isHidden = this.parent.querySelector(PASSWORD_HIDE)
    this.input = this.parent.querySelector(INPUT)

    this.isHidden.addEventListener('click', () => {
      this.showPassword()
    })

    this.isVisible.addEventListener('click', () => {
      this.hidePassword()
    })
  }

  showPassword() {
    if (this.isHidden.getAttribute(PASSWORD_TOGGLE) === 'false') {
      this.isHidden.setAttribute(PASSWORD_TOGGLE, 'true')
      this.isVisible.setAttribute(PASSWORD_TOGGLE, 'true')
      this.input.type = 'text'
    }
  }

  hidePassword() {
    if (this.isVisible.getAttribute(PASSWORD_TOGGLE) === 'true') {
      this.isHidden.setAttribute(PASSWORD_TOGGLE, 'false')
      this.isVisible.setAttribute(PASSWORD_TOGGLE, 'false')
      this.input.type = 'password'
    }
  }
}
