import React from 'react'

export class ReactSearchModule {
  constructor(el) {
    const bsProps = el.getAttribute('data-props')
    const bsLocalization = el.getAttribute('data-localization')
    const defaultImageUrl = el.getAttribute('data-default-image-url')

    Promise.all([
      import(/* webpackChunkName: 'AlgoliaSearchModule' */ './search/algolia/AlgoliaSearchModule.js'),
      import(/* webpackChunkName: 'ReactDOM' */ 'react-dom')
    ])
      .then(([module, ReactDOM]) => {
        const AlgoliaSearchModule = module.default
        ReactDOM.render(
          <AlgoliaSearchModule
            bsProps={bsProps}
            bsLocalization={bsLocalization}
            defaultPromoImage={defaultImageUrl}
          />,
          el
        )
      })
      .catch(e => console.warn('Error loading react search module', e))
  }
}
