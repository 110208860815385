/* global HTMLElement */
import Flickity from 'flickity'
import FixFlickity from '../FlickityFixes'
import { throttle } from '../utils/Throttle.js'
import { EventTracking } from '../segment/PageInteractions'
import { Tracker } from '../utils/Events'

/*
Expected attributes for the timeline-module component:
<timeline-module>
  <ul data-timeline-items>
    <li data-timeline-item></li>
  </ul>
</timeline-module>
*/

export class TimelineModule extends HTMLElement {
  static selectors = {
    timelineModule: 'timeline-module',
    timelineNavigation: '[data-timeline-navigation]',
    navItems: '.TimelineModule-nav-item',
    timelineItems: '[data-timeline-items]',
    timelineItem: '[data-timeline-item]',
    timelineCardTitle: '.TimelineEventCard-title',
    noWraparound: 'data-no-wraparound',
    previewNeighbors: 'data-preview-neighbors'
  }

  constructor() {
    super()
    FixFlickity()
  }

  connectedCallback() {
    this.listenerTracker = new Tracker()
    this.timelineItems = this.querySelector(
      TimelineModule.selectors.timelineItems
    )
    this.timelineNavigation = this.querySelector(
      TimelineModule.selectors.timelineNavigation
    )
    this.navItems = this.querySelectorAll(TimelineModule.selectors.navItems)
    this.initTimeline().then(() => {
      this.timelineEvents.forEach(image =>
        image.addEventListener('load', this.resizeTimeline)
      )

      window.addEventListener(
        'resize',
        throttle(250, () => {
          this.initTimeline()
        })
      )
    })
  }

  disconnectedCallback() {
    this.timelineEvents.forEach(image =>
      image.removeEventListener('load', this.resizeTimeline)
    )

    window.removeEventListener(
      'resize',
      throttle(250, () => {
        this.initTimeline()
      })
    )
  }

  async initTimeline() {
    if (this.timelineEvents.length <= 1) {
      return null
    }

    if (this.timeline) {
      this.destroyTimeline(this.timeline)
      delete this.timeline
    }

    if (this.timelineNav) {
      this.destroyTimeline(this.timelineNav)
      this.listenerTracker.removeListeners()
      delete this.timelineNav
    }

    this.timeline = new Flickity(this.timelineItems, {
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      wrapAround: this.wrapAround,
      prevNextButtons: true
    })

    if (this.timelineNavigation) {
      await import(/* webpackChunkName: 'FlickityAsNavFor' */ 'flickity-as-nav-for')
      this.timelineNav = new Flickity(this.timelineNavigation, {
        asNavFor: this.timelineItems,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: false,
        contain: false,
        cellAlign: 'center'
      })
    }

    this.navItems &&
      this.navItems.forEach((item, i) => {
        this.listenerTracker.addListener(item, 'click', () => {
          this.timeline.select(i)
        })
      })

    this.timeline.on('change', index => {
      let title = document.querySelector('.TimelineModule-title')
        ? document.querySelector('.TimelineModule-title').innerText
        : null

      if (!title) {
        // fall back to other timeline style
        title = document.querySelector('.TimelineModuleCompact-title')
          ? document.querySelector('.TimelineModuleCompact-title').innerText
          : null
      }

      let currentItem = this.timeline ? this.timeline.selectedElement : false
      let itemTitle =
        currentItem && currentItem.querySelector('.TimelineEventCard-title')
          ? currentItem.querySelector('.TimelineEventCard-title').innerText
          : null

      this.timelineNav && this.timelineNav.select(index)
      // If the current slide's title is missing, use the current nav item's title
      if (itemTitle === null) {
        currentItem = this.timelineNav
          ? this.timelineNav.selectedElement
          : false
        itemTitle =
          currentItem && currentItem.querySelector('span')
            ? currentItem.querySelector('span').innerText
            : null
      }

      const timelineProps = {
        timeline_title: title,
        timeline_content_title: itemTitle
      }

      EventTracking('Timeline Module Clicked', timelineProps)

      if (this.previewNeighbors) {
        TimelineModule.addNeighborPreview(this.timeline)
      }
    })

    this.timelineNav &&
      this.timelineNav.on('change', index => {
        this.timeline.select(index)
      })

    if (this.previewNeighbors) {
      TimelineModule.addNeighborPreview(this.timeline)
    }
  }

  static addNeighborPreview(flickity) {
    const size = flickity.slides && flickity.slides.length
    const index = flickity.selectedIndex
    const next = flickity.nextButton && flickity.nextButton.element
    const prev = flickity.prevButton && flickity.prevButton.element

    if (!(size && next && prev && size > index)) {
      console.warn(
        'Flickity instance must have a size, previous and next buttons, and a size greater than selected index.'
      )
      return false
    }

    if (index > 0) {
      const prevElement = TimelineModule.getElementFromSlides(
        flickity,
        index - 1
      )
      const prevTitle = prevElement
        ? prevElement.querySelector(TimelineModule.selectors.timelineCardTitle)
        : false
      prev.innerText = prevTitle ? prevTitle.innerText : ''
    }

    if (size - 1 > index) {
      const nextElement = TimelineModule.getElementFromSlides(
        flickity,
        index + 1
      )
      const nextTitle = nextElement
        ? nextElement.querySelector(TimelineModule.selectors.timelineCardTitle)
        : false
      next.innerText = nextTitle ? nextTitle.innerText : ''
    }
  }

  static getElementFromSlides(flickity, index, cell = 0) {
    if (!(Array.isArray(flickity.slides) && flickity.slides.length > index)) {
      return false
    }

    if (
      !(
        Array.isArray(flickity.slides[index].cells) &&
        flickity.slides[index].cells.length > cell
      )
    ) {
      return false
    }

    return flickity.slides[index].cells[cell].element
  }

  /**
   * Destroys given timeline
   *
   * @param {Object}   timeline
   *
   *  @returns {*}
   */
  destroyTimeline = timeline => timeline.destroy()

  resizeTimeline = () => {
    this.timeline.resize()
  }

  /**
   * Events that the timeline displays.
   *
   * @returns {Array} timelineEvents
   */
  get timelineEvents() {
    return (
      [...this.querySelectorAll(TimelineModule.selectors.timelineItem)] || []
    )
  }

  /**
   * Returns weather or not the carousel should wrap around back to the first
   * item form last based on attribute.
   *
   * @return {boolean}
   */
  get wrapAround() {
    return !this.hasAttribute(TimelineModule.selectors.noWraparound)
  }

  /**
   * Returns weather or not the previous and next buttons should preview the
   * linked card's title.
   *
   * @return {boolean}
   */
  get previewNeighbors() {
    return this.hasAttribute(TimelineModule.selectors.previewNeighbors)
  }
}
