import {
  eraseCookie,
  getCookie,
  getRootCookieDomain,
  hasCookie
} from '../utils/CookieJar'
import auth0 from 'auth0-js'
import { EventEmitter } from 'events'
import { getPageProperties } from '../segment/PageInteractions'
import { DOMContentLoaded } from './DOMContentLoaded'

/**
 * Core user data functionality without jsrs verification. This is needed because
 * the header does not need this verification and doens't support dynamic loading
 * modules.
 */
export default class UserDataUnverified {
  static GLOB_COOKIE = 'globid'
  static ACCESSES_COOKIE = 'bsp.accesses'
  static STB_SUB_AUTH_COOKIE = 'stb-sub-auth'
  static RESTRICTABLE_PUB_KEY_ATTR = 'data-redactable-token'
  static EMITTER = new EventEmitter()

  static events = {
    bspAccessesFetched: 'userdata.bspAccessesFetched'
  }

  static accesses = {
    AUTHENTICATED: 'authenticated', // Set for any user that is logged in
    PREFERRED: 'preferred', // Blue tier membership
    TIER2: 'preferred.tier-2', // Gold tier membership
    TIER1: 'preferred.tier-1', // Platinum tier membership
    PREMIUM: 'premium'
  }

  // ----- Singleton Instance -----

  /**
   * Gets singleton instance of the class.
   *
   * @description used to get the singleton instance of the class. Can be called from static methods
   *  to work with singleton instance data. Stores singleton instance in window global variable.
   *  If this fails, then it falls back to restoring from static class variable.
   * @return {UserDataUnverified} instance
   */
  static get instance() {
    // Try to use object if already created.
    // Try window object first, fallback to static from class (if window isn't available)
    const cache =
      window.__BPS_USER_DATA_UTIL__ || UserDataUnverified.__instance__

    // not checking against instanceof UserDataUnverified because UserDataUnverified class name is mangled after minification
    const instance = cache instanceof Object ? cache : new UserDataUnverified()

    // set new cache
    window.__BPS_USER_DATA_UTIL__ = instance // store in window object
    UserDataUnverified.__unverified_instance__ = instance // store in static class

    return instance
  }

  // ----- Static Public Methods -----

  /**
   * Configures UserDataUnverified options,
   *
   * @param {object} options to configure. Supported options include:
   * - origin to send /bsp-api requests to
   */
  static configure = options => {
    UserDataUnverified.instance.options = {
      ...UserDataUnverified.instance.options,
      ...options
    }
  }

  /**
   * Checks if Auth0 is configured
   *
   * @function isAuth0Configured
   * @return {boolean} true if auth0 is configured
   */
  static isAuth0Configured = () =>
    UserDataUnverified.instance.auth0 !== undefined

  /**
   * Configures Auth0. Configure this if you need to do any calls to Auth0.
   *
   * @param {string|array} domain the Auth0 account domain, or array of domains to try
   * @param {string} clientID the Client ID in the Auth0 Application settings page
   * @param {string} audience the default audience
   * @return {boolean} true if successful, false otherwise
   */
  static configureAuth0 = (domain, clientID, audience) => {
    if (!domain || !clientID || !audience) {
      console.warn(
        'Auth0 not configured correctly! Parameters domain, clientID, audience should be set',
        domain,
        clientID,
        audience
      )
      return false
    }

    UserDataUnverified.instance.domain = domain
    UserDataUnverified.instance.clientID = clientID
    UserDataUnverified.instance.audience = audience
    return UserDataUnverified.isAuth0Configured()
  }

  /**
   * Gets a new Auth0 Token if the user is logged in, will return false
   * if the user's session is no longer valid.
   *
   * @async
   * @function getAuth0Token
   * @requires UserDataUnverified#configureAuth0 to make sure Auth0 is configured
   * @return {Promise<string>} resolves to the Auth0 Token
   */
  static getAuth0Token = () =>
    new Promise((resolve, reject) => {
      if (!UserDataUnverified.isAuth0Configured()) {
        return reject(
          'Auth0 must be configured first by calling UserDataUnverified#configureAuth0.'
        )
      }

      UserDataUnverified.instance.auth0.checkSession(
        {
          scope: 'profile openid email',
          responseType: 'token'
        },
        (err, authResult = {}) => {
          if (err && err.error === 'login_required') {
            if (UserDataUnverified.instance.nextAuth0()) {
              // try next Auth0 configuration
              UserDataUnverified.getAuth0Token()
                .then(res => resolve(res))
                .catch(err => reject(err))
            } else {
              reject(err) // reject if no more Auth0 configurations to try
            }
          } else if (err) {
            reject(err)
          } else if (!authResult.accessToken) {
            reject(authResult)
          } else {
            resolve(authResult.accessToken)
          }
        }
      )
    })

  /**
   * Checks if the user has been authenticated
   * @return {boolean} true if user has been authenticated
   */
  static meetsAuthPrecondition = () =>
    hasCookie(UserDataUnverified.STB_SUB_AUTH_COOKIE) &&
    hasCookie(UserDataUnverified.GLOB_COOKIE)

  /**
   * Gets unverified accesses JWT.
   *
   * @async
   * @see UserDataUnverified#getAccesses for a verified version which
   *  requires the public key.
   * @function getUnverifiedAccesses
   * @requires UserDataUnverified#configureAuth0 to make sure Auth0 is configured
   * @return {Promise<array>} resolves to array of unverified access strings
   */
  static getUnverifiedAccesses = () =>
    new Promise((resolve, reject) => {
      if (UserDataUnverified.instance.accessesErrorCache) {
        return reject(
          `Cached error: ${UserDataUnverified.instance.accessesErrorCache}`
        )
      }

      if (!UserDataUnverified.meetsAuthPrecondition()) {
        // check sub-auth cookie
        return resolve([]) // not logged in, no accesses
      }

      UserDataUnverified.instance
        ._getAccessesJWT()
        .then(jwt => resolve(UserDataUnverified.instance._parseJWT(jwt)))
        .catch(err => reject(err))
    })

  /**
   * Checks if we have the bsp.accesses cookie set. Does not verify if it is a valid token.
   *
   * @return {boolean} true if we have the bsp.accesses cookie set
   */
  static hasAccessesCookie = () => hasCookie(UserDataUnverified.ACCESSES_COOKIE)

  /**
   * Clears bsp accesses cookie.
   *
   * @function clearAccesses
   * @param {string} [domain] optional domain, will fallback to root domain in page attrs.
   */
  static clearAccesses = domain => {
    UserDataUnverified.instance.verifiedAccessesCache = null
    eraseCookie(
      UserDataUnverified.ACCESSES_COOKIE,
      domain || getRootCookieDomain()
    )
  }

  /**
   * Checks if user has given access, without verifying the token.
   *
   * Pass each access as a separate parameter, will only return true if all the
   * accesses passed are in the user's accesses.
   *
   * @async
   * @function hasAccess
   * @param {string} accesses to query if the user has this access. Can accept multiple.
   * @throws {error} if access queried for not part of UserDataUnverified.accesses, or
   *  any error while getting user accesses.
   * @return {Promise<boolean>} true if the user has this access.
   */
  static hasUnverifiedAccess = async (...accesses) => {
    // ensure access queried for is valid
    const allowedAccesses = Object.values(UserDataUnverified.accesses)
    if (accesses.filter(a => !allowedAccesses.includes(a)).length > 0) {
      throw new Error(
        `Access not valid. Must be one of the following: ${allowedAccesses.join(
          ', '
        )}.`
      )
    }

    await DOMContentLoaded // wait for dom to finish parsing

    // ensure Auth0 is configured before getting accesses
    if (!UserDataUnverified.isAuth0Configured()) {
      const { auth0Domain, auth0ClientID, auth0Audience } = getPageProperties(
        'data-page-properties'
      )
      UserDataUnverified.configureAuth0(
        auth0Domain,
        auth0ClientID,
        auth0Audience
      )
    }

    const userAccesses = await UserDataUnverified.getUnverifiedAccesses()

    return accesses.filter(a => !userAccesses.includes(a)).length === 0
  }

  // ----- Constructor -----

  /**
   * Class constructor. Should ideally only be invoked once per window.
   */
  constructor() {
    this.options = {
      // set default options
      origin: ''
    }
  }

  // ----- Setters / Getters -----

  get auth0() {
    if (!Array.isArray(UserDataUnverified.instance._auth0)) {
      UserDataUnverified.instance._auth0 = new Array()
    }

    if (UserDataUnverified.instance._auth0.length === 0) {
      if (!Array.isArray(UserDataUnverified.instance.domain)) {
        UserDataUnverified.instance.domain = [
          UserDataUnverified.instance.domain
        ]
      }

      UserDataUnverified.instance.domain
        .filter(domain => !!domain) // make sure domain is not null
        .map(domain => {
          try {
            const auth0Configuration = new auth0.WebAuth({
              domain,
              clientID: this.clientID,
              audience: this.audience,
              redirectUri: window.location.origin
            })

            UserDataUnverified.instance._auth0.push(auth0Configuration)
          } catch (e) {
            console.warn(e)
          }
        })
    }

    return UserDataUnverified.instance._auth0.length > 0
      ? UserDataUnverified.instance._auth0[0]
      : undefined
  }

  nextAuth0 = () => {
    if (UserDataUnverified.instance._auth0.length > 1) {
      UserDataUnverified.instance._auth0.shift()
      return true
    }
    return false
  }

  // ----- Helper Methods -----

  /**
   * Gets bsp accesses JWT if the user is authenticated.
   *
   * Checks session with Auth0 to generate a new Auth0 ID token,
   * then sends that to BSP to generate the bsp.accesses token.
   *
   * @async
   * @function getAccessesJWT
   * @requires UserDataUnverified#configureAuth0 to make sure Auth0 is configured
   * @param {boolean} forceFetch by default this function doest not send another request
   *  if there is another request processing, this forces another request
   * @return {Promise<string, string>} The bsp.accesses token, if it got the auth0Token it will
   *  return that as the second parameter also
   */
  _getAccessesJWT = forceFetch =>
    new Promise((resolve, reject) => {
      if (UserDataUnverified.instance.accessesErrorCache) {
        return reject(
          `Cached error: ${UserDataUnverified.instance.accessesErrorCache}`
        )
      }

      if (!UserDataUnverified.isAuth0Configured()) {
        return reject(
          'Auth0 must be configured first by calling UserDataUnverified#configureAuth0.'
        )
      }

      if (!UserDataUnverified.meetsAuthPrecondition()) {
        // check sub-auth cookie
        return reject('User is not authenticated')
      }

      let cookie = getCookie(UserDataUnverified.ACCESSES_COOKIE)
      if (cookie) {
        // has bsp.accesses token, resolve
        return resolve(cookie)
      }

      // -- does not have bsp.accesses token --
      if (!UserDataUnverified.instance.isFetchingAccessesJWT || forceFetch) {
        // first request or force request, so do request
        UserDataUnverified.instance.isFetchingAccessesJWT = true
        UserDataUnverified.getAuth0Token()
          .then(auth0Token => {
            // pass auth0Token to bsp-accesses endpoint to get JWT
            window
              .fetch(
                `${
                  UserDataUnverified.instance.options.origin
                }/bsp-api/accesses-cookie`,
                {
                  credentials: 'include',
                  headers: {
                    Authorization: 'Bearer ' + auth0Token
                  },
                  mode: 'cors'
                }
              )
              .then(res => {
                if (res.status === 204) {
                  cookie = getCookie(UserDataUnverified.ACCESSES_COOKIE)
                  if (cookie) {
                    resolve(cookie, auth0Token) // got JWT successfully
                  } else {
                    reject(
                      'Cookie not sent back from /bsp-api/accesses-cookie call. Make sure cookie domain matches current domain.'
                    )
                  }
                } else {
                  reject(res)
                }
              })
              .catch(err => reject(err)) // rejection
              .finally(() => {
                UserDataUnverified.instance.isFetchingAccessesJWT = false
                UserDataUnverified.EMITTER.emit(
                  UserDataUnverified.events.bspAccessesFetched
                )
              })
          })
          .catch(err => reject(err))
      } else {
        // there is currently a request right now, listen for fetched event and try again
        UserDataUnverified.EMITTER.setMaxListeners(
          UserDataUnverified.EMITTER.getMaxListeners() + 1
        )
        UserDataUnverified.EMITTER.once(
          UserDataUnverified.events.bspAccessesFetched,
          () => {
            this._getAccessesJWT(true) // force request this time
              .then((res, auth0Token) => {
                UserDataUnverified.EMITTER.setMaxListeners(
                  Math.max(UserDataUnverified.EMITTER.getMaxListeners() - 1, 0)
                )
                return resolve(res, auth0Token)
              })
              .catch(err => reject(err))
          }
        )
      }
    })

  _parseJWT = jwt => {
    if (!jwt) {
      return []
    }

    const base64Url = jwt.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

    try {
      // need try-catch when parsing so it doesn't kill all JS
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      const payloadObj = JSON.parse(jsonPayload)
      if (payloadObj && Array.isArray(payloadObj.permissions)) {
        return payloadObj.permissions
      }
      return []
    } catch (e) {
      console.warn(e)
      return []
    }
  }
}
