import { ORDER_PAYMENT_SUCCESS, ORDER_PAYMENT_FAIL, ADD_PROMOTION_CODE_FAIL, ADD_PROMOTION_CODE_SUCCESS, REMOVE_PROMOTION_CODE } from '../constants/checkout'
import { CART_FETCH_SUCCESS } from '../constants/cart'

const initialState = {
  completed: false,
  errors: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADD_PROMOTION_CODE_FAIL:
    case ORDER_PAYMENT_FAIL:
      return {
        ...state,
        errors: action.payload || {}
      }

    case CART_FETCH_SUCCESS:
    case ADD_PROMOTION_CODE_SUCCESS:
    case REMOVE_PROMOTION_CODE:
      return {
        ...state,
        promotion: action.payload.cart.promotionCodes,
        errors: {}
      }

    case ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        creditCardLastFour: action.payload.creditCardLastFour,
        orderPaymentID: action.payload.orderPaymentID,
        name: action.payload.nameOnCreditCard,
        completed: true,
        errors: {}
      }

    default:
      return state
  }
}
