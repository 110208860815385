/* eslint-disable */
import { progressBarStart } from './HeroProgressBar'
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'
import { accessTier } from '../../utils/UserData'
import { getUserProperties } from '../../segment/IdentityTracking'

const heroVersionName = 'Corner Info Slider'

export class HeroCornerInfoSlider {
  constructor(el) {
    FixFlickity()

    this.element = el
    this.cssSlideName = 'HeroCornerInfoSlide'
    this.slideList = this.element.querySelectorAll(`.${this.cssSlideName}`)
    this.count = this.slideList.length
    this.isPaused = false
    this.progressInterval = 6000
    this.progressTimer = ''

    // Only initialize the carousel if there is more than one slide
    if (this.count > 1) {
      this.initCarousel()
    }

    if (typeof analytics !== 'undefined') {
      const heroLinks = this.element.querySelectorAll('a')
      const heroImage = this.element.querySelector('.Image')

      let featuredText = ''
      const firstTitle = this.element.querySelector(
        '.HeroCornerInfoSlide-info-title'
      )
      if (firstTitle) {
        featuredText = firstTitle.innerText
      }

      let heroProps = {
        homepage_featuredtext: featuredText,
        hero_version: heroVersionName
      }

      if (heroImage.getAttribute('alt') !== '') {
        heroProps.homepage_imagename = heroImage.getAttribute('alt')
      }

      heroLinks.forEach(link => {
        link.addEventListener('click', e => {
          const auth0Id = getPageProperties('data-page-properties')[
            'auth0ClientID'
          ]
          const tier = !accessTier ? 0 : accessTier
          const customerId = getUserProperties().customer_id

          let cardType = 'hero'
          const card = this.element.querySelector(
            '[class$="is-selected"] [data-type]'
          )
          if (card) {
            cardType = card.getAttribute('data-type')
          }

          const props = {
            ...heroProps,
            card_type: cardType,
            carousel_style: heroVersionName,
            carousel_title: '',
            card_title: link.innerText,
            auth0_id: auth0Id,
            preferred_member_flag: tier,
            customer_id: customerId
          }

          EventTracking('Click Action', props)
        })
      })
    }
  }

  appendZero(n) {
    const count = n.toString()
    return count.length === 1 ? `0${count}` : count
  }

  initCarousel() {
    this.slideList.forEach((slide, i) => {
      let infoWrapper = slide.querySelector(
        `.${this.cssSlideName}-info-wrapper`
      )
      if (!infoWrapper.querySelector(`.${this.cssSlideName}-nav`)) {
        let infoNav = document.createElement('div')
        let progressWrap = document.createElement('div')
        let progressBar = document.createElement('div')
        let prevBtn = document.createElement('span')
        let nextBtn = document.createElement('span')
        let counterEl = document.createElement('span')
        let counterTxt = document.createTextNode(
          this.appendZero(i + 1) + ' / ' + this.appendZero(this.count)
        )

        progressWrap.classList.add(this.cssSlideName + '-progress')
        infoNav.classList.add(this.cssSlideName + '-nav')
        prevBtn.classList.add(this.cssSlideName + '-nav-prev')
        nextBtn.classList.add(this.cssSlideName + '-nav-next')
        counterEl.classList.add(this.cssSlideName + '-nav-count')

        counterEl.appendChild(counterTxt)
        progressWrap.appendChild(progressBar)

        infoNav.appendChild(prevBtn)
        infoNav.appendChild(counterEl)
        infoNav.appendChild(nextBtn)

        infoWrapper.prepend(infoNav)
        infoWrapper.prepend(progressWrap)
      }
    })

    this.carousel = new Flickity(this.element, {
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      autoPlay: false,
      wrapAround: true,
      selectedAttraction: 1,
      friction: 1,
      on: {
        ready: () => {
          let activeSlide = this.slideList[0]
          progressBarStart(this, activeSlide)
        }
      }
    })

    let prevBtnList = this.element.querySelectorAll(
      `.${this.cssSlideName}-nav-prev`
    )
    let nextBtnList = this.element.querySelectorAll(
      `.${this.cssSlideName}-nav-next`
    )

    prevBtnList.forEach(btn => {
      btn.addEventListener('click', e => {
        this.carousel.previous()
      })
    })

    nextBtnList.forEach(btn => {
      btn.addEventListener('click', e => {
        this.carousel.next()
      })
    })

    this.carousel.on('change', () => {
      clearInterval(this.progressTimer)
      let activeSlide = this.carousel.selectedSlide.cells[0].element
      progressBarStart(this, activeSlide)
    })

    this.carousel.element.addEventListener('mouseover', e => {
      this.isPaused = true
    })

    this.carousel.element.addEventListener('mouseout', e => {
      this.isPaused = false
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }
}
