/* global HTMLElement */
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'

const SEGMENT_EVENT = 'View Type Selected'

export class AuctionsStyleToggle extends HTMLElement {
  static attributes = {
    toggle: 'data-toggle',
    auctionsStyle: 'data-auctions-style'
  }

  static events = {
    styleUpdated: 'AUCTIONS_VIEW_STYLE_UPDATED'
  }

  connectedCallback() {
    this.toggles = this.querySelectorAll(
      `[${AuctionsStyleToggle.attributes.toggle}]`
    )
    this.auctionsMain = this.closest(
      `[${AuctionsStyleToggle.attributes.auctionsStyle}]`
    )
    this.styleUpdatedEvent = new window.CustomEvent(
      AuctionsStyleToggle.events.styleUpdated
    )

    this.addEventListeners()

    var auctionMode = window.sessionStorage.getItem('AuctionMode')

    this.auctionsMain.setAttribute(
      AuctionsStyleToggle.attributes.auctionsStyle,
      auctionMode || 'grid'
    )
  }

  addEventListeners() {
    this.toggles.forEach(item => {
      item.addEventListener('click', () => {
        this.toggle(item)

        window.sessionStorage.setItem(
          'AuctionMode',
          item.getAttribute(AuctionsStyleToggle.attributes.toggle)
        )
      })
    })
  }

  toggle(toggled) {
    const toggleStyle = toggled.getAttribute(
      AuctionsStyleToggle.attributes.toggle
    )

    if (toggleStyle) {
      this.auctionsMain.setAttribute(
        AuctionsStyleToggle.attributes.auctionsStyle,
        toggleStyle
      )
      window.dispatchEvent(this.styleUpdatedEvent)
      this.sendToggleAnalytics(toggleStyle)
    }
  }

  sendToggleAnalytics(style) {
    const pageTrackingProps = getPageProperties()
    const selection = style[0].toUpperCase() + style.slice(1)

    if (pageTrackingProps) {
      const toggleProps = {
        view_selection: `${pageTrackingProps.name}: ${selection}`
      }

      EventTracking(SEGMENT_EVENT, toggleProps)
    }
  }
}
