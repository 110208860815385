/* eslint-disable */
import { throttle } from '../../utils/Throttle'
import { progressBarStart } from './HeroProgressBar'
import shave from 'shave'
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import MediaQueries from '../../utils/MediaQueries'
import {
  EventTracking,
  getPageProperties
} from '../../segment/PageInteractions'
import { accessTier } from '../../utils/UserData'
import { getUserProperties } from '../../segment/IdentityTracking'

const mq = new MediaQueries().mq
const heroVersionName = 'Side Info Slide'

export class HeroSideInfoSlider {
  constructor(el) {
    FixFlickity()

    this.element = el
    this.parent = this.element.parentNode
    this.cssSlideName = 'HeroSideInfoSlide'
    this.slideList = this.element.querySelectorAll(`.${this.cssSlideName}`)
    this.infoCard = this.parent.querySelector('[data-hero-info-card]')
    this.count = this.slideList.length
    this.isPaused = false
    this.progressInterval = 6000
    this.progressTimer = 0
    this.progressTimer2 = 0
    this.truncateMaxHeight = 105
    this.truncateMaxTitleHeight = 56

    // Only initialize the carousel if there is more than one slide
    if (this.count > 1) {
      this.initCarousel()
    }
  }

  appendZero(n) {
    const count = n.toString()
    return count.length === 1 ? `0${count}` : count
  }

  initCarousel() {
    this.slideList.forEach((slide, i) => {
      slide.setAttribute('data-slide-number', i)
      let infoWrapper = slide.querySelector(
        `.${this.cssSlideName}-info-wrapper`
      )
      if (!infoWrapper.querySelector(`.${this.cssSlideName}-count`)) {
        let progressWrap = document.createElement('div')
        let progressBar = document.createElement('div')
        let counterEl = document.createElement('div')
        let counterTxt = document.createTextNode(
          this.appendZero(i + 1) + ' / ' + this.appendZero(this.count)
        )

        progressWrap.classList.add(this.cssSlideName + '-progress')
        counterEl.classList.add(this.cssSlideName + '-count')

        counterEl.appendChild(counterTxt)
        progressWrap.appendChild(progressBar)

        infoWrapper.prepend(counterEl)
        infoWrapper.prepend(progressWrap)

        let clone = infoWrapper.cloneNode(true)
        clone.setAttribute('data-slide-ref', i)
        this.infoCard.appendChild(clone)
      }
    })

    this.infoCardList = this.infoCard.querySelectorAll('[data-slide-ref]')

    this.carousel = new Flickity(this.element, {
      contain: true,
      pageDots: false,
      prevNextButtons: true,
      autoPlay: false,
      wrapAround: true,
      on: {
        ready: () => {
          let activeSlide = this.infoCardList[0]
          let activeInfo = this.slideList[0].querySelector(
            `.${this.cssSlideName}-info-wrapper`
          )
          this.infoCardList[0].classList.add('active')
          this.slideBarStart(this, activeInfo)
          progressBarStart(this, activeSlide)
          if (mq['mq-viewport-md'].matches) {
            this.implementTracking(
              this.infoCard,
              '[data-slide-ref]',
              'data-slide-ref'
            )
          } else {
            this.implementTracking(
              this.element,
              '.HeroSideInfoSlide',
              'data-slide-number'
            )
          }

          window.setTimeout(() => {
            this.carousel.slides[1].cells[0].element.classList.add('is-next')
          }, 50)
        }
      }
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })

    this.carousel.on('change', () => {
      clearInterval(this.progressTimer)
      clearInterval(this.progressTimer2)
      let activeSlide = this.infoCardList[this.carousel.selectedIndex]
      let nextSlide =
        this.carousel.slides.length >= this.carousel.selectedIndex + 2
          ? this.carousel.selectedIndex + 1
          : 0

      this.carousel.slides.forEach((slide, i) => {
        slide.cells[0].element.classList.remove('is-next')
      })

      this.infoCardList.forEach((card, i) => {
        card.classList.remove('active')
      })

      this.carousel.slides[nextSlide].cells[0].element.classList.add('is-next')
      this.infoCardList[this.carousel.selectedIndex].classList.add('active')
      this.slideBarStart(this, this.carousel.selectedElement)
      progressBarStart(this, activeSlide)
    })

    this.parent.addEventListener('mouseover', e => {
      if (
        e.target.closest('[class*="-items-item"].is-selected') ||
        e.target.closest('[data-hero-info-card]')
      ) {
        this.isPaused = true
      }
    })

    this.parent.addEventListener('mouseout', e => {
      if (
        e.target.closest('[class*="-items-item"].is-selected') ||
        e.target.closest('[data-hero-info-card]')
      ) {
        this.isPaused = false
      }
    })

    this.infoCardList.forEach((card, i) => {
      window.setTimeout(() => {
        this.truncateTexts(card)

        if (this.infoCardList.length === i + 1) {
          window.dispatchEvent(new window.Event('resize'))
        }
      }, 500)

      // need to add this to maintain truncation at different breakpoints
      window.addEventListener(
        'resize',
        throttle(500, () => {
          this.truncateTexts(card)
        })
      )
    })

    this.slideList.forEach((card, i) => {
      window.setTimeout(() => {
        this.truncateTitleTexts(card)

        if (this.slideList.length === i + 1) {
          window.dispatchEvent(new window.Event('resize'))
        }
      }, 500)

      // need to add this to maintain truncation at different breakpoints
      window.addEventListener(
        'resize',
        throttle(500, () => {
          this.truncateTitleTexts(card)
        })
      )
    })
  }

  /**
   * When you clone a Node its event listeners do not get copied as well
   * Therefore tracking needs to be implemented after the node is copied to the cloned node itself
   * This is only necessary for non mobile breakpoints which are checked when the method is called
   *
   * @param  {object}   node The DOM node that will receive the event listeners
   * @param  {string}   parentNode The parent data-attr or class to the DOM node that contains a list
   * @param  {string}   parentNodeAttr The attribute on the parent node that keeps track of the current Index
   */
  implementTracking(node, parentNode, parentNodeAttr) {
    if (typeof analytics !== 'undefined') {
      const heroLinks = node.querySelectorAll(
        '.HeroSideInfoSlide-info-title, .HeroSideInfoSlide-info-ctaText'
      )
      const heroImages = this.element.querySelectorAll('.Image')

      heroLinks.forEach(link => {
        let currentIndex = link.closest(parentNode).getAttribute(parentNodeAttr)
        let heroProps = {
          homepage_featuredtext: link.innerText,
          hero_version: heroVersionName
        }
        if (heroImages[currentIndex].getAttribute('alt') !== '') {
          heroProps.homepage_imagename = heroImages[currentIndex].getAttribute(
            'alt'
          )
        }

        link.addEventListener('click', () => {
          const auth0Id = getPageProperties('data-page-properties')[
            'auth0ClientID'
          ]
          const tier = !accessTier ? 0 : accessTier
          const customerId = getUserProperties().customer_id || ''

          let cardType = 'hero'
          const card = node.querySelector('.active[data-type]')
          if (card) {
            cardType = card.getAttribute('data-type')
          }

          const props = {
            ...heroProps,
            card_type: cardType,
            carousel_style: heroVersionName,
            carousel_title: '',
            card_title: link.innerText,
            auth0_id: auth0Id,
            preferred_member_flag: tier,
            customer_id: customerId
          }

          EventTracking('Click Action', props)
        })
      })
    }
  }

  // This version of the hero needs a seperate interval
  // and progress bars to track the progress bar
  // within each slide so not just the image is swipable on mobile.
  slideBarStart(hero, active) {
    let progressBar = active.querySelector(
      `.${this.cssSlideName}-progress > div`
    )
    let progress = 0
    progressBar.style.width = progress

    hero.progressTimer2 = setInterval(() => {
      if (!hero.isPaused) {
        if (progress >= hero.progressInterval) {
          clearInterval(hero.progressTimer2)
        } else {
          progress += 10
          let width = (progress / hero.progressInterval) * 100
          if (width <= 100) {
            progressBar.style.width = width + '%'
          }
        }
      }
    }, 10)
  }

  truncateTexts(el) {
    let description = el.querySelector('[class$="-info-description"]')
    shave(description, this.truncateMaxHeight)
  }

  truncateTitleTexts(el) {
    let title = el.querySelector('[class$="-info-title"]')
    if (!mq['mq-viewport-sm'].matches) {
      shave(title, this.truncateMaxTitleHeight)
    }
  }
}
