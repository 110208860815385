/* global HTMLElement */

export class CopyLink extends HTMLElement {
  connectedCallback() {
    this.link = this.getAttribute('link')
    this.feedbackEl = this.querySelector('[data-feedback]')

    import(/* webpackChunkName: 'ClipboardJS' */ 'clipboard')
      .then(ClipboardJS => {
        this.clipboard = new ClipboardJS.default(this)
        this.clipboard.on('success', this.feedback)
      })
      .catch(e => console.warn('Error loading ClipboardJS', e))

    this.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        this.click()
      }
    })
  }

  disconnectedCallback() {
    this.clipboard.destroy()
  }

  feedback = () => {
    this.changeFeedbackVisibility('visible')
    window.setTimeout(this.changeFeedbackVisibility, 2000)
  }

  changeFeedbackVisibility = visibility => {
    if (this.feedbackEl instanceof HTMLElement) {
      this.feedbackEl.setAttribute('data-feedback', visibility)
    }
  }
}
