import rootReducer from './reducers/index'
import { addBirghtspotProps } from './actions/brightspot'
import { LOCAL_STORAGE_UPDATED } from './constants/cart'
import thunk from 'redux-thunk'
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

class CartStore {
  static localStorageKey = 'cartState'
  static persistentProperties = ['products', 'order'] // whitelist of properties to persist

  constructor () {
    let persisted = {}

    try {
      persisted = this.getPersistedState()
    } catch (e) {
      console.warn('Failed to retrieve persisted state', e)
    }

    this.store = createStore(
        rootReducer(),
        persisted,
        composeEnhancers(applyMiddleware(thunk))
      )

    try {
      this.observeStore(this.persistStore, true)
    } catch (e) {
      console.warn('Failed to persist state in local storage', e)
    }

    window.addEventListener('storage', this.observeLocalStorage, false)
  }

  addBrightspotProps (props) {
    this.store.dispatch(addBirghtspotProps(props))
    return this.store
  }

  persistStore (state) {
    const perist = Object.keys(state)
      .filter(key => CartStore.persistentProperties.includes(key))
      .reduce((obj, validKey) => ({
        ...obj,
        [validKey]: state[validKey]
      }), {})

    window.localStorage.setItem(CartStore.localStorageKey, JSON.stringify(perist))
  }

  getPersistedState () {
    const persisted = JSON.parse(window.localStorage.getItem(CartStore.localStorageKey)) || {}
    const state = Object.keys(persisted)
        .filter(key => CartStore.persistentProperties.includes(key))
        .reduce((obj, validKey) => ({
          ...obj,
          [validKey]: persisted[validKey]
        }), {})

    return state
  }

  clearPersistedState () {
    window.localStorage.setItem(CartStore.localStorageKey, '')
  }

  observeStore = (onChange, ignoreLocalStoreageUpdates = false) => {
    let currentState

    const handleChange = () => {
      const nextState = this.store.getState()
      const allowChange = !ignoreLocalStoreageUpdates || nextState.lastAction !== LOCAL_STORAGE_UPDATED
      if (nextState !== currentState && allowChange) {
        currentState = nextState
        onChange(currentState)
      }
    }

    const unsubscribe = this.store.subscribe(handleChange)
    handleChange()
    return unsubscribe
  }

  observeLocalStorage = ({key, oldValue, newValue}) => {
    if (key === CartStore.localStorageKey && oldValue !== newValue) {
      try {
        this.store.dispatch({
          type: LOCAL_STORAGE_UPDATED,
          payload: JSON.parse(newValue)
        })
      } catch (e) {
        console.warn('Failed to parse persisted state update', e)
      }
    }
  }

  dispatch = action => {
    if (typeof action.type !== 'string') {
      console.warn('action must be an object with a string property named \'type\'')
      return false
    }

    this.store.dispatch(action)
    return true
  }
}

// EXPORTS: we use singleton for use anywhere client-side
const instance = window.__REDUX_CART_STORE_INSANCE__ ? window.__REDUX_CART_STORE_INSANCE__ : new CartStore()
window.__REDUX_CART_STORE_INSANCE__ = instance

/**
 * Redux cart store, uses local storage to retain info.
 */
export default instance.store

/**
 * Adds brightspot props and returns the redux cart store.
 *
 * @param {object} props from brightspot to add
 */
export const getStoreWithBrightspotProps = (props) => {
  instance.addBrightspotProps(props)
  return instance.store
}

/**
 * Executes a given function whenver the store is changed,
 * it is passed the new state.
 */
export const observeStore = (onChange) => {
  instance.observeStore(onChange)
}

/**
 * Dispatches an action to the store.
 *
 * Action must be an object in that containst the 'type' property.
 * Data can be passed under the 'payload' property.
 *
 * @param {object} action to dispatch
 */
export const dispatch = action => instance.dispatch(action)

/**
 * removes state from local storage
 */
export const clearPersistedState = () => instance.clearPersistedState()
