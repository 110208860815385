import './utils/polyfills/PromisePolyfill'
import 'babel-polyfill'
import polyfills from './utils/polyfills'
import init from './All'
import './All.less'

/* Dynamically load needed polyfills, then start app */
Promise.all(polyfills)
  .catch(e => console.warn('Error fetching polyfills: ', e))
  .finally(init)
