import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'

export class HeaderCarousel {
  static selectors = {
    slide: '[data-slide]'
  }

  constructor(el) {
    FixFlickity()

    this.el = el
    let count = this.el.querySelectorAll(HeaderCarousel.selectors.slide).length
    if (count > 1) {
      this.initCarousel()
    }
  }

  initCarousel() {
    this.carousel = new Flickity(this.el, {
      cellAlign: 'left',
      freeScroll: false,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      autoPlay: 3000,
      wrapAround: true
    })

    this.carousel.on('dragStart', () => {
      document.ontouchmove = e => e.preventDefault()
    })
    this.carousel.on('dragEnd', () => {
      document.ontouchmove = () => true
    })
  }
}
