import { EventTracking } from './PageInteractions'

export class CompanionTracking {
  constructor(el) {
    this.init(el)
  }

  init(el) {
    el.addEventListener('click', e => {
      const clickedPromo = e.currentTarget
      const title = clickedPromo
        .querySelector('.CompanionContentItem-title')
        .textContent.trim()

      EventTracking('Video Companion Content Clicked', {
        cta_text: title
      })
    })
  }
}
