/* eslint-disable no-undef */
import MediaQueries from '../../utils/MediaQueries'
import SyndicationUtils from '../../utils/SyndicationUtils'
import Flickity from 'flickity'
import FixFlickity from '../../FlickityFixes'
import { EventTracking } from '../../segment/PageInteractions'
import { DynamicCta } from '../../cta/DynamicCta'
import { DynamicSalePrice } from '../../price/DynamicSalePrice'

const mq = new MediaQueries().mq
const COUNT_CUTOFF = 5
const LENGTH = 'data-length'
const DATA_DYNAMIC = 'data-dynamic'
const TWO_WIDE = 'data-two-wide'
const FOUR_WIDE = 'data-four-wide'
const MOSAIC_CAROUSEL = 'MosaicCarousel-items'
const STACKED_CARD_ATTR = 'data-stacked-cards'
const UNSTACKED_CARD_ATTR = 'data-unstacked'
const ALIGN_RIGHT = 'align-right'

export class CardCarousel {
  constructor(el) {
    FixFlickity()

    el.cardCarouselAPI = this
    this.element = el
    this.elImg = this.element.querySelectorAll('img')
    this.isMosiac = false
    this.rightAligned = false
    this.stackedCardCount = 1
    this.collapsibleContainer = this.element.closest(
      '[data-collapsible-module]'
    )
    this.carouselContainer = this.element.closest('[data-carousel]')
    this.itemCount = window.parseInt(this.element.getAttribute(LENGTH))

    // DynamicCarousel.hbs starts off with data-two-wide, so if the item length
    // is greater or equal to the COUNT_CUTOFF, we remove that attribute so the
    // carousel can act like a four wide (data-four-wide)
    if (
      this.element.hasAttribute(DATA_DYNAMIC) &&
      this.itemCount >= COUNT_CUTOFF
    ) {
      this.element.removeAttribute(TWO_WIDE)
      this.element.setAttribute(FOUR_WIDE, '')
    }

    this.initCarousel()
    this.imageLoadEvent()
    this.bindMqListener()
    this.tabsCarouselBind()
    this.collapsibleCarouselBind()
    DynamicCta.dynamicCtasLoaded()
    DynamicSalePrice.dynamicPricesLoaded()
  }

  bindMqListener() {
    this.mqListenerEventMethod = this.mqListenerEvent.bind(this)
    // behavior of carousel changes when it switches from large to md viewports
    // and vice versa
    mq['mq-viewport-lg'].addListener(this.mqListenerEventMethod)
  }

  mqListenerEvent() {
    this.initCarousel()
  }

  tabsCarouselBind() {
    if (this.element.closest('.Tabs') !== null) {
      this.tabsModule = this.element.closest('[data-collapsible-module]')
      this.elTabs = [...this.tabsModule.querySelectorAll('.Tabs-tab')]
      this.elTabs.forEach(elTab => {
        elTab.addEventListener('click', e => {
          if (e.currentTarget.getAttribute('data-active') === 'false') {
            mq['mq-viewport-lg'].removeListener(this.mqListenerEventMethod)
          }
        })
      })
    }
  }

  collapsibleCarouselBind() {
    if (
      this.element.closest('[data-collapsible-module]') !== null &&
      !mq['mq-viewport-md'].matches
    ) {
      this.collapsibleModule = this.element.closest('[data-collapsible-module]')
      this.collapsibleTitle = this.collapsibleModule.querySelector(
        '[data-collapsible-title]'
      )
      this.collapsibleTitle.addEventListener('click', e => {
        // prevent interaction of collapsing if on the md breakpoint or above
        if (!mq['mq-viewport-md'].matches) {
          mq['mq-viewport-lg'].removeListener(this.mqListenerEventMethod)
        }
      })
    }
  }

  resizeCarousel() {
    if (this.carousel) {
      this.carousel.resize()
    }
  }

  destroyCarousel() {
    if (this.carousel) {
      this.carousel.destroy()
      delete this.carousel
    }
  }

  buildStackedCardContainer() {
    Array.from(this.element.children).forEach((item, i) => {
      if (
        (!this.rightAligned &&
          item.nextElementSibling &&
          (i + this.stackedCardCount) % 3) === 0 ||
        (this.rightAligned &&
          item.nextElementSibling &&
          item.nextElementSibling.nextElementSibling &&
          item.nextElementSibling.nextElementSibling.nextElementSibling &&
          (i + this.stackedCardCount) % 3) === 0
      ) {
        this.stackedCardCount--
        const wrapper = document.createElement('div')
        const cards = [item, item.nextElementSibling]
        wrapper.setAttribute(STACKED_CARD_ATTR, '')
        wrapper.setAttribute('role', 'presentation')
        item.parentNode.insertBefore(wrapper, item)
        this.wrapAll(cards, wrapper)
      }
    })
  }

  checkCarouselAlignment() {
    if (this.element.hasAttribute(ALIGN_RIGHT)) {
      this.stackedCardCount = this.stackedCardCount - 1
      this.rightAligned = true
    }
    this.buildStackedCardContainer()
  }

  wrapAll(nodes, wrapper) {
    const parent = nodes[0].parentNode
    const previousSibling = nodes[0].previousSibling

    for (let i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && i++) {
      wrapper.appendChild(nodes[i])
    }

    const nextSibling = previousSibling
      ? previousSibling.nextSibling
      : parent.firstChild
    parent.insertBefore(wrapper, nextSibling)

    return wrapper
  }

  unwrap(wrapper) {
    // place childNodes in document fragment
    const docFrag = document.createDocumentFragment()
    while (wrapper.firstChild) {
      var child = wrapper.removeChild(wrapper.firstChild)
      docFrag.appendChild(child)
    }

    // replace wrapper with document fragment
    wrapper.parentNode.replaceChild(docFrag, wrapper)
  }

  buildCarousel() {
    if (this.element.classList.contains(MOSAIC_CAROUSEL)) {
      this.isMosiac = true
    }

    let carouselProps = {
      carousel_style: 'Two wide'
    }

    if (mq['mq-viewport-lg'].matches) {
      this.group = 2
      if (this.isMosiac) {
        if (!this.element.hasAttribute(UNSTACKED_CARD_ATTR)) {
          this.checkCarouselAlignment()
        }

        // MosaicCarousel has data-four-wide, but is split by 50% 25% 25%,
        // so we need a group of 3
        this.group = 3
        carouselProps.carousel_style = 'Mosaic'
      } else if (this.element.hasAttribute(FOUR_WIDE)) {
        this.group = 4
        carouselProps.carousel_style = 'Four Wide'
      }

      // Desktop carousel
      this.carousel = new Flickity(this.element, {
        cellAlign: 'left',
        freeScroll: false,
        contain: true,
        pageDots: false,
        groupCells: this.group,
        prevNextButtons: true
      })
    } else {
      if (
        this.element.querySelector('[' + STACKED_CARD_ATTR + ']') !== 'null'
      ) {
        this.element
          .querySelectorAll('[' + STACKED_CARD_ATTR + ']')
          .forEach((stackedCard, i) => {
            this.unwrap(stackedCard)
            this.stackedCardCount = 1
          })
      }
      this.group = 1
      carouselProps.carousel_style = 'Mobile'
      // Mobile carousel
      this.carousel = new Flickity(this.element, {
        cellAlign: 'left',
        freeScroll: true,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        touchVerticalScroll: false,
        friction: 0.15
      })

      this.carousel.on('dragStart', () => {
        document.ontouchmove = e => e.preventDefault()
      })
      this.carousel.on('dragEnd', () => {
        document.ontouchmove = () => true
      })
    }

    this.carousel.reposition()

    // analytics on change
    if (typeof analytics !== 'undefined' || SyndicationUtils.isIframe) {
      if (this.collapsibleContainer !== null) {
        if (
          this.collapsibleContainer.querySelector(
            '[class$="Carousel-title"]'
          ) !== null
        ) {
          carouselProps.carousel_title = this.collapsibleContainer.querySelector(
            '[class$="Carousel-title"]'
          ).innerText
        } else if (this.element.closest('.Tabs-panel') !== null) {
          if (this.collapsibleContainer.closest('.Tabs-panel') !== null) {
            carouselProps.carousel_title = this.collapsibleContainer
              .closest('.Tabs-panel')
              .getAttribute('id')
          }
        }
      }

      this.carousel.on('change', () => {
        EventTracking('Click Action', carouselProps)
      })
    }

    // carousel ajax
    if (
      this.carouselContainer.querySelector('[class$="Carousel-loadMore"]') !==
        null &&
      this.carouselContainer.closest('.Enhancement') === null
    ) {
      this.carousel.on('settle', () => {
        let loadMoreEl = this.carouselContainer.querySelector(
          '[class$="Carousel-loadMore"]'
        )
        // We are assuming one link will be in pagination (the next group from 1 - 12) and we
        // want to only ajax the next 12 if pagination exists and we are 4 or less cards away from the end
        if (
          loadMoreEl !== null &&
          (this.carousel.selectedIndex + 1) * this.group >=
            this.carousel.cells.length - 4
        ) {
          let loadMoreUrl = loadMoreEl.getAttribute('href')
          this.fetchPagination(loadMoreUrl, loadMoreEl)
        }
      })
    }

    /* fix flickity's broken aria by removing it */
    const settleAria = selected => {
      this.element.removeAttribute('tabIndex')

      for (const pres of this.element.querySelectorAll(
        '.flickity-viewport, .flickity-slider'
      )) {
        pres.setAttribute('role', 'presentation')
      }

      let current = 0

      for (const cell of this.carousel.cells) {
        if (this.isMosiac) {
          cell.element.removeAttribute('aria-hidden')

          cell.element.setAttribute('tabindex', '-1')
        } else {
          cell.element.setAttribute(
            'aria-label',
            `${current + 1} of ${this.carousel.cells.length}`
          )

          cell.element.removeAttribute('tabIndex')

          const tabIndex =
            current >= selected * this.group &&
            current < (selected + 1) * this.group
              ? '0'
              : '-1'

          for (const a of cell.element.querySelectorAll('a')) {
            a.setAttribute('tabIndex', tabIndex)

            a.removeAttribute('aria-hidden')
          }

          cell.element.removeAttribute('aria-hidden')
        }

        current++
      }

      // const slides = Array.from(this.element.querySelectorAll(`.flickity-slider > *`))

      // const total = slides.length / this.group

      // const toFocus = selected + 1 < total ? slides[selected * this.group] : slides[slides.length - this.group]

      // if (toFocus) {
      //   let el = toFocus

      //   if (!el.matches('.Card')) {
      //     el = el.querySelector('.Card')
      //   }

      //   if (el) {
      //     el.focus()
      //   }
      // }

      // this.carousel.cells[selected * this.group].element.querySelector('[tabindex="0"]').focus()
    }

    this.carousel.on('settle', settleAria)

    settleAria(0)
  }

  removeElement(el) {
    el.parentNode.removeChild(el)
  }

  fetchPagination(url, loadMoreEl) {
    window
      .fetch(url, { credentials: 'include', mode: 'cors' })
      .then(response => {
        return response.text()
      })
      .then(body => {
        const parser = new window.DOMParser()
        const bodyHtml = parser.parseFromString(body, 'text/html')
        const containsDynamicCta =
          bodyHtml.querySelector('dynamic-cta') !== null
        const listItems = bodyHtml.querySelectorAll('[role="listitem"]')
        const newLoadMore = bodyHtml.querySelector(
          '[class$="Carousel-loadMore"]'
        )
        this.removeElement(loadMoreEl)
        this.carousel.append(listItems)

        if (containsDynamicCta) {
          DynamicCta.dynamicCtasLoaded()
        }
        DynamicSalePrice.dynamicPricesLoaded()

        if (newLoadMore !== null) {
          this.carouselContainer.insertAdjacentHTML(
            'beforeend',
            newLoadMore.outerHTML
          )
        }
      })
  }

  initCarousel() {
    if (this.carousel) {
      this.destroyCarousel()
      setTimeout(() => {
        this.buildCarousel()
      }, 0)
    } else {
      this.buildCarousel()
    }
  }

  imageLoadEvent() {
    // Each promo in a carousel has 1 img element inside each picture element.
    // we need to check the load of both because depending on the carousel type,
    // only one of them will load. Whichever one is loaded will determine the
    // size of the rest of the carousel, so we are calling flickity resize.
    if (typeof this.elImg[0] !== 'undefined') {
      this.elImg[0].addEventListener('load', () => {
        this.resizeCarousel()
      })
    }

    if (typeof this.elImg[1] !== 'undefined') {
      this.elImg[1].addEventListener('load', () => {
        this.resizeCarousel()
      })
    }
  }
}
