import { GetUrlParams } from '../../utils/UrlUtils'

export class AutofillInputs {
  static selectors = {
    el: '[data-autofill-inputs]'
  }

  constructor(el) {
    this.el = el
    const searchString = window.location.search

    if (searchString !== '') {
      this.matchKeyToInput(searchString)
    }
  }

  matchKeyToInput(searchString) {
    searchString = searchString.substring(1)
    let searchParams = new window.URLSearchParams(searchString)
    for (var key of searchParams.keys()) {
      if (this.el.querySelector(`input[name="${key}"]`) !== null) {
        const input = this.el.querySelector(`input[name="${key}"]`)
        input.setAttribute('value', decodeURIComponent(GetUrlParams(key)))
      }
    }
  }
}
