/* eslint-disable no-unused-vars */
import { CardCarousel } from '../list/CardCarousel'

const TABS = '[data-tab]'
const TAB_PANELS = '[data-tab-panel]'
const DATA_ACTIVE = 'data-active'
const DATA_VISIBLE = 'data-visible'
/**
 * @module Tabs
 * Transforms a set of DOM elements into a tabbed widget, which is
 * a horizontal navigation (tabs) that shows/hides content in
 * container Elements (elTabPanels).
 *
 * Panels respond to URL hash changes and not event click handlers,
 * so that when the page is first loaded, the correct panel will be displayed if there is
 * a #id in the URL that matches an on a Tabs-panel. When the user
 * clicks on a navigation link in a Tab, the natural behavior of
 * the browser takes over and appends the href # to the URL. This
 * allows the page with the open tab to be stored in history, bookmarkable,
 * and shareable.
 *
 * Note: The Tabs Tab text is denoted in the JSON as 'label'. 'title'
 * represents the title of the module to be displayed on the UI.
 *
 * @example - Minimum Markup Needed
 * <div class="Tabs" data-bsp-plugin="Tabs">
 *  <ul class="Tabs-tabs">
 *    <li>
 *      <a class="Tabs-tab" href="#1" data-active="true">Kiaf</a>
 *    </li>
 *  </ul>
 *  <div class="Tabs-content">
 *    <div class="Tabs-panel" id="1" data-visible="true">
 *      <div class="TabItem">
 *        <div class="TabItem-content"></div>
 *      </div>
 *    </div>
 *  </div>
 * </div>
 */
export class Tabs {
  constructor(el) {
    /**
     * @property {!Element} - The root Tabs DOM Element.
     */
    this.el = el
    this.clicked = false
    this.init()
    this.setBinds()
  }

  init() {
    /**
     * @property {Array.<Element>} - The tab DOM Elements that
     * the user selects to swap the content views.
     */
    this.elTabs = [...this.el.querySelectorAll(TABS)]

    /**
     * @property {Array.<Element>} - The tab panels DOM Elements,
     * which contain the Tab content.
     */
    this.elTabPanels = [...this.el.querySelectorAll(TAB_PANELS)]

    if (
      !this.elTabs ||
      !this.elTabs.length ||
      !this.elTabPanels ||
      !this.elTabPanels.length
    ) {
      console.error('DOM elements for Tabs are missing, failing fast.')
    }

    /**
     * @property {number|text|null} - The tab panels ID that is active/visible
     * to the user.
     */
    this.setActivePanel() // context is this module because it is called here
  }

  /**
   * Sets the active panel for the tab group if at least one element in that
   * group contains the new hash.
   *
   * @this refers to the Tabs class. If this function is being called in an
   * event listener, the Tabs class context must be bound.
   *
   * @example - Attaching to 'hashchange' From Tabs Module
   * window.addEventListener('hashchange', this.setActivePanel.bind(this))
   */
  setActivePanel() {
    const newHash = window.decodeURIComponent(window.location.hash).substring(1)
    if (
      this.elTabs.filter(t => t.getAttribute('href').substr(1) === newHash)
        .length > 0
    ) {
      this.activePanel = newHash
    } else {
      if (!this.activePanel) {
        this.activePanel = this.elTabPanels[0].id
      }
    }
  }

  /**
   * Attaches the onchashchange listener to the window and
   * sets the activePanel class property to the hash value, then
   * calls the method to set the active tabs and panels.
   */
  setBinds() {
    window.addEventListener('hashchange', this.setActivePanel.bind(this))
    this.elTabs.forEach(elTab => {
      elTab.addEventListener('click', e => {
        if (elTab.getAttribute('href').substr(1) === this.activePanel) {
          e.preventDefault()
        } else {
          let activePanel = document.getElementById(this.activePanel)
          let carousels = this.el.querySelectorAll('[data-card-carousel]')
          if (activePanel.querySelector('[data-card-carousel]') !== null) {
            this.destroyCarousels(carousels)
          }
          this.clicked = true
        }
      })
    })
  }

  /**
   * Sets the active/visibility of the tabs and panels based on whether
   * the tab/panel is the same as the active panel.
   *
   * Note: This could be abstracted
   * out into one generic for each function, but leaving this broken out into
   * two calls for readability.
   */
  set activePanel(val) {
    if (this._activePanel !== val) {
      this._activePanel = val

      this.elTabs.forEach((elTab, index) => {
        const HASH_LENGTH = elTab.getAttribute('href').substr(1).length
        elTab.setAttribute(
          DATA_ACTIVE,
          val.substr(0, HASH_LENGTH) === elTab.getAttribute('href').substr(1)
        )
      })

      this.elTabPanels.forEach((elTabPanel, index) => {
        const HASH_LENGTH = elTabPanel.id.length
        elTabPanel.setAttribute(
          DATA_VISIBLE,
          val.substr(0, HASH_LENGTH) === elTabPanel.id
        )
        if (this.clicked) {
          let carousels = elTabPanel.querySelectorAll('[data-card-carousel]')
          this.initCarousels(carousels)
        }
      })
    }
    return this._activePanel
  }

  get activePanel() {
    return this._activePanel
  }

  destroyCarousels(carousels) {
    carousels.forEach(carouselEl => {
      carouselEl.cardCarouselAPI.destroyCarousel()
    })
  }

  initCarousels(carousels) {
    carousels.forEach(carouselEl => {
      if (carouselEl.offsetParent !== null) {
        let carousel = new CardCarousel(carouselEl)
      }
    })
  }
}
